
import React, {useEffect, useRef} from "react";
import BadgeContainer from "@/components/Badges/BadgeContainer";
import StatusBadge from "@/components/Badges/StatusBadge";
import {
  DeviceStatus,
  MaintenanceReason,
  MaintenanceStatus,
} from "@/models/Enums";
import EnumProvider from "@/providers/EnumProvider";
import {DeviceMaintenance, DeviceMaintenanceReason} from "@/models/DeviceMaintenance";
import UnleashHelper from "@/helpers/UnleashHelper";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import Localizer from "@/localization/Localizer";
import ToolsUtility from "@/helpers/ToolsUtility";
import {Dropdown, Modal} from "@renta-apps/athenaeum-react-components";
import styles from './EditDeviceStatus.module.scss';
import Device from "@/pages/Models/Device";
import AddPendingStatusModal from "@/pages/EditDevicePage/components/AddPendingStatusModal";
import AddMaintenanceReasonModal from "@/pages/EditDevicePage/components/AddMaintenanceReasonModal";
import Loader from "@/components/Loader/Loader";
import RentaToolsController from "@/pages/RentaToolsController";
import ServiceReportDefinition from "@/pages/Models/ServiceReportDefinition";
import {EditDeviceStatusRequest} from "@/models/server/requests/EditDeviceStatusRequest";
import {FeatureSwitch} from "@/providers/FeatureSwitch";
export interface IEditDeviceStatusProps {
  device: Device | null;
  setRequest : (request : EditDeviceStatusRequest) => void;
}

const EditDeviceStatus = ({ device, setRequest }: IEditDeviceStatusProps) => {
  const [maintenanceReasons, setMaintenanceReasons] = React.useState<DeviceMaintenanceReason[]>([]);
  const [maintenanceStatus, setMaintenanceStatus] = React.useState<DeviceMaintenance[]>([]);
  const [deviceStatus, setDeviceStatus] = React.useState<DeviceStatus>(DeviceStatus.InStock);
  const ref  = useRef<Modal>(null);
  const maintenanceReasonsRef  = useRef<Modal>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [reportDefinition, setReportDefinition] = React.useState<ServiceReportDefinition | null>(null);
  
    useEffect(() => {
        setIsLoading(true);
        
        if(device) {
            const reasons: DeviceMaintenanceReason[] = [];
            
            if(device.serviceReportDefinitionId) {
                RentaToolsController.findServiceReportDefinitionAsync(device.serviceReportDefinitionId)
                    .then((reportDefinition) => {
                        setReportDefinition(reportDefinition);
                    }).finally(() => {
                        setIsLoading(false);
                    });
            } else {
                setIsLoading(false);
            }
            
            let maintenance: MaintenanceReason[] = device.maintenance;

            for (let i = 0; i < device.extendedMaintenanceReasons.length; i++) {
                const extendedMaintenanceReason = device.extendedMaintenanceReasons[i].maintenanceReasons.filter(item => item > MaintenanceReason.Service);

                maintenance = maintenance.filter(item => !extendedMaintenanceReason.includes(item));
                
                reasons.push(...extendedMaintenanceReason.map(item => ({
                    reasonText: EnumProvider.getMaintenanceReasonText(item),
                    reason: item,
                    typeId: device.extendedMaintenanceReasons[i].typeId,
                    definitionName: device.extendedMaintenanceReasons[i].definitionTypeName
                })));
            }
            
            for (let item of maintenance) {
                const reason: DeviceMaintenanceReason = {
                    reasonText: EnumProvider.getMaintenanceReasonText(item),
                    reason: item
                }

                reasons.push(reason);
            }

            setMaintenanceReasons(reasons);

            const deviceMaintenanceStatus: DeviceMaintenance[] = [];

            const serviceAndRepairEnabled = UnleashHelper.isEnabled(RentaToolsConstants.featureFlagServiceAndRepairEnabled);

            const status: MaintenanceStatus = (!serviceAndRepairEnabled)
                ? device.maintenanceStatus & MaintenanceStatus.RequiresReturnInspection
                : device.maintenanceStatus;
            
            if (ToolsUtility.hasFlag(status, MaintenanceStatus.RequiresRepair)) {
                deviceMaintenanceStatus.push({
                    reasonText: Localizer.enumMaintenanceReasonRepair,
                    reason: MaintenanceStatus.RequiresRepair
                });
            }

            const annualInspectionsEnabled = UnleashHelper.isEnabled(RentaToolsConstants.featureFlagAnnualInspectionsEnabled);
            if (annualInspectionsEnabled && ToolsUtility.hasFlag(status, MaintenanceStatus.RequiresAnnualInspection)) {
                deviceMaintenanceStatus.push({
                    reasonText: Localizer.genericAnnualInspection,
                    reason: MaintenanceStatus.RequiresAnnualInspection
                });
            }

            if (ToolsUtility.hasFlag(status, MaintenanceStatus.RequiresReturnInspection)) {
                deviceMaintenanceStatus.push({
                    reasonText: Localizer.enumReportDefinitionTypeReturnInspection,
                    reason: MaintenanceStatus.RequiresReturnInspection
                });
            }

            if (ToolsUtility.hasFlag(status, MaintenanceStatus.RequiresService)) {
                deviceMaintenanceStatus.push({
                    reasonText: Localizer.enumMaintenanceReasonService,
                    reason: MaintenanceStatus.RequiresService
                });
            }
            
            setMaintenanceStatus(deviceMaintenanceStatus);
           
            setDeviceStatus(device.status);
        }
    }, []);
  
  const maintenanceStatusChanged = (incomingMaintenanceStatus : MaintenanceStatus) : DeviceMaintenance[] => {
      const deviceMaintenanceStatus: DeviceMaintenance[] = [];
      
      const serviceAndRepairEnabled = UnleashHelper.isEnabled(RentaToolsConstants.featureFlagServiceAndRepairEnabled);

      const maintenanceStatus: MaintenanceStatus = (!serviceAndRepairEnabled)
          ? incomingMaintenanceStatus & MaintenanceStatus.RequiresReturnInspection
          : incomingMaintenanceStatus;

      if (ToolsUtility.hasFlag(maintenanceStatus, MaintenanceStatus.RequiresRepair)) {
          deviceMaintenanceStatus.push({
              reasonText: Localizer.enumMaintenanceReasonRepair,
              reason: MaintenanceStatus.RequiresRepair
          });
      }

      const annualInspectionsEnabled = UnleashHelper.isEnabled(RentaToolsConstants.featureFlagAnnualInspectionsEnabled);
      if (annualInspectionsEnabled && ToolsUtility.hasFlag(maintenanceStatus, MaintenanceStatus.RequiresAnnualInspection)) {
          deviceMaintenanceStatus.push({
              reasonText: Localizer.genericAnnualInspection,
              reason: MaintenanceStatus.RequiresAnnualInspection
          });
      }

      if (ToolsUtility.hasFlag(maintenanceStatus, MaintenanceStatus.RequiresReturnInspection)) {
          deviceMaintenanceStatus.push({
              reasonText: Localizer.enumReportDefinitionTypeReturnInspection,
              reason: MaintenanceStatus.RequiresReturnInspection
          });
      }

      if (ToolsUtility.hasFlag(maintenanceStatus, MaintenanceStatus.RequiresService)) {
          deviceMaintenanceStatus.push({
              reasonText: Localizer.enumMaintenanceReasonService,
              reason: MaintenanceStatus.RequiresService
          });
      }
          
      return deviceMaintenanceStatus;
  }
    
  const removeMaintenanceReasons = (reason : string) => {
      const maintenanceReasonsCopy = [...maintenanceReasons];
      const maintenanceReason = maintenanceReasonsCopy.find(p=> getReasonText(p) === reason);
      if(maintenanceReason) {
          const index = maintenanceReasonsCopy.indexOf(maintenanceReason);
          if (index > -1) {
              maintenanceReasonsCopy.splice(index, 1);
              setMaintenanceReasons(maintenanceReasonsCopy);
          }
      }
  }
  
  const removeMaintenanceStatus = (reason : string) => {
      const maintenanceStatusCopy = [...maintenanceStatus];
      const status = maintenanceStatusCopy.find(p => p.reasonText === reason);
      if (status) {
          const index = maintenanceStatusCopy.indexOf(status);
          if (index > -1) {
              maintenanceStatusCopy.splice(index, 1);
              setMaintenanceStatus(maintenanceStatusCopy);
          }
      }
  }
  
  const onModalClose = (inconmingMaintenanceStatus : MaintenanceStatus | null) => {
      if(inconmingMaintenanceStatus) {
            const maintenance = maintenanceStatusChanged(inconmingMaintenanceStatus);
            const maintenanceStatusCopy = [...maintenanceStatus];
          
            for (let item of maintenance) {
                if (!maintenanceStatusCopy.some(p => p.reason === item.reason)) {
                    maintenanceStatusCopy.push(item);
                }
            }
            
            setMaintenanceStatus(maintenanceStatusCopy);  
      }
  }
  
  const onMaintenanceModalClose = (incomingMaintenanceReason : DeviceMaintenanceReason | null) => {
    if(incomingMaintenanceReason){
        const maintenanceReason :DeviceMaintenanceReason = {
            reasonText: EnumProvider.getMaintenanceReasonText(incomingMaintenanceReason.reason),
            reason: incomingMaintenanceReason.reason,
            typeId: incomingMaintenanceReason.typeId,
            definitionName: incomingMaintenanceReason.definitionName,
        }

        const maintenanceReasonsCopy = [...maintenanceReasons];
        if (!maintenanceReasonsCopy.some(p => getReasonText(p) === getReasonText(maintenanceReason))) {
            maintenanceReasonsCopy.push(maintenanceReason);
            setMaintenanceReasons(maintenanceReasonsCopy);
        }
    }
  }
  
  const getReasonText = (reason : DeviceMaintenanceReason) : string => {
        return reason.definitionName ? `${reason.reasonText} (${reason.definitionName})` : reason.reasonText;
  }
  
  
  const updateRequest = () => {
        const request : EditDeviceStatusRequest = {
            deviceExternalId: device?.externalId || "",
            deviceStatus: deviceStatus,
            maintenanceStatus: maintenanceStatus.map(p => p.reason),
            maintenanceReasons: maintenanceReasons.map(p => ({
                reason: p.reason,
                typeId: p.typeId,
                definitionName: p.definitionName
            }))
        }
        
        setRequest(request);
  }

    useEffect(() => {
        updateRequest();
    }, [deviceStatus, maintenanceStatus, maintenanceReasons]);

  return (
    <div className={styles.editDeviceStatusContainer}>
        <Loader isLoading={isLoading}/>
        <AddPendingStatusModal modalRef={ref} onModalClose={onModalClose} />
        <AddMaintenanceReasonModal modalRef={maintenanceReasonsRef} onModalClose={onMaintenanceModalClose} reportDefinition={reportDefinition} />
        <FeatureSwitch flagName={RentaToolsConstants.featureFlagEditDeviceStatus}>
         <div className={styles.statusContainer}>
             <span className={styles.titleWrapper}>{Localizer.devicePageMaintenanceStatus}</span>
             <Dropdown
                id={"deviceStatuses"}
                name="deviceStatuses"
                nothingSelectedText={"-"}
                disabled={device?.status === DeviceStatus.InRent}
                items={EnumProvider.getDeviceStatusItems().filter(p => p.value !== DeviceStatus.InRent.toString())}
                value={deviceStatus.toString()}
                selectedItem={deviceStatus.toString()}
                onChange={async (sender, item, userInteraction) => {
                    if(item){
                        setDeviceStatus(parseInt(item.value));
                    }
                }}
             />
         </div>
        </FeatureSwitch>

        <div className={styles.statusContainer}>
            <span className={styles.titleWrapper}>{Localizer.genericPending}:</span>
            <BadgeContainer addBadge={() => {ref.current?.openAsync()}} renderAddBadge={UnleashHelper.isEnabled(RentaToolsConstants.featureFlagAddNewMaintenance)}>
                {maintenanceStatus.map((reason, index) => (
                    <StatusBadge reason={reason.reasonText} onRemove={removeMaintenanceStatus} key={index} />
                ))}
            </BadgeContainer>
        </div>
        
        <FeatureSwitch flagName={RentaToolsConstants.featureFlagServiceAndRepairEnabled}>
            <div className={styles.statusContainer}>
                <span className={styles.titleWrapper}>{Localizer.devicePageMaintenanceReasons}</span>
                <BadgeContainer addBadge={() => {maintenanceReasonsRef.current?.openAsync()}} renderAddBadge={UnleashHelper.isEnabled(RentaToolsConstants.featureFlagAddNewMaintenance)}>
                    {maintenanceReasons.map((reason, index) => (
                        <StatusBadge reason={getReasonText(reason)} onRemove={removeMaintenanceReasons} key={index} />
                    ))}
                </BadgeContainer>
            </div>
        </FeatureSwitch>
    </div>
  );
};

export default EditDeviceStatus;
