import DeviceInfo, {ReturnInspectionSteps} from "../../models/server/DeviceInfo";
import IActivePhase from "../../models/IActivePhase";
import UnleashHelper from "../../helpers/UnleashHelper";
import RentaToolsConstants from "../../helpers/RentaToolsConstants";
import Localizer from "../../localization/Localizer";
import styles from "./ReturnInspectionDevicesList.module.scss";
import {MaintenanceStatus, ReturnInspectionStatus} from "../../models/Enums";
import TransformProvider from "../../providers/TransformProvider";
import ToolsUtility from "../../helpers/ToolsUtility";
import React from "react";
interface PhaseInfoProps {
    device: DeviceInfo;
    returnInspectionList: boolean;
    shortView: boolean;
}

const PhaseInfo : React.FC<PhaseInfoProps> = ({ device, returnInspectionList, shortView }) => {
    const hasWaitingInspection = (device: DeviceInfo): boolean => {
        return ToolsUtility.hasFlag(device.maintenanceStatus, MaintenanceStatus.RequiresReturnInspection) && !device.underInspection;
    };
    
    const getUserName = (device: DeviceInfo): string => {
        if (device.returnInspectionStatus === ReturnInspectionStatus.PartlyCompleted || device.returnInspectionStatus === ReturnInspectionStatus.Completed) {
            return device.modifiedBy ? TransformProvider.userToString(device.modifiedBy, true) : "";
        }
        return device.currentInspectionUser ? TransformProvider.userToString(device.currentInspectionUser, true) : "";
    };
    
    const getActivePhase = (items: ReturnInspectionSteps): IActivePhase => {
        if (!UnleashHelper.isEnabled(RentaToolsConstants.featureFlagShowPhasesEnabled)) {
            return { phases: [items.steps], activePhase: items.steps };
        }
        const phases = items.steps.groupBy((p) => p.phaseId);
        let activePhase = phases.filter((p) => p.firstOrDefault((p) => !p.isCompleted) != null)[0];
        if (activePhase == null) {
            activePhase = phases[phases.length - 1];
        }
        return { phases: phases, activePhase };
    };
    
    if (device.returnInspectionSteps != null && returnInspectionList && !hasWaitingInspection(device) && shortView) {
        const item = device.returnInspectionSteps!;
        const phasesResult = getActivePhase(item);
        const phases = phasesResult.phases;
        const activePhase = phasesResult.activePhase;
        const stepCompletedCount = activePhase.filter((p) => p.isCompleted).length;
        const hasMorePhases = phases.length > 1;

        return (
            <>
                {UnleashHelper.isEnabled(RentaToolsConstants.featureFlagShowPhasesEnabled) && (
                    <>
                        {hasMorePhases && (
                            <>
                                <p>{Localizer.genericPhase}</p>
                                <p className={styles.fontBold}>{activePhase[0].phaseName}</p>
                            </>
                        )}
                        {!hasMorePhases && <p>{Localizer.returnInspectionHasNoPhases}</p>}
                    </>
                )}
                <p>
                    {Localizer.returnInspectionSteps} {stepCompletedCount}/{activePhase.length}
                </p>
                <p className={`${styles.fontBold} ${styles.mechanicName}`}>{getUserName(device)}</p>
            </>
        );
    }
    return null;
};

export default PhaseInfo;