import {IOperatingHoursRecord} from "@/models/server/DeviceOperatingHourRecordsResponse";
import ToolsUtility from "@/helpers/ToolsUtility";
import {RecordOperatingHoursSource} from "@/models/Enums";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import OperatingHourRecordSourceName
    from "@/components/OperatingHourRecordsTable/components/OperatingHourRecordSourceName";
import RentaToolsController from "@/pages/RentaToolsController";
import Localizer from "@/localization/Localizer";

import styles from './OperatingHoursRecordsTable.module.scss';

export interface IOperatingHourRecordsTableProps{
    records : IOperatingHoursRecord[];
}

const OperatingHourRecordsTable = ({ records } : IOperatingHourRecordsTableProps) => {

    const goToSourceRecord = async (item: IOperatingHoursRecord): Promise<void> => {
        switch (item.sourceType) {
            case RecordOperatingHoursSource.Service:
                const serviceReportId: string = item.sourceId;

                RentaToolsController.servicePreview = await RentaToolsController.getServiceReportAsync(serviceReportId);

                await PageRouteProvider.redirectAsync(PageDefinitions.servicePreviewRoute);
                break;
            case RecordOperatingHoursSource.ReturnInspection:
                const returnInspectionId: string = item.sourceId;

                RentaToolsController.reportPreview = await RentaToolsController.getReportAsync(returnInspectionId);

                await PageRouteProvider.redirectAsync(PageDefinitions.reportPreviewRoute);
                break;
            case RecordOperatingHoursSource.AnnualInspection:
                // No preview page for annual inspections
                await PageRouteProvider.redirectAsync(PageDefinitions.devicePreviousAnnualInspectionsRoute);
                break;
        }
    }

    return (
        <table className={styles.OperatingHoursTable}>
            <thead>
            <tr className={styles.operatingHoursTitle}>
                <td className={styles.titleTd}>{Localizer.operatingHoursHistoryPageOperatingHours}</td>
                <td className={styles.titleTd}>{Localizer.operatingHoursHistoryOdometerOph}</td>    
                <td className={styles.titleTd}>{Localizer.operatingHoursHistoryPageRecordDate}</td>
                <td className={styles.titleTd}>{Localizer.operatingHoursHistoryPageSource}</td>
            </tr>
            </thead>
            <tbody>
            {records.map((item, index) => (
                <tr key={index}
                    className={index % 2 === 0 ? styles.orangeRecord : styles.normalRecord}
                    onClick={async () => await goToSourceRecord(item)}>
                    <td className={styles.titleTd}>{"{0:0.00}".format(item.operatingHours)}h</td>
                    <td className={styles.titleTd}>{item.odometerOperatingHours ? "{0:0.00}".format(item.odometerOperatingHours) : "-"}</td>
                    <td className={styles.titleTd}>{ToolsUtility.toDateString(item.recordDate)}</td>
                    <td className={styles.titleTd}><OperatingHourRecordSourceName sourceType={item.sourceType}/></td>
                </tr>
            ))
            }
            </tbody>
        </table>
    )
}

export default OperatingHourRecordsTable;