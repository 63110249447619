import QuestionReportItem from "./QuestionReportItem";
import {ResourceItemType} from "@/models/Enums";
import NumbersHelper from "@/helpers/NumbersHelper";

export default class QuestionResourceReportItem extends QuestionReportItem {

    public value: number = 0;

    public invoiced: boolean | null = null;
    
    public totalValue : number | null = null;

    public static isValid(item: QuestionResourceReportItem): boolean {
        if (QuestionReportItem.isValid(item)) {
            if (item.resourceType === ResourceItemType.Operating) {
                const minValueOk: boolean = item.min == null || NumbersHelper.greaterOrEqual(item.value, item.min);
                const maxValueOk: boolean = item.max == null || NumbersHelper.lessOrEqual(item.value, item.max);

                return minValueOk && maxValueOk;
            } else {
                if (item.value === 0) {
                    return true;
                }

                return !!item.valueTypeId && (!item.min || item.min <= item.value);
            }
        }

        return false;
    }
}