import BaseController from "./BaseController";
import EndpointPaths from "../common/EndpointPaths";
import ListContractsRequest from "@/models/server/requests/ListContractsRequest";
import ListContractsResponse from "@/models/server/responses/ListContractsResponse";
import ContractDetailsRequest from "@/models/server/requests/ContractDetailsRequest";
import ContractDetailsResponse from "@/models/server/responses/ContractDetailsResponse";
import EditContractDetailsRequest from "@/models/server/requests/EditContractDetailsRequest";
import SyncContractRequest from "@/models/server/requests/SyncContractRequest";
import HttpClient from "@/common/HttpClient";
import SyncContractResponse from "@/models/server/responses/SyncContractResponse";

class ContractsController extends BaseController {

    public async listContractsAsync(request: ListContractsRequest): Promise<ListContractsResponse> {
        return await this.postAsync(EndpointPaths.Contracts.ListContracts, request);
    }

    public async fetchContractDetailsAsync(request: ContractDetailsRequest): Promise<ContractDetailsResponse> {
        return await this.postAsync(EndpointPaths.Contracts.ContractDetails, request);
    }

    public async editContractDetailsAsync(request: EditContractDetailsRequest): Promise<void> {
        return await this.postAsync(EndpointPaths.Contracts.EditContract, request);
    }

    public async syncContractAsync(request: SyncContractRequest): Promise<SyncContractResponse> {
        return await HttpClient.postAsync<SyncContractResponse>(EndpointPaths.Contracts.SyncContract, request);
    }
}

export default new ContractsController();