import React from "react";
import {
    PageContainer,
    PageHeader,
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import Localizer from "../../localization/Localizer";
import ApplicationSettings from "@/components/ApplicationSettings/ApplicationSettings";

export default class ApplicationSettingsPage extends AuthorizedPage {

    public getTitle(): string {
        return Localizer.topNavAdmin;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>

                <PageHeader title={Localizer.applicationSettingsPageTitle} />

                <ApplicationSettings />

            </PageContainer>
        );
    }
}