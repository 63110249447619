import AuthorizedPage from "@/models/base/AuthorizedPage";
import React from "react";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import newStyles from "@/pages/NewUI.module.scss";
import pageStyles from "@/pages/DataManagementPage/DataManagementPage.module.scss";
import {ButtonType, PageContainer} from "@renta-apps/athenaeum-react-components";
import RentaToolsController from "@/pages/RentaToolsController";
import DeviceInfoPanel from "@/components/DeviceInfoPanel/DeviceInfoPanel";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import Localizer from "@/localization/Localizer";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import styles from './EditDevicePage.module.scss';
import EditDeviceStatus from "@/pages/EditDevicePage/components/EditDeviceStatus";
import {EditDeviceStatusRequest} from "@/models/server/requests/EditDeviceStatusRequest";
import HttpClient from "@/common/HttpClient";
import {EditDeviceResponse} from "@/models/server/responses/EditDeviceResponse";
import EndpointPaths from "@/common/EndpointPaths";

export interface IEditDevicePageProps {
}

export interface IEditDevicePageState {
    request : EditDeviceStatusRequest;
}

export default class EditDevicePage extends AuthorizedPage<IEditDevicePageProps, IEditDevicePageState> {
    
    public setRequest(request : EditDeviceStatusRequest){
        if(request) {
            this.setState({request});
        }
    }
    
    public async onSave() {
        const response = await HttpClient.postAsync<EditDeviceResponse>(EndpointPaths.DevicePaths.EditDevice, this.state.request);
        if(response.success){
           RentaToolsController.device = response.device;
           RentaToolsController.saveContext();
           PageRouteProvider.back();
        }
    }
    
    
    public render(): React.ReactNode {
        return (
            <PageContainer
                className={this.css(
                    rentaToolsStyles.pageContainer,
                    newStyles.pageContainer,
                    pageStyles.searchResults, 
                )}
            >
                <DeviceInfoPanel device={RentaToolsController.device!} renderInfo={() => null}  />
                <EditDeviceStatus device={RentaToolsController.device!} setRequest={(request : EditDeviceStatusRequest) => this.setRequest(request)} />
                
                <div className={styles.buttonContainer}>
                    <ArsenalButton
                        block
                        big
                        type={ButtonType.Blue}
                        className={rentaToolsStyles.arsenalButton}
                        label={Localizer.genericSave}
                        onClick={async () => this.onSave()}
                    />
    
                    <ArsenalButton
                        block
                        big
                        type={ButtonType.Orange}
                        className={rentaToolsStyles.arsenalButton}
                        label={Localizer.devicePageReturnBack}
                        onClick={async () => PageRouteProvider.back()}
                    />
                </div>
            
            </PageContainer>
        )
    }
}