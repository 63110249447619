import React from "react";
import {NumberWidget} from "@renta-apps/athenaeum-react-components";
import MeasuringReportItem from "@/pages/Models/MeasuringReportItem";
import ReturnInspectionWizardPage from "@/pages/ReturnInspectionWizardPage";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import IReturnInspectionWizardPageState from "@/models/base/IReturnInspectionWizardPageState";
import RentaToolsController from "../RentaToolsController";

import rentaToolsStyles from "../RentaTools.module.scss";
import newStyles from "../NewUI.module.scss";
import {ch} from "@renta-apps/athenaeum-react-common";
import {ResourceItemType} from "@/models/Enums";
import styles from "@/pages/ResourcePage/ResourcePage.module.scss";
import Localizer from "@/localization/Localizer";

export interface IMeasuringResourcePageProps {
}

interface IMeasuringResourcePageState extends IReturnInspectionWizardPageState {
}

export default class MeasuringResourcePage extends ReturnInspectionWizardPage<IMeasuringResourcePageProps, IMeasuringResourcePageState> {

    state: IMeasuringResourcePageState = {};

    private async setValueAsync(value: number): Promise<void> {
        this.reportItem.value = value;
        RentaToolsController.saveContext();
    }

    private get reportItem(): MeasuringReportItem {
        return this.getReportItem<MeasuringReportItem>();
    }

    private get min(): number {
        return (this.reportItem.min == null) ? RentaToolsConstants.intMinValue : this.reportItem.min;
    }

    private get max(): number {
        return (this.reportItem.max == null) ? RentaToolsConstants.intMaxValue : this.reportItem.max;
    }

    private get displayTrackUnitHours(): boolean {
        return !ch.isNorway;
    }

    public getWizardDescription(): string {
        return "";
    }

    public get reportItemValueOrDefault(): number {

        if (ch.isNorway && this.report.trackUnitOperatingHours && this.reportItem.resourceType === ResourceItemType.Operating) {
            return Number(this.report.trackUnitOperatingHours.toFixed(2));
        }

        return (this.reportItem.value != null) ? this.reportItem.value! : this.reportItem.default!;
    }

    private get trackUnitOperatingHoursText(): string {
        return `${Localizer.genericOperatingHoursFromTrackUnit}: ${this.report.trackUnitOperatingHours?.toFixed(1)}`;
    }

    public async nextAsync(): Promise<void> {
        this.reportItem.ok = MeasuringReportItem.isOk(this.reportItem)
        RentaToolsController.saveContext();
        await super.nextAsync();
    }

    public renderContent(): React.ReactNode {

        return (
            <React.Fragment>

                {
                    (this.displayTrackUnitHours && this.reportItem.resourceType == ResourceItemType.Operating && this.report.trackUnitOperatingHours) &&
                    (
                        <p className={styles.numberWidgetExtraText}>
                            {this.trackUnitOperatingHoursText}
                        </p>
                    )
                }

                <NumberWidget className={this.css(rentaToolsStyles.arsenalNumberWidget, newStyles.numberWidget)}
                              format={((this.reportItem.step != null) && (this.reportItem.step >= 1.0)) ? "0" : "0.0"}
                              step={this.reportItem.step || 0.0}
                              min={this.min}
                              max={this.max}
                              readonly={this.preview}
                              value={this.reportItemValueOrDefault}
                              onChange={async (sender, value) => await this.setValueAsync(value)}/>

            </React.Fragment>
        );
    }
}