import BaseController from "./BaseController";
import EndpointPaths from "../common/EndpointPaths";
import ListContractInvoicesResponse from "@/models/server/responses/ListContractInvoicesResponse";
import ListContractInvoicesRequest from "@/models/server/requests/ListContractInvoicesRequest";

class InvoiceController extends BaseController {
    public async listContractInvoicesAsync(request: ListContractInvoicesRequest): Promise<ListContractInvoicesResponse> {
        return await this.postAsync(EndpointPaths.InvoicePaths.ListContractInvoices, request);
    }
}

export default new InvoiceController();