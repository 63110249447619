import React from "react";
import {
    PageContainer,
    PageHeader,
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import Localizer from "../../localization/Localizer";

import styles from "./DevicesManagementPage.module.scss";
import rentaToolsStyles from "../RentaTools.module.scss";
import DeviceManagement from "@/components/DeviceManagement/DeviceManagement";

export default class DevicesManagementPage extends AuthorizedPage {
    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(styles.devices)} scale>
                <PageHeader title={Localizer.devicesManagementPageTitle}/>

                <DeviceManagement />
            </PageContainer>
        );
    }

}