import React, {FC} from "react";
import DeviceContractView from "@/models/server/DeviceContractView";
import {ThreeColumns} from "@renta-apps/athenaeum-react-components";
import ToolsUtility from "@/helpers/ToolsUtility";

interface IDeviceContractItemHeaderProps {
    item: DeviceContractView;
}

const DeviceContractItemHeader = (props: IDeviceContractItemHeaderProps) => {
    return (
        <ThreeColumns>
            <span>{"{0}".format(props.item.deviceExternalId || "-")}</span>
            <span>{props.item.startAt ? ToolsUtility.toDateString(props.item.startAt) : "-"}</span>
            <span>{props.item.endAt ? ToolsUtility.toDateString(props.item.endAt) : "-"}</span>
        </ThreeColumns>
    )
}

export default DeviceContractItemHeader;