import { IDeviceClearFilters } from "./IDeviceClearFilters";
import { FiltersType } from "@/models/Enums";
import ReturnInspectionClearFilters from "./ReturnInspectionClearFilters";
import ServiceListClearFilters from "./ServiceListClearFilters";
import AnnualInspectionsClearFilters from "@/services/clearFilters/AnnualInspectionsClearFilters";

class DeviceClearFiltersFactory {
  public static create(type: FiltersType): IDeviceClearFilters {
    switch (type) {
      case FiltersType.ReturnInspectionList:
        return ReturnInspectionClearFilters;
      case FiltersType.ServiceList:
        return ServiceListClearFilters;
      case FiltersType.AnnualInspectionList:
        return AnnualInspectionsClearFilters;
      default:
        throw new Error("Unknown filters type for clearing filter");
    }
  }
}

export default DeviceClearFiltersFactory;
