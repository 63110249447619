import React from "react";
import {PageRoute, PageRouteProvider, ServerError, BasePageParameters} from "@renta-apps/athenaeum-react-common";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import {PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import Localizer from "../../localization/Localizer";

import styles from "./ErrorPage.module.scss";
import RentaToolsController from "@/pages/RentaToolsController";
import {IContactSupportPageParameters} from "@/models/IContactSupportPageParameters";

export interface IErrorPageParameters extends BasePageParameters {
    error: ServerError | null;
}

export default class ErrorPage extends AnonymousPage<IErrorPageParameters> {

    private get error(): ServerError | null {
        return ((this.props.parameters != null) && (this.props.parameters.error != null))
            ? this.props.parameters.error
            : null;
    }

    private async redirectToContactPage(): Promise<void> {
        const route = new PageRoute(PageDefinitions.contactSupportRouteName);
        if (this.error) {
            const parameters = {} as IContactSupportPageParameters;
            parameters.requestId = this.error.requestId;
            route.parameters = parameters;
        }
        await PageRouteProvider.redirectAsync(route);
    }

    private get isProduction(): boolean {
        return RentaToolsController.isProduction()
    }

    public render(): React.ReactNode {

        return (
            <PageContainer className={styles.error}>
                <PageHeader title={Localizer.errorPageTitle} subtitle={Localizer.errorPageErrorMessage} />

                <PageRow>
                    <div className="col">
                        {
                            (this.error) &&
                            (
                                <React.Fragment>
                                    <p>
                                        {Localizer.errorPageErrorMessage}
                                        &nbsp;
                                        <a href="javascript:void(0)" rel="noreferrer" onClick={async () => await this.redirectToContactPage()}>
                                            {Localizer.errorPageContactSupport}
                                        </a>
                                    </p>

                                    {
                                        (this.error.debugDetails && !this.isProduction) &&
                                        (
                                            <p className={styles.debugDetails}>
                                                {this.error.debugDetails}
                                            </p>
                                        )
                                    }

                                </React.Fragment>
                            )
                        }
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}