import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {
    PageContainer,
} from "@renta-apps/athenaeum-react-components";
import styles from "./ContractsPage.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import newStyles from "@/pages/NewUI.module.scss";
import ContractsList from "@/components/ContractsList/ContractsList";
import ContractsController from "@/pages/ContractsController";
import ListContractsRequest from "@/models/server/requests/ListContractsRequest";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import ContractView from "@/models/server/ContractView";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "@/providers/PageDefinitions";

interface IContractsPageProps {
}

interface IContractsPageState {
    listContractsRequest : ListContractsRequest;
    contractsCount: number;
}

export default class ContractsPage extends AuthorizedPage<IContractsPageProps, IContractsPageState> {

    state: IContractsPageState = {
        contractsCount: 0,
        listContractsRequest: new ListContractsRequest(),
    };

    private async listContractsAsync(listContractsRequest : ListContractsRequest): Promise<ContractView[]> {
        UserInteractionDataStorage.setPersistent("ListContracts", listContractsRequest);

        const response = await ContractsController.listContractsAsync(listContractsRequest);

        if (listContractsRequest.modified) {
            const contractsCount: number = 0;

            listContractsRequest.modified = false;

            this.setState({contractsCount, listContractsRequest})
        }

        await this.reRenderAsync();

        return response.contracts;
    }

    private async openItem(contract: ContractView) {
        await PageRouteProvider.redirectAsync(PageDefinitions.contractDetailsRoute(contract.id!, contract.contractExternalId));
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(rentaToolsStyles.pageContainer, styles.returnedDevicesPage, newStyles.pageContainer, newStyles.returnedDevicesPage)}>

                <ContractsList sticky
                               fetchData={async (request) => await this.listContractsAsync(request)}
                               request={this.state.listContractsRequest}
                               onContractOpen={async (contract) => await this.openItem(contract)}
                />

            </PageContainer>
        )
    }
}