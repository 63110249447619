import React from "react";
import {PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import AnonymousPage from "../../models/base/AnonymousPage";
import Localizer from "../../localization/Localizer";
import ContactSupport from "@/components/ContactSupport/ContactSupport";
import {IContactSupportPageParameters} from "@/models/IContactSupportPageParameters";

export default class ContactSupportPage extends AnonymousPage<IContactSupportPageParameters> {

    private get requestId(): string | null {
        return this.props.parameters?.requestId ?? null;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>
                <PageHeader title={Localizer.contactSupportPageTitle} />

                <ContactSupport requestId={this.requestId} />
            </PageContainer>
        );
    }
}