import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {BaseAsyncComponent, IBaseAsyncComponentState, IBaseComponent, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, Icon, IconSize, IconStyle, Modal} from "@renta-apps/athenaeum-react-components";
import DeviceInfo from "@/models/server/DeviceInfo";
import {DeviceStatus, FiltersType, MaintenanceStatus} from "@/models/Enums";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import Localizer from "../../../localization/Localizer";
import styles from "./AnnualInspectionDeviceList.module.scss";
import newStyles from "../../NewUI.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import ToolsUtility from "@/helpers/ToolsUtility";
import ListAnnualInspectionDevicesRequest from "@/models/server/requests/ListAnnualInspectionDevicesRequest";
import FiltersData from "@/pages/Models/FiltersData";
import DevicesFiltersModal from "@/components/DevicesFiltersModal/DevicesFiltersModal";
import {convertAnnualInspectionRequest, RequestRawData} from "@/models/RequestRawData";

interface IAnnualInspectionDeviceListProps {
    title: string;
    noDataText: string;
    request: ListAnnualInspectionDevicesRequest;
    filtersData: FiltersData;
    id: string;
    /**
     * Is the list showing the "remarks" tab
     * Needed for rendering certain things
     */
    isRemarksList?: boolean;

    fetchData( request: ListAnnualInspectionDevicesRequest): Promise<DeviceInfo[]>;

    onInspectionOpen(sender: IBaseComponent, device: DeviceInfo): Promise<void>;
}

export interface IAnnualInspectionDeviceListState extends IBaseAsyncComponentState<DeviceInfo[]> {
    request: ListAnnualInspectionDevicesRequest;
    isFetching : boolean;
}

export default class AnnualInspectionDeviceList extends BaseAsyncComponent<IAnnualInspectionDeviceListProps, IAnnualInspectionDeviceListState, DeviceInfo[]> {

    state: IAnnualInspectionDeviceListState = {
        data: null,
        isLoading: false,
        request: this.props.request,
        isFetching: false,
    };

    private readonly _annualInspectionFiltersModalRef: React.RefObject<Modal> = React.createRef();
    private readonly _lastElement: React.RefObject<HTMLDivElement> = React.createRef();
    private _intersectionObserver: IntersectionObserver | null = null;

    private getColorCodingClassName(item: DeviceInfo): string {
        let className = styles.verticalLineTransparent;
        if (item.nextAnnualReportDate) {
            if (this.inspectionUpComingIn(item.nextAnnualReportDate!, true)) {
                className = styles.verticalLineOrange;
            } else if (this.inspectionUpComingIn(item.nextAnnualReportDate!, false)) {
                className = styles.verticalLineBlue
            } else if (Utility.inPast(item.nextAnnualReportDate)) {
                className = styles.verticalLineRed
            }
        }
        return className;

    }

    private getLastAnnualInspectionDate(device: DeviceInfo): string | null {
        const date: Date | null = this.props.request.getLastAnnualInspectionDate(device);
        return (date)
            ? ToolsUtility.toDateString(date)
            : "";
    }

    private getDateToShow(device: DeviceInfo): string | null {
        const date: Date | null = device.remarksDueDate
            ? this.props.request.getRemarksDueDate(device)
            : this.props.request.getNextAnnualInspectionDate(device);

        return (date)
            ? ToolsUtility.toDateString(date)
            : "";
    }

    private inspectionUpComingIn(inspectionDate: Date, fourteenDays?: boolean): boolean {
        const upcomingInDays = Utility.diff(inspectionDate, Utility.now()).totalDays;
        if (fourteenDays) {
            return (upcomingInDays > 0 && upcomingInDays < 14);
        }

        return (upcomingInDays > 0 && upcomingInDays < 30);
    }

    private async openFiltersAsync(): Promise<void> {
        await this._annualInspectionFiltersModalRef.current!.openAsync();
    }

    private async submitFiltersAsync(rawRquest: RequestRawData): Promise<void> {
        await this._annualInspectionFiltersModalRef.current?.closeAsync();
        
        const annualInspectionRequest = ListAnnualInspectionDevicesRequest.create(rawRquest);
        
        this.setState({request: annualInspectionRequest});
        await this.reloadAsync();
    }

    private async onItemClickAsync(device: DeviceInfo): Promise<void> {
        await this.props.onInspectionOpen(this, device);
    }

    protected async fetchDataAsync(): Promise<DeviceInfo[]> {
        return this.props.fetchData(this.state.request);
    }

    protected getEndpoint(): string {
        return "";
    }

    protected getIconName(): string {
        return (this.state.request.isEmpty())
            ? "far filter"
            : "fas filter";
    }

    public isAsync(): boolean {
        return true;
    }

    public get haveMoreDataToLoad(): boolean {
            return (this.state.request.pageSize * (this.state.request.pageNumber === 0 ? 1 : this.state.request.pageNumber + 1) === this.state.data?.length!);
    }

    public deviceStatusText(item: DeviceInfo): string {
        if (item.status === DeviceStatus.InRent) {
            return Localizer.annualInspectionsListDeviceInRent;
        }

        if (ToolsUtility.hasFlag(item.maintenanceStatus, MaintenanceStatus.RequiresReturnInspection)) {
            return Localizer.annualInspectionsListDeviceWaitingForReturn;
        }

        if (ToolsUtility.hasFlag(item.maintenanceStatus, MaintenanceStatus.RequiresRepair)) {
            return Localizer.enumDeviceStatusRepairIsNeeded;
        }

        if (ToolsUtility.hasFlag(item.maintenanceStatus, MaintenanceStatus.RequiresService) || item.underService) {
            return Localizer.annualInspectionsListDeviceInMaintenance;
        }

        return Localizer.annualInspectionsListDeviceInStock;
    }

    public async componentDidUpdate() {
        this._intersectionObserver = new IntersectionObserver(async (entries) => {
            if (entries[0].isIntersecting && !this.state.isFetching && this.haveMoreDataToLoad) {

                const request = this.state.request;
                
                request.nextPage();
                
                this.setState({...this.state, isFetching: true})

                const result = await this.props.fetchData(request);

                const currentState = this.state.data;

                currentState?.push(...result)

                this.setState({...this.state, data: currentState, isFetching: false, request: request})
            }
        });

        if (this._intersectionObserver && this._lastElement.current) {
            this._intersectionObserver.observe(this._lastElement.current);
        }
    }


    public render(): React.ReactNode {

        return (
            <div data-cy={`annual_inspection_list_${this.props.id}`} className={this.css(styles.annualInspectionDeviceList)}>
                

                <div className={this.css(rentaToolsStyles.stickyDeviceListHeader, styles.stickyDeviceListHeader)}>

                        <div className={styles.header}>
                            <Button icon={{name: this.getIconName(), size: IconSize.Large}}
                                    type={ButtonType.Orange}
                                    className={this.css(styles.filter)}
                                    onClick={async () => await this.openFiltersAsync()}
                            />
                        </div>
                </div>
                

                {
                    (!this.isLoading) && ((this.state.data == null) || (this.state.data.length == 0)) &&
                    (
                        <div className={this.css(styles.annualInspectionDeviceListItem, styles.noItems)}>
                            {this.props.noDataText}
                        </div>
                    )
                }

                {
                    (this.state.data) &&
                    (
                        this.state.data.map((item, index) =>
                            <div key={index}
                                 ref={index === this.state.data?.length! - 1 ? this._lastElement : undefined}
                                 className={this.css(styles.annualInspectionDeviceListItem,
                                     "cursor-pointer", newStyles.col
                                 )}
                                 onClick={async () => await this.onItemClickAsync(item)}
                            >
                                {
                                    item.remarksDueDate &&
                                    (
                                        <Icon name={"fa-tools"}
                                              className={styles.remarksIcon}
                                              size={IconSize.Large}
                                              style={IconStyle.Regular}
                                        />
                                    )
                                }
                                
                                <span className={styles.itemBlock}>
                                    <div className={this.css(styles.left, styles.relative)}>
                                        <div className={this.css(styles.fourLines, styles.verticalLine, this.getColorCodingClassName(item))}
                                        >
                                            <span data-cy={`deviceType_${this.props.id}_${index}`} className={this.css(styles.deviceType)}>{item.type}</span>
                                            <span>{item.externalId}, {item.name}</span>
                                            {
                                                (!!item.serialNumber) && (
                                                    <span>{item.serialNumber}</span>
                                                )
                                            }
                                            <span>{item.productGroupId}, {item.productGroupName}</span>
                                            <span>{item.depoCostPool}</span>
                                            <span>{this.deviceStatusText(item)}</span>
                                        </div>
                                    </div>
                                </span>
                                
                                <span className={styles.itemBlock}>
                                    <div>
                                        {Localizer.annualInspectionsListColumnPreviousInspection}
                                    </div>
                                    <div>
                                        {this.getLastAnnualInspectionDate(item)}
                                    </div>
                                </span>

                                <span className={styles.itemBlock}>
                                    <div>
                                    {item.remarksDueDate
                                        ? Localizer.annualInspectionDeviceListRemarksMustBeRepaired
                                        : Localizer.annualInspectionsListColumnInspectedLatest}
                                    </div>
                                    <div>
                                        {this.getDateToShow(item)}
                                    </div>
                                </span>

                            </div>
                        )
                    )
                }

                <ArsenalPageRow>
                    <ArsenalButton block type={ButtonType.Orange}
                                   id={"backButton"}
                                   label={Localizer.devicePageReturnBack}
                                   icon={{name: "arrow-left"}}
                                   onClick={async () => PageRouteProvider.back()}
                    />
                </ArsenalPageRow>

                <DevicesFiltersModal deviceStatusSelection
                                     modalRef={this._annualInspectionFiltersModalRef}
                                     title={this.props.title}
                                     request={convertAnnualInspectionRequest(this.state.request)}
                                     filtersData={this.props.filtersData}
                                     filtersType={FiltersType.AnnualInspectionList}
                                     onSubmit={async (request) => await this.submitFiltersAsync(request)}
                />

            </div>
        );
    }
};