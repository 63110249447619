import {useEffect, useState} from "react";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import FiltersData from "@/pages/Models/FiltersData";
import {SelectListItem} from "@renta-apps/athenaeum-react-components";
import UnleashHelper from "@/helpers/UnleashHelper";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import {
    AnnualInspectionDevicesType,
    DeviceListOrder,
    FiltersType,
    PredictionTime,
    ReturnInspectionStatus,
    ServiceAndRepairsStatuses,
    ServiceTypeFilter
} from "@/models/Enums";
import Localizer from "@/localization/Localizer";

export interface DeviceFilterData {
    depos : SelectListItem[];
    productGroups : SelectListItem[];
    rentalObjectGroups : SelectListItem[];
    deviceStatuses : SelectListItem[];
    returnInspectionStatus : SelectListItem[];
    servicesTypes: SelectListItem[];
    servicesStatus: SelectListItem[];
    deviceTypes: SelectListItem[];
    showDeleted : SelectListItem[];
    returnInspectionDetails : SelectListItem[];
    annualInspectionStatusFilter: SelectListItem[];
    predictionTime : SelectListItem[];
    orderBy : SelectListItem[];
    annualInspectionTypeFilter : SelectListItem[];
}

export const itemShowActive = "active";
export const itemShowAll = "all";
export const itemShowDeleted = "deleted";

const filterDataEmpty : DeviceFilterData = {
    depos: [],
    productGroups: [],
    rentalObjectGroups: [],
    deviceStatuses: [],
    returnInspectionStatus: [],
    servicesTypes : [],
    servicesStatus: [],
    deviceTypes: [],
    showDeleted: [],
    returnInspectionDetails: [],
    annualInspectionStatusFilter: [],
    predictionTime: [],
    orderBy: [],
    annualInspectionTypeFilter: [],
};

const useFiltersData = (props: FiltersData, filterType : FiltersType) => {
    const [filtersData, setFiltersData] = useState<DeviceFilterData>(filterDataEmpty);

    const getDeletedFilters = (): SelectListItem[] => {
        return [
            new SelectListItem(
                itemShowAll,
                Localizer.devicesFiltersModalLabelShowAll,
            ),
            new SelectListItem(
                itemShowDeleted,
                Localizer.devicesFiltersModalLabelShowOnlyDeleted,
            ),
            new SelectListItem(
                itemShowActive,
                Localizer.devicesFiltersModalLabelShowOnlyActive,
            ),
        ];
    };

    const getReturnInspectionStatusFilters = (): SelectListItem[] => {
        if (UnleashHelper.isEnabled(RentaToolsConstants.featureFlagRiMultiUser)) {
            return [
                new SelectListItem(
                    ReturnInspectionStatus.InProgress.toString(),
                    Localizer.returnInspectionInProgress,
                ),
                new SelectListItem(
                    ReturnInspectionStatus.PartlyCompleted.toString(),
                    Localizer.returnInspectionPartlyCompleted,
                ),
                new SelectListItem(
                    ReturnInspectionStatus.Completed.toString(),
                    Localizer.returnInspectionCompleted,
                ),
                new SelectListItem(
                    ReturnInspectionStatus.Waiting.toString(),
                    Localizer.returnInspectionWaitingForReturnInspection,
                ),
                new SelectListItem(
                    ReturnInspectionStatus.NotCompleted.toString(),
                    Localizer.returnInspectionNotCompleted,
                ),
            ];
        }

        return [
            new SelectListItem(
                ReturnInspectionStatus.InProgress.toString(),
                Localizer.returnInspectionInProgress,
            ),
            new SelectListItem(
                ReturnInspectionStatus.Completed.toString(),
                Localizer.returnInspectionCompleted,
            ),
            new SelectListItem(
                ReturnInspectionStatus.Waiting.toString(),
                Localizer.returnInspectionWaitingForReturnInspection,
            ),
            new SelectListItem(
                ReturnInspectionStatus.NotCompleted.toString(),
                Localizer.returnInspectionNotCompleted,
            ),
        ];
    };

    const getServiceTypeFilters = (): SelectListItem[] => {
        return [
            new SelectListItem(
                ServiceTypeFilter.Service.toString(),
                Localizer.servicedPageService,
            ),
            new SelectListItem(
                ServiceTypeFilter.Repair.toString(),
                Localizer.servicedPageRepair,
            ),
        ];
    };

    const getServiceStatusFilters = (): SelectListItem[] => {
        const items: SelectListItem[] = [
            new SelectListItem(
                ServiceAndRepairsStatuses.Waiting.toString(),
                Localizer.servicedPageWaitingForServiceOrRepair,
            ),
            new SelectListItem(
                ServiceAndRepairsStatuses.InProgress.toString(),
                Localizer.servicedPageInProgress,
            ),
            new SelectListItem(
                ServiceAndRepairsStatuses.Completed.toString(),
                Localizer.servicedPageCompleted,
            )];
        
        
        if(RentaToolsConstants.featureFlagPausedService) {
            items.push(new SelectListItem(
                ServiceAndRepairsStatuses.Paused.toString(),
                Localizer.servicePagePaused,
            ));
            
        }
        
        if (UnleashHelper.isEnabled(RentaToolsConstants.featureFlagServiceForecastList)) {
            items.push(new SelectListItem(
                ServiceAndRepairsStatuses.UpcomingService.toString(),
                Localizer.servicedPageUpcoming,
            ));
        }

        return items;
    };
    const getServiceForecastGaps = (): SelectListItem[] => {
        return [
            new SelectListItem(
                PredictionTime.Week.toString(),
                Localizer.predictionTimeWeek,
            ),
            new SelectListItem(
                PredictionTime.TwoWeeks.toString(),
                Localizer.predictionTimeTwoWeeks,
            ),
            new SelectListItem(
                PredictionTime.OneMonth.toString(),
                Localizer.predictionTimeOneMonth,
            ),
            new SelectListItem(
                PredictionTime.TwoMonths.toString(),
                Localizer.predictionTimeTwoMonths,
            ),
        ];
    };

    const getListOrdersItems = () : SelectListItem[] => {
        const items: SelectListItem[] = [new SelectListItem(DeviceListOrder.ByPriority.toString(), Localizer.enumDeviceListOrderByPriority)];

        if(filterType === FiltersType.ServiceList) {
            items.push(new SelectListItem(DeviceListOrder.ByDate.toString(), Localizer.enumDeviceListOrderByServiceDate));
        } else {
            items.push(new SelectListItem(DeviceListOrder.ByDate.toString(), Localizer.enumDeviceListOrderByDate));
        }

        return items;
    }
    
    const getAnnualInspectionTypeFilters = (): SelectListItem[] => {
        return [
            new SelectListItem(
                AnnualInspectionDevicesType.Upcoming.toString(),
                Localizer.generalUpcoming,
            ),
            new SelectListItem(
                AnnualInspectionDevicesType.Remarks.toString(),
                Localizer.annualInspectionsPageTabRemarks,
            ),
        ];
    
    }


    useEffect(() => {
        const deviceFilterData = {...filterDataEmpty};

        deviceFilterData.depos = props.depos.map((item) =>
            TransformProvider.toDepoListItem(item, true),
        );

        deviceFilterData.productGroups = props.productGroups.map(
            (item) => TransformProvider.toSelectListItem(item),
        );

        deviceFilterData.rentalObjectGroups = props.rentalObjectGroups.map((item) =>
            TransformProvider.toSelectListItem(item),
        );

        deviceFilterData.deviceStatuses = EnumProvider.getDeviceStatusItems();

        deviceFilterData.returnInspectionStatus = getReturnInspectionStatusFilters();
        
        deviceFilterData.servicesTypes = getServiceTypeFilters();
        
        deviceFilterData.servicesStatus = getServiceStatusFilters();
        
        deviceFilterData.deviceTypes =  props.deviceTypes.map((item) =>
            TransformProvider.toSelectListItem(item),
        );
        
        deviceFilterData.showDeleted = getDeletedFilters();
        
        deviceFilterData.returnInspectionDetails = EnumProvider.getReturnInspectionDetailsTypesItems()
        
        deviceFilterData.annualInspectionStatusFilter = EnumProvider.getAnnualInspectionStatusFilterTypeItems();
        
        deviceFilterData.predictionTime = getServiceForecastGaps();
        
        deviceFilterData.orderBy = getListOrdersItems();
        
        deviceFilterData.annualInspectionTypeFilter = getAnnualInspectionTypeFilters();

        setFiltersData(deviceFilterData);
    }, [props]);
    
    
    return filtersData;
}

export default useFiltersData;