import Device from "../../pages/Models/Device";
import React from "react";
import {Icon, IconSize, IconStyle} from "@renta-apps/athenaeum-react-components";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import styles from "./DeviceInfoPanel.module.scss"

export interface IDeviceTitleProps {
    device : Device | null;
    openExtraInfoAsync? : () => Promise<void>;
    showExclamationMark? : boolean;
}


const DeviceTitle = ({ device, openExtraInfoAsync, showExclamationMark }: IDeviceTitleProps) => {
    const deviceProductGroupName = device?.productGroupName;
    const deviceType = device?.type;
    const deviceExternalId = device?.externalId;
    const deviceGroupId = device?.productGroupId;

    return (
        <React.Fragment>

            <div>
                <div id={"multiTitleDiv"}
                     className={styles.multiTitle}
                     onClick={async () => openExtraInfoAsync && await openExtraInfoAsync()}>

                        <span id={"deviceProductGroup"} data-cy="product_group" className={styles.deviceProductGroup}>
                            {
                                (showExclamationMark) &&
                                (
                                    <Icon name="fal exclamation-triangle"
                                          className={Utility.css(styles.exclamationMark)}
                                          size={IconSize.Large}
                                          style={IconStyle.Regular}
                                    />
                                )
                            }
                            {deviceProductGroupName} {deviceGroupId}
                        </span>
                    <span id={"deviceType"} data-cy="deviceType" className={styles.deviceType}>{deviceType}</span>
                    <span id={"device_externalId"}>{deviceExternalId}</span>
                </div>
            </div>

        </React.Fragment>
    );
}

export default DeviceTitle;