import ReportDefinitionPage, {ReportDefinitionSettings, ReportDefinitionStepsSettings} from "@/tests/pages/ReportDefinitionPage";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import NumbersHelper from "@/helpers/NumbersHelper";
import {TestReturnInspectionAdditionalExpense} from "@/tests/models/TestReturnInspectionAdditionalExpense";
import {onRedirect} from "@/helpers/CypressHelper";
import ReturnInspectionPage from "@/tests/pages/ReturnInspectionPage";
import SummaryPage from "@/tests/pages/SummaryPage";
import DashboardPage from "@/tests/pages/DashboardPage";
import SavePage from "@/tests/pages/SavePage";
import DevicePage from "@/tests/pages/DevicePage";
import ExpensesPage from "@/tests/pages/ExpensesPage";
import MappingsPage from "@/tests/pages/MappingsPage";
import CypressFeatureFlagService from "@/tests/CypressFeatureFlagService";
import Localizer from "@/localization/Localizer";
import ReturnInspectionPreviewPage from "@/tests/pages/ReturnInspectionPreviewPage";

const customInvoiceableTestExpense: TestReturnInspectionAdditionalExpense = {
  name: "Custom Invoiceable expense " + `${NumbersHelper.getRandomInteger(1, 9999)}`,
  customExpenseName: "Ladle needs replacement",
  externalId: `${NumbersHelper.getRandomInteger(1, 99999)}`,
  amount: "99.90",
  price: "19.99",
  unit: Localizer.enumExpenseUnitMeter2,
  isInvoiceable: true,
  invoiced: false,
  isCustom: true,
  toDelete: false,
};

const ordinaryInvoiceableTestExpense: TestReturnInspectionAdditionalExpense = {
  name: "Ordinary Invoiceable expense " + `${NumbersHelper.getRandomInteger(1, 9999)}`,
  externalId: `${NumbersHelper.getRandomInteger(1, 99999)}`,
  amount: "99",
  price: "19.99",
  unit: Localizer.enumExpenseUnitBottle,
  isInvoiceable: true,
  invoiced: true,
  isCustom: false,
  toDelete: false,
};

const ordinaryNotInvoiceableTestExpense: TestReturnInspectionAdditionalExpense = {
  name: "Ordinary Not Invoiceable expense " + `${NumbersHelper.getRandomInteger(1, 9999)}`,
  externalId: `${NumbersHelper.getRandomInteger(1, 99999)}`,
  amount: "100",
  price: "19.99",
  unit: Localizer.enumExpenseUnitPiece,
  isInvoiceable: false,
  invoiced: false,
  isCustom: false,
  toDelete: true,
};

const testAdditionalExpenses: TestReturnInspectionAdditionalExpense[] = [customInvoiceableTestExpense, ordinaryInvoiceableTestExpense, ordinaryNotInvoiceableTestExpense];
const testStepNameWithAdditionalExpenses: string = "Test Question step name";
const testStepTitleWithAdditionalExpenses: string = "Test Question step title";
const repairComment: string = "Test #1";

describe("Return Inspection tests", () => {
  beforeEach(() => {
    CypressFeatureFlagService.enable(RentaToolsConstants.featureFlagServiceAndRepairEnabled);
    CypressFeatureFlagService.enable(RentaToolsConstants.featureFlagTelematicEnabled);
    cy.viewport(1480, 768);
    cy.login();
    cy.goToDashboard();
  });

  it("Return Inspection should be completed without errors", () => {
    DashboardPage.openDevice("922591");

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.completeQuestionStep("Question #1");

    ReturnInspectionPage.completeQuestionStep("Question #2");

    ReturnInspectionPage.completeQuestionStep("Question Resource #3");

    ReturnInspectionPage.completeQuestionStep("Question #4");

    SummaryPage.validateSummaryListCount(4);

    SummaryPage.validateSummary([
      {name: "Question #1", okay: true, userName: "juhani.sihvonen@weare.fi"},
      {name: "Question #2", okay: true, userName: "juhani.sihvonen@weare.fi"},
      {
        name: "Question Resource #3",
        okay: true,
        userName: "juhani.sihvonen@weare.fi",
      },
      {name: "Test 123", okay: true, userName: "juhani.sihvonen@weare.fi"},
    ]);

    SummaryPage.saveInspection();

    SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);

    SavePage.goToDevicePage();
  });

  it("Return Inspection should be completed with faults", () => {
    cy.goToDashboard();

    DashboardPage.openDevice("922592");

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.stepNeedRepair("Question #1", "Test #1");

    ReturnInspectionPage.stepNeedRepair("Question #2", "Test #1");

    ReturnInspectionPage.stepNeedRepair("Question Resource #3", "Test #1");

    ReturnInspectionPage.stepNeedRepair("Question #4", "Test #1");

    SummaryPage.validateSummaryListCount(4);

    const items = [
      {
        name: "Question #1",
        okay: false,
        userName: "juhani.sihvonen@weare.fi",
      },
      {
        name: "Question #2",
        okay: false,
        userName: "juhani.sihvonen@weare.fi",
      },
      {
        name: "Question Resource #3",
        okay: false,
        userName: "juhani.sihvonen@weare.fi",
      },
      {name: "Test 123", okay: false, userName: "juhani.sihvonen@weare.fi"},
    ];

    SummaryPage.validateSummary(items);

    SummaryPage.saveInspection();

    SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);

    SavePage.goToDevicePage();

    DevicePage.assertToolsStatus(Localizer.enumMaintenanceReasonRepair);

    DevicePage.openFaultModal();

    DevicePage.validateFaults(items);
  });

  it("Return Inspection major fault should put device in repair", () => {
    cy.goToDashboard();

    DashboardPage.openDevice("922593");

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.stepNeedRepair("Question #1", repairComment);

    ReturnInspectionPage.completeQuestionStep("Question #2");

    ReturnInspectionPage.completeQuestionStep("Question Resource #3");

    ReturnInspectionPage.completeQuestionStep("Question #4");

    SummaryPage.validateSummaryListCount(4);

    SummaryPage.validateSummary([
      {
        name: "Question #1",
        okay: false,
        userName: "juhani.sihvonen@weare.fi",
      },
      {name: "Question #2", okay: true, userName: "juhani.sihvonen@weare.fi"},
      {
        name: "Question Resource #3",
        okay: true,
        userName: "juhani.sihvonen@weare.fi",
      },
      {name: "Test 123", okay: true, userName: "juhani.sihvonen@weare.fi"},
    ]);

    SummaryPage.saveInspection();

    SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);
    SavePage.summaryShouldContain(repairComment);

    SavePage.goToDevicePage();

    DevicePage.assertToolsStatus(Localizer.enumMaintenanceReasonRepair);
  });

  it("Return Inspection 3 minor faults should put device in repair", () => {
    cy.goToDashboard();

    DashboardPage.openDevice("922594");

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.completeQuestionStep("Question #1");

    ReturnInspectionPage.stepNeedRepair("Question #2", "Test #1");

    ReturnInspectionPage.stepNeedRepair("Question Resource #3", "Test #1");

    ReturnInspectionPage.stepNeedRepair("Question #4", "Test #1");

    SummaryPage.validateSummaryListCount(4);

    const items = [
      {name: "Question #1", okay: true, userName: "juhani.sihvonen@weare.fi"},
      {
        name: "Question #2",
        okay: false,
        userName: "juhani.sihvonen@weare.fi",
      },
      {
        name: "Question Resource #3",
        okay: false,
        userName: "juhani.sihvonen@weare.fi",
      },
      {name: "Test 123", okay: false, userName: "juhani.sihvonen@weare.fi"},
    ];

    SummaryPage.validateSummary(items);

    SummaryPage.saveInspection();

    SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);

    SavePage.goToDevicePage();

    DevicePage.assertToolsStatus(Localizer.enumMaintenanceReasonRepair);

    DevicePage.openFaultModal();

    DevicePage.validateFaults(items);
  });

  it.only("Return Inspection 2 minor faults shouldn't put device in repair", () => {
    cy.goToDashboard();

    DashboardPage.openDevice("922595");

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.completeQuestionStep("Question #1");

    ReturnInspectionPage.completeQuestionStep("Question #2");

    ReturnInspectionPage.stepNeedRepair("Question Resource #3", repairComment);

    ReturnInspectionPage.stepNeedRepair("Question #4", "Test #1");

    SummaryPage.validateSummaryListCount(4);

    const items = [
      {name: "Question #1", okay: true, userName: "juhani.sihvonen@weare.fi"},
      {name: "Question #2", okay: true, userName: "juhani.sihvonen@weare.fi"},
      {
        name: "Question Resource #3",
        okay: false,
        userName: "juhani.sihvonen@weare.fi",
      },
      {name: "Test 123", okay: false, userName: "juhani.sihvonen@weare.fi"},
    ];

    SummaryPage.validateSummary(items);

    SummaryPage.saveInspection();

    SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);
    SavePage.summaryShouldNotContain(repairComment);

    SavePage.goToDevicePage();

    DevicePage.openFaultModal();

    DevicePage.validateFaults(items);
  });

  it("Return Inspection fix before lease should put device in to repair", () => {
    cy.goToDashboard();

    DashboardPage.openDevice("922596");

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.completeQuestionStep("Question #1");

    ReturnInspectionPage.completeQuestionStep("Question #2");

    ReturnInspectionPage.stepNeedRepairAndFixBeforeNextLease(
        "Question Resource #3",
        "Test #1",
    );

    ReturnInspectionPage.completeQuestionStep("Question #4");

    SummaryPage.validateSummaryListCount(4);

    SummaryPage.validateSummary([
      {name: "Question #1", okay: true, userName: "juhani.sihvonen@weare.fi"},
      {name: "Question #2", okay: true, userName: "juhani.sihvonen@weare.fi"},
      {
        name: "Question Resource #3",
        okay: false,
        userName: "juhani.sihvonen@weare.fi",
      },
      {name: "Test 123", okay: true, userName: "juhani.sihvonen@weare.fi"},
    ]);

    SummaryPage.saveInspection();

    SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);

    SavePage.goToDevicePage();

    DevicePage.assertToolsStatus(Localizer.enumMaintenanceReasonRepair);
  });

  it("Return Inspection fixed during inspection shouldn't put device in maintenance", () => {
    cy.goToDashboard();

    DashboardPage.openDevice("922597");

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.stepNeedRepairAndFixInReturnInspection(
        "Question #1",
        "Test",
    );

    ReturnInspectionPage.completeQuestionStep("Question #2");

    ReturnInspectionPage.completeQuestionStep("Question Resource #3");

    ReturnInspectionPage.completeQuestionStep("Question #4");

    SummaryPage.validateSummaryListCount(4);

    SummaryPage.validateSummary([
      {name: "Question #1", okay: true, userName: "juhani.sihvonen@weare.fi"},
      {name: "Question #2", okay: true, userName: "juhani.sihvonen@weare.fi"},
      {
        name: "Question Resource #3",
        okay: true,
        userName: "juhani.sihvonen@weare.fi",
      },
      {name: "Test 123", okay: true, userName: "juhani.sihvonen@weare.fi"},
    ]);

    SummaryPage.saveInspection();

    SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);

    SavePage.goToDevicePage();
  });

  it("Return Inspection permanent fault shouldn't put device in maintenance", () => {
    cy.goToDashboard();

    DashboardPage.openDevice("922598");

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.completeQuestionStep("Question #1");

    ReturnInspectionPage.stepNeedRepairAndPermanentFault(
        "Question #2",
        "Permanent Fault",
    );

    ReturnInspectionPage.stepNeedRepair("Question Resource #3", "fault");

    ReturnInspectionPage.stepNeedRepair("Question #4", "fault");

    SummaryPage.validateSummaryListCount(4);

    const items = [
      {name: "Question #1", okay: true, userName: "juhani.sihvonen@weare.fi"},
      {
        name: "Question #2",
        okay: false,
        userName: "juhani.sihvonen@weare.fi",
      },
      {
        name: "Question Resource #3",
        okay: false,
        userName: "juhani.sihvonen@weare.fi",
      },
      {name: "Test 123", okay: false, userName: "juhani.sihvonen@weare.fi"},
    ];

    SummaryPage.validateSummary(items);

    SummaryPage.saveInspection();

    SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);

    SavePage.goToDevicePage();

    DevicePage.openFaultModal();

    DevicePage.validateFaults(items);
  });

  it("Complete Device' return inspection with additional expenses test", () => {
    const testDeviceExternalId = ReturnInspectionPage.constants.cypressTestDeviceExternalIdForAdditionalExpenses;

    // Create test data
    createTestExpenses();
    createTestReportDefinitionAndMapping();

    cy.goToDashboard();

    DashboardPage.openDevice(testDeviceExternalId);

    DevicePage.rollbackToReturnInspectionNeeded();

    // Start the return inspection.
    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.markStepAsDone(testStepNameWithAdditionalExpenses);

    ReturnInspectionPage.addAdditionalExpensesToReturnInspectionStep(testAdditionalExpenses);

    ReturnInspectionPage.continueReturnInspection();

    SummaryPage.validateSummaryListCount(1);

    SummaryPage.validateSummary([
      {
        name: testStepTitleWithAdditionalExpenses,
        okay: true,
        userName: "juhani.sihvonen@weare.fi",
        additionalExpensesCount: 2
      },
    ]);

    SummaryPage.saveInspection();
    SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);

    // Assert
    SavePage.goToDevicePage();

    DevicePage.elements.inspectionsBtn().click();

    // Open the last report
    ReturnInspectionPreviewPage.openReport(0);
    onRedirect(() => SummaryPage.elements.items().first().click());
    // Need to wait until the data on UI is rendered.
    cy.wait(1000);

    ReturnInspectionPage.elements.additionalExpenses.addAdditionalExpenseButton()
        .should('not.exist');

    ReturnInspectionPage.elements.additionalExpenses.additionalExpensesContainer()
        .get('form')
        .should('have.length', 2);

    ReturnInspectionPage.assertReturnInspectionStepAdditionalExpenses([customInvoiceableTestExpense, ordinaryInvoiceableTestExpense]);
  });


  it("Telematics table should have all the rows", () => {
    DashboardPage.openDevice("123-tele");

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.checkTelematics();
  });


  function createTestExpenses() {
    cy.goToAdminPage();
    ExpensesPage.open();
    ExpensesPage.clickAdditionalExpensesTab();
    ExpensesPage.unsetGenericAdditionalExpenseIfExists();

    ExpensesPage.elements.additionalExpenseButtons.add().click();

    const customMin: number = 0.5;
    const customMax: number = 999;
    const customDefaultValue: number = 1;
    const customStep: number = 0.5;

    // Fill expense
    ExpensesPage.updateExpense(
        true,
        customInvoiceableTestExpense.name,
        customInvoiceableTestExpense.externalId,
        customInvoiceableTestExpense.unit,
        customMin,
        customMax,
        customDefaultValue,
        customStep,
        customInvoiceableTestExpense.isInvoiceable || null,
        (customInvoiceableTestExpense.isCustom) ? true : null);

    // Actions (Save)
    ExpensesPage.saveExpense(customInvoiceableTestExpense.name, true);

    ExpensesPage.elements.additionalExpenseButtons.add().click();

    const ordinaryInvoiceableMin: number = 0.5;
    const ordinaryInvoiceableMax: number = 999;
    const ordinaryInvoiceableDefaultValue: number = 1;
    const ordinaryInvoiceableStep: number = 0.5;

    // Fill expense
    ExpensesPage.updateExpense(
        true,
        ordinaryInvoiceableTestExpense.name,
        ordinaryInvoiceableTestExpense.externalId,
        ordinaryInvoiceableTestExpense.unit,
        ordinaryInvoiceableMin,
        ordinaryInvoiceableMax,
        ordinaryInvoiceableDefaultValue,
        ordinaryInvoiceableStep,
        ordinaryInvoiceableTestExpense.isInvoiceable || null,
        (ordinaryInvoiceableTestExpense.isCustom) ? true : null);

    // Actions (Save)
    ExpensesPage.saveExpense(ordinaryInvoiceableTestExpense.name, true);

    ExpensesPage.elements.additionalExpenseButtons.add().click();

    const ordinaryNotInvoiceableMin: number = 0.5;
    const ordinaryNotInvoiceableMax: number = 999;
    const ordinaryNotInvoiceableDefaultValue: number = 1;
    const ordinaryNotInvoiceableStep: number = 0.5;

    // Fill expense
    ExpensesPage.updateExpense(
        true,
        ordinaryNotInvoiceableTestExpense.name,
        ordinaryNotInvoiceableTestExpense.externalId,
        ordinaryNotInvoiceableTestExpense.unit,
        ordinaryNotInvoiceableMin,
        ordinaryNotInvoiceableMax,
        ordinaryNotInvoiceableDefaultValue,
        ordinaryNotInvoiceableStep,
        ordinaryNotInvoiceableTestExpense.isInvoiceable || null,
        (ordinaryNotInvoiceableTestExpense.isCustom) ? true : null);

    // Actions (Save)
    ExpensesPage.saveExpense(ordinaryNotInvoiceableTestExpense.name, true);
  }

  function createTestReportDefinitionAndMapping() {
    const steps: ReportDefinitionStepsSettings[] = [
      {
        icon: "square",
        name: testStepNameWithAdditionalExpenses,
        title: testStepTitleWithAdditionalExpenses,
        typeDropdown: "Tarkastus",
        canAddAdditionalExpenses: true,
        additionalExpenses: [customInvoiceableTestExpense.name, ordinaryInvoiceableTestExpense.name, ordinaryNotInvoiceableTestExpense.name],
      }];

    const testReportDefinitionSettings: ReportDefinitionSettings = ReportDefinitionPage.createReportDefinition("Test Report Definition With Additional Expenses " + `${NumbersHelper.getRandomInteger(1, 999)}`, steps);
    ReportDefinitionPage.addReportDefinition(testReportDefinitionSettings);

    MappingsPage.mapProductGroupToReportDefinition(testReportDefinitionSettings.reportName, ReturnInspectionPage.constants.cypressTestProductGroupNameForAdditionalExpenses);
  }
});