
export enum RentaToolsStorageTable {

    DataStorage,
    
    Files,
    
    Observations,
}

class RentaToolsStorage {

    private static readonly dbName: string = "RentaTools.Storage.2.0";

    private static getDbName(table: RentaToolsStorageTable): string {
        return RentaToolsStorageTable[table].toString();
    }

    private static createObjectStore(db: any, table: RentaToolsStorageTable): any {
        const tableName: string = RentaToolsStorage.getDbName(table);

        return db.result.createObjectStore(tableName, {keyPath: "key"})
    }

    private static openIndexedDB(): any {
        // This works on all devices/browsers, and uses IndexedDBShim as a final fallback
        let w = window as any;
        let indexedDB = w.indexedDB || w.mozIndexedDB || w.webkitIndexedDB || w.msIndexedDB || w.shimIndexedDB;

        let openDB = indexedDB.open(RentaToolsStorage.dbName, 2);
        openDB.onupgradeneeded = function () {
            let db: any = {};

            db.result = openDB.result;

            db[RentaToolsStorage.getDbName(RentaToolsStorageTable.Files)] = RentaToolsStorage.createObjectStore(db, RentaToolsStorageTable.Files);

            db[RentaToolsStorage.getDbName(RentaToolsStorageTable.DataStorage)] = RentaToolsStorage.createObjectStore(db, RentaToolsStorageTable.DataStorage);

            db[RentaToolsStorage.getDbName(RentaToolsStorageTable.Observations)] = RentaToolsStorage.createObjectStore(db, RentaToolsStorageTable.Observations);
        };

        return openDB;
    }

    private static getStoreIndexedDB(openDB: any, table: RentaToolsStorageTable): any {

        const tableName: string = RentaToolsStorage.getDbName(table);

        let db: any = {};

        db.result = openDB.result;

        db.tx = db.result.transaction(tableName, "readwrite");

        db[tableName] = db.tx.objectStore(tableName);

        return db;
    }

    private static saveIndexedDB(table: RentaToolsStorageTable, key: string, data: any, callback: () => void): boolean {

        const openDB = RentaToolsStorage.openIndexedDB();

        const tableName: string = RentaToolsStorage.getDbName(table);

        openDB.onsuccess = function () {

            const db = RentaToolsStorage.getStoreIndexedDB(openDB, table);

            const request: IDBRequest = db[tableName].put({key: key, data: data});

            request.onsuccess = function () {
                callback();
            }
        };

        return true;
    }

    private static loadIndexedDB(table: RentaToolsStorageTable, key: string, callback: (data: any) => void) {

        let openDB = RentaToolsStorage.openIndexedDB();

        const tableName: string = RentaToolsStorage.getDbName(table);

        openDB.onsuccess = function () {

            const db = RentaToolsStorage.getStoreIndexedDB(openDB, table);

            const getData = db[tableName].get(key);

            getData.onsuccess = function () {

                const data: any = (getData.result != null) ? getData.result.data : null;

                callback(data);
            };

            db.tx.oncomplete = function () {
                db.result.close();
            };
        };

        return true;
    }

    public async clearDataAsync<TData>(table: RentaToolsStorageTable): Promise<void> {

        return new Promise<void>(function (resolve, reject) {

            const openDB = RentaToolsStorage.openIndexedDB();

            const tableName: string = RentaToolsStorage.getDbName(table);

            openDB.onsuccess = function () {

                const db = RentaToolsStorage.getStoreIndexedDB(openDB, table);

                db[tableName].clear();

                db.tx.oncomplete = () => {
                    resolve();
                }
            };

        });
    }

    public clearData<TData>(table: RentaToolsStorageTable): void {
        // noinspection ES6MissingAwait
        this.clearDataAsync(table);
    }

    public async setDataAsync<TData>(table: RentaToolsStorageTable, key: string, data: TData | null): Promise<void> {

        return new Promise<void>(function (resolve, reject) {

            RentaToolsStorage.saveIndexedDB(table, key, data, () => {
                resolve();
            });

        });
    }

    public async getDataAsync<TData>(table: RentaToolsStorageTable, key: string): Promise<TData | null> {

        return new Promise<TData | null>(function (resolve, reject) {

            RentaToolsStorage.loadIndexedDB(table, key, (data) => {
                resolve(data as TData | null);
            });

        });
    }

    public async setAsync<TData>(key: string, value: TData | null): Promise<void> {
        await this.setDataAsync(RentaToolsStorageTable.DataStorage, key, value);
    }

    public async getAsync<TData>(key: string): Promise<TData | null> {
        return await this.getDataAsync(RentaToolsStorageTable.DataStorage, key);
    }

    public async getPictureAsync<TData>(key: string): Promise<TData | null> {
        return await this.getDataAsync(RentaToolsStorageTable.Files, key);
    }

    public async remove(table: RentaToolsStorageTable, keys: string[]): Promise<void> {

        return new Promise<void>(function (resolve, reject) {

            const openDB = RentaToolsStorage.openIndexedDB();

            const tableName: string = RentaToolsStorage.getDbName(table);

            openDB.onsuccess = () => {

                const db = openDB.result;

                let transaction = db.transaction([tableName], "readwrite");

                keys.forEach(p => {
                    const request = transaction.objectStore(tableName).get(p);

                    request.onsuccess = () => {
                        const item = request.result;

                        if (item) {
                            transaction.objectStore(tableName).delete(item.key);
                        }
                    }
                });

                transaction.oncomplete = () => {
                    resolve();
                }

            }
        });
    }
}

//Singleton
export default new RentaToolsStorage();