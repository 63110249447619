import { IReturnInspectionTestInfo } from "@/tests/models/ReturnInspectionTestInfo";
import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";
import ServicedDevicesPage from "@/tests/pages/ServicedDevicesPage";
import SkipServiceModal from "@/tests/pages/SkipServiceModal";
import Localizer from "@/localization/Localizer";

class DevicePage {
  public elements = {
    deviceInfoPanel: () => cy.get("[data-cy=device_info_panel]"),
    actionButtonsContainer: () => cy.get("[data-cy=actionButtonsContainer]"),
    continueReturnInspectionBtn: () => cy.get("[id=continue_return_inspection]"),
    startReturnInspectionBtn: () =>
        cy.get("[id=device_page_start_inspection_button]", {timeout: 10000}),
    stopReturnInspectionBtn: () => cy.get("[id=stop_return_inspection_button]"),
    startServiceButton: () => cy.get('[id=device_page_start_service_button]'),
    stopServiceButton: () => cy.get('[id=device_page_stop_service_button]'),
    fuelingAndWashingBtn: () => cy.get("#device_page_fuel_and_clean_button"),
    recordObservationBtn: () => cy.get("#device_page_record_observation_button"),
    recordAnnualInspectionBtn: () => cy.get("#record_annual_inspection_button"),
    annualInspectionHistoryBtn: () => cy.get("#annual_inspection_history_button"),
    inspectionsBtn: () => cy.get("#device_page_inspections_button"),
    previousInspectionsBtn: () => cy.get("#device_page_previous_inspections_button"),
    previousInspectionsBackBtn: () => cy.get("#device_page_previous_inspections_back_button"),
    servicesBtn: () => cy.get("#device_page_previous_services_button"),
    skipServiceBtn: () => cy.get("#skip_service_button"),
    resetButton: () => cy.get("#reset_button"),
    deviceStatus: () => cy.get("[data-cy=device_status]"),
    rentalStatus: () => cy.get("[data-cy=device_rental_status]"),
    maintenanceReasons: () => cy.get("[data-cy=maintenance_reasons]"),
    deviceType: () => cy.get("[data-cy=deviceType]"),
    deviceModalFailedItem: () => cy.get("[data-cy=failed_item]"),
    banOfUseBanner: () => cy.get("#device_ban_of_use_banner"),
    previousInspectionsPicturesBtn: () => cy.get("#device_page_previous_inspections_pictures_button"),
    returnInspectionStartedBy: () => cy.get('[data-cy=returnInspectionStartedBy]'),
    banOfUseInfo: () => cy.get("#device_ban_of_use_info"),
  };

  public routes = {
    stopService: {
      path: "/api/report/stopService",
      method: "POST",
    } as RouteData,
    skipService: {
      path: "api/report/skipService",
      method: "POST",
    } as RouteData,
    continueInspection: {
      path: "/api/ReturnInspection/ContinueReturnInspection",
      method: "POST",
    } as RouteData,
    previewReturnInspection: {
      path: "/api/ReturnInspection/PreviewReturnInspection",
      method: "POST",
    } as RouteData,
    cancelReturnInspection: {
      path: "/api/ReturnInspection/CancelReturnInspection",
      method: "POST",
    } as RouteData,
    resetDeviceStatus: {
      path: "/api/device/resetDeviceStatus",
      method: "POST",
    } as RouteData,
    findDevice: {
      path: "/api/device/findDevice",
      method: "POST",
    } as RouteData,
    inspectionHistory: {
      path: "/api/AnnualInspection/ListDeviceAnnualInspections",
      method: "POST",
    } as RouteData,
  }

  public startReturnInspection(): void {
    executeWithIntercept(() => this.elements
            .startReturnInspectionBtn()
            .should("be.visible")
            .and("contain.text", Localizer.devicePageStartInspection)
            .click(),
        [this.routes.previewReturnInspection])
  }

  public stopServiceAndGoToFrontPage(): void {
    this.elements
        .stopServiceButton()
        .should("be.visible")
        .and("contain.text", Localizer.devicePageStopService)
        .trigger('click')

    executeWithIntercept(() =>
            cy.contains(Localizer.yes).should('be.visible').trigger('click'),
        [this.routes.stopService]);

    cy.goToFrontPage();
  }

  public assertToolsStatus(status: string): void {
    this.elements.deviceStatus().contains(status);
  }

  public assertInStockWithNoStatus(): void {
    this.elements.deviceStatus().should('not.exist');
    this.elements.rentalStatus().contains(Localizer.enumDeviceStatusInStock);
  }

  public assertInStockWithReturnInspectionPending(): void {
    this.elements.deviceStatus().contains(Localizer.enumReportDefinitionTypeReturnInspection)
    this.elements.rentalStatus().contains(Localizer.enumDeviceStatusInStock);
  }

  public assertInStockWithMaintenanceReasons(text: string[]): void {
    this.elements.deviceStatus().contains(Localizer.enumReportDefinitionTypeService)
    this.elements.rentalStatus().contains(Localizer.enumDeviceStatusInStock);

    text.forEach(item => {
      this.elements.maintenanceReasons().contains(item);
    })
  }

  public openFaultModal(): void {
    cy.intercept("POST", "/api/report/getFailedReportItems").as("getFaults");

    this.elements.deviceType().should("be.visible").click();

    cy.wait("@getFaults");
  }

  validateFaults(items: IReturnInspectionTestInfo[]): void {
    const faults = items.filter((p) => !p.okay);

    this.elements.deviceModalFailedItem().each(($element, index) => {
      cy.wrap($element).contains(faults[index].name);
    });
  }

  public startService(): void {
    executeWithIntercept(() => this.elements.startServiceButton().click(),
        [
          ServicedDevicesPage.routes.startService,
          ServicedDevicesPage.routes.listServiceReports,
          ServicedDevicesPage.routes.getServiceExpenses,
          ServicedDevicesPage.routes.getUserDepos,
        ]);
  }

  public startServiceWithoutOngoingReport(): void {
    executeWithIntercept(() => this.elements.startServiceButton().click(),
        [
          ServicedDevicesPage.routes.startService,
          ServicedDevicesPage.routes.listServiceReports,
          ServicedDevicesPage.routes.getServiceExpenses,
          ServicedDevicesPage.routes.getUserDepos,
        ]);
  }

  public openDeviceInfo(): void {
    this.elements.deviceInfoPanel().find("[id=infobutton]").click();
  }

  public deviceAttribute(key: string) {
    return this.elements.deviceInfoPanel().contains(key);
  }

  validateBanOfUseBanner(bannerText: string): void {
    this.elements
        .banOfUseBanner()
        .should('contain', bannerText);
  }

  assertAnnualInspectionBtnNotExists(): void {
    this.elements.rentalStatus();
    this.elements
        .recordAnnualInspectionBtn()
        .should('not.exist');
  }

  assertAnnualInspectionBtnExists(): void {
    this.elements.rentalStatus();
    this.elements.recordAnnualInspectionBtn();
  }

  assertStandardButtonsVisible(): void {
    this.elements.startServiceButton();
    this.elements.fuelingAndWashingBtn();
    this.elements.recordObservationBtn();
  }

  assertInspectionsButtonsNotVisible(): void {
    this.elements.previousInspectionsBtn().should('not.exist');
    this.elements.previousInspectionsPicturesBtn().should('not.exist');
  }

  navigateToInspections(): void {
    this.elements.inspectionsBtn().click();
  }

  navigateBackFromInspections(): void {
    this.elements.previousInspectionsBackBtn().click();
  }

  public checkInspectionStartedBy(name: string): void {
    this.elements.returnInspectionStartedBy().should('contain.text', name)
        .should('be.visible');
  }

  assertInspectionsButtonsVisible(): void {
    this.elements.previousInspectionsPicturesBtn();
    this.elements.previousInspectionsBtn();
    this.elements.previousInspectionsBackBtn();
  }

  assertCommonButtonsVisible(): void {
    this.elements.inspectionsBtn().should('contain', Localizer.devicePageInspections);
    this.elements.servicesBtn().should('contain', Localizer.devicePagePreviousServices);
  }

  assertStartInspectionBtnVisible(): void {
    this.elements.startReturnInspectionBtn().should('contain', Localizer.devicePageStartInspection);
  }

  assertStartServiceBtnVisible(): void {
    this.elements.startServiceButton().should('contain', Localizer.devicePageStartService);
  }

  assertSkipServiceBtnVisible(): void {
    this.elements.skipServiceBtn().should('contain', Localizer.skipServiceModalTitle)
  }

  openAnnualInspectionsWithIntercept(): void {
    executeWithIntercept(() => {
      this.elements.recordAnnualInspectionBtn().click();
    }, [this.routes.inspectionHistory])
  }

  stopService(): void {
    executeWithIntercept(() => {
      this.elements.stopServiceButton().realClick();
      this.confirmOk();
    }, [this.routes.stopService])
  }

  startServiceDeviceLocked(): void {
    this.elements.startServiceButton().trigger('click');
  }

  skipService(comment: string): void {
    this.elements.skipServiceBtn().click();

    SkipServiceModal.elements.commentInput().type(comment);

    executeWithIntercept(() => {
      SkipServiceModal.elements.skipServiceModalConfirmBtn().click();
    }, [this.routes.skipService])
  }

  confirmOk(): void {
    cy.get('[id^=confirmationContent-]').first().find('[id^=confirmation-dialog-confirm-]').trigger('click');
  }

  public continueReturnInspection(): void {
    executeWithIntercept(() => {
      this.elements
          .continueReturnInspectionBtn()
          .should("be.visible")
          .and("contain.text", Localizer.devicePageContinueInspection)
          .click();
    }, [this.routes.continueInspection]);
  }

  public tryToStopReturnInspectionAsync(): Promise<boolean> {
    return new Promise((resolve) => {
      this.elements.actionButtonsContainer()
          .then(($el) => {
            if ($el.find("[id=stop_return_inspection_button]").length > 0) {
              executeWithIntercept(() => {
                    this.elements.stopReturnInspectionBtn().trigger('click');
                    cy.get('.athenaeum-confirmation-dialog-opened button.athenaeum-button-color_orange')
                        .first()
                        .trigger('click', {force: true});
                  },
                  [this.routes.cancelReturnInspection]);

              resolve(true);
            } else {
              resolve(false);
            }
          })
    });
  }

  public tryToResetReturnInspection(): void {
    this.elements.actionButtonsContainer()
        .then(($el) => {
          if ($el.find("[id=device_page_start_inspection_button]").length === 0) {
            executeWithIntercept(() =>
                    this.elements.resetButton().trigger('click'),
                [this.routes.resetDeviceStatus]);
          }
        });
  }

  public rollbackToReturnInspectionNeeded(): void {
    this.tryToStopReturnInspectionAsync()
        .then((stopped) => {
          if (!stopped) {
            this.tryToResetReturnInspection();
          }
        });
  }

  assertActionModalVisible(): void {
    this.confirmCancel();
  }

  confirmCancel(): void {
    cy.get('[id^=messageBox-]')
        .should('be.visible');

    cy.get('[id^=message-box-cancel]')
        .click()
  }

  public clickDevicePicture(): void {
    this.elements.deviceInfoPanel()
        .find('#imageDiv')
        .should('be.visible')
        .click();
  }

  public textShownOnPage(text: string): void {
    cy.contains(text).should('be.visible');
  }

  public assertNoBan(): void {
    this.elements.deviceInfoPanel()
        .children()
        .should('contain', `${Localizer.deviceAnnualInspectionPageDeviceUseBan}: ${Localizer.no}`);
  }
}

export default new DevicePage();