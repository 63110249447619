import {Accordion, ButtonType, Modal, OneColumn, PageHeader, Tab, TabContainer, TextInput, ThreeColumns, TwoColumns} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import styles from "@/pages/ContractDetailsPage/ContractDetailsPage.module.scss";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import newStyles from "@/pages/NewUI.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import {ch} from "@renta-apps/athenaeum-react-common";
import ToolsUtility from "@/helpers/ToolsUtility";
import React, {useEffect, useState} from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import ListContractInvoicesRequest from "@/models/server/requests/ListContractInvoicesRequest";
import InvoiceController from "@/pages/InvoiceController";
import DeviceContractView from "@/models/server/DeviceContractView";
import DeviceContractItemHeader from "@/components/DeviceContractItemHeader/DeviceContractItemHeader";
import Invoice from "@/models/server/Invoice";
import InvoiceHeader from "@/components/InvoiceHeader/InvoiceHeader";
import SyncContractRequest from "@/models/server/requests/SyncContractRequest";
import ContractsController from "@/pages/ContractsController";
import EditContractDetailsRequest from "@/models/server/requests/EditContractDetailsRequest";
import ContractDetailsRequest from "@/models/server/requests/ContractDetailsRequest";
import ContractDetailsResponse from "@/models/server/responses/ContractDetailsResponse";

interface IContractDetailsProps {
    contractExternalId: string | null;
    contractId: string | null;
}

const ContractDetails = (props: IContractDetailsProps) => {
    const _modalRef: React.RefObject<Modal> = React.createRef();
    const _nameInputRef: React.RefObject<TextInput> = React.createRef();
    const _customerIdInputRef: React.RefObject<TextInput> = React.createRef();

    const [contractId, setContractId] = useState<string | null>(props.contractId)
    const [contractExternalId, setContractExternalId] = useState(props.contractExternalId)
    const [customerName, setCustomerName] = useState<string>()
    const [customerId, setCustomerId] = useState<string>()
    const [deviceContracts, setDeviceContracts] = useState<DeviceContractView[]>([])
    const [invoices, setInvoices] = useState<Invoice[]>([])

    useEffect(() => {
        const request = {
            contractId: props.contractId,
            contractExternalId: props.contractExternalId
        } as ContractDetailsRequest;

        fetchContractDetailsAsync(request).then((response: ContractDetailsResponse) => {

            setContractId(response.id)
            setContractExternalId(response.contractId)
            setCustomerId(response.customerId)
            setCustomerName(response.customerName)
            setDeviceContracts(response.deviceContractViews)
        })
    }, []);

    const fetchContractDetailsAsync = async (request: ContractDetailsRequest): Promise<ContractDetailsResponse> => {
        return await ContractsController.fetchContractDetailsAsync(request);
    }

    const fetchInvoicesAsync = async () => {
        const request = {
            contractId: contractId,
        } as ListContractInvoicesRequest;

        const invoiceResponse = await InvoiceController.listContractInvoicesAsync(request);

        if (invoiceResponse?.invoices !== null) {
            setInvoices(invoiceResponse.invoices)
        }
    }

    const getItemHeader = (item: DeviceContractView) => {
        return (
            <DeviceContractItemHeader item={item}/>
        )
    }

    const invoiceHeader = (item: Invoice) => {
        return (
            <InvoiceHeader invoice={item}/>
        )
    }

    const deviceContractsTabHeader = (): string => {
        return `${Localizer.enumSearchItemTypeDevice} ${Localizer.genericContracts}`.toUpperCase();
    }

    const syncContractAsync = async (item: DeviceContractView | null = null): Promise<void> => {
        const request = {
            contractId: contractId,
            deviceExternalId: item?.deviceExternalId,
            deviceContractId: item?.id,
        } as SyncContractRequest;

        const response = await ContractsController.syncContractAsync(request);

        if (response?.contract !== null) {
            setCustomerName(response.contract.customerName)
            setCustomerId(response.contract.customerExternalId)
        }

        if (response?.deviceContract !== null) {
            const deviceContract: DeviceContractView = response.deviceContract;
            const contracts: DeviceContractView[] = deviceContracts;
            const matching: DeviceContractView | null = contracts.firstOrDefault(dc => dc.id == deviceContract.id)

            if (!matching) return;

            matching.constructionSiteExternalId = deviceContract.constructionSiteExternalId;
            matching.constructionSiteName = deviceContract.constructionSiteName;
            matching.startAt = deviceContract.startAt;
            matching.endAt = deviceContract.endAt;

            setDeviceContracts(contracts);
        }
    }

    const openEditModalAsync = async (): Promise<void> => {
        await _modalRef.current?.openAsync();
    }

    const saveContractDetailsAsync = async (request: EditContractDetailsRequest): Promise<void> => {
        await ContractsController.editContractDetailsAsync(request)
    }

    const saveContractEditAsync = async (): Promise<void> => {
        await _modalRef.current?.closeAsync();

        const newName = _nameInputRef.current?.value;
        const newCustomerId = _customerIdInputRef.current?.value;

        const request = {
            contractId: contractId,
            customerName: newName,
            customerExternalId: newCustomerId,
        } as EditContractDetailsRequest;

        await saveContractDetailsAsync(request);

        if (!!newName) {
            setCustomerName(newName);
        }

        if (!!newCustomerId) {
            setCustomerId(newCustomerId);
        }
    }

    return (
        <>
            <PageHeader title={Localizer.genericContractDetails}/>

            <ArsenalPageRow>

                <table className={Utility.css(styles.table, "table table-striped")}>
                    <tbody>
                    <tr>
                        <td>{Localizer.invoiceDetailsPageCustomerName}</td>
                        <td>{"{0}".format(customerName || "-")}</td>
                    </tr>
                    <tr>
                        <td>{Localizer.invoiceDetailsPageContractId}</td>
                        <td>{"{0}".format(contractExternalId)}</td>
                    </tr>
                    <tr>
                        <td>{Localizer.customersIds}</td>
                        <td>{"{0}".format(customerId)}</td>
                    </tr>
                    </tbody>
                </table>

            </ArsenalPageRow>

            <ArsenalPageRow>
                <ArsenalButton block ellipsis
                               className={Utility.css(styles.label, newStyles.label)}
                               type={ButtonType.Default}
                               label={Localizer.genericEdit}
                               icon={{name: "fal pen"}}
                               onClick={async () => await openEditModalAsync()}
                />

                <ArsenalButton block ellipsis
                               className={Utility.css(styles.label, newStyles.label)}
                               type={ButtonType.Default}
                               label={Localizer.devicePageRefresh}
                               icon={{name: "fal sync-alt"}}
                               onClick={async () => await syncContractAsync()}
                />
            </ArsenalPageRow>

            <ArsenalPageRow>
                <TabContainer>
                    <Tab active id={"tab_device_contracts"} title={deviceContractsTabHeader()} className={rentaToolsStyles.deviceListPageTab}>
                        <div className={styles.deviceContractsContainer}>

                            <ThreeColumns>
                                <span>{Localizer.devicesManagementPageDeviceExternalId}</span>
                                <span>{Localizer.genericStartAt}</span>
                                <span>{Localizer.genericEndAt}</span>
                            </ThreeColumns>

                            {
                                (deviceContracts?.length > 0) && (
                                    deviceContracts.map(item => (
                                        <Accordion autoCollapse={false} className={styles.deviceContractRow} header={getItemHeader(item)} key={ch.getComponentId()}>
                                            <TwoColumns>
                                                <span>{Localizer.devicesManagementPageDeviceName}</span>
                                                <span>{"{0}".format(item.deviceName || "-")}</span>
                                            </TwoColumns>

                                            <TwoColumns>
                                                <span>{Localizer.invoiceDetailsPageConstructionSite}</span>
                                                <span>{"{0}".format(item.constructionSiteName || "-")}</span>
                                            </TwoColumns>

                                            <TwoColumns>
                                                <span>{Localizer.devicesFiltersModalLabelConstructionSitesIds}</span>
                                                <span>{item.constructionSiteExternalId ? "{0}".format(item.constructionSiteExternalId) : "-"}</span>
                                            </TwoColumns>

                                            {
                                                (ch.isNorway) && (
                                                    <>
                                                        <p/>
                                                        <OneColumn>
                                                            <ArsenalButton block ellipsis
                                                                           className={Utility.css(styles.label, newStyles.label)}
                                                                           type={ButtonType.Default}
                                                                           label={Localizer.devicePageRefresh}
                                                                           icon={{name: "fal sync-alt"}}
                                                                           onClick={async () => await syncContractAsync(item)}
                                                            />
                                                        </OneColumn>
                                                    </>
                                                )
                                            }

                                        </Accordion>
                                    ))
                                )
                            }

                        </div>
                    </Tab>

                    <Tab id={"tab_invoices"} title={Localizer.invoicesListInvoices.toUpperCase()} className={rentaToolsStyles.deviceListPageTab} onSelect={async () => await fetchInvoicesAsync()}>

                        <div className={styles.deviceContractsContainer}>

                            <ThreeColumns>
                                <span>{Localizer.devicesManagementPageDeviceExternalId}</span>
                                <span>{Localizer.genericInvoiced}</span>
                                <span>{Localizer.genericType}</span>
                            </ThreeColumns>

                            {
                                (invoices?.length > 0) && (
                                    invoices.map(item => (
                                        <Accordion autoCollapse={false} className={styles.deviceContractRow} header={invoiceHeader(item)} key={ch.getComponentId()}>
                                            <TwoColumns>
                                                <span>{Localizer.enumResourceItemTypeFueling}</span>
                                                <span>{"{0}".format(item.fuelAmount || "-")}</span>
                                            </TwoColumns>

                                            <TwoColumns>
                                                <span>{Localizer.enumResourceItemTypeWashing}</span>
                                                <span>{"{0}".format(item.washingTime || "-")}</span>
                                            </TwoColumns>

                                            <TwoColumns>
                                                <span>{Localizer.enumResourceItemTypeAdBlue}</span>
                                                <span>{"{0}".format(item.adBlueAmount || "-")}</span>
                                            </TwoColumns>

                                            <TwoColumns>
                                                <span>{Localizer.userManagementPageGridProcessedAt}</span>
                                                <span>{"{0}".format((item.approvedAt ?? item.declinedAt) ? ToolsUtility.toDateString(item.approvedAt ?? item.declinedAt) : "-")}</span>
                                            </TwoColumns>

                                            <TwoColumns>
                                                <span>{Localizer.expensesPageTabAdditionalExpenses}</span>
                                                <span>{"{0}".format(item.additionalExpensesCount || "-")}</span>
                                            </TwoColumns>

                                            <TwoColumns>
                                                <span>{Localizer.genericComment}</span>
                                                <span>{"{0}".format(item.comment || "-")}</span>
                                            </TwoColumns>
                                        </Accordion>
                                    ))
                                )
                            }

                        </div>
                    </Tab>
                </TabContainer>
            </ArsenalPageRow>

            <Modal ref={_modalRef}
                   className={rentaToolsStyles.extraInfoModal}
                   contentClassName={rentaToolsStyles.contentModal}
                   title={Localizer.genericContractDetails}>
                {
                    <div>
                        <TextInput label={Localizer.genericName} ref={_nameInputRef} value={customerName || "-"}/>
                        <TextInput label={Localizer.customersIds} ref={_customerIdInputRef} value={customerId || "-"}/>

                        <p/>
                        <ArsenalButton block ellipsis
                                       className={Utility.css(styles.label, newStyles.label)}
                                       type={ButtonType.Default}
                                       label={Localizer.genericSave}
                                       icon={{name: "fal sync-alt"}}
                                       onClick={async () => await saveContractEditAsync()}
                        />
                    </div>
                }
            </Modal>
        </>
    )
}

export default ContractDetails;