import { RecordOperatingHoursSource } from "@/models/Enums";
import styles from "./OperatingHourRecordSourceName.module.scss";
import Localizer from "@/localization/Localizer";

export interface IRecordOperatingHoursSourceProps {
  sourceType: RecordOperatingHoursSource;
}
const OperatingHourRecordSourceName = ({ sourceType } :  IRecordOperatingHoursSourceProps) => {
        if(sourceType === RecordOperatingHoursSource.Service){
            return <p className={styles.serviceSourceType}>{Localizer.operatingHoursHistoryPageSourceTypeService}</p>;
        }

        if(sourceType === RecordOperatingHoursSource.ReturnInspection){
            return <p className={styles.serviceSourceType}>{Localizer.operatingHoursHistoryPageSourceTypeRI}</p>;
        }
        
        if(sourceType === RecordOperatingHoursSource.OdometerChange) {
            return <p className={styles.serviceSourceType}>{Localizer.operatingHoursHistoryPageOdometerChange}</p>;
        }
        
        return <p className={styles.serviceSourceType}>{Localizer.operatingHoursHistoryPageSourceTypeAnnualInspection}</p>
}

export default OperatingHourRecordSourceName;