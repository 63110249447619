import React from "react";
import {PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import AnonymousPage from "../../models/base/AnonymousPage";
import Localizer from "../../localization/Localizer";

import styles from "./ForgotPasswordPage.module.scss";
import ForgotPassword from "@/components/ForgotPassword/ForgotPassword";

export default class ForgotPasswordPage extends AnonymousPage {

    public getTitle(): string {
        return Localizer.forgotPasswordPagePageTitle;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>
                <PageHeader title={Localizer.forgotPasswordPagePageTitle} subtitle={Localizer.forgotPasswordPageHelpText} className={styles.header}/>

                <ForgotPassword />
            </PageContainer>
        );
    }
}