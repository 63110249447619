import React from "react";
import DeviceInfo from "@/models/server/DeviceInfo";
import styles from "./ReturnInspectionDevicesList.module.scss";
import {ResourceItemType} from "@/models/Enums";
import ReturnInspectionController from "@/pages/ReturnInspectionController";

interface ExtendedReturnInspectionInfoProps {
    item: DeviceInfo;
}

const ExtendedReturnInspectionInfo: React.FC<ExtendedReturnInspectionInfoProps> = ({ item }) => {
    const openIconStep = async (
        event: React.MouseEvent,
        deviceExternalId: string,
        underInspection: boolean,
        startingPoint: ResourceItemType,
    ): Promise<void> => {
        event.stopPropagation();
        await ReturnInspectionController.startReturnInspectionFromStep(
            deviceExternalId,
            underInspection,
            startingPoint,
        );
    };
    
    return (
        <div className={`${styles.icons} ${styles.marginTop}`} id={"ri_progress"}>
            {item.returnInspectionDetails?.fueling === true && (
                <i
                    className={`fas fa-gas-pump ${DeviceInfo.fuelingDone(item) && styles.done}`}
                    onClick={(event) =>
                        openIconStep(event, item.externalId, item.underInspection, ResourceItemType.Fueling)
                    }
                ></i>
            )}
            {item.returnInspectionDetails?.washing === true && (
                <i
                    className={`fas fa-shower ${DeviceInfo.washingDone(item) && styles.done}`}
                    onClick={(event) =>
                        openIconStep(event, item.externalId, item.underInspection, ResourceItemType.Washing)
                    }
                ></i>
            )}
        </div>
    );
};

export default ExtendedReturnInspectionInfo;