import DeviceInfo from "@/models/server/DeviceInfo";
import {Utility} from "@renta-apps/athenaeum-toolkit";

export class CalculateFutureOperatingHoursService {

    public static calculateMaxOperatingHoursFromLastService(operatingHours: number | null, device: DeviceInfo): number | undefined {
        const lastModifiedDate: Date | null = device.lastOperatingHoursRecordTimestamp;

        if (lastModifiedDate == null || operatingHours == null) {
            return undefined;
        }

        let timespan: number = Utility.diff(Utility.now(), lastModifiedDate).totalHours;
        
        if(timespan < 1) {
            timespan = 24; 
        }

        return Utility.round(operatingHours + timespan, 1);
    }
}