import {Fault} from "@/models/server/DeviceInfo";
import {useEffect, useState} from "react";

export interface IFaultTextProps{
    faults : Fault[]
}

const FaultText = ({ faults } : IFaultTextProps) => {
    const [hasFaults, setHasFaults] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        const validFaults = faults.filter(p => p.faultText);

        if (validFaults.length < 0) {
            setHasFaults(false);
        } else {
            const faultsComments = validFaults.map(p => p.faultText);

            const faultsMessage = faultsComments.join(" ,");

            setHasFaults(true);
            setMessage(faultsMessage);
        }

    }, [faults])

    if (hasFaults) {
        return <p>{message}</p>
    }

    return null;
}

export default FaultText;