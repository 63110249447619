import React from "react";
import {
    ITabContainerClassNames,
    PageContainer,
    Tab,
    TabContainer,
    TabContainerHeaderStyleType,
    TabRenderType
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import ListInvoicesRequest from "@/models/server/requests/ListInvoicesRequest";
import InvoiceFiltersData from "@/models/server/InvoiceFiltersData";
import ReturnInspectionInvoice from "@/models/server/ReturnInspectionInvoice";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import Invoice from "@/models/server/Invoice";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import {InvoiceType} from "@/models/Enums";
import InvoicePageParameters from "@/models/InvoicePageParameters";
import GetInvoicesFiltersResponse
    from "@/models/server/responses/GetInvoicesFiltersResponse";
import ToolsPageHeader from "@/components/ToolsPageHeader/ToolsPageHeader";
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import InvoicesList from "@/pages/InvoicesPage/InvoicesList/InvoicesList";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import Localizer from "@/localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./InvoicesPage.module.scss";
import newStyles from "@/pages/NewUI.module.scss";

export interface IInvoicesPageProps {
}

interface IInvoicesPageState {
    request: ListInvoicesRequest;
    manualInvoicesRequest: ListInvoicesRequest;
    skippedInvoicesRequest: ListInvoicesRequest;
    serviceInvoicesRequest: ListInvoicesRequest;
    filtersData: InvoiceFiltersData;
    manualInvoicesFiltersData: InvoiceFiltersData;
    skippedInspectionFiltersData: InvoiceFiltersData;
    serviceInvoicesFiltersData: InvoiceFiltersData;
}

export default class InvoicesPage extends AuthorizedPage<IInvoicesPageProps, IInvoicesPageState> {

    state: IInvoicesPageState = {
        request: InvoicesPage.initializeListInvoicesRequest(),
        manualInvoicesRequest: InvoicesPage.initializeManualInvoicesRequest(),
        skippedInvoicesRequest: InvoicesPage.initializeSkippedInvoicesRequest(),
        serviceInvoicesRequest: InvoicesPage.initializeServiceInvoicesRequest(),
        filtersData: new InvoiceFiltersData(),
        manualInvoicesFiltersData: new InvoiceFiltersData(),
        skippedInspectionFiltersData: new InvoiceFiltersData(),
        serviceInvoicesFiltersData: new InvoiceFiltersData(),
    };

    private static initializeListInvoicesRequest(): ListInvoicesRequest {
        const request = UserInteractionDataStorage.restore("ListInvoicesRequest", new ListInvoicesRequest());

        request.pageNumber = 0;
        request.pageSize = RentaToolsConstants.itemsPerReturnInspectionPage;

        return request;
    }

    private static initializeManualInvoicesRequest(): ListInvoicesRequest {
        const request = UserInteractionDataStorage.restore("ManualInvoicesRequest", new ListInvoicesRequest());

        request.pageNumber = 0;
        request.pageSize = RentaToolsConstants.itemsPerReturnInspectionPage;

        return request;
    }

    private static initializeSkippedInvoicesRequest(): ListInvoicesRequest {
        const request = UserInteractionDataStorage.restore("SkippedInvoicesRequest", new ListInvoicesRequest());

        request.pageNumber = 0;
        request.pageSize = RentaToolsConstants.itemsPerReturnInspectionPage;

        return request;
    }

    private static initializeServiceInvoicesRequest(): ListInvoicesRequest {
        const request = UserInteractionDataStorage.restore("ServiceInvoicesRequest", new ListInvoicesRequest());

        request.pageNumber = 0;
        request.pageSize = RentaToolsConstants.itemsPerReturnInspectionPage;

        return request;
    }

    private async onInvoiceOpenAsync(invoice: Invoice, invoiceType: InvoiceType): Promise<void> {
        const params: InvoicePageParameters = {
            invoiceId: invoice.id,
            invoiceType: invoiceType
        };
        await PageRouteProvider.redirectAsync(PageDefinitions.invoiceDetails(params));
    }

    private async fetchInvoicesAsync(request?: ListInvoicesRequest): Promise<ReturnInspectionInvoice[]> {
        const req = request ?? this.state.request;
        UserInteractionDataStorage.set("ListInvoicesRequest", req);
        return await this.postAsync("api/invoice/ListInvoicesReturnInspection", req);
    }

    private async fetchManualInvoicesAsync(request?: ListInvoicesRequest): Promise<Invoice[]> {
        const req = request ?? this.state.request;
        UserInteractionDataStorage.set("ManualInvoicesRequest", req);
        return await this.postAsync("api/invoice/ListManualInvoices", req);
    }

    private async fetchSkippedInvoicesAsync(request?: ListInvoicesRequest): Promise<Invoice[]> {
        const req = request ?? this.state.request;
        UserInteractionDataStorage.set("SkippedInvoicesRequest", req);
        return await this.postAsync("api/invoice/SkippedReturnInspectionInvoices", req);
    }

    private async fetchServiceInvoicesAsync(request?: ListInvoicesRequest): Promise<Invoice[]> {
        const req = request ?? this.state.request;
        UserInteractionDataStorage.set("ServiceInvoicesRequest", req);
        return await this.postAsync("api/invoice/ListServiceInvoices", req);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const response: GetInvoicesFiltersResponse = await this.postAsync("api/invoice/getInvoicesFilters");

        await this.setState({
            filtersData: response.data,
            manualInvoicesFiltersData: response.data,
            skippedInspectionFiltersData: response.data,
            serviceInvoicesFiltersData: response.data
        });
    }

    public getManual(): string {
        return Localizer.invoicesPageManual;
    }

    public get tabContainerClassNames(): ITabContainerClassNames {
        return {
            navTabs: rentaToolsStyles.tabContainerNavTabs,
            headerLink: rentaToolsStyles.tabHeaderLink,
            navigationContainer: this.css(rentaToolsStyles.stickyNavigationContainer, styles.stickyNavigationContainer)
        };
    }

    public render(): React.ReactNode {
        return (

            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(rentaToolsStyles.pageContainer)}>

                <ToolsPageHeader stickyHeader id={"invoices_title"} title={Localizer.invoicesPageHeaderTitle.toUpperCase()}/>


                <TabContainer id={"invoicesTabContainer"}
                              renderType={TabRenderType.Once}
                              headerStyleType={TabContainerHeaderStyleType.Underline}
                              className={rentaToolsStyles.deviceListPageTabs}
                              classNames={this.tabContainerClassNames}
                >

                    <Tab id={"invoices"}
                         title={Localizer.invoicesListInspectionInvoices.toUpperCase()}
                         className={rentaToolsStyles.deviceListPageTab}
                    >
                        <ArsenalPageRow className={newStyles.row}>

                            <div className={this.css("w-100", newStyles.col)}>

                                <InvoicesList invoicesType={InvoiceType.ReturnInspectionInvoice}
                                              isManager={this.isAdminOrManager}
                                              modalTitle={Localizer.invoicesPageModalTitleFilters}
                                              noDataText={Localizer.invoicesPageInvoicesListNoData}
                                              request={this.state.request}
                                              filtersData={this.state.filtersData}
                                              fetchData={async (req) => await this.fetchInvoicesAsync(req)}
                                              onInvoiceOpen={async (invoice) => await this.onInvoiceOpenAsync(invoice, InvoiceType.ReturnInspectionInvoice)}
                                />

                            </div>

                        </ArsenalPageRow>

                    </Tab>

                    <Tab id={"manualInvoices"}
                         title={Localizer.invoicesListManualInvoices.toUpperCase()}
                         className={rentaToolsStyles.deviceListPageTab}
                    >
                        <ArsenalPageRow className={newStyles.row}>

                            <div className={this.css("w-100", newStyles.col)}>

                                <InvoicesList invoicesType={InvoiceType.ManualInvoices}
                                              isManager={this.isAdminOrManager}
                                              modalTitle={Localizer.invoicesPageManual}
                                              noDataText={Localizer.invoicesPageInvoicesListNoData}
                                              request={this.state.manualInvoicesRequest}
                                              filtersData={this.state.manualInvoicesFiltersData}
                                              fetchData={async (req) => await this.fetchManualInvoicesAsync(req)}
                                              onInvoiceOpen={async (invoice) => await this.onInvoiceOpenAsync(invoice, InvoiceType.ManualInvoices)}
                                />

                            </div>

                        </ArsenalPageRow>

                    </Tab>

                    <Tab id={"skippedInspectionRows"}
                         title={Localizer.invoiceListSkippedInspections.toUpperCase()}
                         className={rentaToolsStyles.deviceListPageTab}
                    >
                        <ArsenalPageRow className={newStyles.row}>

                            <div className={this.css("w-100", newStyles.col)}>

                                <InvoicesList invoicesType={InvoiceType.SkippedInspectionInvoice}
                                              isManager={this.isAdminOrManager}
                                              modalTitle={Localizer.invoicesPageManual}
                                              noDataText={Localizer.invoicesPageInvoicesListNoData}
                                              request={this.state.skippedInvoicesRequest}
                                              filtersData={this.state.skippedInspectionFiltersData}
                                              fetchData={async (req) => await this.fetchSkippedInvoicesAsync(req)}
                                              onInvoiceOpen={async (invoice) => await this.onInvoiceOpenAsync(invoice, InvoiceType.SkippedInspectionInvoice)}
                                />

                            </div>

                        </ArsenalPageRow>
                    </Tab>

                    <Tab id={"serviceInvoices"}
                         title={Localizer.invoiceListServiceInvoices.toUpperCase()}
                         className={rentaToolsStyles.deviceListPageTab}
                    >
                        <ArsenalPageRow className={newStyles.row}>

                            <div className={this.css("w-100", newStyles.col)}>

                                <InvoicesList invoicesType={InvoiceType.ServiceInvoice}
                                              isManager={this.isAdminOrManager}
                                              modalTitle={Localizer.invoicesPageModalTitleFilters}
                                              noDataText={Localizer.invoicesPageInvoicesListNoData}
                                              request={this.state.serviceInvoicesRequest}
                                              filtersData={this.state.serviceInvoicesFiltersData}
                                              fetchData={async (req) => await this.fetchServiceInvoicesAsync(req)}
                                              onInvoiceOpen={async (invoice) => await this.onInvoiceOpenAsync(invoice, InvoiceType.ServiceInvoice)}
                                />

                            </div>

                        </ArsenalPageRow>
                    </Tab>

                </TabContainer>

            </PageContainer>
        );
    }
}