import React, {useEffect, useRef, useState} from "react";
import {
    ch,
    IBaseComponent,
    PageRouteProvider,
    ReactUtility,
} from "@renta-apps/athenaeum-react-common";
import {
    Button,
    ButtonType,
    IconSize,
    Modal,
} from "@renta-apps/athenaeum-react-components";
import DeviceInfo from "@/models/server/DeviceInfo";
import DevicesFiltersModal from "../DevicesFiltersModal/DevicesFiltersModal";
import {
    DeviceListOrderType,
    DeviceStatus,
    FiltersType,
    MaintenanceStatus,
    ReturnInspectionStatus,
} from "@/models/Enums";
import ToolsPageHeader from "@/components/ToolsPageHeader/ToolsPageHeader";
import ListUnInspectedDevicesRequest from "@/models/server/requests/ListUnInspectedDevicesRequest";
import ToolsUtility from "@/helpers/ToolsUtility";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import FiltersData from "@/pages/Models/FiltersData";
import PageDefinitions from "@/providers/PageDefinitions";
import User from "@/models/server/User";
import ExpandableMenu from "@/components/ExpandableMenu/ExpandableMenu";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "@/localization/Localizer";

import styles from "./ReturnInspectionDevicesList.module.scss";
import newStyles from "@/pages/NewUI.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import {
    convertReturnInspectionRequest,
    RequestRawData,
} from "@/models/RequestRawData";
import PhaseInfo from "@/components/ReturnInspectionDevicesList/PhaseInfo";
import StatusName from "@/components/ReturnInspectionDevicesList/ReturnInspectionStatus";
import ExtendedReturnInspectionInfo from "@/components/ReturnInspectionDevicesList/ExtendedReturnInspectionInfo";

interface IReturnInspectionDevicesListProps {
    sticky?: boolean;
    columnHeader?: boolean;
    title: string;
    noDataText: string;
    request: ListUnInspectedDevicesRequest;
    filtersData: FiltersData;

    fetchData(
        request: ListUnInspectedDevicesRequest,
        scrollTrigger: boolean,
    ): Promise<DeviceInfo[]>;

    onDeviceOpen(sender: IBaseComponent, device: DeviceInfo): Promise<void>;

    returnInspectionList?: boolean;

    renderTitle?(): React.ReactNode;
}

const ReturnInspectionDevicesList: React.FC<
    IReturnInspectionDevicesListProps
> = (props) => {
    const [data, setData] = useState<DeviceInfo[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [request, setRequest] = useState<ListUnInspectedDevicesRequest>(
        props.request,
    );
    const [isFetching, setIsFetching] = useState(false);
    const [shortView, setShortView] = useState<boolean>(true);

    const filtersModalRef = useRef<Modal>(null);
    const lastElementRef = useRef<HTMLDivElement>(null);
    const intersectionObserver = useRef<IntersectionObserver | null>(null);

    useEffect(() => {
        const item = localStorage.getItem(
            RentaToolsConstants.returnInspectionListViewSetting,
        );

        if (item) {
            setShortView(item.toLowerCase() == "true");
        }

        fetchDataAsync().then((r) => setData(r));
    }, []);

    useEffect(() => {
        intersectionObserver.current = new IntersectionObserver(async (entries) => {
            if (entries[0].isIntersecting && !isFetching && haveMoreDataToLoad()) {
                const newRequest = request;
                newRequest.nextPage();

                setIsFetching(true);
                const result = await props.fetchData(newRequest, true);
                setData((prevData) => (prevData ? [...prevData, ...result] : result));
                setIsFetching(false);
                setRequest(newRequest);
            }
        });

        if (intersectionObserver.current && lastElementRef.current) {
            intersectionObserver.current.observe(lastElementRef.current);
        }

        return () => {
            if (intersectionObserver.current && lastElementRef.current) {
                intersectionObserver.current.unobserve(lastElementRef.current);
            }
        };
    }, [isFetching, request, props]);

    const getDate = (device: DeviceInfo): string | null => {
        const date: Date | null = props.request.getDate(device);
        return date ? ToolsUtility.toDateString(date) : "";
    };

    const openFiltersAsync = async (): Promise<void> => {
        await filtersModalRef.current!.openAsync();
    };

    const submitFiltersAsync = async (
        newRequest: RequestRawData,
    ): Promise<void> => {
        await filtersModalRef.current?.closeAsync();

        const returnInspectionRequest =
            ListUnInspectedDevicesRequest.create(newRequest);
        request.modified = true;
        request.resetPage();

        setRequest(request);

        const result = await props.fetchData(returnInspectionRequest, false);

        setData(result);
    };

    const onItemClickAsync = async (device: DeviceInfo): Promise<void> => {
        await props.onDeviceOpen({} as IBaseComponent, device);
    };

    const fetchDataAsync = async (): Promise<DeviceInfo[]> => {
        return props.fetchData(request, false);
    };

    const haveMoreDataToLoad = (): boolean => {
        return (
            request.pageSize *
            (request.pageNumber === 0 ? 1 : request.pageNumber + 1) ===
            data?.length!
        );
    };
    const changeView = async () => {
        const nextView = !shortView;
        setShortView(nextView);
        localStorage.setItem(
            RentaToolsConstants.returnInspectionListViewSetting,
            nextView.toString(),
        );
    };

    const getStatusStyles = (item: DeviceInfo): string => {
        if (
            item.returnInspectionStatus === ReturnInspectionStatus.NotCompleted &&
            item.status !== DeviceStatus.ReturnInspectionIsNeeded
        ) {
            return styles.notCompleted;
        }
        switch (item.returnInspectionStatus) {
            case ReturnInspectionStatus.PartlyCompleted:
                return styles.partlyCompleted;
            case ReturnInspectionStatus.InProgress:
                return styles.inProgress;
        }
        if (isInspectionCompleted(item)) {
            return styles.completed;
        }
        return "";
    };

    const isInspectionCompleted = (device: DeviceInfo): boolean => {
        return !ToolsUtility.hasFlag(
            device.maintenanceStatus,
            MaintenanceStatus.RequiresReturnInspection,
        );
    };

    const getDateText = (item: DeviceInfo): string => {
        if (isInspectionCompleted(item)) {
            return Localizer.returnInspectionListLastInspection;
        }
        return Localizer.returnInspectionListReturnedDate;
    };

    const getTitle = (): React.ReactNode => {
        if (props.renderTitle) {
            return props.renderTitle();
        }
        return (
            <span className={newStyles.subTitle}>
        {ReactUtility.toMultiLines(props.title)}
      </span>
        );
    };

    const getIconName = (): string => {
        return request.isEmpty() ? "far filter" : "fas filter";
    };

    return (
        <div
            className={`${styles.devicesList} ${newStyles.deviceList}`}
            data-cy={"devices_list"}
        >
            <div
                className={
                    props.sticky
                        ? `${rentaToolsStyles.stickyDeviceListHeader} ${styles.stickyDeviceListHeader}`
                        : ""
                }
            >
                <ToolsPageHeader
                    stickyHeader
                    title={Localizer.returnedDevicesPageTitle.toUpperCase()}
                />
                <div className={newStyles.col}>
                    <div className={`${styles.header} ${newStyles.header}`}>
                        {getTitle()}
                        <div className={styles.filterButton}>
                            <Button
                                id="device_list_filter"
                                small
                                icon={{name: getIconName(), size: IconSize.X2}}
                                type={ButtonType.Blue}
                                className={`${styles.filter} ${newStyles.filter}`}
                                onClick={openFiltersAsync}
                            />
                        </div>
                        <div className={styles.filterButtonSettings}>
                            <ExpandableMenu
                                onClick={changeView}
                                buttonDescription={Localizer.genericExpandedMenu}
                                buttonState={shortView}
                            >
                                {ch.getUser<User>().canEditDeviceOrder && (
                                    <Button
                                        id="device_edit_order"
                                        small
                                        type={ButtonType.Orange}
                                        className={`${styles.filter} ${newStyles.filter}`}
                                        label={Localizer.genericEditOrder}
                                        onClick={async () => {
                                            await PageRouteProvider.redirectAsync(
                                                PageDefinitions.depoSelectionRoute(
                                                    DeviceListOrderType.ReturnedDevices,
                                                ),
                                            );
                                        }}
                                    />
                                )}
                            </ExpandableMenu>
                        </div>
                    </div>
                </div>
            </div>

            {!isLoading && (!data || data.length === 0) && (
                <div
                    className={`${styles.deviceListItem} ${styles.noItems} ${newStyles.deviceListItem}`}
                >
                    {props.noDataText}
                </div>
            )}

            {data &&
                data.map((item, index) => (
                    <div
                        key={index}
                        ref={index === data.length - 1 ? lastElementRef : undefined}
                        className={`${styles.deviceListItem} cursor-pointer ${newStyles.deviceListItem}`}
                        onClick={() => onItemClickAsync(item)}
                        id={`device_list_element_${item.externalId}`}
                        data-cy={"device_list_element"}
                    >
                        <div
                            data-cy={`device_status_bar_${item.externalId}`}
                            className={`${styles.priorityBar} ${
                                props.returnInspectionList && styles.waitingForReturnInspection
                            } ${getStatusStyles(item)}`}
                        />
                        <div className={styles.leftCell}>
                            <p className={styles.fontBold}>
                                {item.externalId}{" "}
                                <span id={`depo_costpool`}>{item.depoCostPool}</span>
                            </p>
                            {item.serialNumber && (
                                <p className={styles.fontBold}>{item.serialNumber}</p>
                            )}
                            <p className={styles.fontBold}>{item.name}</p>
                            {shortView && <p>{item.secondaryInformation}</p>}
                        </div>
                        <div>
                            {shortView && (
                                <div className={styles.leftCell}>
                                    {props.returnInspectionList && (
                                        <React.Fragment>
                                            <div className={styles.centerCell}>
                                                <StatusName
                                                    deviceStatus={item.status}
                                                    maintenanceStatus={item.maintenanceStatus}
                                                    status={item.returnInspectionStatus}
                                                    underInspection={item.underInspection}
                                                />
                                                <PhaseInfo
                                                    device={item}
                                                    returnInspectionList={props.returnInspectionList}
                                                    shortView={shortView}
                                                />
                                            </div>
                                            {shortView && (
                                                <ExtendedReturnInspectionInfo item={item}/>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className={styles.rightCell}>
                            <span className={styles.dateStyles}>{getDate(item)}</span>
                            <span className={styles.dateText}>{getDateText(item)}</span>
                            <div className={styles.iconsWrapper}/>
                        </div>
                    </div>
                ))}

            <DevicesFiltersModal
                priorityOrder={UnleashHelper.isEnabled(
                    RentaToolsConstants.productGroupUtilizationUI,
                )}
                modalRef={filtersModalRef}
                title={props.title}
                request={convertReturnInspectionRequest(request)}
                filtersData={props.filtersData}
                onSubmit={async (request) => await submitFiltersAsync(request)}
                filtersType={FiltersType.ReturnInspectionList}
            />
        </div>
    );
};

export default ReturnInspectionDevicesList;