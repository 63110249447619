import React from "react";
import {
  BaseAsyncComponent,
  ch,
  IBaseAsyncComponentState,
  IBaseComponent,
  PageRouteProvider,
} from "@renta-apps/athenaeum-react-common";
import {
  Button,
  ButtonType,
  Icon,
  IconSize,
  IconStyle,
  Modal,
} from "@renta-apps/athenaeum-react-components";
import DeviceInfo from "@/models/server/DeviceInfo";
import ToolsUtility from "@/helpers/ToolsUtility";
import ListUnServicedDevicesRequest from "@/models/server/requests/ListUnServicedDevicesRequest";
import {
  DeviceListOrderType,
  DeviceStatus,
  FiltersType,
  MaintenanceStatus,
  PredictionDatesType,
  ServiceAndRepairsStatuses,
} from "@/models/Enums";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import ToolsPageHeader from "@/components/ToolsPageHeader/ToolsPageHeader";
import BaseListDevicesRequest from "@/models/server/requests/BaseListDevicesRequest";
import FiltersData from "@/pages/Models/FiltersData";
import DevicesFiltersModal from "@/components/DevicesFiltersModal/DevicesFiltersModal";
import PageDefinitions from "@/providers/PageDefinitions";
import User from "@/models/server/User";
import SaveCounterfactualForecastRequest from "@/models/server/requests/SaveCounterfactualForecastRequest";
import SaveCounterfactualForecastResponse from "@/models/server/responses/SaveCounterfactualForecastResponse";
import ReportWrongForecastModal, {
  IReportWrongForecastModalData,
} from "@/pages/ServicedDevicesPage/ReportWrongForecastModal/ReportWrongForecastModal";
import FaultText from "@/components/FaultText/FaultText";
import ServicedDevicePageStatus from "@/components/ServicedDevicesList/components/ServicedDevicePageStatus";
import ServicedDeviceDate from "@/components/ServicedDevicesDate/ServicedDeviceDate";
import ExpandableMenu from "@/components/ExpandableMenu/ExpandableMenu";
import ServicedPredictionInfo from "@/components/ServicedDevicesList/components/ServicedPredictionInfo";
import { FeatureSwitch } from "@/providers/FeatureSwitch";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "@/localization/Localizer";

import styles from "./ServicedDevicesList.module.scss";
import newStyles from "@/pages/NewUI.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import {convertUnServicedRequest, RequestRawData} from "@/models/RequestRawData";

interface IServicedDevicesListProps {
  sticky?: boolean;
  columnHeader?: boolean;
  title: string;
  noDataText: string;
  request: ListUnServicedDevicesRequest;
  filtersData: FiltersData;

  fetchData(
      request: ListUnServicedDevicesRequest,
  ): Promise<DeviceInfo[]>;

  onDeviceOpen(sender: IBaseComponent, device: DeviceInfo): Promise<void>;
}

export interface IServicedDevicesList extends IBaseAsyncComponentState<DeviceInfo[]> {
    request: ListUnServicedDevicesRequest;
    isFetching: boolean;
    shortView: boolean;
}

export default class ServicedDevicesList extends BaseAsyncComponent<IServicedDevicesListProps, IServicedDevicesList, DeviceInfo[]> {

    state: IServicedDevicesList = {
        data: null,
        isLoading: false,
        request: this.props.request,
        isFetching: false,
        shortView: ServicedDevicesList.initializeViewSettings(),
    };

    private readonly _filtersModalRef: React.RefObject<Modal> = React.createRef();
    private readonly _reportWrongForecastModalRef: React.RefObject<Modal<IReportWrongForecastModalData>> = React.createRef();
    private readonly _lastElement: React.RefObject<HTMLDivElement> = React.createRef();

    private _intersectionObserver: IntersectionObserver | null = null;

    public get haveMoreDataToLoad(): boolean {
            return (this.state.request.pageSize * (this.state.request.pageNumber + 1) === this.state.data?.length!);
    }

    public async componentDidUpdate() {
        this._intersectionObserver = new IntersectionObserver(async (entries) => {
            if (entries[0].isIntersecting && !this.state.isFetching && this.haveMoreDataToLoad) {

                const request = this.state.request;
                
                request.nextPage();
                
                this.setState({...this.state, isFetching: true})

                const result = await this.props.fetchData(request);

                const currentState = this.state.data;

                currentState?.push(...result)

                this.setState({...this.state, data: currentState, isFetching: false, request: request})
            }
        });

        if (this._intersectionObserver && this._lastElement.current) {
            this._intersectionObserver.observe(this._lastElement.current);
        }
    }

    private async openFiltersAsync(): Promise<void> {
        await this._filtersModalRef.current!.openAsync();
    }

    private async openReportWrongForecastModalAsync(deviceInfo: DeviceInfo): Promise<void> {
        if (this._reportWrongForecastModalRef.current) {
            await this._reportWrongForecastModalRef.current.openAsync({deviceInfo});
        }
    }

    private async submitFiltersAsync(requestRawData: RequestRawData): Promise<void> {
        await this._filtersModalRef.current?.closeAsync();

        const newRequest = ListUnServicedDevicesRequest.create(requestRawData);
        
        newRequest.modified = true;
        newRequest.resetPage();

        this.setState({request: newRequest});
        await this.reloadAsync();
    }

    private async onReportWrongForecastAsync(request: SaveCounterfactualForecastRequest): Promise<void> {
        const response: SaveCounterfactualForecastResponse = await this.postAsync("api/forecast/saveCounterfactualForecast", request);

        await this.reloadAsync();

        if (response.success && response.forecastReport) {
            await ch.alertMessageAsync(Localizer.reportWrongForecastAlertMessageReportCreated);
        } else {
            await ch.alertErrorAsync(Localizer.reportWrongForecastAlertErrorFailedCreateReport);
        }
    }

    private async onItemClickAsync(device: DeviceInfo): Promise<void> {
        await this.props.onDeviceOpen(this, device);
    }

    protected async fetchDataAsync(): Promise<DeviceInfo[]> {
        return this.props.fetchData(this.state.request);
    }

    protected getEndpoint(): string {
        return "";
    }

    protected get upcomingFilter(): boolean {
        return this.state.request.serviceStatus.some(p => p === ServiceAndRepairsStatuses.UpcomingService.toString());
    }

    protected getIconName(): string {
        return (this.state.request.isEmpty())
            ? "far filter"
            : "fas filter";
    }

    public isAsync(): boolean {
        return true;
    }

    private servicedStatus = [MaintenanceStatus.RequiresRepair, MaintenanceStatus.RequiresService]

    private needService = (device: DeviceInfo) => {
        return (this.servicedStatus.some(p => ToolsUtility.hasFlag(device.maintenanceStatus, p)) || (device.status === DeviceStatus.InMaintenance || device.status === DeviceStatus.RepairIsNeeded)) && !device.underService;
    }

    private hasForecast = (device: DeviceInfo): boolean => {
        return device.closestPredictionDate !== null;
    }

    private completedService = (device: DeviceInfo) => {
        return !this.needService(device) && !device.underService && !this.hasForecast(device);
    }

    private static initializeViewSettings = (): boolean => {
        const item = localStorage.getItem(RentaToolsConstants.servicedPageListViewSetting);

        if (item) {
            return item.toLowerCase() == 'true'
        }

        return true;
    }
    private changeView = async () => {
        const nextView = !this.state.shortView;

        this.setState({...this.state, shortView: nextView});

        localStorage.setItem(RentaToolsConstants.servicedPageListViewSetting, nextView.toString());
    }

    public render(): React.ReactNode {

        return (
            <div className={this.css(styles.devicesList, newStyles.deviceList)} data-cy={"devices_list"}>

                <div className={this.props.sticky ? this.css(rentaToolsStyles.stickyDeviceListHeader, styles.stickyDeviceListHeader) : ""}>

                    <ToolsPageHeader stickyHeader title={Localizer.servicedDevicesPageTitle.toUpperCase()}/>

                    <div className={newStyles.col}>

                        <div className={this.css(styles.header, newStyles.header)}>

                            <span className={newStyles.subTitle}>
                                {this.toMultiLines(this.props.title)}
                            </span>

                            <div className={this.css(styles.filterButton)}>
                                <Button small
                                        id={"filterButton"}
                                        icon={{name: this.getIconName(), size: IconSize.X2}}
                                        type={ButtonType.Blue}
                                        className={this.css(styles.filter, newStyles.filter)}
                                        onClick={async () => await this.openFiltersAsync()}
                                />
                            </div>

                            <div className={this.css(styles.filterButtonSettings)}>

                                <ExpandableMenu onClick={this.changeView} buttonDescription={Localizer.genericExpandedMenu} buttonState={!this.state.shortView}>
                                    {ch.getUser<User>().canEditDeviceOrder &&
                                        <Button id="edit_order"
                                                type={ButtonType.Orange}
                                                className={this.css(styles.filter, newStyles.filter)}
                                                label={Localizer.genericEditOrder}
                                                onClick={async () => {
                                                    await PageRouteProvider.redirectAsync(PageDefinitions.depoSelectionRoute(DeviceListOrderType.ServiceDevices))
                                                }}
                                        />
                                    }
                                </ExpandableMenu>
                            </div>

                        </div>

                    </div>
                </div>

                {
                    (!this.isLoading) && ((this.state.data == null) || (this.state.data.length == 0)) &&
                    (
                        <div className={this.css(styles.deviceListItem, styles.noItems, newStyles.deviceListItem)}>
                            {this.props.noDataText}
                        </div>
                    )
                }

                {
                    (this.state.data) &&
                    (
                        this.state.data.map((item, index) =>
                            <div key={index}
                                 data-cy={`deviceServiceListItem_${item.externalId}`}
                                 ref={index === this.state.data?.length! - 1 ? this._lastElement : undefined}
                                 className={this.css(styles.deviceListItem,
                                     "cursor-pointer",
                                     newStyles.deviceListItem
                                 )}
                                 onClick={async () => await this.onItemClickAsync(item)}>

                                <div data-cy={`priorityBar${item.underService ? "_progress" : ""}`} className={this.css(styles.priorityBar,
                                    this.hasForecast(item) && styles.forecast,
                                    this.needService(item) && styles.waitingForService,
                                    this.completedService(item) && styles.completed,
                                    item.underService && styles.inProgress,
                                    item.paused && styles.paused,
                                )}
                                />

                                <div className={this.css(styles.leftCell)}>
                                    <p data-cy={"deviceId"} className={this.css(styles.fontBold)}><b>{item.externalId}</b> {item.depoCostPool}</p>
                                    <p data-cy={"deviceName"}>{item.type}</p>
                                    <ServicedDeviceDate item={item} upcomingFilter={this.upcomingFilter}/>
                                    {!this.state.shortView && (
                                        <>
                                            <p>{item.name}</p>
                                            {!this.hasForecast(item) && <ServicedDevicePageStatus item={item}/>}
                                            {this.hasForecast(item) && <ServicedPredictionInfo item={item}/>}
                                            <FaultText faults={item.faults}/>
                                        </>
                                    )}
                                </div>

                                <div className={styles.rightCell}>
                                    {
                                        ((DeviceInfo.needService(item)) || (DeviceInfo.needRepair(item))) &&
                                        (
                                            <div className={styles.iconsService}>
                                                {
                                                    DeviceInfo.needService(item) &&
                                                    (
                                                        <Icon name={"fa-oil-can"}
                                                              size={IconSize.Large}
                                                              style={IconStyle.Regular}
                                                        />
                                                    )
                                                }
                                                {
                                                    DeviceInfo.needRepair(item) &&
                                                    (
                                                        <Icon name={"fa-tools"}
                                                              size={IconSize.Large}
                                                              style={IconStyle.Regular}
                                                        />
                                                    )
                                                }
                                            </div>
                                        )
                                    }

                                    {
                                        (this.hasForecast(item) && !DeviceInfo.getLatestPredictionInfo(item)?.isCounterfactual && UnleashHelper.isEnabled(RentaToolsConstants.featureFlagWrongServicePredictionReportingEnabled)) &&
                                        (
                                            <div className={styles.reportWrongForecastContainer}>
                                                <Button small stopPropagation
                                                        title={"Report"}
                                                        icon={{name: "fad fa-comment-alt-exclamation", style: IconStyle.Duotone, size: IconSize.Large}}
                                                        onClick={async () => this.openReportWrongForecastModalAsync(item)}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    )
                }

                <DevicesFiltersModal 
                                     priorityOrder
                                     modalRef={this._filtersModalRef}
                                     title={this.props.title}
                                     filtersType={FiltersType.ServiceList}
                                     request={convertUnServicedRequest(this.state.request)}
                                     filtersData={this.props.filtersData}
                                     onSubmit={async (request) => await this.submitFiltersAsync(request)}
                                     
                />

                <FeatureSwitch flagName={RentaToolsConstants.featureFlagWrongServicePredictionReportingEnabled}>

                    <ReportWrongForecastModal modalRef={this._reportWrongForecastModalRef}
                                              onReportWrongForecast={(request) => this.onReportWrongForecastAsync(request)}
                    />

                </FeatureSwitch>

            </div>
        );
    }
};