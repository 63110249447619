import {ButtonType, Dropdown, Modal, SelectListItem} from "@renta-apps/athenaeum-react-components";
import React, {useEffect} from "react";
import UnleashHelper from "@/helpers/UnleashHelper";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import {MaintenanceStatus} from "@/models/Enums";
import Localizer from "@/localization/Localizer";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import {useClearDropdownsFilters} from "@/components/DevicesFiltersModal/ClearDropdownsFilters";
import styles from './AddPendingStatusModal.module.scss';
export interface AddPendingStatusModalProps {
    modalRef: React.RefObject<Modal>;
    onModalClose: (maintenanceStatus : MaintenanceStatus | null) => void;
}

const AddPendingStatusModal = ({ modalRef, onModalClose } : AddPendingStatusModalProps) => {
    const [deviceStatuses, setDeviceStatuses] = React.useState<SelectListItem[]>([]);
    const [deviceStatus, setDeviceStatus] = React.useState<MaintenanceStatus | null>(null);
    const { addRef, clearDropdowns } = useClearDropdownsFilters();
    
    useEffect(() => {
        setDeviceStatus(null);
        
        const statuses = [
            new SelectListItem(
                MaintenanceStatus.RequiresReturnInspection.toString(),
                Localizer.enumReportDefinitionTypeReturnInspection,
            ),    
            
        ];
        
        if (UnleashHelper.isEnabled(RentaToolsConstants.featureFlagServiceAndRepairEnabled)) {
            statuses.push(new SelectListItem(
                MaintenanceStatus.RequiresRepair.toString(),
                Localizer.enumMaintenanceReasonRepair
            ));

            statuses.push(new SelectListItem(
                MaintenanceStatus.RequiresService.toString(),
                Localizer.enumMaintenanceReasonService
            ));
        }
        
        if (UnleashHelper.isEnabled(RentaToolsConstants.featureFlagAnnualInspectionsEnabled)) {
            statuses.push(new SelectListItem(
                MaintenanceStatus.RequiresAnnualInspection.toString(),
                Localizer.genericAnnualInspection
            ));
        }

        setDeviceStatuses(statuses)
        
    }, []);
    
    const closeModal = () => {
        setDeviceStatus(null);
        clearDropdowns();
        modalRef.current?.closeAsync();
    }
    
    
    return (
        <Modal id={"addPendingStatusModal"} ref={modalRef} >
            <div>
                <div>
                    <Dropdown
                        ref={addRef}
                        id={"deviceStatuses"}
                        name="deviceStatuses"
                        nothingSelectedText={"-"}
                        items={deviceStatuses}
                        className={styles.dropdownContainer}
                        value={deviceStatus?.toString()}
                        selectedItem={deviceStatus?.toString()}
                        onChange={async (sender, item, userInteraction) => {
                            if(item){
                                setDeviceStatus(parseInt(item.value));
                            }
                        }}
                    />
                </div>
                
                <ArsenalButton
                    block
                    big
                    type={ButtonType.Blue}
                    className={rentaToolsStyles.arsenalButton}
                    label={Localizer.genericSave}
                    onClick={async () => {
                        onModalClose(deviceStatus)
                        closeModal()
                    }}
                />
                
                <ArsenalButton
                    block
                    big
                    type={ButtonType.Orange}
                    className={rentaToolsStyles.arsenalButton}
                    label={Localizer.genericExit}
                    onClick={async () => closeModal()}
                />
            </div>
        </Modal>    
    )
}


export default AddPendingStatusModal;