import DashboardPage from "@/tests/pages/DashboardPage";
import DevicePage from "@/tests/pages/DevicePage";
import ReturnInspectionPage from "@/tests/pages/ReturnInspectionPage";
import AdvanceCheckPage, {
  AdvanceCheckStatus,
  IAdvanceCheckInfo,
} from "@/tests/pages/AdvanceCheckPage";
import SummaryPage from "@/tests/pages/SummaryPage";
import SavePage from "@/tests/pages/SavePage";
import Localizer from "@/localization/Localizer";

describe("Advanced check tests", () => {
  beforeEach(() => {
    cy.login();
    cy.visit("Dashboard");
  });

  it("Advanced check -> validation tests", () => {
    DashboardPage.openDevice("911111");

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.elements.nextButton().realClick();

    const checksSteps: IAdvanceCheckInfo[] = [
      { name: "Not required check", status: AdvanceCheckStatus.No },
    ];

    AdvanceCheckPage.completeChecks(checksSteps);

    ReturnInspectionPage.elements.nextButton().realClick();

    AdvanceCheckPage.validateErrorMessage();

    AdvanceCheckPage.validateErrors([0, 1, 2]);
  });

  it("Advanced check -> major fault should put device into maintenance", () => {
    const majorFaultComment = "major test";

    const checksSteps: IAdvanceCheckInfo[] = [
      { name: "Not required check", status: AdvanceCheckStatus.Yes },
      {
        name: "Required Major",
        status: AdvanceCheckStatus.No,
        comment: majorFaultComment,
      },
      {
        name: "Minor",
        status: AdvanceCheckStatus.Yes,
      },
      {
        name: "Minor #2",
        status: AdvanceCheckStatus.Yes,
      },
      {
        name: "Minor #3",
        status: AdvanceCheckStatus.Yes,
      },
    ];

    AdvanceCheckPage.completeChecks(checksSteps);

    ReturnInspectionPage.elements.nextButton().realClick();

    SummaryPage.validateSummary([
      {
        name: "Advenced Checks",
        okay: false,
        userName: "juhani.sihvonen@weare.fi",
      },
    ]);

    SummaryPage.validateAdvancedChecks(checksSteps);

    SummaryPage.saveInspection();

    SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);
    SavePage.summaryShouldContain(majorFaultComment);

    SavePage.goToDevicePage();

    DevicePage.assertToolsStatus(Localizer.enumMaintenanceReasonRepair);
  });

  it("Advanced check -> 3 minor fault should put device into maintenance", () => {
    cy.goToDashboard();

    DashboardPage.openDevice("911112");

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.elements.nextButton().realClick();

    const firstComment = "test";
    const secondComment = "test2";
    const thirdComment = "test3";
    const checksSteps: IAdvanceCheckInfo[] = [
      { name: "Not required check", status: AdvanceCheckStatus.Yes },
      {
        name: "Required Major",
        status: AdvanceCheckStatus.Yes,
      },
      {
        name: "Minor",
        status: AdvanceCheckStatus.No,
        comment: firstComment,
      },
      {
        name: "Minor #2",
        status: AdvanceCheckStatus.No,
        comment: secondComment,
      },
      {
        name: "Minor #3",
        status: AdvanceCheckStatus.No,
        comment: thirdComment,
      },
    ];

    AdvanceCheckPage.completeChecks(checksSteps);

    ReturnInspectionPage.elements.nextButton().realClick();

    SummaryPage.validateSummary([
      {
        name: "Advenced Checks",
        okay: false,
        userName: "juhani.sihvonen@weare.fi",
      },
    ]);

    SummaryPage.validateAdvancedChecks(checksSteps);

    SummaryPage.saveInspection();

    SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);

    SavePage.summaryShouldContain(firstComment);
    SavePage.summaryShouldContain(secondComment);
    SavePage.summaryShouldContain(thirdComment);

    SavePage.goToDevicePage();

    DevicePage.assertToolsStatus(Localizer.enumMaintenanceReasonRepair);
  });

  it("Advanced check -> RI should be completed without problems", () => {
    cy.goToDashboard();

    DashboardPage.openDevice("911113");

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.elements.nextButton().realClick();

    const checksSteps: IAdvanceCheckInfo[] = [
      { name: "Not required check", status: AdvanceCheckStatus.Yes },
      {
        name: "Required Major",
        status: AdvanceCheckStatus.Yes,
      },
      {
        name: "Minor",
        status: AdvanceCheckStatus.Yes,
      },
      {
        name: "Minor #2",
        status: AdvanceCheckStatus.Yes,
      },
      {
        name: "Minor #3",
        status: AdvanceCheckStatus.Yes,
      },
    ];

    AdvanceCheckPage.completeChecks(checksSteps);

    ReturnInspectionPage.elements.nextButton().realClick();

    SummaryPage.validateSummary([
      {
        name: "Advenced Checks",
        okay: true,
        userName: "juhani.sihvonen@weare.fi",
      },
    ]);

    SummaryPage.validateAdvancedChecks(checksSteps);

    SummaryPage.saveInspection();

    SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);

    SavePage.goToDevicePage();
  });
});