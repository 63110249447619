import Device from "@/pages/Models/Device";
import RentaToolsController from "@/pages/RentaToolsController";
import { DeviceCounterType } from "@/models/Enums";

class OperatingHoursService {
    public odometerChanged(device: Device): boolean {
        const totalCounter = this.getTotalOperatingHours(device);
    
        const odometerCounter = this.getOdometer(device);
    
        return totalCounter !== odometerCounter;
    }
  
    public getOdometer(device: Device): number {
        return RentaToolsController.getDeviceCounter(
        device,
        DeviceCounterType.OdometerOperatingHours) || 0;
    }    
    
    public getTotalOperatingHours(device: Device): number {
        return RentaToolsController.getDeviceCounter(
        device,
        DeviceCounterType.TotalOperatingHours) || 0;
    }
    
    public getLatestValue(device: Device): number {
        if(this.odometerChanged(device)) {
            return this.getOdometer(device);
        }
        
        return this.getTotalOperatingHours(device);
    }


    public getOdometerNumber(device: Device) {
        return RentaToolsController.getDeviceCounter(
            device,
            DeviceCounterType.OdometerNumber) || 0;
    }
}


export default new OperatingHoursService();