import DeviceContract from "@/models/server/DeviceContract";
import Depo from "@/models/server/Depo";
import User from "@/models/server/User";

export default class InvoiceFiltersData {
    public deviceContracts: DeviceContract[] = [];

    public depots: Depo[] = [];

    public users: User[] = [];

    public isInvoiceFiltersData: boolean = true;
}