import {useState} from "react";
import {Dropdown, SelectListItem} from "@renta-apps/athenaeum-react-components";

export const useClearDropdownsFilters = () => {
    const [refs, setRefs] = useState<Dropdown<SelectListItem>[]>([]);

    const addRef = (ref: Dropdown<SelectListItem> | null): void => {
        if (ref !== null && !refs.includes(ref)) {
            setRefs([...refs, ref]);
        }
    };

    const clearDropdowns = (): void => {
        refs.forEach(ref => {
            ref.reRenderAsync();
        });
    }

    return {addRef, clearDropdowns};
}