import { ServiceAndRepairsStatuses, ServiceTypeFilter } from "@/models/Enums";
import BaseDeviceClearFilters from "@/services/clearFilters/BaseDeviceClearFilters";
import { RequestRawData } from "@/models/RequestRawData";

class ServiceListClearFilters extends BaseDeviceClearFilters {
  public override clear(data: RequestRawData): RequestRawData {
    const copy = super.clear(data);

    copy.deviceStatuses = [];
    copy.servicesStatus = [
      ServiceAndRepairsStatuses.Waiting.toString(),
      ServiceAndRepairsStatuses.InProgress.toString(),
    ];

    copy.servicesTypes = [
      ServiceTypeFilter.Service.toString(),
      ServiceTypeFilter.Repair.toString(),
    ];

    return copy;
  }
}

export default new ServiceListClearFilters();
