import React, {useState} from "react";
import {Button, ButtonContainer, ButtonType, Form, Inline, Modal, TextInput} from "@renta-apps/athenaeum-react-components";
import styles from "@/components/DevicesFiltersModal/DevicesFiltersModal.module.scss";
import Localizer from "@/localization/Localizer";
import ListContractsRequest from "@/models/server/requests/ListContractsRequest";
import {Utility} from "@renta-apps/athenaeum-toolkit";

interface IContractFiltersModalProps {
    modalRef: React.RefObject<Modal>;
    request: ListContractsRequest;
    title: string;

    onSubmit(request: ListContractsRequest): Promise<void>;
}

const ContractsFilterModal: React.FC<IContractFiltersModalProps> = (props) => {
    const [request, setRequest] = useState<ListContractsRequest>(props.request);

    const clearAsync = async (): Promise<void> => {
        setRequest({
            pageNumber: 0,
            pageSize: 100
        } as ListContractsRequest);
    };

    const submitAsync = async (): Promise<void> => {
        await props.onSubmit(request);
    };

    return (
        <Modal id={"devicesFiltersModal"}
               title={props.title}
               ref={props.modalRef}
               className={styles.devicesFiltersModal} >

            <Form
                className={styles.form}
                onSubmit={async (_, data) => await submitAsync()} >

                <Inline className={styles.inline}>

                    <TextInput
                        id="deviceExternalId"
                        name="deviceExternalId"
                        label={Localizer.devicesFiltersModalLabelDeviceId}
                        value={request.deviceExternalId}
                        onChange={async (sender, value) =>
                            setRequest({ ...request, deviceExternalId: value } as ListContractsRequest)
                        }
                    />

                    <TextInput
                        id="contractExternalId"
                        name="contractExternalId"
                        label={Localizer.invoiceDetailsPageContractId}
                        value={request.contractExternalId}
                        onChange={async (sender, value) =>
                            setRequest({ ...request, contractExternalId: value } as ListContractsRequest)
                        }
                    />

                </Inline>

                <Inline className={styles.inline}>

                    <TextInput
                        id="constructionSiteExternalId"
                        name="constructionSiteExternalId"
                        label={Localizer.devicesFiltersModalLabelConstructionSitesIds}
                        value={request.constructionSiteExternalId}
                        onChange={async (sender, value) =>
                            setRequest({ ...request, constructionSiteExternalId: value } as ListContractsRequest)
                        }
                    />

                    <TextInput
                        id="customerId"
                        name="customerId"
                        label={Localizer.customersIds}
                        value={request.customerId}
                        onChange={async (sender, value) =>
                            setRequest({ ...request, customerId: value } as ListContractsRequest)
                        }
                    />

                </Inline>

                <Inline className={styles.inline}>

                    <TextInput
                        id="constructionSiteName"
                        name="constructionSiteName"
                        label={"Construction site name"}
                        value={request.constructionSiteName}
                        onChange={async (sender, value) =>
                            setRequest({ ...request, constructionSiteName: value } as ListContractsRequest)
                        }
                    />

                    <TextInput
                        id="customerName"
                        name="customerName"
                        label={Localizer.invoiceDetailsPageCustomerName}
                        value={request.customerName}
                        onChange={async (sender, value) =>
                            setRequest({ ...request, customerName: value } as ListContractsRequest)
                        }
                    />

                </Inline>

                <ButtonContainer className={Utility.css(styles.buttons)}>
                    <Button submit
                            type={ButtonType.Orange}
                            label={Localizer.devicesFiltersModalButtonApply}
                    />

                    <Button type={ButtonType.Blue}
                            label={Localizer.devicesFiltersModalButtonClear}
                            onClick={async () => await clearAsync()}
                    />
                </ButtonContainer>

            </Form>
        </Modal>
    );
}

export default ContractsFilterModal;