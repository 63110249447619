import OperatingHoursService from "../../services/OperatingHoursService";
import {Icon, PageRow} from "@renta-apps/athenaeum-react-components";
import styles from './TotalOperatingHours.module.scss';
import React from "react";
import {TotalOperatingHoursProps} from "@/components/TotalOperatingHours/TotalOperatingHours.types";
import Localizer from "@/localization/Localizer";

const TotalOperatingHours = ({ totalOperatingHours, device } : TotalOperatingHoursProps) => {
    if (OperatingHoursService.odometerChanged(device))
        return (
            <PageRow className={styles.totalOperatingHoursContainer}>
                <Icon name={"fas fa-tachometer-slow"} className={styles.odometerIcon}/> <span> {Localizer.dataServiceAttributesTotalOperatingHours}: {totalOperatingHours}h</span>
            </PageRow>
        );
    
    return null;
}

export default TotalOperatingHours;