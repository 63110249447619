import {Button, ButtonContainer, ButtonType, Dropdown, EmailInput, Form, PageRow, PhoneInput, Spinner, Tab, TabContainer, TextInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import {AuthType, NotificationType} from "@/models/Enums";
import PageDefinitions from "@/providers/PageDefinitions";
import UnleashHelper from "@/helpers/UnleashHelper";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import NotificationSettings from "@/components/NotificationSettings/NotificationSettings";
import React, {useEffect, useState} from "react";
import User from "@/models/server/User";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import SaveUserRequest from "@/models/server/requests/SaveUserRequest";
import SaveUserResponse from "@/models/server/responses/SaveUserResponse";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import TransformProvider from "@/providers/TransformProvider";
import {ch} from "@renta-apps/athenaeum-react-common";
import HttpClient from "@/common/HttpClient";
import UserContext from "@/models/server/UserContext";
import EndpointPaths from "@/common/EndpointPaths";

const Account = () : React.ReactNode => {

    const user: User = ch.getUser();
    const isAdmin: boolean = (ch.getContext() as UserContext).isAdmin;

    const [isLoading, setIsLoading] = useState(true);
    const [availableNotificationTypes, setAvailableNotificationTypes] = useState<NotificationType[]>([])

    const isNotificationSettingsEnabled: boolean = UnleashHelper.isEnabled(RentaToolsConstants.featureFlagNotificationSettingsEnabled);

    useEffect(() => {

        fetchNotificationTypes().then(notificationData => {
            setAvailableNotificationTypes(notificationData);
            setIsLoading(false);
        })

    }, []);

    const fetchNotificationTypes = async () : Promise<NotificationType[]> => {
        if (!isNotificationSettingsEnabled) {
            return [];
        }

        return HttpClient.postAsyncWithoutErrorHandling<NotificationType[]>(EndpointPaths.Account.GetNotificationTypes);
    }

    const handleSubmitAsync = async (data: Dictionary<string, any>): Promise<void> => {

        const request = new SaveUserRequest();
        request.id = user.id;
        request.authType = user.authType;
        request.canInvoiceFuelingAndWashing = user.canInvoiceFuelingAndWashing;
        request.canInvoiceAdditionalExpenses = user.canInvoiceAdditionalExpenses;

        Utility.copyTo(data, request, user);

        const response: SaveUserResponse = await HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.Account.SaveAccount, request);

        if (response.userAlreadyExists) {
            await ch.alertErrorAsync(Localizer.accountPageAlertErrorUserAlreadyExists, true);
            return;
        }

        if (response.invalidPhoneNumber) {
            await ch.alertErrorAsync(Localizer.accountPageAlertErrorInvalidPhoneNumber, true);
            return;
        }

        const message: string = Utility.format(Localizer.userManagementPageAlertMessageAsyncAccountSaved, TransformProvider.userToString(user));
        await ch.alertMessageAsync(message, true);
    }

    return (
        <PageRow>
            {
                (isLoading) && <Spinner/>
            }

            {
                (!isLoading) &&
                (
                    <div className="col">
                        <TabContainer id="accountTabs">
                            <Tab id="personal"
                                 title={Localizer.myAccountPageMyInfoTabTitle}
                            >
                                {
                                    (user) &&
                                    (
                                        <Form id="form" onSubmit={async (_, data) => await handleSubmitAsync(data)}>

                                            <TwoColumns>

                                                <EmailInput id="email"
                                                            label={Localizer.componentFormEmail}
                                                            value={user.email}
                                                            required={(user.authType == AuthType.Email)}
                                                            readonly={!!user.email && (user.authType == AuthType.Email)}/>

                                                <PhoneInput id="phone"
                                                            label={Localizer.componentFormPhone}
                                                            value={user.phone}
                                                            required={(user.authType == AuthType.Phone)}
                                                            readonly={!!user.phone && (user.authType == AuthType.Phone)}
                                                />

                                            </TwoColumns>

                                            <TwoColumns>

                                                <TextInput required
                                                           id="firstName"
                                                           label={Localizer.componentFormFirstName}
                                                           value={user.firstName} />

                                                <TextInput required
                                                           id="lastName"
                                                           label={Localizer.componentFormLastname}
                                                           value={user.lastName} />

                                            </TwoColumns>

                                            <TwoColumns>

                                                <TextInput id="middleName"
                                                           label={Localizer.componentFormMiddleName}
                                                           value={user.middleName}/>

                                                <Dropdown required
                                                          id="language"
                                                          label={Localizer.componentFormLanguage}
                                                          items={Localizer.supportedLanguages}
                                                          selectedItem={Localizer.findLanguage(user.language)}
                                                />

                                            </TwoColumns>

                                            <ButtonContainer>

                                                <Button type={ButtonType.Blue}
                                                        route={PageDefinitions.changePasswordRoute}
                                                        label={Localizer.changePasswordButton}/>

                                                <Button submit
                                                        type={ButtonType.Orange}
                                                        label={Localizer.componentFormSave} />

                                            </ButtonContainer>

                                        </Form>
                                    )
                                }
                            </Tab>

                            {
                                (isAdmin && availableNotificationTypes.length > 0 && UnleashHelper.isEnabled(RentaToolsConstants.featureFlagNotificationSettingsEnabled)) &&
                                (
                                    <Tab id="userNotificationsSettings"
                                         title={Localizer.myAccountPageNotificationTabTitle}
                                    >
                                        <NotificationSettings isGlobal={false}
                                                              availableNotificationTypes={availableNotificationTypes}
                                        />

                                    </Tab>
                                )
                            }

                        </TabContainer>

                    </div>
                )
            }

        </PageRow>
    )
}

export default Account;