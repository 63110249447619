import React from "react";
import {
    ButtonContainer,
    ButtonType,
    Checkbox,
    Dropdown,
    DropdownRequiredType, Icon,
    InlineType,
    NumberWidget, PageRow,
} from "@renta-apps/athenaeum-react-components";
import ResourceReportItem from "../Models/ResourceReportItem";
import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";
import {ResourceItemType} from "@/models/Enums";
import UserContext from "@/models/server/UserContext";
import BaseClassifier from "@/models/server/BaseClassifier";
import ReturnInspectionWizardPage from "@/pages/ReturnInspectionWizardPage";
import ResourceDefaultValueSelector from "@/helpers/ResourceDefaultValueSelector";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import UpdateReturnInspectionReportOperatingHoursRequest from "@/models/server/requests/UpdateReturnInspectionReportOperatingHoursRequest";
import {CalculateFutureOperatingHoursService} from "@/services/CalculateFutureOperatingHoursService";
import DeviceInfo from "@/models/server/DeviceInfo";
import {ch} from "@renta-apps/athenaeum-react-common";
import IReturnInspectionWizardPageState from "@/models/base/IReturnInspectionWizardPageState";
import NumbersHelper from "@/helpers/NumbersHelper";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import styles from "@/pages/ResourcePage/ResourcePage.module.scss";
import rentaToolsStyles from "../RentaTools.module.scss";
import resourceStyles from "./ResourcePage.module.scss";
import OperatingHoursService from "@/services/OperatingHoursService";
import {TotalOperatingHours} from "@/components/TotalOperatingHours";
import Show from "@/components/Show/Show";

export interface IResourcePageProps {
}

interface IResourcePageState extends IReturnInspectionWizardPageState {
    valueTypes: BaseClassifier[];
    isValid : boolean;
    edit: boolean;
    editedValue: number | null;
    calculatedMaxValue: number | null;
}

export default class ResourcePage extends ReturnInspectionWizardPage<IResourcePageProps, IResourcePageState> {

    state: IResourcePageState = {
        valueTypes: [],
        isValid: false,
        edit: false,
        editedValue: null,
        calculatedMaxValue: null,
    };

    private get reportItem(): ResourceReportItem {
        return this.getReportItem<ResourceReportItem>();
    }

    private get resourceType(): ResourceItemType {
        return this.reportItem.resourceType || ResourceItemType.Operating;
    }

    private get valueTypes(): BaseClassifier[] {
        return this.state.valueTypes;
    }

    private get fuelingAndWashingEnabled(): boolean {
        const userContext: UserContext = this.getContext();
        return ch.isNorway || ch.isDenmark || userContext.settings.fuelingAndWashingEnabled;
    }

    private get canInvoiceFuelingAndWashing(): boolean {
        return ReportDefinitionItem.canInvoiceFuelingAndWashing(this.reportItem, this.getContext());
    }

    private get isResourceInvoiceable(): boolean {
        const value: BaseClassifier | null = this.valueTypes.find(item => item.id == this.reportItem.valueTypeId) || null;
        return ((value != null) && (value.isInvoiceable));
    }

    private get invoiceFuelingAndWashingLabel(): string {
        switch (this.resourceType) {
            case ResourceItemType.Washing:
                return Localizer.fuelingAndWashingInvoiceWashing;
            case ResourceItemType.Fueling:
                return Localizer.fuelingAndWashingInvoiceFueling;
            case ResourceItemType.AdBlue:
                return Localizer.fuelingAndWashingInvoiceAdBlue;
        }
        return "";
    }

    private get valueTypeLabel(): string {
        switch (this.resourceType) {
            case ResourceItemType.Washing:
                return Localizer.fuelingAndWashingWashingType;
            case ResourceItemType.Fueling:
                return Localizer.fuelingAndWashingFuelType;
            case ResourceItemType.AdBlue:
                return Localizer.fuelingAndWashingAdBlue;
        }
        return "";
    }

    private get isInvoiceable(): boolean {
        if (ch.isNorway) {
            return false;
        }

        return (this.fuelingAndWashingEnabled && this.canInvoiceFuelingAndWashing && this.isResourceInvoiceable);
    }

    private get isInvoiced(): boolean {
        if (ch.isNorway) {
            return this.reportItem.invoiced ?? false;
        }

        return this.reportItem.invoiced || this.invoicing;
    }

    private get invoicing(): boolean {
        if (this.reportItem.invoiced == null && this.reportItem.value > 0) {
            this.reportItem.invoiced = true
        }

        return this.reportItem.invoiced == true;
    }

    private get canEditResource(): boolean {
        return this.canEdit && this.getReportItem().resourceType == ResourceItemType.Operating;
    }

    private get defaultTypeId(): string | null {
        return this.reportItem.valueTypeId;
    }

    private get maxOperatingHours(): number | undefined {

        if (this.reportItem.resourceType != ResourceItemType.Operating) {
            return undefined;
        }

        const value: number | null = (!this.state.edit || (this.state.edit && this.isLatestReport)) ? this.state.calculatedMaxValue : this.reportItem.max;

        return value ?? undefined;
    }

    private get minOperatingHours(): number | undefined {

        if (this.reportItem.resourceType != ResourceItemType.Operating) {
            return undefined;
        }

        return this.reportItem.min ?? undefined;
    }

    private get isLatestReport(): boolean {
        return (this.report.operatingHours! >= DeviceInfo.getTotalOperatingHours(RentaToolsController.device!)!) &&
            (this.report.startedAt.toDateString() == RentaToolsController.device!.lastOperatingHoursRecordTimestamp?.toDateString());
    }

    private async initializeValueTypeIdAsync(valueTypes: BaseClassifier[]): Promise<void> {
        if (!this.reportItem.valueTypeId) {
            if (this.resourceType == ResourceItemType.Fueling) {
                this.reportItem.valueTypeId = ResourceDefaultValueSelector.getResourceDefaultValue(RentaToolsController.device?.fuelTypeId, this.reportItem.defaultFuelTypeId, valueTypes);
                RentaToolsController.saveContext();
            }

            if (this.resourceType == ResourceItemType.AdBlue) {
                this.reportItem.valueTypeId = ResourceDefaultValueSelector.getResourceDefaultValue(RentaToolsController.device?.adBlueId, this.reportItem.defaultAdBlueTypeId, valueTypes);
                RentaToolsController.saveContext();
            }

            if (this.resourceType === ResourceItemType.Washing) {
                this.reportItem.valueTypeId = ResourceDefaultValueSelector.getResourceDefaultValue(undefined, this.reportItem.defaultWashingTypeId, valueTypes);
                RentaToolsController.saveContext();
            }
        }
    }

    private async setInvoicedAsync(invoiced: boolean): Promise<void> {
        if (this.reportItem.invoiced != invoiced) {
            this.reportItem.invoiced = invoiced;
            RentaToolsController.saveContext();
        }
    }

    private async setValueTypeAsync(value: BaseClassifier): Promise<void> {
        if (!value) {
            this.reportItem.valueTypeId = "";
            RentaToolsController.saveContext();
            await this.reRenderAsync();
            await this.validate();
            return;
        }

        if (this.reportItem.valueTypeId != value.id) {
            this.reportItem.valueTypeId = value.id;
            RentaToolsController.saveContext();
            await this.reRenderAsync();
        }

        await this.validate();
    }

    private async validate(): Promise<void> {
        if (this.isItemValid(this.reportItem)) {

            this.setState({isValid: true});
            return;
        }

        this.setState({isValid: false});
    }

    private async setValueAsync(value: number): Promise<void> {
        this.reportItem.value = Number(value.toFixed(2));

        // If report resource item invoiced has been edited and value is brought back to 0, we can set invoiced to false automatically
        if (value == 0 && !!this.reportItem.invoiced) {
            this.reportItem.invoiced = false;
        }

            // In cases where user has access and the invoiced value hasn't been changed yet and the value changes
        // we can select the checkbox automatically (as requested by finnish business)
        else if (!ch.isNorway && value > 0 && this.reportItem.invoiced == null && this.isInvoiceable) {
            this.reportItem.invoiced = true;
        }

        RentaToolsController.saveContext();

        await this.validate();

        if ((this.minOperatingHours != null) && (NumbersHelper.less(value, this.minOperatingHours))) {
            this.setState({isValid: false});
            await this.alertErrorAsync(Localizer.returnInspectionOperatingHoursMinError.format(this.minOperatingHours));
        } else if ((this.maxOperatingHours != null) && (NumbersHelper.greater(value, this.maxOperatingHours))) {
            this.setState({isValid: false});
            await this.alertErrorAsync(Localizer.returnInspectionOperatingHoursMaxError.format(this.maxOperatingHours));
        } else {
            await ch.hideAlertAsync();
        }

        await this.reRenderAsync();
    }

    private async updateValueAsync(value: number): Promise<void> {
        let newValue: number = Number(value.toFixed(2));

        if (newValue != this.initialStepItem?.value) {
            this.reportItem.value = Number(value.toFixed(2));
            this.setState({editedValue: newValue});
        }
    }

    private async saveOperatingHours(): Promise<void> {
        if (this.state.editedValue) {
            const request: UpdateReturnInspectionReportOperatingHoursRequest = {
                id: this.getReportItem().id,
                reportId: this.report.id,
                operatingHours: this.state.editedValue,
                deviceId: RentaToolsController.device!.id,
            }
            const valid = await ReturnInspectionController.updateReturnInspectionStepOperatingHours(request);
            super._needToProcessOnLeave = false;
            if (valid) {
                this.setState({edit: !this.state.edit, editedValue: null});
            }
        }
    }

    protected isNextButtonDisabled(): boolean {
        return !this.state.isValid;
    }

    public getWizardDescription(): string {
        return "";
    }

    public getReportItemValueOrDefault(): number {

        if (ch.isNorway && this.report.trackUnitOperatingHours && this.reportItem.resourceType === ResourceItemType.Operating) {
            return Number(this.report.trackUnitOperatingHours.toFixed(2));
        }

        return (this.reportItem.value !== null)
            ? Number(this.reportItem.value.toFixed(2))
            : this.reportItem.default!;
    }

    public async nextAsync(): Promise<void> {
        if (ResourceReportItem.isValid(this.reportItem)) {
            await super.nextAsync();
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const valueTypes: BaseClassifier[] = await RentaToolsController.getValueTypes(this.reportItem.resourceType, this.reportItem.valueTypeIds);

        await this.initializeValueTypeIdAsync(valueTypes);

        if (this.reportItem.value === null) {
            if (this.reportItem.default) {
                this.reportItem.value = this.reportItem.default;
            } else {
                this.reportItem.value = this.reportItem.min ?? 0;
            }
        }

        let calculatedMaxValue: number | null;
        if (this.reportItem.max) {
            calculatedMaxValue = this.reportItem.max;
        } else {
            const operatingHours: number = OperatingHoursService.getOdometer(RentaToolsController.device!)
            calculatedMaxValue = CalculateFutureOperatingHoursService.calculateMaxOperatingHoursFromLastService(operatingHours, RentaToolsController.device!) ?? null;
        }

        RentaToolsController.saveContext();

        this.setState({valueTypes, calculatedMaxValue: calculatedMaxValue});

        await this.validate();
    }

    private get displayTrackUnitHours(): boolean {
        return !ch.isNorway;
    }

    private get trackUnitOperatingHoursText(): string {
        return `${Localizer.genericOperatingHoursFromTrackUnit}: ${this.report.trackUnitOperatingHours?.toFixed(1)}`;
    }

    public renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                <div className={this.css(resourceStyles.stepTitle)}>{this.reportItem.title}</div>

                {
                    (this.displayTrackUnitHours && this.resourceType == ResourceItemType.Operating && this.report.trackUnitOperatingHours) &&
                    (
                        <p className={styles.numberWidgetExtraText}>
                            {this.trackUnitOperatingHoursText}
                        </p>
                    )
                }

                <NumberWidget className={this.css(rentaToolsStyles.arsenalNumberWidget)}
                              id={"numberWidget"}
                              format={((this.reportItem.step != null) && (this.reportItem.step % 1 == 0)) ? "0" : "0.0"}
                              step={this.reportItem.step || 0.0}
                              readonly={this.preview && !this.state.edit}
                              value={this.state.editedValue ?? this.getReportItemValueOrDefault()}
                              onChange={async (sender, value) => this.state.edit ? await this.updateValueAsync(value) : await this.setValueAsync(value)}
                />

                <Show.When isTrue={this.reportItem.resourceType === ResourceItemType.Operating}>
                    <TotalOperatingHours totalOperatingHours={OperatingHoursService.getTotalOperatingHours(RentaToolsController.device!)}
                                         device={RentaToolsController.device!}
                    />
                </Show.When>

                {
                    (this.preview && this.canEditResource) && (
                        <ButtonContainer>
                            <ArsenalButton action fullWidth big
                                           id={"ri_edit_button"}
                                           className={this.css(rentaToolsStyles.navigationButton, rentaToolsStyles.bigNavButton)}
                                           type={!this.state.edit ? ButtonType.Orange : ButtonType.Light}
                                           label={!this.state.edit ? Localizer.genericEdit : Localizer.genericCancel}
                                           onClick={async () => {
                                               this.setState({edit: !this.state.edit, editedValue: null});
                                           }}
                            />
                            <ArsenalButton action fullWidth big
                                           id={"ri_edit_button"}
                                           className={this.css(rentaToolsStyles.navigationButton, rentaToolsStyles.bigNavButton)}
                                           disabled={!this.state.edit}
                                           type={ButtonType.Orange}
                                           label={Localizer.genericSave}
                                           onClick={async () => {
                                               await this.saveOperatingHours()
                                           }}
                            />
                        </ButtonContainer>
                    )
                }

                {
                    (this.fuelingAndWashingEnabled) && (
                        <>
                            {
                                (this.valueTypes.length > 0) &&
                                (
                                    <Dropdown required
                                              requiredType={DropdownRequiredType.Manual}
                                              id={`value_type`}
                                              className={this.css(rentaToolsStyles.arsenalDropdown, (this.desktop) && styles.desktopDropdownStyle)}
                                              label={this.valueTypeLabel}
                                              disabled={this.preview}
                                              items={this.valueTypes}
                                              selectedItem={this.defaultTypeId ?? undefined}
                                              onChange={async (sender, value) => this.setValueTypeAsync(value!)}
                                    />
                                )
                            }

                            {
                                (this.isInvoiceable) &&
                                (
                                    <div className={rentaToolsStyles.arsenalCheckboxes}>
                                        <Checkbox inline
                                                  readonly={this.preview || (this.reportItem.value == 0)}
                                                  label={this.invoiceFuelingAndWashingLabel}
                                                  inlineType={InlineType.Right}
                                                  value={this.isInvoiced}
                                                  onChange={async (sender, value) => this.setInvoicedAsync(value)}
                                        />
                                    </div>
                                )
                            }
                        </>
                    )
                }

            </React.Fragment>
        );
    }
}