import React from "react";
import {ButtonType, Checkbox, Dropdown, DropdownRequiredType, InlineType, Link, NumberWidget, PageContainer, PageHeader, Spinner,} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import ReturnInspectionInvoice from "@/models/server/ReturnInspectionInvoice";
import DeviceContract from "@/models/server/DeviceContract";
import DeviceInfo from "@/models/server/DeviceInfo";
import Report from "@/pages/Models/Report";
import {ch, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import ProcessReturnInspectionInvoiceRequest from "@/models/server/requests/ProcessReturnInspectionInvoiceRequest";
import FindDeviceResponse from "@/models/server/responses/FindDeviceResponse";
import ReportDefinition from "@/pages/Models/ReportDefinition";
import FuelType from "@/models/server/FuelType";
import {InvoiceType, ResourceItemType, UserRole} from "@/models/Enums";
import WashingType from "@/models/server/WashingType";
import AdBlue from "@/models/server/AdBlue";
import ProcessReturnInspectionInvoiceResponse from "@/models/server/responses/ProcessReturnInspectionInvoiceResponse";
import SaveInvoiceRequest from "@/models/server/requests/SaveInvoiceRequest";
import InvoicePageParameters from "@/models/InvoicePageParameters";
import ToolsUtility from "@/helpers/ToolsUtility";
import AdditionalExpense from "@/models/server/AdditionalExpense";
import InvoiceAdditionalExpense from "@/models/server/InvoiceAdditionalExpense";
import User from "@/models/server/User";
import SaveInvoiceResponse from "@/models/server/responses/SaveInvoiceResponse";
import EndpointPaths from "@/common/EndpointPaths";
import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";
import ServiceInvoice from "@/models/server/ServiceInvoice";
import ServiceReport from "@/pages/Models/ServiceReport";
import ProcessServiceInvoiceRequest from "@/models/server/requests/ProcessServiceInvoiceRequest";
import ProcessServiceInvoiceResponse from "@/models/server/responses/ProcessServiceInvoiceResponse";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import RentaToolsController from "@/pages/RentaToolsController";
import HttpClient from "@/common/HttpClient";
import Localizer from "../../localization/Localizer";

import styles from "./InvoiceDetailsPage.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";

interface IInvoiceDetailsPageProps {
}

interface IInvoiceDetailsPageState {
    invoice: ReturnInspectionInvoice | ServiceInvoice | null;
    invoiceId: string | null,
    supportsAdBlue: boolean;
    supportsFueling: boolean;
    supportsWashing: boolean;
    invoiceType : InvoiceType;
    invoiceOtherResources: boolean;
    fueling: boolean;
    washing: boolean;
    adBlue: boolean;
    initialAdBlueAmount: number;
    initialFuelAmount: number;
    initialWashingTime: number;
    returnInspectionReportDefinition: ReportDefinition | null;
    fuelTypes: FuelType[];
    washingTypes: WashingType[];
    adBlues: AdBlue[];
    deviceContracts: DeviceContract[];
    manualFuelStep: number | null;
    additionalExpenses: AdditionalExpense[];
    initialInvoicedAdditionalExpenses: InvoiceAdditionalExpense[];
    isLoading: boolean;
}

export default class InvoiceDetailsPage extends AuthorizedPage<IInvoiceDetailsPageProps, IInvoiceDetailsPageState> {

    state: IInvoiceDetailsPageState = {
        invoiceId: null,
        supportsAdBlue: false,
        supportsFueling: false,
        supportsWashing: false,
        invoiceType: InvoiceType.ReturnInspectionInvoice,
        invoiceOtherResources: false,
        invoice: null,
        fueling: false,
        washing: false,
        adBlue: false,
        initialAdBlueAmount: 0,
        initialFuelAmount: 0,
        initialWashingTime: 0,
        returnInspectionReportDefinition: null,
        fuelTypes: [],
        washingTypes: [],
        adBlues: [],
        deviceContracts: [],
        manualFuelStep: null,
        additionalExpenses: [],
        initialInvoicedAdditionalExpenses: [],
        isLoading: false
    };

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.setState({isLoading: true});

        let invoice: ReturnInspectionInvoice | ServiceInvoice;
        let manualFuelStep: number | null = null;
        let returnInspectionReportDefinition: ReportDefinition | null = null;
        let params: InvoicePageParameters | null = this.parameters as InvoicePageParameters;

        if (Object.keys(params).length === 0 && !this.invoiceId) {
            invoice = new ReturnInspectionInvoice();
            await this.setState({invoiceType: InvoiceType.ManualInvoices});
        } else {

            await this.setState({invoiceId: params.invoiceId})

            switch (params.invoiceType) {
                case InvoiceType.ManualInvoices:
                    await this.setState({invoiceType: InvoiceType.ManualInvoices});
                    invoice = await this.postAsync("api/invoice/GetInvoice", params.invoiceId);
                    break;
                case InvoiceType.ReturnInspectionInvoice:
                    await this.setState({invoiceType: InvoiceType.ReturnInspectionInvoice});
                    invoice = await this.postAsync("api/invoice/getReturnInspectionInvoice", params.invoiceId) as ReturnInspectionInvoice;
                    returnInspectionReportDefinition = await this.postAsync("api/ReportDefinition/findReportDefinition", invoice.returnInspectionReport.reportDefinitionId);
                    break;
                case InvoiceType.ServiceInvoice:
                    await this.setState({invoiceType: InvoiceType.ServiceInvoice});
                    invoice = await this.postAsync("api/invoice/getServiceInvoice", params.invoiceId) as ServiceInvoice;
                    break;
                case InvoiceType.SkippedInspectionInvoice:
                    await this.setState({invoiceType: InvoiceType.SkippedInspectionInvoice});
                    invoice = await this.postAsync("api/invoice/GetSkippedInvoice", params.invoiceId);
                    break;
                default:
                    throw new Error("unknown type of invoice");
            }

            const initialInvoicedAdditionalExpenses: InvoiceAdditionalExpense[] = Utility.clone(invoice.additionalExpenses);

            await this.setState({invoice, initialInvoicedAdditionalExpenses});
        }

        let allFuelTypes: FuelType[];

        if (this.manualInvoice) {
            allFuelTypes = await RentaToolsController.getInvoiceableFuelTypesAsync();
            manualFuelStep = await RentaToolsController.getManualFuelStep();
        } else {
            allFuelTypes = await RentaToolsController.getFuelTypesAsync();
        }

        const [allWashingTypes, allAdBlues, deviceContracts, allAdditionalExpenses] = await Promise
            .all([
                (this.manualInvoice)
                    ? await RentaToolsController.getInvoiceableWashingTypesAsync()
                    : await RentaToolsController.getWashingTypesAsync(),
                (this.manualInvoice)
                    ? await RentaToolsController.getInvoiceableAdBluesAsync()
                    : await RentaToolsController.getAdBluesAsync(),
                await RentaToolsController.getDeviceContractsAsync(this.device.id, false),
                await this.getAdditionalExpenseTypes(),
            ]);

        if (invoice.deviceContract != null && !deviceContracts.some(c => c.id == invoice.deviceContract?.id)) {
            deviceContracts.push(invoice.deviceContract);
        }

        const fuelTypes: FuelType[] = allFuelTypes.filter(item => !item.isDeleted);
        const washingTypes: WashingType[] = allWashingTypes.filter(item => !item.isDeleted);
        const adBlues: AdBlue[] = allAdBlues.filter(item => !item.isDeleted);
        const additionalExpenses: AdditionalExpense[] = allAdditionalExpenses
            .where(item => (!item.isDeleted) && (!this.manualInvoice || item.isInvoiceable));

        const supportsAdBlue: boolean = (invoice.adBlueAmount != null);
        const supportsFueling: boolean = (invoice.fuelAmount != null);
        const supportsWashing: boolean = (invoice.washingTime != null);

        const initialAdBlueAmount: number = invoice.adBlueAmount || 0;
        const initialFuelAmount: number = invoice.fuelAmount || 0;
        const initialFuelTypeExist: boolean = invoice.fuelTypeId != null;
        const initialWashingTime: number = invoice.washingTime || 0;
        const initialWashingTypeExist: boolean = invoice.washingTypeId != null;
        const initialAdBlueExist: boolean = invoice.adBlueId != null;

        const fueling: boolean = ((this.manualInvoice) || (!invoice.declined) && (initialFuelAmount > 0) && (initialFuelTypeExist));
        const washing: boolean = ((this.manualInvoice) || (!invoice.declined) && (initialWashingTime > 0) && (initialWashingTypeExist));
        const adBlue: boolean = ((this.manualInvoice) || (!invoice.declined) && (initialAdBlueAmount > 0) && (initialAdBlueExist));

        if (this.invoicedAdditionalExpenses.length > 0 && additionalExpenses.length > 0) {
            this.invoicedAdditionalExpenses
                .where(item => additionalExpenses.find(type => type.id == item.typeId)?.isInvoiceable ?? false)
                .map(item => item.invoiced = (
                        (this.manualInvoice) ||
                        (
                            (this.isProcessed) ||
                            (!this.isProcessed && InvoiceAdditionalExpense.isValid(item))
                        )
                    )
                )
        }

        await this.setState({
            deviceContracts,
            supportsAdBlue,
            supportsFueling,
            supportsWashing,
            invoice,
            fueling,
            washing,
            adBlue,
            initialFuelAmount,
            initialWashingTime,
            initialAdBlueAmount,
            returnInspectionReportDefinition,
            fuelTypes,
            washingTypes,
            adBlues,
            manualFuelStep,
            additionalExpenses,
            isLoading: false
        });
    }

    private async returnBackAsync(): Promise<void> {
        await PageRouteProvider.back();
    }

    private async toDevicePage(): Promise<void> {
        await RentaToolsController.searchDeviceAsync(this.device.externalId);
    }

    private async setFuelingAsync(fueling: boolean): Promise<void> {
        await this.setState({fueling});
    }

    private async setContractIdAsync(contract: DeviceContract): Promise<void> {
        this.invoice.deviceContract = contract;
        this.invoice.deviceContractExternalId = contract.contractExternalId;

        await this.reRenderAsync();
    }

    private async setFuelTypeAsync(fuelType: FuelType): Promise<void> {
        this.invoice.fuelTypeId = fuelType.id;
        this.invoice.fuelType = fuelType;

        await this.setState({fueling: fuelType.isInvoiceable});

        await this.reRenderAsync();
    }

    private async setFuelAmountAsync(fuelAmount: number): Promise<void> {

        if (this.state.fueling) {
            this.invoice.fuelAmount = fuelAmount;
        } else
            this.invoice.fuelAmount = 0;

        if (fuelAmount === 0 && !this.manualInvoice) {
            await this.setState({fueling: false});
        }

        await this.reRenderAsync();
    }

    private async setAdBlueAmountAsync(adBlueAmount: number): Promise<void> {

        if (this.state.adBlue)
            this.invoice.adBlueAmount = adBlueAmount;
        else
            this.invoice.adBlueAmount = 0;

        if (adBlueAmount === 0 && !this.manualInvoice) {
            await this.setState({adBlue: false});
        }

        await this.reRenderAsync();
    }

    private async setWashingAsync(washing: boolean): Promise<void> {
        await this.setState({washing});
    }

    private async setWashingTypeAsync(washingType: WashingType): Promise<void> {
        this.invoice.washingTypeId = washingType.id;
        this.invoice.washingType = washingType;

        await this.setState({washing: washingType.isInvoiceable});

        await this.reRenderAsync();
    }

    private async setInvoiceOtherResourcesAsync(value: boolean): Promise<void> {
        await this.setState({invoiceOtherResources: value})
    }

    private async setAdBlueAsync(adBlue: boolean): Promise<void> {
        await this.setState({adBlue});
    }

    private async setAdBlueIdAsync(adBlue: AdBlue): Promise<void> {
        this.invoice.adBlueId = adBlue.id;
        this.invoice.adBlue = adBlue;

        await this.setState({adBlue: adBlue.isInvoiceable});

        await this.reRenderAsync();
    }

    private async setWashingTimeAsync(washingTime: number): Promise<void> {

        if (this.state.washing)
            this.invoice.washingTime = washingTime;
        else
            this.invoice.washingTime = 0;

        if (washingTime === 0 && !this.manualInvoice) {
            await this.setState({washing: false});
        }

        await this.reRenderAsync();
    }

    private async processInvoiceAsync(approve: boolean, decline: boolean, comment: string | null): Promise<void> {
        switch (this.state.invoiceType) {
            case InvoiceType.ManualInvoices:
            case InvoiceType.SkippedInspectionInvoice:
                await this.processManualInvoiceAsync(approve, decline);
                break;

            case InvoiceType.ReturnInspectionInvoice:
                await this.processReturnInspectionInvoiceAsync(approve, decline, comment);
                break;

            case InvoiceType.ServiceInvoice:
                await this.processServiceInvoiceAsync(approve, decline, comment);
                break;

            default:
                throw Error(`Unknown invoice type \"${this.state.invoiceType}\".`);
        }
    }

    private async processManualInvoiceAsync(approve: boolean, decline: boolean): Promise<void> {
        const invoiceFueling = this.state.fueling;
        const invoiceAdBlue = this.state.adBlue;
        const invoiceWashing = this.state.washing;

        const request: SaveInvoiceRequest = {
            invoiceId: this.isNewInvoice ? null : this.invoice.id,
            approve: approve,
            reject: decline,
            force: false,
            invoiceFueling: invoiceFueling && !!this.invoice.fuelAmount,
            invoiceAdBlue: invoiceAdBlue && !!this.invoice.adBlueAmount,
            invoiceWashing: invoiceWashing && !!this.invoice.washingTime,
            washingTypeId: (invoiceWashing && !!this.invoice.washingTime) ? this.invoice.washingTypeId : null,
            fuelTypeId: (invoiceFueling && !!this.invoice.fuelAmount) ? this.invoice.fuelTypeId : null,
            adBlueId: (invoiceAdBlue && !!this.invoice.adBlueAmount) ? this.invoice.adBlueId : null,
            fuelingAmount: this.invoice.fuelAmount ?? 0,
            washingTime: this.invoice.washingTime ?? 0,
            comment: this.invoice.comment,
            adBlueAmount: this.invoice.adBlueAmount ?? 0,
            additionalExpenses: this.invoice.additionalExpenses,
            deviceContractExternalId: this.invoice.deviceContractExternalId,
            deviceId: this.device!.id,
            invoiceType: this.state.invoiceType,
        }

        let response = await HttpClient.postAsync<SaveInvoiceResponse>(EndpointPaths.InvoicePaths.SaveInvoice, request, 120);

        let skip: boolean = false;

        if (response.success) {

            if (response.contractIsClosed) {
                const reopen: boolean = await this.confirmAsync(Localizer.invoiceDetailsPageAlertReopenContract);

                if (reopen) {
                    request.force = true;

                    response = await HttpClient.postAsync(EndpointPaths.InvoicePaths.SaveInvoice, request, 120);
                } else {
                    skip = true;
                }
            }

            if (!skip && response.success) {
                await this.redirectAsync();
                await ch.alertMessageAsync(Localizer.invoiceDetailsPageInvoiceSend, true);
            }
        }
    }

    private async processReturnInspectionInvoiceAsync(approve: boolean, decline: boolean, comment: string | null): Promise<void> {
        const request: ProcessReturnInspectionInvoiceRequest = {
            returnInspectionInvoiceId: this.invoice.id,
            approve: approve,
            decline: decline,
            comment: comment,
            fuelingAmount: this.invoice.fuelAmount || 0,
            adBlueAmount: this.invoice.adBlueAmount || 0,
            washingTime: this.invoice.washingTime || 0,
            invoiceFueling: this.state.fueling,
            invoiceWashing: this.state.washing,
            invoiceAdBlue: this.state.adBlue,
            fuelTypeId: this.invoice.fuelTypeId,
            washingTypeId: this.invoice.washingTypeId,
            adBlueId: this.invoice.adBlueId,
            additionalExpenses: this.invoice.additionalExpenses,
            force: false,
            invoiceType: this.state.invoiceType
        };

        let response: ProcessReturnInspectionInvoiceResponse = await HttpClient.postAsync(EndpointPaths.InvoicePaths.ProcessReturnInspectionInvoice, request, 120);

        let invoice: ReturnInspectionInvoice = response.invoice!;

        let skip: boolean = false;

        if (response.success) {

            if (response.contractIsClosed) {
                const reopen: boolean = await this.confirmAsync(Localizer.invoiceDetailsPageAlertReopenContract);

                if (reopen) {
                    request.force = true;

                    response = await HttpClient.postAsync(EndpointPaths.InvoicePaths.ProcessReturnInspectionInvoice, request, 120);

                    invoice = response.invoice!;
                } else {
                    skip = true;
                }
            }

            this.setState({invoice});

            if (!skip) {
                await this.redirectAsync();

                const message: string = (approve)
                    ? Localizer.invoiceDetailsPageInvoiceSend
                    : Localizer.invoiceDetailsPageAlertDeclined;

                await this.alertMessageAsync(message, true);
            }
        }
    }

    private async processServiceInvoiceAsync(approve: boolean, decline: boolean, comment: string | null): Promise<void> {
        const request = {
            serviceReportInvoiceId: this.invoice.id,
            approve: approve,
            decline: decline,
            comment: comment,
            additionalExpenses: this.invoice.additionalExpenses,
            force: false,
            invoiceType: this.state.invoiceType
        } as ProcessServiceInvoiceRequest;

        let response: ProcessServiceInvoiceResponse = await HttpClient.postAsync(EndpointPaths.InvoicePaths.ProcessServiceInvoice, request, 120);

        let invoice: ServiceInvoice = response.invoice!;

        let skip: boolean = false;

        if (response.success) {

            if (response.contractIsClosed) {
                const reopen: boolean = await this.confirmAsync(Localizer.invoiceDetailsPageAlertReopenContractForService);

                if (reopen) {
                    request.force = true;

                    response = await HttpClient.postAsync(EndpointPaths.InvoicePaths.ProcessServiceInvoice, request, 120);

                    invoice = response.invoice!;
                } else {
                    skip = true;
                }
            }

            this.setState({invoice});

            if (!skip) {
                await this.redirectAsync();

                const message: string = (approve)
                    ? Localizer.invoiceDetailsPageInvoiceSend
                    : Localizer.invoiceDetailsPageAlertDeclined;

                await this.alertMessageAsync(message, true);
            }
        }
    }

    private async redirectAsync(): Promise<void> {
        (this.isTechnician)
            ? await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute)
            : await PageRouteProvider.redirectAsync(PageDefinitions.invoicesRoute);
    }

    private async previewReturnInspectionAsync(step?: number | null): Promise<void> {
        if ((this.hasData) && (this.report) && (!this.report.skipped)) {

            const response: FindDeviceResponse = await RentaToolsController.findDeviceByIdAsync(this.device.id);

            if (response.device) {
                RentaToolsController.device = response.device;

                if (this.returnInspectionInvoice) {
                    RentaToolsController.reportPreview = this.report as Report;

                    if (step != null) {
                        await ReturnInspectionController.openStepAsync(step);
                    } else {
                        await PageRouteProvider.redirectAsync(PageDefinitions.reportPreviewRoute);
                    }
                } else if (this.serviceInvoice) {
                    RentaToolsController.servicePreview = this.report as ServiceReport;

                    await PageRouteProvider.redirectAsync(PageDefinitions.servicePreviewRoute);
                }
            }
        }
    }

    private async getAdditionalExpenseTypes(): Promise<AdditionalExpense[]> {
        return await RentaToolsController.getAdditionalExpensesAsync(true);
    }

    private async setAdditionalExpenseInvoicedAsync(invoiceAdditionalExpense: InvoiceAdditionalExpense, invoiced: boolean): Promise<void> {
        invoiceAdditionalExpense.invoiced = invoiced;

        await this.reRenderAsync();
    }

    private async setAdditionalExpenseValueAsync(invoiceAdditionalExpense: InvoiceAdditionalExpense, value: number): Promise<void> {
        if (invoiceAdditionalExpense.invoiced) {
            invoiceAdditionalExpense.value = value;
        } else {
            invoiceAdditionalExpense.value = 0;
        }

        if (value === 0 && !this.manualInvoice) {
            invoiceAdditionalExpense.invoiced = false;
        }

        await this.reRenderAsync();
    }

    private get isLoading(): boolean {
        return this.state.isLoading;
    }

    private get isTechnician(): boolean {
        return ch.getUser<User>().role === UserRole.Technician;
    }

    private get invoice(): ReturnInspectionInvoice | ServiceInvoice {
        return this.state.invoice!;
    }

    private get isProcessed(): boolean {
        return ((this.invoice.declined != null && this.invoice.declined) || (this.invoice.approved != null && this.invoice.approved));
    }

    private get returnInspectionReportDefinition(): ReportDefinition {
        return this.state.returnInspectionReportDefinition!;
    }

    private get fuelTypes(): FuelType[] {
        return this.state.fuelTypes;
    }

    private get washingTypes(): WashingType[] {
        return this.state.washingTypes;
    }

    private get adBlues(): AdBlue[] {
        return this.state.adBlues;
    }

    private get additionalExpenses(): AdditionalExpense[] {
        return this.state.additionalExpenses;
    }

    private get invoicedAdditionalExpenses(): InvoiceAdditionalExpense[] {
        let additionalExpenses: InvoiceAdditionalExpense[] = this.invoice?.additionalExpenses ?? [];

        if (additionalExpenses.length > 0) {
            additionalExpenses.order(item => item.returnInspectionStepNumber);
        }

        return additionalExpenses;
    }

    private get deviceContracts(): DeviceContract[] {
        return this.state.deviceContracts;
    }

    private get invoiceId(): string | null {
        return this.state.invoiceId;
    }

    private get device(): DeviceInfo {
        return this.invoice?.device ?? RentaToolsController.device!;
    }

    private get report(): Report | ServiceReport | null {
        return (this.invoice)
            ? (this.serviceInvoice)
                ? (this.invoice as ServiceInvoice).serviceReport
                : (this.invoice as ReturnInspectionInvoice).returnInspectionReport
            : null;
    }

    private get deviceContract(): DeviceContract | null {
        return this.invoice.deviceContract;
    }

    private get readonly(): boolean {
        return ((!this.hasData) || (this.invoice.approved == true) || (this.invoice.declined == true));
    }

    private get hasData(): boolean {
        return (this.invoice != null);
    }

    private get statusLabel(): string {
        if (this.isNewInvoice) {
            return Localizer.invoiceDetailsPageNewInvoice;
        }
        return (this.invoice.approved)
            ? Localizer.invoiceDetailsPageInvoiceStatusApproved
            : (this.invoice.declined)
                ? Localizer.invoiceDetailsPageInvoiceStatusDeclined
                : Localizer.invoiceDetailsPageInvoiceStatusPending;
    }

    private get fuelStep(): number | null {
        return (this.manualInvoice) ? this.state.manualFuelStep : null;
    }

    public get noContractSelected(): boolean {
        // Has no selected contract, or device contracts collection is empty.
        return this.deviceContracts?.length <= 0
            || this.selectedDeviceContractIdForManualInvoice == null
            || this.selectedDeviceContractIdForManualInvoice.length === 0;
    }

    public get canReject(): boolean {
        return !this.isNewInvoice;
    }

    private get isNewInvoice(): boolean {
        return this.invoice.id.length === 0;
    }

    private get canApprove(): boolean {
        const fuelingAmountToInvoice: number = (this.state.fueling) ? this.invoice.fuelAmount || 0 : 0;
        const adBlueAmountToInvoice: number = (this.state.adBlue) ? this.invoice.adBlueAmount || 0 : 0;
        const washingTimeToInvoice: number = (this.state.washing) ? this.invoice.washingTime || 0 : 0;
        const adBlueType: string | null = this.invoice.adBlueId;
        const invoiceFuelType: string | null = this.invoice.fuelTypeId;
        const invoiceWashingType: string | null = this.invoice.washingTypeId;
        const hasAdditionalExpenses: boolean = this.invoicedAdditionalExpenses.length > 0;
        const hasAdditionalExpensesToInvoice: boolean = this.invoicedAdditionalExpenses
            .some(item => InvoiceAdditionalExpense.readyForApproval(item));

        return (
            (adBlueAmountToInvoice > 0 && adBlueType != null) ||
            (fuelingAmountToInvoice > 0 && invoiceFuelType != null) ||
            (washingTimeToInvoice > 0 && invoiceWashingType != null) ||
            (hasAdditionalExpenses && hasAdditionalExpensesToInvoice)
        );
    }

    private get possibleFuelTypes(): FuelType[] {
        const availableFuelTypeIds: string[] = this.manualInvoice
            ? []
            : this
                .returnInspectionReportDefinition!
                .items
                .filter(item => (item.resourceType == ResourceItemType.Fueling) && (item.valueTypeId != null))
                .map(item => item.valueTypeId!);

        return (availableFuelTypeIds.length > 0)
            ? this.fuelTypes.filter(item => availableFuelTypeIds.includes(item.id))
            : this.fuelTypes;
    }

    private get isFuelTypeInvoiceable(): boolean {
        const value: FuelType | null = this.possibleFuelTypes.find(item => item.id == this.invoice.fuelTypeId) || null;
        return ((value != null) && (value.isInvoiceable));
    }

    private get possibleWashingTypes(): WashingType[] {
        const availableWashingTypeIds: string[] = this.manualInvoice
            ? []
            : this
                .returnInspectionReportDefinition!
                .items
                .filter(item => (item.resourceType == ResourceItemType.Washing) && (item.valueTypeId != null))
                .map(item => item.valueTypeId!);

        return (availableWashingTypeIds.length > 0)
            ? this.washingTypes.filter(item => availableWashingTypeIds.includes(item.id))
            : this.washingTypes;
    }

    private get isWashingTypeInvoiceable(): boolean {
        const value: WashingType | null = this.possibleWashingTypes.find(item => item.id == this.invoice.washingTypeId) || null;
        return ((value != null) && (value.isInvoiceable));
    }

    private get possibleAdBlues(): AdBlue[] {
        const availableAdBlueIds: string[] = this.manualInvoice
            ? []
            : this
                .returnInspectionReportDefinition!
                .items
                .filter(item => (item.resourceType == ResourceItemType.AdBlue) && (item.valueTypeId != null))
                .map(item => item.valueTypeId!);

        return (availableAdBlueIds.length > 0)
            ? this.adBlues.filter(item => availableAdBlueIds.includes(item.id))
            : this.adBlues;
    }

    private get isAdBlueTypeInvoiceable(): boolean {
        const value: AdBlue | null = this.possibleAdBlues.find(item => item.id == this.invoice.adBlueId) || null;
        return ((value != null) && (value.isInvoiceable));
    }

    private getInitialAdditionalExpenseAmountDetails(invoicedExpense: InvoiceAdditionalExpense, format: string): string {
        const initialInvoicedExpense: InvoiceAdditionalExpense | null = this
            .state
            .initialInvoicedAdditionalExpenses
            .firstOrDefault(item => item.id == invoicedExpense.id);

        return (initialInvoicedExpense != null)
            ? `{0:${format}} ({1:ExpenseUnit})`.format(initialInvoicedExpense.value, initialInvoicedExpense.unit)
            : "";
    }

    private getAdditionalExpenseName(invoicedExpense: InvoiceAdditionalExpense): string {
        return Localizer.invoiceDetailsPageAdditionalExpenseName.format(invoicedExpense.name, invoicedExpense.externalId);
    }

    private isAdditionalExpenseInvoiceable(expenseType: AdditionalExpense | null): boolean {
        return ((expenseType != null) && (expenseType.isInvoiceable));
    }

    private get selectedDeviceContractIdForManualInvoice(): string | null {
        if (this.deviceContract) {
            return this.deviceContract.contractExternalId;
        }

        return (!!this.device.currentContractExternalId)
            ? this.device.currentContractExternalId
            : this.device.lastContractExternalId ?? this.invoice.deviceContractExternalId;
    }

    public get title(): string {
        return (this.state.invoice != null)
            ? this.state.invoice.device!.name
            : Localizer.invoiceDetailsPageTitleNoDevice;
    }

    public get supportsFueling(): boolean {
        return (this.state.supportsFueling || (this.manualInvoice && this.isNewInvoice));
    }

    public get supportsAdBlue(): boolean {
        return (this.state.supportsAdBlue || (this.manualInvoice && this.invoiceOtherResources));
    }

    public get supportsWashing(): boolean {
        return (this.state.supportsWashing || (this.manualInvoice && this.invoiceOtherResources));
    }

    public get invoiceOtherResources(): boolean {
        return this.state.invoiceOtherResources;
    }

    public get manualInvoice(): boolean {
        return this.state.invoiceType === InvoiceType.ManualInvoices || this.state.invoiceType === InvoiceType.SkippedInspectionInvoice;
    }

    public get returnInspectionInvoice(): boolean {
        return this.state.invoiceType == InvoiceType.ReturnInspectionInvoice;
    }

    public get serviceInvoice(): boolean {
        return this.state.invoiceType == InvoiceType.ServiceInvoice;
    }

    public isSpinning(): boolean {
        return (this.isLoading || super.isSpinning());
    }

    private renderTitle(): React.ReactNode {
        const deviceProductGroupName: string = (this.hasData)
            ? this.device.productGroupName
            : "";
        const deviceType: string = (this.hasData)
            ? this.device.type
            : "";
        const deviceExternalId: string = (this.hasData) ? this.device.externalId : "";

        return (
            <React.Fragment>

                <div>
                    <div className={styles.multiTitle}>
                        <span className={styles.deviceProductGroup}>{deviceProductGroupName}</span>
                        <span className={styles.deviceType}>{deviceType}</span>
                        <span>{deviceExternalId}</span>
                    </div>
                </div>

            </React.Fragment>
        );
    }

    public renderAdditionalExpenses(invoicedExpenses: InvoiceAdditionalExpense[], index: number): React.ReactNode {
        const step: number | null = invoicedExpenses
            .map(item => item.returnInspectionStepNumber)
            .firstOrDefault();
        let stepTitle: string | null = null;

        if (this.returnInspectionInvoice) {
            if (step != null) {
                const stepItem: ReportDefinitionItem = (this.invoice as ReturnInspectionInvoice).returnInspectionReport.items[step];

                stepTitle = (stepItem)
                    ? `${Localizer.genericStepNumber.format(step + 1)}: \"${stepItem.name}\"`
                    : Localizer.genericStepNumber.format(step + 1);
            }
        }

        return (
            <div key={index}
                 className={this.css(this.mobile && styles.additionalExpenses)}>

                {
                    (!this.manualInvoice && step != null) &&
                    (
                        <Link className={styles.additionalExpensesStepLink}
                              route={() => this.previewReturnInspectionAsync(step)}>
                            {stepTitle}
                        </Link>
                    )
                }

                {
                    invoicedExpenses.map(item => this.renderAdditionalExpense(item, index))
                }

                {
                    (this.desktop) &&
                    (
                        <hr/>
                    )
                }

            </div>
        )
    }

    public renderAdditionalExpense(invoicedExpense: InvoiceAdditionalExpense, index: number): React.ReactNode {
        const expenseType: AdditionalExpense | null = this
            .additionalExpenses
            .firstOrDefault(item => (item.id == invoicedExpense.typeId));

        const step: number = (expenseType != null && !expenseType.isGeneric)
            ? expenseType.step
            : AdditionalExpense.getSuggestedStep(invoicedExpense.unit);

        const format: string = (step != null) && (step % 1 == 0)
            ? "0"
            : "0.00";

        return (
            <>
                <span className={styles.additionalExpenseName}>{this.getAdditionalExpenseName(invoicedExpense)}</span>

                <div className={this.manualInvoice ? "" : styles.value}>

                    {
                        (!this.manualInvoice) && (
                            <Checkbox inline
                                      id={`additionalExpenseInvoiced_${index}`}
                                      readonly={this.readonly || !this.isAdditionalExpenseInvoiceable(expenseType)}
                                      inlineType={InlineType.Right}
                                      value={(this.isProcessed && this.isAdditionalExpenseInvoiceable(expenseType)) || invoicedExpense.invoiced}
                                      onChange={(_, value) => this.setAdditionalExpenseInvoicedAsync(invoicedExpense, value)}
                            />
                        )
                    }

                    <NumberWidget id={`setAdditionalExpenses_${index}`}
                                  className={rentaToolsStyles.arsenalNumberWidget}
                                  label={this.getInitialAdditionalExpenseAmountDetails(invoicedExpense, format)}
                                  format={format}
                                  step={step}
                                  min={0}
                                  max={expenseType?.max}
                                  readonly={this.readonly}
                                  value={invoicedExpense.value || 0}
                                  onChange={(_, value) => this.setAdditionalExpenseValueAsync(invoicedExpense, value)}
                    />

                </div>
            </>
        )
    }

    public render(): React.ReactNode {
        const inspectedAt: string = (this.report != null)
            ? (this.serviceInvoice)
                ? ToolsUtility.toDateString((this.report as ServiceReport).date)
                : ToolsUtility.toDateString((this.report as Report).completedAt)
            : "";

        const groupedAdditionalExpenses: InvoiceAdditionalExpense[][] = this
            .invoicedAdditionalExpenses
            .groupBy(item => item.returnInspectionStepNumber);

        const dropDownCss: string = this.css(rentaToolsStyles.arsenalDropdown, (!this.mobile && !this.manualInvoice) && styles.desktopDropdownStyle, (this.manualInvoice && !this.mobile) && styles.newInvoiceDesktopDropdownStyle);

        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(rentaToolsStyles.pageContainer, styles.returnInspectionInvoiceDetails)}>

                {
                    (this.isSpinning()) && <Spinner global/>
                }

                <PageHeader title={() => this.renderTitle()} className={this.css(rentaToolsStyles.leftPageHeader)}>

                    {
                        (!this.manualInvoice) && (
                            <div className={styles.deviceActions}>

                                <ArsenalButton block secret label={Localizer.invoiceDetailsPageButtonLabelPreview}
                                               className={this.css(rentaToolsStyles.arsenalButton, styles.infoButton)}
                                               onClick={async () => await this.previewReturnInspectionAsync()}
                                />

                            </div>
                        )
                    }


                </PageHeader>

                <ArsenalPageRow>

                    <div className="col">

                        <div className={styles.container}>

                            {
                                (this.hasData) &&
                                (
                                    <React.Fragment>

                                        <div>

                                            <table className={this.css(styles.table, "table table-striped")}>

                                                <tr>
                                                    <td>{Localizer.invoiceDetailsPageCreatedAt}</td>
                                                    <td>{ToolsUtility.toDateString(this.invoice.createdAt)}</td>
                                                </tr>

                                                <tbody>
                                                {
                                                    (!this.manualInvoice && this.report) && (
                                                        <>
                                                            <tr>
                                                                <td>{Localizer.invoiceDetailsPageInspectedAt}</td>
                                                                <td>{inspectedAt}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{Localizer.invoiceDetailsPageInspectedBy}</td>
                                                                <td>{"{0}".format(this.report.user)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{Localizer.invoiceDetailsPageDepo}</td>
                                                                <td>{"{0}".format(this.report.depo)}</td>
                                                            </tr>
                                                            {
                                                                (this.returnInspectionInvoice) &&
                                                                (
                                                                    <tr>
                                                                        <td>{Localizer.invoiceDetailsPageInspectionResult}</td>
                                                                        <td>
                                                                            {
                                                                                ((this.report as Report).passed)
                                                                                    ? Localizer.invoiceDetailsPageInspectionPassed
                                                                                    : Localizer.invoiceDetailsPageInspectionFailed
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }

                                                {
                                                    (this.deviceContract) && (
                                                        <>
                                                            <tr>
                                                                <td>{Localizer.invoiceDetailsPageCustomerName}</td>
                                                                <td>{"{0}".format(this.deviceContract.customerName || "-")}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{Localizer.invoiceDetailsPageConstructionSite}</td>
                                                                <td>{"{0}".format(this.deviceContract.constructionSiteName || "-")}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{Localizer.invoiceDetailsPageContractId}</td>
                                                                <td>{"{0}".format(this.deviceContract.contractExternalId)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{Localizer.invoiceDetailsPageConstructionSiteNumber}</td>
                                                                <td>{this.deviceContract.constructionSiteExternalId ? "{0}".format(this.deviceContract.constructionSiteExternalId) : "-"}</td>
                                                            </tr>
                                                        </>
                                                    )
                                                }

                                                {
                                                    (this.invoice.approved) &&
                                                    (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>{Localizer.invoiceDetailsPageApprovedAt}</td>
                                                                <td>{ToolsUtility.toDateString(this.invoice.approvedAt)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{Localizer.invoiceDetailsPageApprovedBy}</td>
                                                                <td>{"{0}".format(this.invoice.approvedBy)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{Localizer.invoiceDetailsPageComment}</td>
                                                                <td>{"{0}".format(this.invoice.comment)}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                }
                                                {
                                                    (this.invoice.declined) &&
                                                    (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>{Localizer.invoiceDetailsPageDeclinedAt}</td>
                                                                <td>{ToolsUtility.toDateString(this.invoice.declinedAt)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{Localizer.invoiceDetailsPageDeclinedBy}</td>
                                                                <td>{"{0}".format(this.invoice.declinedBy)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{Localizer.invoiceDetailsPageComment}</td>
                                                                <td>{"{0}".format(this.invoice.comment)}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                }
                                                </tbody>
                                            </table>

                                        </div>

                                        <div id={"statusInfo"}
                                             className={styles.info}
                                        >
                                            <p>
                                                {Localizer.invoiceDetailsPageInvoiceStatus}
                                                <span>{this.statusLabel}</span>
                                            </p>
                                        </div>

                                        {
                                            (this.manualInvoice) && (
                                                <Dropdown required
                                                          id={"deviceContractId"}
                                                          className={dropDownCss}
                                                          label={Localizer.invoiceDetailsPageContractId}
                                                          items={this.deviceContracts}
                                                          selectedItem={this.selectedDeviceContractIdForManualInvoice}
                                                          disabled={this.readonly}
                                                          onChange={async (sender, value) => await this.setContractIdAsync(value!)}
                                                />
                                            )
                                        }

                                        {
                                            (this.supportsFueling) &&
                                            (
                                                <React.Fragment>

                                                    <div className={this.manualInvoice ? "" : styles.value}>
                                                        {
                                                            (!this.manualInvoice) && (
                                                                <Checkbox inline
                                                                          readonly={this.readonly || !this.isFuelTypeInvoiceable}
                                                                          inlineType={InlineType.Right}
                                                                          value={this.state.fueling}
                                                                          onChange={async (sender, value) => this.setFuelingAsync(value)}
                                                                />
                                                            )
                                                        }

                                                        <NumberWidget id={"setFueling"}
                                                                      className={this.css(rentaToolsStyles.arsenalNumberWidget)}
                                                                      label={Localizer.get(Localizer.invoiceDetailsPageNumberInputLabelInvoiceFueling, "({0:0.0} l)".format(this.state.initialFuelAmount))}
                                                                      format={"0.0"}
                                                                      step={this.fuelStep || 0.1}
                                                                      min={0}
                                                                      max={99999}
                                                                      readonly={this.readonly}
                                                                      value={this.invoice.fuelAmount || 0}
                                                                      onChange={async (sender, value) => await this.setFuelAmountAsync(value)}
                                                        />

                                                    </div>

                                                    {
                                                        (this.possibleFuelTypes.length > 0) &&
                                                        (
                                                            <Dropdown id={`fuelTypes`} required
                                                                      requiredType={DropdownRequiredType.Restricted}
                                                                      className={dropDownCss}
                                                                      label={Localizer.invoiceDetailsPageDropdownFuelType}
                                                                      disabled={this.readonly}
                                                                      items={this.possibleFuelTypes}
                                                                      selectedItem={this.invoice.fuelTypeId || undefined}
                                                                      onChange={async (sender, value) => await this.setFuelTypeAsync(value!)}
                                                            />
                                                        )
                                                    }

                                                </React.Fragment>
                                            )
                                        }

                                        {
                                            (this.manualInvoice && this.isNewInvoice) &&
                                            (
                                                <Checkbox inline
                                                          id={"invoiceOtherResourcesCheckbox"}
                                                          className={styles.invoiceOtherResourcesCheckbox}
                                                          label={Localizer.invoiceDetailsPageInvoiceOther}
                                                          inlineType={InlineType.Right}
                                                          value={this.invoiceOtherResources}
                                                          onChange={async (sender, checked) => await this.setInvoiceOtherResourcesAsync(checked)}
                                                />
                                            )
                                        }


                                        {
                                            (this.supportsAdBlue) &&
                                            (
                                                <React.Fragment>

                                                    <div className={this.manualInvoice ? "" : styles.value}>
                                                        {
                                                            (!this.manualInvoice) && (
                                                                <Checkbox inline
                                                                          readonly={this.readonly || !this.isAdBlueTypeInvoiceable}
                                                                          inlineType={InlineType.Right}
                                                                          value={this.state.adBlue}
                                                                          onChange={async (sender, value) => this.setAdBlueAsync(value)}
                                                                />

                                                            )
                                                        }

                                                        <NumberWidget id={"setAdBlue"}
                                                                      className={this.css(rentaToolsStyles.arsenalNumberWidget)}
                                                                      label={Localizer.get(Localizer.invoiceDetailsPageNumberInputLabelInvoiceAdBlue, "({0:0.0} l)".format(this.state.initialAdBlueAmount))}
                                                                      format={"0.0"}
                                                                      step={0.1}
                                                                      min={0}
                                                                      max={99999}
                                                                      readonly={this.readonly}
                                                                      value={this.invoice.adBlueAmount || 0}
                                                                      onChange={async (sender, value) => await this.setAdBlueAmountAsync(value)}
                                                        />

                                                    </div>

                                                    {
                                                        (this.possibleAdBlues.length > 0) &&
                                                        (
                                                            <Dropdown id={`adBlues`} required
                                                                      className={dropDownCss}
                                                                      label={Localizer.invoiceDetailsPageDropdownAdBlue}
                                                                      disabled={this.readonly}
                                                                      items={this.possibleAdBlues}
                                                                      selectedItem={this.invoice.adBlueId || undefined}
                                                                      onChange={async (sender, value) => await this.setAdBlueIdAsync(value!)}
                                                            />
                                                        )
                                                    }

                                                </React.Fragment>
                                            )
                                        }

                                        {
                                            (this.supportsWashing) &&
                                            (
                                                <React.Fragment>

                                                    <div className={this.manualInvoice ? "" : styles.value}>

                                                        {
                                                            (!this.manualInvoice) && (
                                                                <Checkbox inline
                                                                          readonly={this.readonly || !this.isWashingTypeInvoiceable}
                                                                          inlineType={InlineType.Right}
                                                                          value={this.state.washing}
                                                                          onChange={async (sender, value) => this.setWashingAsync(value)}
                                                                />
                                                            )
                                                        }


                                                        <NumberWidget id={"setWashing"}
                                                                      className={this.css(rentaToolsStyles.arsenalNumberWidget)}
                                                                      label={Localizer.get(Localizer.invoiceDetailsPageNumberInputLabelInvoiceWashing, "({0:0.0} h)".format(this.state.initialWashingTime))}
                                                                      format={"0.0"}
                                                                      step={0.1}
                                                                      min={0}
                                                                      max={99999}
                                                                      readonly={this.readonly}
                                                                      value={this.invoice.washingTime || 0}
                                                                      onChange={async (sender, value) => await this.setWashingTimeAsync(value)}
                                                        />

                                                    </div>

                                                    {
                                                        (this.possibleWashingTypes.length > 0) &&
                                                        (
                                                            <Dropdown id={`washingTypes`} required
                                                                      className={dropDownCss}
                                                                      label={Localizer.invoiceDetailsPageDropdownWashingType}
                                                                      disabled={this.readonly}
                                                                      items={this.possibleWashingTypes}
                                                                      selectedItem={this.invoice.washingTypeId || undefined}
                                                                      onChange={async (sender, value) => await this.setWashingTypeAsync(value!)}
                                                            />
                                                        )
                                                    }

                                                </React.Fragment>
                                            )
                                        }

                                        {
                                            (this.invoicedAdditionalExpenses.length > 0) &&
                                            (
                                                <>
                                                    <p className={styles.additionalExpensesTitle}>{Localizer.invoiceDetailsPageAdditionalExpensesTitle}</p>

                                                    {groupedAdditionalExpenses.map((item, index) => this.renderAdditionalExpenses(item, index))}
                                                </>
                                            )
                                        }

                                        {
                                            (this.manualInvoice && this.isNewInvoice) && (
                                                <ArsenalButton block big
                                                               type={ButtonType.Orange}
                                                               className={rentaToolsStyles.arsenalButton}
                                                               icon={{name: "far save"}}
                                                               label={Localizer.genericSave}
                                                               disabled={this.readonly || !this.canApprove || this.noContractSelected}
                                                               confirm={{title: Localizer.invoiceDetailsPageModalApproveInvoice, comment: false}}
                                                               onClick={async (_, data) => await this.processInvoiceAsync(true, false, data)}
                                                />
                                            )
                                        }
                                        {
                                            (!this.isNewInvoice) && (
                                                <>
                                                    <ArsenalButton block big
                                                                   id={"approveInvoice"}
                                                                   type={ButtonType.Success}
                                                                   className={rentaToolsStyles.arsenalButton}
                                                                   icon={{name: "far thumbs-up"}}
                                                                   label={Localizer.invoiceDetailsPageButtonLabelApprove}
                                                                   disabled={this.readonly || !this.canApprove}
                                                                   confirm={{title: Localizer.invoiceDetailsPageModalApproveInvoice, comment: false}}
                                                                   onClick={async (_, data) => await this.processInvoiceAsync(true, false, data)}
                                                    />

                                                    <ArsenalButton block big
                                                                   id={"declineInvoice"}
                                                                   type={ButtonType.Blue}
                                                                   className={rentaToolsStyles.arsenalButton}
                                                                   icon={{name: "far thumbs-down"}}
                                                                   label={Localizer.invoiceDetailsPageButtonLabelDecline}
                                                                   disabled={this.readonly || !this.canReject}
                                                                   confirm={{title: Localizer.invoiceDetailsPageModalDeclineInvoice, comment: true}}
                                                                   onClick={async (sender, data) => await this.processInvoiceAsync(false, true, data)}
                                                    />
                                                </>
                                            )
                                        }

                                        <ArsenalButton block
                                                       className={rentaToolsStyles.arsenalButton}
                                                       type={ButtonType.Orange}
                                                       label={Localizer.devicePageToDevicePage}
                                                       onClick={async () => await this.toDevicePage()}/>

                                        <ArsenalButton block big
                                                       type={ButtonType.Orange}
                                                       className={rentaToolsStyles.arsenalButton}
                                                       label={Localizer.devicePageReturnBack}
                                                       onClick={async () => await this.returnBackAsync()}
                                        />

                                    </React.Fragment>
                                )
                            }

                        </div>

                    </div>

                </ArsenalPageRow>

            </PageContainer>
        );
    }
}