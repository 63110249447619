import {FileModel, Utility} from "@renta-apps/athenaeum-toolkit";
import {UploadFullImageResponse} from "@/models/UploadFullImageResponse";
import {ch, ILayoutPage} from "@renta-apps/athenaeum-react-common";
import PreSignedUrlItem from "@/pages/Models/PreSignedUrlItem";
import EndpointPaths from "@/common/EndpointPaths";
import UploadFileResponse from "@/models/server/responses/UploadFileResponse";
import {ImageValidationResult} from "@/models/Enums";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import FileUploadFailedRequest from "@/models/server/FileUploadFailedRequest";
import HttpClient from "@/common/HttpClient";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "@/localization/Localizer";

class FileService {

    private async validateImageOnUpload(file: FileModel): Promise<boolean> {
        let allowedExtensions =
            /(\.jpg|\.jpeg|\.png|\.heic)$/i;

        if (!allowedExtensions.exec(file.name)) {
            await ch.alertErrorAsync(Localizer.componentImageInputImageTypeNotSupported, true);
            return false;
        }

        const index = file.name.lastIndexOf('.');
        const pureName = file.name.slice(0, index);
        const extension = file.name.slice(index).toLowerCase();

        file.name = `${pureName}${extension}`;

        return true;
    }

    private async uploadEnd(fileName: string): Promise<void> {
        if (UnleashHelper.isEnabled(RentaToolsConstants.featureMeasureUploadTimeEnabled)) {
            await HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.FilePaths.FileUploadEnd(fileName));
        }
    }

    private async convertImage(fileModel: FileModel): Promise<FileModel | null> {
        const response = await HttpClient.postAsyncWithoutErrorHandling<UploadFileResponse>(EndpointPaths.FilePaths.ConvertImage, fileModel);

        switch (response.validationResult) {
            case ImageValidationResult.ResolutionTooHigh:
                await ch.alertErrorAsync(Localizer.componentImageInputResolutionTooBig, true);
                break;
            case ImageValidationResult.UnsupportedFormat:
                await ch.alertErrorAsync(Localizer.componentImageInputImageTypeNotSupported, true);
                break;
        }

        return response.fileModel;
    }

    public async fileUploadFailedAsync(fileName: string, errorMessage: string, statusCode: string | null = null): Promise<void> {
        const request: FileUploadFailedRequest = new FileUploadFailedRequest();

        request.fileName = fileName;
        request.message = errorMessage;
        
        if (statusCode) {
            request.statusCode = statusCode;   
        }

        await HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.FilePaths.FileUploadFailed, request);
    }

    public async invokeUploadImageToS3Async(fileName: string, url: string, requestOptions: any, attempts: number = 3, delayInMs: number = 100, attempt: number = 1): Promise<boolean> {
        try {
            const response: Response = await fetch(url, requestOptions);

            if (response.ok) {
                return true;
            }

            if (attempt >= attempts) {
                await this.fileUploadFailedAsync(fileName, response.statusText, response.status.toString());
                
                return false;
            }
        } catch (error) {
            if (attempt >= attempts) {
                await this.fileUploadFailedAsync(fileName, `Failed to upload an image after the ${attempt} attempt.`);
                
                return false;
            }
        }

        await Utility.wait(delayInMs);

        return await this.invokeUploadImageToS3Async(fileName, url, requestOptions, attempts, delayInMs, ++attempt);
    }

    public async uploadFullImageAsync(file: FileModel): Promise<UploadFullImageResponse | null> {
        await this.validateImageOnUpload(file);

        const layout: ILayoutPage = ch.getLayout();
        await layout.setSpinnerAsync(true);

        // Get url to upload file to. Don't need to send image data
        let noBodyFile: FileModel = {...file, src: ""};
        const preSignedUrl: PreSignedUrlItem = await HttpClient.postAsyncWithoutErrorHandling<PreSignedUrlItem>(EndpointPaths.FilePaths.CreatePreSignedUrl, noBodyFile);

        if (preSignedUrl.useLocalInsteadOfS3) {
            await layout.setSpinnerAsync(false);

            const response: FileModel | null = await this.convertImage(file);

            if (response == null) {
                return null;
            }

            await this.uploadEnd(response.name);

            return UploadFullImageResponse.Create(response, file.src, file.src);
        }

        // Spinner while uploading
        try {
            // Image data to blob
            let fileBlob: Blob = await fetch(file.src).then(r => r.blob());

            const requestOptions = {
                method: 'PUT',
                body: fileBlob,
            };

            const success: boolean = await this.invokeUploadImageToS3Async(file.name, preSignedUrl.url, requestOptions);

            if (!success) {
                const message: string = Localizer.get(Localizer.rentaToolsControllerErrorUploadingFullSizedImageFailed);
                await ch.alertErrorAsync(message, true);
                return null;
            }
        } finally {
            await this.uploadEnd(preSignedUrl.fileModel?.name!);
            await layout.setSpinnerAsync(false);
        }

        return UploadFullImageResponse.Create(preSignedUrl.fileModel!, file.src, file.src);
    }
}

export default new FileService();