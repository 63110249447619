import styles from "@/pages/DevicesManagementPage/DevicesManagementPage.module.scss";
import {CellAction, CellModel, ColumnActionType, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridOddType, PageRow, RowModel, ToolbarContainer} from "@renta-apps/athenaeum-react-components";
import Toolbar from "@/pages/DevicesManagementPage/Toolbar/Toolbar";
import React, {useEffect, useState} from "react";
import DeviceInfo from "@/models/server/DeviceInfo";
import Localizer from "@/localization/Localizer";
import {ActionType} from "@renta-apps/athenaeum-react-common";
import AnnualInspectionController from "@/pages/AnnualInspectionController";
import {DeviceBanOfUse} from "@/models/Enums";
import AdBlue from "@/models/server/AdBlue";
import RentaToolsController from "@/pages/RentaToolsController";
import FuelType from "@/models/server/FuelType";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import ListDevicesRequest from "@/models/server/requests/ListDevicesRequest";
import DeviceDetailsPanel from "@/pages/DevicesManagementPage/DeviceDetailsPanel/DeviceDetailsPanel";
import ToolbarModel from "@/models/server/ToolbarModel";
import HttpClient from "@/common/HttpClient";
import EndpointPaths from "@/common/EndpointPaths";

const DeviceManagement = () : React.ReactNode => {

    const _devicesGridRef: React.RefObject<Grid<DeviceInfo>> = React.createRef();

    const _devicesGridColumns: ColumnDefinition[] = [
        {
            minWidth: 50,
            noWrap: true,
            className: "grey",
            actions: [
                {
                    title: "Details",
                    type: ColumnActionType.Details,
                    callback: async (cell) => await toggleDetailsAsync(cell)
                }
            ]
        },
        {
            header: Localizer.devicesManagementPageDeviceExternalIdLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.externalId),
            minWidth: 50,
            type: ColumnType.Text,
            noWrap: true,
            sorting: true,
            init: (cell) => initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceTypeLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.type),
            minWidth: 150,
            type: ColumnType.Text,
            noWrap: true,
            sorting: true,
            init: (cell) => initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceDepoCostPoolLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.depoName),
            minWidth: 50,
            type: ColumnType.Text,
            noWrap: true,
            sorting: true,
            init: (cell) => initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceStatusLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.status),
            format: "DeviceStatus",
            minWidth: 100,
            noWrap: true,
            sorting: true,
            init: (cell) => initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDevicePreviousStatusLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.previousStatus),
            format: "DeviceStatus",
            minWidth: 225,
            noWrap: true,
            sorting: true,
            init: (cell) => initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceContractIdLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.currentContractExternalId),
            minWidth: 150,
            type: ColumnType.Text,
            noWrap: true,
            sorting: true,
            init: (cell) => initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceReturnInspectionTypeLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.returnInspectionReportDefinitionName),
            type: ColumnType.Text,
            minWidth: 225,
            noWrap: true,
            init: (cell) => initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceServiceTypeLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.serviceDefinitionName),
            type: ColumnType.Text,
            minWidth: 225,
            noWrap: true,
            init: (cell) => initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceNameLanguageItemName,
            accessor: nameof<DeviceInfo>(d => d.name),
            minWidth: 200,
            type: ColumnType.Text,
            noWrap: true,
            sorting: true,
            init: (cell) => initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceUnderServiceLanguageItemName,
            minWidth: 100,
            type: ColumnType.Custom,
            sorting: true,
            noWrap: true,
            accessor: nameof<DeviceInfo>(d => d.underService),
            transform: (cell, value) => value ? "✓" : "",
            init: (cell) => initDeviceCell(cell)
        },
        {
            header: Localizer.devicesManagementPageDeviceUnderInspectionLanguageItemName,
            minWidth: 100,
            type: ColumnType.Custom,
            noWrap: true,
            sorting: true,
            accessor: nameof<DeviceInfo>(d => d.underInspection),
            transform: (cell, value) => value ? "✓" : "",
            init: (cell) => initDeviceCell(cell)
        },
        {
            minWidth: "75px",
            removable: false,
            init: (cell) => initDeviceInfoActions(cell),
            actions: [
                {
                    name: "cancel",
                    title: Localizer.devicesManagementRemoveBanOfUse,
                    confirm: Localizer.devicesManagementRemoveBanOfUseConfirm,
                    icon: "far lock-open",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await processDeviceInfoActionAsync(cell, action)
                }
            ]
        }
    ];

    const [filters, setFilters] = useState<ToolbarModel>(new ToolbarModel());
    const [adBlueTypes, setAdBlueTypes] = useState<AdBlue[]>([])
    const [fuelTypes, setFuelTypes] = useState<FuelType[]>([])
    const [loadingAdBlues, setLoadingAdBlues] = useState<boolean>(true)
    const [loadingFuelTypes, setLoadingFuelTypes] = useState<boolean>(true)

    useEffect(() => {
        fetchFuelTypesAsync().then(fuelTypes => {
            const fuels = fuelTypes.filter(type => !type.isDeleted);
            setFuelTypes(fuels);
            setLoadingFuelTypes(false)
        })

        fetchAdBluesAsync().then(adBlueTypes => {
            const adBlues = adBlueTypes.filter(type => !type.isDeleted);
            setAdBlueTypes(adBlues);
            setLoadingAdBlues(false)
        })
    }, []);

    const isLoading = () => loadingAdBlues || loadingFuelTypes;

    const processDeviceInfoActionAsync = async (cell: CellModel<DeviceInfo>, action: CellAction<DeviceInfo>): Promise<void> => {

        if (action.action.name === "cancel") {

            await AnnualInspectionController.removeDeviceBanOfUseAsync(cell.model.id)

            await _devicesGridRef.current!.model.reloadAsync();
        }
    }

    const initDeviceInfoActions = async (cell: CellModel<DeviceInfo>): Promise<void> => {
        const unBanAction: CellAction<DeviceInfo> = cell.actions[0];

        unBanAction.visible = ((cell.model.deviceBanOfUse == DeviceBanOfUse.Banned || cell.model.deviceBanOfUse == DeviceBanOfUse.NeedBan));
    }

    const fetchAdBluesAsync = async (): Promise<AdBlue[]> => {
        return await RentaToolsController.getAdBluesAsync();
    }

    const fetchFuelTypesAsync = async (): Promise<FuelType[]> => {
        return await RentaToolsController.getFuelTypesAsync();
    }

    const initDeviceCell = (cell: CellModel<DeviceInfo>): void => {
        cell.readonly = true;
    }

    const getDevicesAsync = async (pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<DeviceInfo>> => {
        const request = new ListDevicesRequest();
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.sortDirection = sortDirection;
        request.sortColumnName = sortColumnName!;
        request.searchTerm = filters.searchTerm;
        request.reportDefinitionId = filters.reportDefinitionId;
        request.serviceReportDefinitionId = filters.serviceReportDefinitionId;

        return await HttpClient.postAsyncWithoutErrorHandling<IPagedList<DeviceInfo>>(EndpointPaths.DevicePaths.ListDevices, request);
    }

    const toggleDetailsAsync = async (cell: CellModel<DeviceInfo>): Promise<void> => {
        const spannedRows: RowModel<DeviceInfo>[] = cell.spannedRows;
        const rowToExpand: RowModel<DeviceInfo> = spannedRows[spannedRows.length - 1];
        await rowToExpand.toggleAsync();
    }

    const initRow = (row: RowModel<DeviceInfo>): void => {
    }

    const renderDetailsContent = (row: RowModel<DeviceInfo>) => {
        const model: DeviceInfo = row.model;
        return (
            <DeviceDetailsPanel device={model}
                                adBlueTypes={adBlueTypes}
                                fuelTypes={fuelTypes}
            />
        );
    }

    const onFilterAsync = async (filters: ToolbarModel): Promise<void> => {
        setFilters(filters)

        await _devicesGridRef.current!.model.reloadAsync();
    }

    return (
        <PageRow>
            <div className="col">

                <div className={styles.container}>

                    <ToolbarContainer className={styles.toolbar}>

                        <Toolbar model={filters}
                                 onChange={async (model) => onFilterAsync(model)}
                        />

                    </ToolbarContainer>

                    {
                        (!isLoading()) && (
                            <Grid responsive
                                  id={"deviceDetails"}
                                  className={styles.devicesGrid}
                                  ref={_devicesGridRef}
                                  pagination={10}
                                  hovering={GridHoveringType.EditableCell}
                                  odd={GridOddType.None}
                                  minWidth="auto"
                                  columns={_devicesGridColumns}
                                  renderDetails={(row) => renderDetailsContent(row)}
                                  initRow={(row) => initRow(row)}
                                  fetchData={async (sender,
                                                    pageNumber,
                                                    pageSize,
                                                    sortColumnName,
                                                    sortDirection) =>
                                      await getDevicesAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                            />
                        )
                    }


                </div>

            </div>
        </PageRow>
    )
}

export default DeviceManagement