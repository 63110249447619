import {Button, ButtonContainer, ButtonType, Form, IStringInputModel, PageRow, TextInput} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import PageDefinitions from "@/providers/PageDefinitions";
import React from "react";
import ForgotPasswordRequest from "@/models/server/requests/ForgotPasswordRequest";
import {LoginResultStatus} from "@/models/Enums";
import HttpClient from "@/common/HttpClient";
import EndpointPaths from "@/common/EndpointPaths";

const ForgotPassword = () => {

    const _usernameInput: IStringInputModel = {value: ""};
    const _formRef: React.RefObject<Form> = React.createRef();

    const handleSubmitAsync = async (): Promise<void> => {
        const request = new ForgotPasswordRequest(_usernameInput.value);
        await HttpClient.postAsyncWithoutErrorHandling<LoginResultStatus>(EndpointPaths.ApplicationPaths.ForgotPassword, request);
    }

    return (
        <PageRow>
            <div className="col-lg-6">

                <Form id="form"
                      ref={_formRef}
                      onSubmit={async () => await handleSubmitAsync()}>

                    <TextInput required
                               id="username"
                               label={Localizer.forgotPasswordPageUsernameInput}
                               model={_usernameInput}/>

                    <ButtonContainer>
                        <Button submit
                                type={ButtonType.Orange}
                                label={Localizer.forgotPasswordPageResetButton}/>

                        <Button type={ButtonType.Default}
                                label={Localizer.forgotPasswordPageLoginButton}
                                route={PageDefinitions.loginRoute}/>
                    </ButtonContainer>

                </Form>
            </div>
        </PageRow>
    )
}

export default ForgotPassword;