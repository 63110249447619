import {ThreeColumns} from "@renta-apps/athenaeum-react-components";
import React from "react";
import Localizer from "@/localization/Localizer";
import EnumProvider from "@/providers/EnumProvider";
import Invoice from "@/models/server/Invoice";

interface IInvoiceHeaderProps {
    invoice: Invoice;
}

const InvoiceHeader = (props: IInvoiceHeaderProps) => {
    return (
        <ThreeColumns>
            <span>{"{0}".format(props.invoice.device?.externalId || "-")}</span>
            <span>{props.invoice.approved ? Localizer.yes : Localizer.no}</span>
            <span>{props.invoice.invoiceType !== null ? EnumProvider.getInvoiceTypeText(props.invoice.invoiceType) : "-"}</span>
        </ThreeColumns>
    )
}

export default InvoiceHeader;