import React, {ReactNode} from "react";
import DeviceInfo from "@/models/server/DeviceInfo";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {ITabContainerClassNames, Modal, PageContainer, PageHeader, Tab, TabContainer, TabContainerHeaderStyleType, TabRenderType} from "@renta-apps/athenaeum-react-components";
import AnnualInspectionDeviceList from "@/pages/AnnualInspectionsPage/AnnualInspectionDeviceList/AnnualInspectionDeviceList";
import ListAnnualInspectionDevicesRequest from "@/models/server/requests/ListAnnualInspectionDevicesRequest";
import GetAnnualInspectionDeviceFiltersResponse from "@/models/server/responses/GetAnnualInspectionDeviceFiltersResponse";
import {AnnualInspectionDevicesType} from "@/models/Enums";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import FiltersData from "@/pages/Models/FiltersData";
import RentaToolsController from "@/pages/RentaToolsController";
import Localizer from "../../localization/Localizer";

import styles from "@/pages/AnnualInspectionsPage/AnnualInspectionsPage.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";

interface IAnnualInspectionsPageProps {
}

interface IAnnualInspectionsState {
    upcomingInspectionsRequest: ListAnnualInspectionDevicesRequest;
    remarksInspectionsRequest: ListAnnualInspectionDevicesRequest;
    pastInspectionsRequest: ListAnnualInspectionDevicesRequest;
    upcomingFiltersData: FiltersData;
    remarksFilterData: FiltersData;
    pastFilterData: FiltersData;
}

export default class AnnualInspectionsPage extends AuthorizedPage<IAnnualInspectionsPageProps, IAnnualInspectionsState> {

    state: IAnnualInspectionsState = {
        upcomingInspectionsRequest: AnnualInspectionsPage.initializeListAnnualInspectionDeviceRequests(
            AnnualInspectionDevicesType.Upcoming, "ListUpcomingAnnualInspectionDevicesRequest"),
        remarksInspectionsRequest: AnnualInspectionsPage.initializeListAnnualInspectionDeviceRequests(
            AnnualInspectionDevicesType.Remarks, "ListRemarksAnnualInspectionDevicesRequest"),
        pastInspectionsRequest: AnnualInspectionsPage.initializeListAnnualInspectionDeviceRequests(
            AnnualInspectionDevicesType.Past, "ListPastAnnualInspectionDevicesRequest"),
        upcomingFiltersData: new FiltersData(),
        remarksFilterData: new FiltersData(),
        pastFilterData: new FiltersData()
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _pageInfoModalRef: React.RefObject<Modal> = React.createRef();

    private get pageInfoModal(): Modal {
        return this._pageInfoModalRef.current!;
    }

    protected get customInfoModal(): boolean {
        return true;
    }

    public get tabContainerClassNames(): ITabContainerClassNames {
        return {
            navTabs: this.mobile
                ? styles.tabContainerNavTabsMobile
                : styles.tabContainerNavTabs,
            headerLink: this.mobile
                ? styles.tabHeaderLinkMobile
                : styles.tabHeaderLink,
            navigationContainer: this.css(rentaToolsStyles.stickyNavigationContainer, styles.stickyNavigationContainer)
        };
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const response: GetAnnualInspectionDeviceFiltersResponse = await RentaToolsController.getAnnualInspectionDeviceFiltersAsync();

        this.setState({
            upcomingFiltersData: response.upcomingFiltersData,
            remarksFilterData: response.remarksFilterData,
            pastFilterData: response.pastFilterData
        });
    }

    private static initializeListAnnualInspectionDeviceRequests(requestType: AnnualInspectionDevicesType, userInteractionDataKey: string): ListAnnualInspectionDevicesRequest {
        const request = new ListAnnualInspectionDevicesRequest();
        request.inspectionType = requestType;
        const restoredRequest = UserInteractionDataStorage.restorePersistent(userInteractionDataKey, request);
        restoredRequest.pageNumber = 0;
        restoredRequest.pageSize = RentaToolsConstants.itemsPerReturnInspectionPage;

        return restoredRequest;
    }

    private async onClosePageInfoModalAsync(): Promise<void> {
        await this.pageInfoModal.closeAsync();
    }

    private async fetchAnnualInspectionDevicesAsync(request: ListAnnualInspectionDevicesRequest, userInteractionDataKey: string): Promise<DeviceInfo[]> {
        UserInteractionDataStorage.setPersistent(userInteractionDataKey, request);
        return await RentaToolsController.listAnnualInspectionDevicesAsync(request);
    }

    private async openDeviceInspectionAsync(deviceInfo: DeviceInfo): Promise<void> {
        await RentaToolsController.searchDeviceAsync(deviceInfo.externalId);
    }

    public manualPropsCallback(): Promise<void> {
        return this.pageInfoModal.openAsync();
    }

    public render(): ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(rentaToolsStyles.pageContainer, styles.annualInspectionPage)}>

                <div className={this.css(rentaToolsStyles.stickyHeader, styles.stickyHeaderTop)}>
                <PageHeader title={Localizer.annualInspectionsPageHeader.toUpperCase()} >
                    {(!this.mobile) &&
                        (
                            <div className={styles.colorLegendInfo}>
                                <div id="legend_blue">
                                    <span className={this.css(styles.circle, styles.blueCircle)}/> {Localizer.annualInspectionsPageInspectionInOneMonth}
                                </div>

                                <div id="legend_orange">
                                    <span className={this.css(styles.circle, styles.orangeCircle)}/> {Localizer.annualInspectionsPageInspectionUpcomingSoon}
                                </div>

                                <div id="legend_red">
                                    <span className={this.css(styles.circle, styles.redCircle)}/> {Localizer.annualInspectionsPageInspectionLate}
                                </div>
                            </div>
                        )}
                </PageHeader>
                    {(this.mobile) &&
                        (
                            <div className={styles.colorLegendInfo}>
                                <div id="legend_blue_mobile">
                                    <span className={this.css(styles.circle, styles.blueCircle)}/> {Localizer.annualInspectionsPageInspectionInOneMonth}
                                </div>

                                <div id="legend_orange_mobile">
                                    <span className={this.css(styles.circle, styles.orangeCircle)}/> {Localizer.annualInspectionsPageInspectionUpcomingSoon}
                                </div>

                                <div id="legend_red_mobile">
                                    <span className={this.css(styles.circle, styles.redCircle)}/> {Localizer.annualInspectionsPageInspectionLate}
                                </div>
                            </div>
                        )}
                </div>
                <TabContainer id="annualInspectionsContainer"
                              renderType={TabRenderType.Once}
                              headerStyleType={TabContainerHeaderStyleType.Underline}
                              className={styles.annualInspectionsTabs}
                              classNames={this.tabContainerClassNames}>

                    <Tab id="upcomingAnnualInspections"
                         title={Localizer.annualInspectionsPageTabUpcoming.toUpperCase()}
                         className={styles.annualInspectionTab}
                    >
                        <AnnualInspectionDeviceList id={"upcoming"}
                                                    title={Localizer.annualInspectionsListTitleUpcoming.toUpperCase()}
                                                    noDataText={Localizer.annualInspectionsListTitleNoUpcomingInspections}
                                                    request={this.state.upcomingInspectionsRequest}
                                                    filtersData={this.state.upcomingFiltersData}
                                                    fetchData={(request: ListAnnualInspectionDevicesRequest) => this.fetchAnnualInspectionDevicesAsync(request, "ListUpcomingAnnualInspectionDevicesRequest")}
                                                    onInspectionOpen={(_, deviceInfo) => this.openDeviceInspectionAsync(deviceInfo)}
                        />
                    </Tab>

                    <Tab id="pastAnnualInspections"
                         title={Localizer.annualInspectionsPageTabPast.toUpperCase()}
                         className={styles.annualInspectionTab}
                    >
                        <AnnualInspectionDeviceList id={"past"}
                                                    title={Localizer.annualInspectionsListTitlePast.toUpperCase()}
                                                    noDataText={Localizer.annualInspectionsListTitleNoPastInspections}
                                                    request={this.state.pastInspectionsRequest}
                                                    filtersData={this.state.pastFilterData}
                                                    fetchData={( request: ListAnnualInspectionDevicesRequest) => this.fetchAnnualInspectionDevicesAsync(request, "ListPastAnnualInspectionDevicesRequest")}
                                                    onInspectionOpen={(_, deviceInfo) => this.openDeviceInspectionAsync(deviceInfo)}
                        />
                    </Tab>

                </TabContainer>

                <Modal id={"annual-inspections-info-modal"}
                       ref={this._pageInfoModalRef}
                       className={rentaToolsStyles.infoPageModal}
                       title={Localizer.annualInspectionsPageInfoHeader}
                       onClose={() => this.onClosePageInfoModalAsync()}
                >
                    {this.getPageInfo(Localizer.annualInspectionsPageInfoContent)}

                </Modal>

            </PageContainer>
        );
    }
}