import styles from './BadgeContainer.module.scss';
import {Icon} from "@renta-apps/athenaeum-react-components";
import React from "react";

export interface BadgeProps
{
    reason: string;
    onRemove: (reason : string) => void;
}

const StatusBadge = ({reason, onRemove} : BadgeProps) => (
    <div className={styles.badge}>
        <span>
            {reason}
        </span>
        <div className={styles.removeIcon} onClick={() => onRemove(reason)}>
            <Icon
                name={"fal fa-times"}
            />
        </div>
    </div>
)

export default StatusBadge;