import {Button, ButtonContainer, ButtonType, CellModel, ColumnDefinition, ColumnType, Dropdown, DropdownType, Form, Grid, Inline, PageRow, SelectListItem} from "@renta-apps/athenaeum-react-components";
import Localizer from "../../localization/Localizer";
import InlineTooltip from "../InlineTooltip/InlineTooltip";
import React, {useEffect, useState} from "react";
import SettingModel from "@/models/server/SettingModel";
import NumericSettingModel from "@/models/server/NumericSettingModel";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import SettingsContainer from "@/models/server/SettingsContainer";
import UserContext from "@/models/server/UserContext";
import DateFormatSelectionModel from "@/models/DateFormatSelectionModel";
import HttpClient from "@/common/HttpClient";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import styles from "./ApplicationSettings.module.scss"
import {ch} from "@renta-apps/athenaeum-react-common";
import EndpointPaths from "@/common/EndpointPaths";

const ApplicationSettings: React.FC = () => {

    const [settingsModified, setSettingsModified] = useState(false)
    const [selectedDateFormat, setSelectedDateFormat] = useState<string | null>(null)
    const [dateFormatItems, setDateFormatItems] = useState<DateFormatSelectionModel[] | null>(null)
    const [settings, setSettings] = useState<SettingModel[]>([])
    const [numericalSettings, setNumericalSettings] = useState<SettingModel[]>([])

    const _settingsGridRef: React.RefObject<Grid<SettingModel>> = React.createRef();
    const _numberSettingsGridRef: React.RefObject<Grid<NumericSettingModel>> = React.createRef();
    const _dateFormatDdlRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();

    const _columns: ColumnDefinition[] = [
        {
            header: Localizer.adminSettingsKeyLanguageItemName,
            accessor: "key",
            transform: (cell): string => transformKeyCell(cell),
            minWidth: 300,
            maxWidth: 300,
        },
        {
            header: Localizer.adminSettingsValueLanguageItemName,
            accessor: "value",
            editable: true,
            type: ColumnType.Text,
            minWidth: 300,
            maxWidth: 300,
            maxHeight: "16px",
            settings: {
                maxLength: 100000
            },
            wordBreak: true,
            stretch: false,
            callback: async (cell) => await onChangeSettingsAsync(cell)
        }
    ];

    const _numberGridColumns: ColumnDefinition[] = [
        {
            header: Localizer.adminSettingsKeyLanguageItemName,
            accessor: "key",
            transform: (cell): string => transformKeyCell(cell),
            minWidth: 300,
            maxWidth: 300,
        },
        {
            header: Localizer.adminSettingsValueLanguageItemName,
            accessor: "value",
            editable: true,
            type: ColumnType.Number,
            minWidth: 300,
            maxWidth: 300,
            maxHeight: "16px",
            settings: {
                maxLength: 100000,
                step: 0.1
            },
            wordBreak: true,
            stretch: false,
            callback: async (cell) => await onChangeSettingsAsync(cell)
        }
    ];

    useEffect(() => {
        setDateFormatItems(RentaToolsConstants.supportedDateFormats);
    }, []);

    const settingsContainer = (): SettingsContainer => {
        const container = {
            stringValueSettings: _settingsGridRef.current!.model.items,
            numberValueSettings: _numberSettingsGridRef.current!.model.items,

        } as SettingsContainer;

        return container;
    }

    const hasSettingsAccess = (): boolean => {
        const context: UserContext = ch.getContext() as UserContext;
        return context.isAdmin;
    }

    const transformKeyCell = (cell: CellModel<SettingModel>): string => {
        const setting: SettingModel = cell.model;

        const key: string = setting.key;

        return Localizer.get(`KnownSettings.${key}`);
    }

    const handleSettingsSubmitAsync = async (): Promise<void> => {
        const container: SettingsContainer = settingsContainer();

        const fromNumeric: SettingModel[] | null = (container.numberValueSettings?.some(s => !Number.isNaN(s.value)))
            ? container.numberValueSettings?.where(s => !Number.isNaN(s.value)).map(setting => ({...setting, isNumeric: true, value: setting.value?.toString() ?? ""}))
            : null;

        let settings: SettingModel[] = container.stringValueSettings!;
        if (fromNumeric) {
            settings = settings.concat(fromNumeric);
        }

        let dateFormatSetting: SettingModel | null | undefined = container.stringValueSettings?.firstOrDefault(setting => setting.key == "DateFormat");
        if (!dateFormatSetting) {
            dateFormatSetting = {
                key: "DateFormat",
                value: selectedDateFormat,
                isNumeric: false,
            } as SettingModel;

            settings.push(dateFormatSetting);
        } else {
            dateFormatSetting.value = selectedDateFormat!;
        }

        await HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.Settings.SaveSettings, settings);

        setSettingsModified(false)

        await ch.alertMessageAsync(Localizer.adminSettingsSaved, true);
    }

    const getSettingsAsync = async (grid: Grid<SettingModel>): Promise<SettingModel[]> => {
        const settingData: SettingModel[] = await HttpClient.postAsyncWithoutErrorHandling<SettingModel[]>(EndpointPaths.Settings.ListSettings, false)

        const dateFormat = settingData.firstOrDefault(s => s.key === "DateFormat");

        setSettings(settingData);
        setSelectedDateFormat(dateFormat?.value ?? null);

        return settingData.where(x => x.key !== "DateFormat");
    }

    const getNumberValueSettingsAsync = async (grid: Grid<NumericSettingModel>): Promise<NumericSettingModel[]> => {

        const numericalSettingData: SettingModel[] = await HttpClient.postAsyncWithoutErrorHandling<SettingModel[]>(EndpointPaths.Settings.ListSettings, true);

        setNumericalSettings(numericalSettingData);

        if (!numericalSettingData || numericalSettingData.length === 0) {
            return [];
        }

        return numericalSettingData.map(setting => ({...setting, value: parseFloat(setting.value)}));
    }

    const onChangeSettingsAsync = async (cell: CellModel<unknown>): Promise<void> => {
        setSettingsModified(cell.grid.modified)
    }

    const dateFormatSelectedAsync = async (item: SelectListItem | null): Promise<void> => {
        if (!item) {
            setSelectedDateFormat(null)
            return;
        }

        setSelectedDateFormat(item?.value ?? null)
        setSettingsModified(true)
    }

    const dateFormatListItems = (): SelectListItem[] => {

        if (dateFormatItems === null || dateFormatItems === undefined) {
            return [];
        }

        const items: DateFormatSelectionModel[] = dateFormatItems;

        return items.map(x => {
            const item: SelectListItem = new SelectListItem();
            item.value = x.value;
            item.text = x.text;

            return item;
        });
    }

    return (
        <PageRow>
            <div className="col">

                <Form id="form" onSubmit={async () => await handleSettingsSubmitAsync()}>

                    <div className={"mb-3"}>

                        <h6>{Localizer.applicationSettingsPageSubTitle}</h6>

                        <Grid responsive
                              id="settings"
                              ref={_settingsGridRef}
                              minWidth={"100%"}
                              readonly={!hasSettingsAccess}
                              columns={_columns}
                              fetchData={async (sender) => await getSettingsAsync(sender)}
                        />

                    </div>

                    <div className={"mb-3"}>
                        <h6>{Localizer.applicationSettingsNumberValues}</h6>
                        <Grid responsive
                              id="numberValueSettings"
                              ref={_numberSettingsGridRef}
                              minWidth={"100%"}
                              readonly={!hasSettingsAccess}
                              columns={_numberGridColumns}
                              fetchData={async (sender) => await getNumberValueSettingsAsync(sender)}
                        />

                    </div>

                    <div className={"mb-3"}>

                        <Inline>
                            <h6>{Localizer.applicationSettingsVisualSettings}</h6>

                            <InlineTooltip text={Localizer.applicationSettingsDateFormatInfo}/>
                        </Inline>


                        <div className={Utility.css("col-3", styles.dateContainer)}>

                            <Dropdown small noWrap
                                      id={"dateFormatDdl"}
                                      ref={_dateFormatDdlRef}
                                      className={styles.dropdown}
                                      type={DropdownType.Dropdown}
                                      items={dateFormatListItems()}
                                      selectedItem={selectedDateFormat}
                                      onItemClick={async (sender, item) => await dateFormatSelectedAsync(item)}
                            />


                        </div>

                    </div>

                    <ButtonContainer>

                        {
                            (hasSettingsAccess()) &&
                            (
                                <Button submit
                                        id={"saveSettings"}
                                        data-cy={"save_settings"}
                                        type={ButtonType.Orange}
                                        icon={{name: "far save"}}
                                        label={Localizer.genericSave}
                                        disabled={!settingsModified}
                                />
                            )
                        }

                    </ButtonContainer>

                </Form>

            </div>
        </PageRow>
    )
}

export default ApplicationSettings;