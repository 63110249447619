import React, {useState} from 'react';
import {Modal, ModalSize, TextAreaInput} from "@renta-apps/athenaeum-react-components";
import User from "@/models/server/User";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

export interface IRemarksInfoModalData {
    remarks: string | null;
    fixBeforeLease: boolean;
    remarksDueDate: Date | null;
    remarksCompletedDate: Date | null;
    remarksCompletedBy: User | null;
    commentsOnly: boolean;
}

interface IRemarksInfoModalProps {
    modalRef: React.RefObject<Modal<IRemarksInfoModalData>>;
}

const RemarksInfoModal: React.FC<IRemarksInfoModalProps> = ({modalRef}) => {
    const [remarks, setRemarks] = useState<string | null>(null);
    const [fixBeforeLease, setFixBeforeLease] = useState<boolean>(false);
    const [remarksDueDate, setRemarksDueDate] = useState<Date | null>(null);
    const [remarksCompletedDate, setRemarksCompletedDate] = useState<Date | null>(null);
    const [remarksCompletedBy, setRemarksCompletedBy] = useState<User | null>(null);
    const [commentsOnly, setCommentsOnly] = useState<boolean>(false);
    const onOpenAsync = async (modal: Modal<IRemarksInfoModalData>): Promise<void> => {
        if (modal.data) {
            setRemarks(modal.data.remarks);
            setFixBeforeLease(modal.data.fixBeforeLease);
            setRemarksDueDate(modal.data.remarksDueDate);
            setRemarksCompletedDate(modal.data.remarksCompletedDate);
            setRemarksCompletedBy(modal.data.remarksCompletedBy);
            setCommentsOnly(modal.data.commentsOnly);

            await modalRef.current?.openAsync();
        }
    };
    

    const modalId = "remarksInfoModal";

    return (
        <Modal id={modalId}
               ref={modalRef}
               title={commentsOnly ? Localizer.genericComment : Localizer.remarksInfoModalTitle}
               size={ModalSize.Large}
               onOpen={onOpenAsync}
        >
            <TextAreaInput
                readonly
                id={`${modalId}Remarks`}
                label={commentsOnly ? Localizer.genericComment : Localizer.remarksInfoModalRemarks}
                value={remarks || ''}
            />

            {(!commentsOnly) &&
                (
                    <>
                        <p id={`${modalId}FixBeforeLease`} className="mt-2 mb-2">
                            {Localizer.deviceManagementFixBeforeLease}: {fixBeforeLease ? Localizer.yes : Localizer.no}
                        </p>
    
                        <p id={`${modalId}RemarksDueDate`} className="mt-2 mb-2">
                            {Localizer.deviceManagementRemarksRepairDueDate}: {remarksDueDate ? "{0:D}".format(remarksDueDate) : '-'}
                        </p>
    
                        <p id={`${modalId}RemarksCompletedDate`} className="mt-2 mb-2">
                            {Localizer.deviceManagementRemarksFixedDate}: {remarksCompletedDate ? "{0:D}".format(remarksCompletedDate) : '-'}
                        </p>
    
                        <p id={`${modalId}RemarksCompletedBy`} className="mt-2 mb-2">
                            {Localizer.deviceManagementRemarksFixedBy}: {remarksCompletedBy ? TransformProvider.userToString(remarksCompletedBy) : '-'}
                        </p>
                    </>
                )
            }
        </Modal>
    );
};

export default RemarksInfoModal;