import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";
import Localizer from "../../localization/Localizer";

class DeviceAnnualInspectionFormPage {
    public elements = {
        form: () => cy.get('form'),
        pdfInput: () => cy.get("#pdf_input"),
        fileInput: () => cy.get('input[type="file"]'),
        header: () => cy.get(".athenaeum-page-container-content"),
        submitButton: () => this.elements.form().find("button[type=submit]"),
        confirmationDialog: () => cy.get('[id^=confirmationContent-]'),
        confirmationDialogConfirmBtn: () => this.elements.confirmationDialog().first().find('button[id^=confirmation-dialog-confirm-]'),
        banOfUseCheckbox: () => cy.get("#ban_of_use_checkbox i"),
        remarksRepairNeededCheckbox: () => cy.get("#remarksRepairNeeded i"),
        banOfUseInfo: () => cy.get("#ban_of_use_info"),
        remarksRepairedDateInput: () => cy.get("#remarksRepairedDate"),
        remarksInput: () => cy.get("#remarks"),
        commentsInput: () => cy.get("#comments"),
        inspectionDateInput: () => cy.get("#inspectionDate"),
        vendorInput: () => cy.get("#annualInspectionVendor"),
        inspectionTypeInput: () => cy.get("#inspectionType"),
        invoiceNumberInput: () => cy.get("#invoiceNumber"),
        depoInput: () => cy.get("#depo"),
        inspectionCostInput: () => cy.get("#inspectionCost"),
    }

    public routes = {
        findDeviceById: {
            path: "/api/device/findDeviceById",
            method: "POST",
        } as RouteData,
        findDevice: {
            path: "/api/device/findDevice",
            method: "POST",
        } as RouteData,
        saveRemarksRepaired: {
            path: "/api/annualInspection/SaveRemarksRepaired",
            method: "POST",
        } as RouteData,
        saveAnnualInspection: {
            path: "/api/annualInspection/SaveDeviceAnnualInspection",
            method: "POST",
        } as RouteData,
        listDeviceAnnualInspections: {
            path: "/api/AnnualInspection/ListDeviceAnnualInspections",
            method: "POST",
        } as RouteData,
        getUserDepos: {
            path: "/api/depo/getUserDepos",
            method: "POST",
        } as RouteData,
        listVendors: {
            path: "/api/annualInspection/ListAnnualInspectionVendors",
            method: "POST",
        } as RouteData,
        getAnnualInspectionTypeItemsByProductGroup: {
            path: "/api/mappings/getAnnualInspectionTypeItemsByProductGroup",
            method: "POST",
        } as RouteData,
        getOperatingHoursLimits: {
            path: "/api/report/getOperatingHoursLimits",
            method: "POST",
        } as RouteData,
        getDeviceAnnualInspectionSetup: {
            path: "/api/annualInspection/GetDeviceAnnualInspectionSetup",
            method: "POST",
        } as RouteData
    };

    public assertHeader() {
        this.elements.header().should('contain.text', Localizer.deviceAnnualInspectionPageRemarksRepaired)
    }

    public submitForm() {
        this.elements.form().submit();
    }

    public attachFile(){
        this.elements.pdfInput();
        cy.fixture('returnInspectionReport.pdf').then(fileContent => {
            this.elements.fileInput().attachFile({
                fileContent: fileContent.toString(),
                fileName: 'returnInspectionReport.pdf',
                mimeType: 'application/pdf'
            });
        });
    }

    public submitFormWithConfirm() {
        this.elements.form().submit();
        this.elements.confirmationDialogConfirmBtn().trigger('click');
    }

    public assertAndFillRemarks() {
        this.elements.remarksRepairNeededCheckbox().click();
        this.elements.remarksRepairedDateInput().click();

        this.elements.remarksRepairedDateInput()
            .get(".react-datepicker__day--today")
            .click();

        this.elements.remarksInput().type("Remarks");
    }

    public assertAndFillForm() {
        this.elements.inspectionDateInput().click();

        this.elements.vendorInput().click();
        this.elements.inspectionTypeInput();

        this.elements.pdfInput();
        cy.fixture('returnInspectionReport.pdf').then(fileContent => {
            this.elements.fileInput().attachFile({
                fileContent: fileContent.toString(),
                fileName: 'returnInspectionReport.pdf',
                mimeType: 'application/pdf'
            });
        });
        this.elements.commentsInput().type("Comment");
        this.elements.invoiceNumberInput().click().type("lasku 123");
        this.elements.inspectionCostInput().click().type("1");
        this.elements.depoInput().click();
    }

    public submitAnnualInspection(confirm: boolean = false): void {
        cy.wait(3000);

        this.submitForm();

        if (confirm) {
            this.elements.confirmationDialogConfirmBtn()
                .click();
        }
    }
}

export default new DeviceAnnualInspectionFormPage();