import ReportDefinitionPage, {ReportDefinitionSettings, ReportDefinitionStepFaultLevel, ReportDefinitionStepsSettings, ReportDefinitionStepType} from "@/tests/pages/ReportDefinitionPage";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import NumbersHelper from "@/helpers/NumbersHelper";
import DevicePage from "@/tests/pages/DevicePage";
import ReturnedDeviceList from "@/tests/pages/ReturnedDeviceList";
import ReturnInspectionPage from "@/tests/pages/ReturnInspectionPage";
import SummaryPage from "@/tests/pages/SummaryPage";
import SavePage from "@/tests/pages/SavePage";
import CypressFeatureFlagService from "@/tests/CypressFeatureFlagService";
import Localizer from "@/localization/Localizer";
import MappingsPage from "@/tests/pages/MappingsPage";
import ReportDefinitionsPage from "@/tests/pages/ReportDefinitionsPage";
import DashboardPage from "@/tests/pages/DashboardPage";

describe('Report definition page tests', () => {
    beforeEach(() => {
        cy.login();
        cy.goToDashboard();
    })

    describe('Service triggers tests', () => {
        before(() => {
            CypressFeatureFlagService.enable(RentaToolsConstants.featureFlagServiceAndRepairEnabled);
        })

        it('Current rental days (after last service) triggers work', () => {

            DashboardPage.openDevice("420694");
            DevicePage.assertInStockWithReturnInspectionPending();

            addTrigger(() => ReportDefinitionPage.toggleCurrentRentalDaysTrigger());

            completeInspection("420694");
            SavePage.goToDevicePage();

            DevicePage.assertInStockWithMaintenanceReasons([Localizer.enumMaintenanceReasonCurrentRentalDaysLimit.toLowerCase()]);
        })

        it('Current operating hours (after last service) triggers work', () => {

            DashboardPage.openDevice("420695");
            DevicePage.assertInStockWithReturnInspectionPending();

            addTrigger(() => ReportDefinitionPage.toggleCurrentOperatingHoursTrigger());

            completeInspection("420695");
            SavePage.goToDevicePage();

            DevicePage.assertInStockWithMaintenanceReasons([Localizer.enumMaintenanceReasonCurrentOperatingHoursLimit.toLowerCase()]);
        });

        it('Annual service months triggers work', () => {

            DashboardPage.openDevice("420696");
            DevicePage.assertInStockWithReturnInspectionPending();

            addTrigger(() => ReportDefinitionPage.toggleAnnualServiceMonthsTrigger());

            completeInspection("420696");
            SavePage.goToDevicePage();

            DevicePage.assertInStockWithMaintenanceReasons([Localizer.enumMaintenanceReasonAnnualServiceMonthsLimit.toLowerCase()]);
        });

        it('Total rental days trigger works', () => {

            DashboardPage.openDevice("420691");
            DevicePage.assertInStockWithReturnInspectionPending();

            addTrigger(() => ReportDefinitionPage.toggleTotalRentalDaysTrigger());

            completeInspection("420691");
            SavePage.goToDevicePage();

            DevicePage.assertInStockWithMaintenanceReasons([Localizer.enumMaintenanceReasonTotalRentalDaysLimit.toLowerCase()]);
        });

        it('Total operating hours trigger works', () => {

            DashboardPage.openDevice("420692");
            DevicePage.assertInStockWithReturnInspectionPending();

            addTrigger(() => ReportDefinitionPage.toggleTotalOperatingHoursTrigger());

            completeInspection("420692");
            SavePage.goToDevicePage();

            DevicePage.assertInStockWithMaintenanceReasons([Localizer.enumMaintenanceReasonTotalOperatingHoursLimit.toLowerCase()]);
        });

        it('Total life months trigger works', () => {

            DashboardPage.openDevice("420693");
            DevicePage.assertInStockWithReturnInspectionPending();

            addTrigger(() => ReportDefinitionPage.toggleTotalLifeMonthsTrigger());

            completeInspection("420693");
            SavePage.goToDevicePage();

            DevicePage.assertInStockWithMaintenanceReasons([Localizer.enumMaintenanceReasonTotalMonthsLimit.toLowerCase()]);
        });

        function addTrigger(func: () => void) {
            cy.goToReportDefinitionsPage();
            ReportDefinitionsPage.chooseType(Localizer.enumReportDefinitionTypeService);
            ReportDefinitionsPage.goToTriggerDefinition();
            func();
            ReportDefinitionPage.saveService();
            cy.contains(Localizer.reportDefinitionsPageDataSaved);
        }
    })

    describe('CRUD ReportDefinition tests', () => {
        before(() => {
            CypressFeatureFlagService.enable(RentaToolsConstants.featureFlagShowPhasesEnabled);
        })

        it('Add ReportDefinition test', () => {
            const checksStep: ReportDefinitionStepsSettings =
                {
                    icon: "circle",
                    name: "Test Checks step name",
                    title: "Test Checks step title",
                    telematics: true,
                    typeDropdown: "Monitarkastus",
                    stepTypeItems:
                        [
                            {
                                checkStepName: "checkStepName"
                            }
                        ],
                };

            const steps: ReportDefinitionStepsSettings[] = getReportDefinitionBasicSteps(checksStep);
            const testAddingReportDefinitionSettings: ReportDefinitionSettings = ReportDefinitionPage.createReportDefinition("Add Test Report Definition " + `${NumbersHelper.getRandomInteger(1, 999)}`, steps, true);
            ReportDefinitionPage.addReportDefinition(testAddingReportDefinitionSettings);
            ReportDefinitionPage.validateReportDefinition(testAddingReportDefinitionSettings);
        });

        it('Update report definition test', () => {
            const steps: ReportDefinitionStepsSettings[] = [
                {
                    icon: "square",
                    name: "Test Question step name",
                    title: "Test Question step title",
                    telematics: true,
                    generateFault: true,
                    faultLevel: "Lievä",
                    typeDropdown: "Tarkastus",
                    canAddAdditionalExpenses: true,
                    additionalExpenses: ["Broken side mirror", "Engine replacement"],
                }];

            const testUpdatingReportDefinitionSettings: ReportDefinitionSettings = ReportDefinitionPage.createReportDefinition("Update Test Report Definition " + `${NumbersHelper.getRandomInteger(1, 999)}`, steps);
            ReportDefinitionPage.addReportDefinition(testUpdatingReportDefinitionSettings);
            ReportDefinitionPage.validateReportDefinition(testUpdatingReportDefinitionSettings);

            ReportDefinitionPage.navigateToReportDefinitionPage(testUpdatingReportDefinitionSettings.reportName);
            cy.wait(1000);
            const newReportName: string = "Update definition works " + `${NumbersHelper.getRandomInteger(1, 999)}`;
            const newStepType: ReportDefinitionStepType = "Tarkastus kuvilla";
            const newStepName: string = "Answer and take a pic of the device";
            const newStepTitle: string = "What is broken? Take a major faulty pic";
            const newStepFaultLevel: ReportDefinitionStepFaultLevel = "Vakava";
            const newStepAdditionalExpense: string = "Spare parts";

            ReportDefinitionPage.elements.reportDefinition.nameInput().clear().type(newReportName);
            ReportDefinitionPage.elements.reportDefinition.steps.typeDropdown(0).selectDropdownValue(newStepType);
            ReportDefinitionPage.elements.reportDefinition.steps.name(0).clear().type(newStepName);
            ReportDefinitionPage.elements.reportDefinition.steps.title(0).clear().type(newStepTitle);
            ReportDefinitionPage.elements.reportDefinition.steps.telematicsCheckbox(0).click();
            ReportDefinitionPage.elements.reportDefinition.steps.faultLevelDropdown(0).selectDropdownValue(newStepFaultLevel);
            ReportDefinitionPage.elements.reportDefinition.steps.additionalExpenses.additionalExpensesDropdown(0).selectMultiselectDropdownValue([newStepAdditionalExpense]);

            ReportDefinitionPage.saveReport();
            cy.contains(Localizer.reportDefinitionsPageDataSaved);

            // Assert
            ReportDefinitionPage.navigateToReportDefinitionPage(newReportName);

            ReportDefinitionPage.elements.reportDefinition.nameInput()
                .should('have.value', newReportName);
            ReportDefinitionPage.elements.reportDefinition.steps.typeDropdown(0)
                .validateDropdownValueIsSelected(newStepType, true);
            ReportDefinitionPage.elements.reportDefinition.steps.name(0)
                .should('have.value', newStepName);
            ReportDefinitionPage.elements.reportDefinition.steps.title(0)
                .should('have.value', newStepTitle);
            // Should be unchecked since it was selected on report creation.
            ReportDefinitionPage.elements.reportDefinition.steps.telematicsCheckbox(0)
                .get('.athenaeum-checkbox-checkbox')
                .should('have.class', 'athenaeum-checkbox-unchecked');
            ReportDefinitionPage.elements.reportDefinition.steps.faultLevelDropdown(0)
                .validateDropdownValueIsSelected(newStepFaultLevel, true);
            ReportDefinitionPage.elements.reportDefinition.steps.additionalExpenses.additionalExpensesDropdown(0)
                .validateDropdownValueIsSelected(newStepAdditionalExpense);

        });

        it('Move report definition step test', () => {
            const steps: ReportDefinitionStepsSettings[] = getReportDefinitionBasicSteps();
            const testMovingStepInReportDefinitionSettings: ReportDefinitionSettings = ReportDefinitionPage.createReportDefinition("Move step Test Report Definition " + `${NumbersHelper.getRandomInteger(1, 999)}`, steps);
            ReportDefinitionPage.addReportDefinition(testMovingStepInReportDefinitionSettings);
            ReportDefinitionPage.moveReportDefinitionStepTest(testMovingStepInReportDefinitionSettings);
        });

        it('Remove report definition step test', () => {
            const steps: ReportDefinitionStepsSettings[] = getReportDefinitionBasicSteps();
            const testRemovingStepFromReportDefinitionSettings: ReportDefinitionSettings = ReportDefinitionPage.createReportDefinition("Remove step Test Report Definition " + `${NumbersHelper.getRandomInteger(1, 999)}`, steps);
            ReportDefinitionPage.addReportDefinition(testRemovingStepFromReportDefinitionSettings);
            ReportDefinitionPage.removeReportDefinitionLastStepTest(testRemovingStepFromReportDefinitionSettings);
        });

        it('Delete ReportDefinition test', () => {
            const steps: ReportDefinitionStepsSettings[] = getReportDefinitionBasicSteps();
            const testDeletingReportDefinitionSettings: ReportDefinitionSettings = ReportDefinitionPage.createReportDefinition("Delete Test Report Definition " + `${NumbersHelper.getRandomInteger(1, 999)}`, steps);
            ReportDefinitionPage.addReportDefinition(testDeletingReportDefinitionSettings);
            ReportDefinitionPage.deleteReportDefinition(testDeletingReportDefinitionSettings.reportName);
        });

        it('Restore ReportDefinition test', () => {
            // Create a new report definition.
            const stepName: string = "Test Question step name";
            const steps: ReportDefinitionStepsSettings[] = [
                {
                    icon: "square",
                    name: "Test Question step name",
                    title: "Test Question step title",
                    typeDropdown: "Tarkastus"
                }];

            const testRestoringReportDefinition: ReportDefinitionSettings = ReportDefinitionPage.createReportDefinition("Cypress Restore Report definition test " + `${NumbersHelper.getRandomInteger(1, 999)}`, steps);
            ReportDefinitionPage.addReportDefinition(testRestoringReportDefinition);

            // Create a new mapping.
            MappingsPage.mapProductGroupToReportDefinition(testRestoringReportDefinition.reportName, ReportDefinitionPage.constants.cypressTestProductGroupNameForRestoringReportDefinition);

            // Perform a return inspection for the device.
            cy.goToReturnedDevicesPage();
            ReturnedDeviceList.openDevice(ReportDefinitionPage.constants.cypressTestDeviceExternalIdForRestoringReportDefinition);

            DevicePage.elements.deviceStatus()
                .should('contain.text', `${Localizer.genericPending}: ${Localizer.enumReportDefinitionTypeReturnInspection}`);

            DevicePage.rollbackToReturnInspectionNeeded();

            DevicePage.startReturnInspection();
            ReturnInspectionPage.startReturnInspection();
            ReturnInspectionPage.markStepAsDone(stepName);
            ReturnInspectionPage.continueReturnInspection();
            SummaryPage.saveInspection();
            SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);

            ReportDefinitionPage.deleteReportDefinition(testRestoringReportDefinition.reportName, true);
            ReportDefinitionPage.restoreReportDefinition(testRestoringReportDefinition.reportName);
        });
    });

    function completeInspection(device: string) {
        cy.goToReturnedDevicesPage();
        ReturnedDeviceList.openDevice(device);
        DevicePage.startReturnInspection();
        ReturnInspectionPage.startReturnInspection();
        ReturnInspectionPage.markStepAsDone("Ulkopuoli");
        ReturnInspectionPage.continueReturnInspection();
        ReturnInspectionPage.increaseNumberInput(2);
        ReturnInspectionPage.continueReturnInspection([ReturnInspectionPage.routes.saveReturnInspectionStep, ReturnInspectionPage.routes.getWashingTypes, ReturnInspectionPage.routes.getFuelTypes]);
        SummaryPage.saveInspection();
        SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);
    }

    function getReportDefinitionBasicSteps(additionalSteps?: ReportDefinitionStepsSettings | ReportDefinitionStepsSettings[] | null): ReportDefinitionStepsSettings[] {
        const steps: ReportDefinitionStepsSettings[] = [
            {
                icon: "square",
                name: "Test Question step name",
                title: "Test Question step title",
                generateFault: true,
                faultLevel: "Vakava",
                typeDropdown: "Tarkastus",
                canAddAdditionalExpenses: true,
                additionalExpenses: ["Broken side mirror", "Engine replacement"],
            },
            {
                icon: "fal camera",
                name: "Test Pictures step name",
                title: "Test Pictures step title",
                telematics: true,
                typeDropdown: "Kuvat",
            },
            {
                icon: "triangle",
                name: "Test Question Pictures step name",
                title: "Test Question Pictures step title",
                manual: "Answer the test question and add test picture(s).",
                typeDropdown: "Tarkastus kuvilla",
            },
        ];

        if (additionalSteps != null) {
            if (Array.isArray(additionalSteps)) {
                steps.concat(additionalSteps);
            } else {
                steps.push(additionalSteps);
            }
        }

        return steps;
    }
})