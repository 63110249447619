import React, {useEffect, useRef, useState} from "react";
import styles from "@/components/ContractsList/ContractsList.module.scss";
import Localizer from "@/localization/Localizer";
import ToolsPageHeader from "@/components/ToolsPageHeader/ToolsPageHeader";
import ContractView from "@/models/server/ContractView";
import ListContractsRequest from "@/models/server/requests/ListContractsRequest";
import ToolsUtility from "@/helpers/ToolsUtility";
import ContractsFilterModal from "@/components/ContractsFilterModal";
import {Button, ButtonType, IconSize, Modal} from "@renta-apps/athenaeum-react-components";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import newStyles from "@/pages/NewUI.module.scss";

interface IContractsListProps {
    sticky?: boolean;
    request: ListContractsRequest;

    fetchData(
        request: ListContractsRequest,
        scrollTrigger: boolean,
    ): Promise<ContractView[]>;

    onContractOpen(item: ContractView): Promise<void>;
}

const ContractsList = (props: IContractsListProps) => {

    const intersectionObserver = useRef<IntersectionObserver | null>(null);
    const lastElementRef = useRef<HTMLDivElement>(null);
    const filtersModalRef = useRef<Modal>(null);

    const [data, setData] = useState<ContractView[] | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [request, setRequest] = useState<ListContractsRequest>(
        props.request,
    );

    useEffect(() => {
        fetchDataAsync().then((r) => {
            setData(r)
        });
    }, [request]);

    useEffect(() => {

        intersectionObserver.current = new IntersectionObserver(async (entries) => {

            if (entries[0].isIntersecting && !isFetching && haveMoreDataToLoad()) {
                const newRequest = request;
                newRequest.nextPage();

                setIsFetching(true);

                const data = await props.fetchData(request, false);

                setData((prevData) => (prevData ? [...prevData, ...data] : data));
                setIsFetching(false);
                setRequest(newRequest);
            }
        });

        if (intersectionObserver.current && lastElementRef.current) {
            intersectionObserver.current.observe(lastElementRef.current);
        }

        return () => {
            if (intersectionObserver.current && lastElementRef.current) {
                intersectionObserver.current.unobserve(lastElementRef.current);
            }
        };
    }, [isFetching, request, props]);

    const fetchDataAsync = async (): Promise<ContractView[]> => {
        return await props.fetchData(request, false);
    };

    const haveMoreDataToLoad = (): boolean => {
        return (
            request.pageSize *
            (request.pageNumber === 0 ? 1 : request.pageNumber + 1) ===
            data?.length!
        );
    };

    const onItemClickAsync = async (contract: ContractView): Promise<void> => {
        await props.onContractOpen(contract);
    };

    const getStatusStyles = (item: ContractView): string => {

        if (ToolsUtility.isNullOrEmpty(item.customerName)) {
            return styles.old;
        }

        return styles.active;
    };

    const getIconName = (): string => {
        const isEmpty: boolean = ToolsUtility.isNullOrEmpty(request.customerName)
            && ToolsUtility.isNullOrEmpty(request.deviceExternalId)
            && ToolsUtility.isNullOrEmpty(request.constructionSiteName)
            && ToolsUtility.isNullOrEmpty(request.customerId)
            && ToolsUtility.isNullOrEmpty(request.constructionSiteExternalId)
            && ToolsUtility.isNullOrEmpty(request.customerName)
            && (!request.depos || request.depos.length == 0);

        return isEmpty
            ? "far filter"
            : "fas filter";
    }

    const openFiltersAsync = async (): Promise<void> => {
        await filtersModalRef.current!.openAsync();
    }

    async function submitFiltersAsync(newRequest: ListContractsRequest) {
        await filtersModalRef.current?.closeAsync();

        setData([])

        const req = {
            ...newRequest,
            pageNumber: 0,
        } as ListContractsRequest;

        setRequest(req);
    }

    return (
        <div className={styles.contractsList}>

            <div className={`${rentaToolsStyles.stickyDeviceListHeader} ${styles.stickyContractsListHeader}`}>

                <ToolsPageHeader stickyHeader title={Localizer.genericContracts.toUpperCase()}/>

                <div className={`${styles.header} ${newStyles.header}`}>
                    <div className={styles.filterButton}>
                        <Button small
                                id={`contracts_list_filter_button`}
                                icon={{name: getIconName(), size: IconSize.X2}}
                                type={ButtonType.Blue}
                                className={Utility.css(styles.filter)}
                                onClick={async () => await openFiltersAsync()}
                        />
                    </div>
                </div>

            </div>

            {(!data || data.length === 0) && (
                <div className={`${styles.contractsListItem} ${styles.noItems}`}>
                    {Localizer.genericNoData}
                </div>
            )}

            {data &&
                data.map((item, index) => (
                    <div
                        key={index}
                        ref={index === data.length - 1 ? lastElementRef : undefined}
                        className={`${styles.contractsListItem} cursor-pointer`}
                        onClick={async () => await onItemClickAsync(item)}
                        id={`contract_list_element_${item.contractExternalId}`}
                        data-cy={"contract_list_element"}
                    >
                        <div
                            data-cy={`contract_status_bar_${item.contractExternalId}`}
                            className={`${styles.priorityBar} ${getStatusStyles(item)}`}
                        />

                        <div className={styles.leftCell}>
                            <p className={styles.fontBold}>
                                <p>{Localizer.invoiceDetailsPageContractId} {item.contractExternalId}</p>
                            </p>
                        </div>

                        <div className={styles.centerCell}>
                            <span>{item.customerName} </span>
                            <span>{item.customerExternalId} </span>
                        </div>

                        <div className={styles.rightCell}>
                        </div>
                    </div>
                ))}


            <ContractsFilterModal request={request}
                                  modalRef={filtersModalRef}
                                  title={Localizer.genericContracts.toUpperCase()}
                                  onSubmit={async (req) => await submitFiltersAsync(req)}/>

        </div>
    );
};

export default ContractsList;