import React from "react";
import {ch, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {ButtonType, Icon, IconStyle, PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import ArsenalPageRow from "../../components/ArsenalPageRow/ArsenalPageRow";
import PageDefinitions from "../../providers/PageDefinitions";
import ArsenalButton from "../../components/ArsenalButton/ArsenalButton";
import Report from "../Models/Report";
import Device from "../Models/Device";
import DownloadReportPdfResponse from "../../models/server/responses/DownloadReportPdfResponse";
import DeviceInfo from "@/models/server/DeviceInfo";
import {DeviceStatus, InspectionErrors, MaintenanceStatus} from "@/models/Enums";
import ToolsUtility from "@/helpers/ToolsUtility";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import UnleashHelper from "@/helpers/UnleashHelper";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "../DevicePage/DevicePage.module.scss";
import savePageStyles from './SavePage.module.scss';
import EndpointPaths from "@/common/EndpointPaths";
import GetDeviceMaintenanceDetailsResponse from "@/models/server/responses/GetDeviceMaintenanceDetailsResponse";
import HttpClient from "@/common/HttpClient";
import {Utility} from "@renta-apps/athenaeum-toolkit";

export interface ISavePageParams {
    inspectionError?: InspectionErrors;
}

interface ISavePageProps  {
    inspectionError?: InspectionErrors;
}

interface ISavePageState {
    hasFaults : boolean;
    serviceTypeName: string;
    faults: string[];
}

export default class SavePage extends AuthorizedPage<ISavePageProps, ISavePageState> {

    state: ISavePageState = {
        hasFaults: false,
        serviceTypeName: "",
        faults: [],
    };

    private async closeAsync(): Promise<void> {

        await RentaToolsController.clearReportAsync();

        await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute);
    }

    private async downloadReportPdfAsync(): Promise<void> {
        if (this.report) {

            const reportId: string = this.report.id;
            const response: DownloadReportPdfResponse = await RentaToolsController.downloadReportPdfAsync(reportId);

            if (response.reportIsTooBig) {
                await ch.alertErrorAsync(Localizer.savePageAlertErrorReportIsTooBig);
            } else {
                ch.download(response.pdf!);
            }
        }
    }

    private async toDevicePage(): Promise<void> {
        if (this.report) {
            await RentaToolsController.searchDeviceAsync(this.report.deviceExternalId)
        }
    }

    private get device(): Device | null {
        return RentaToolsController.device;
    }

    private get report(): Report | null {
        return RentaToolsController.report;
    }

    private get rightTitle(): string {
        return this.report ? `${Localizer.reportReport} ${ToolsUtility.toDateString(this.report.completedAt)}` : "";
    }

    private get inspectionError(): InspectionErrors {
        return this.typedParameters?.inspectionError ?? InspectionErrors.None;
    }

    private get getInspectionError(): string {
        let msg = "";

        const error: InspectionErrors = this.typedParameters?.inspectionError ?? InspectionErrors.None;

        if (ToolsUtility.hasFlag(error, InspectionErrors.ContractConflict)) {
            msg += `${Localizer.enumInspectionErrorsContractConflict}\n`;
        }

        if (ToolsUtility.hasFlag(error, InspectionErrors.ContractNotFound)) {
            msg += `${Localizer.enumInspectionErrorsContractNotFound}\n`;
        }

        if (ToolsUtility.hasFlag(error, InspectionErrors.WrongContract)) {
            msg += `${Localizer.enumInspectionErrorsWrongContract}\n`;
        }

        if (ToolsUtility.hasFlag(error, InspectionErrors.ReportNotFound)) {
            msg += Localizer.enumInspectionErrorsReportNotFound;
        }

        return msg;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if ((this.report == null) || (this.device == null)) {
            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute);
        }

        let serviceTypeName: string = "";
        let faults: string[] = [];
        let hasFaults: boolean;

        if (UnleashHelper.isEnabled(RentaToolsConstants.featureFlagServiceAndRepairEnabled)) {
            hasFaults = DeviceInfo.hasFaults(RentaToolsController.device);

            const serviceTypeNameResponse = await HttpClient.postAsync<GetDeviceMaintenanceDetailsResponse>(EndpointPaths.DevicePaths.GetDeviceMaintenanceDetails, RentaToolsController.device?.externalId);
            serviceTypeName = serviceTypeNameResponse?.serviceTypeName ?? "";
            faults = serviceTypeNameResponse?.unfixedFaults ?? [];
        } else {
            hasFaults = this.report?.passed === false;
        }

        this.setState({
            hasFaults: hasFaults,
            faults: faults,
            serviceTypeName: serviceTypeName,
        })

    }

    private get summaryMessage(): string {
        let message = "";

        if (!this.device) {
            return message;
        }

        if (this.device.status == DeviceStatus.InStock) {
            return message + Localizer.summaryPageResultingStatusInStock;
        }

        const maintenanceStatus = this.device?.maintenanceStatus ?? MaintenanceStatus.None;
        if (ToolsUtility.hasFlag(maintenanceStatus, MaintenanceStatus.RequiresService) && ToolsUtility.hasFlag(maintenanceStatus, MaintenanceStatus.RequiresRepair)) {
            message = Localizer.summaryPageResultingStatusRepairAndService.format(this.state.serviceTypeName);
        }
        else if (ToolsUtility.hasFlag(maintenanceStatus, MaintenanceStatus.RequiresService) && !ToolsUtility.isNullOrEmpty(this.state.serviceTypeName)) {
            message = Localizer.summaryPageResultingStatusRequiresService.format(this.state.serviceTypeName);
        }
        else if (ToolsUtility.hasFlag(maintenanceStatus, MaintenanceStatus.RequiresRepair)) {
            message = Localizer.summaryPageResultingStatusRequiresRepair;
        }

        if (this.state.faults?.length > 0) {
            message += "\n\n" + Localizer.enumMaintenanceReasonFaultsFound + ":";
            message += "\n - " + this.state.faults.join("\n - ");
        }

        return message;
    }

    public get passedIcon(): React.ReactNode {
        const id = this.state.hasFaults ? "failed_icon" : "success_icon";
        const className = this.state.hasFaults ? savePageStyles.failedIcon : savePageStyles.checkIcon;
        const iconName = this.state.hasFaults ? "far fa-times-circle" : "far fa-check-circle";
        return (
            <Icon id={id} name={iconName} className={className} style={IconStyle.Light} />
        );
    }

    public get maintenanceIcon(): React.ReactNode {
        let name = "";

        const inStock = this.device?.status == DeviceStatus.InStock;
        if (this.state.faults?.length > 0) {
            name = "fa-tools";
        }
        else if (this.device?.status == DeviceStatus.InMaintenance) {
            name = "fa-oil-can";
        }
        else {
            name = "fa-forklift";
        }

        return (
            <Icon className={Utility.css(savePageStyles.maintenanceIcon, inStock && savePageStyles.passed)} name={name} style={IconStyle.Light} />
        );
    }

    private renderTitle(): React.ReactNode {
        return (
            <React.Fragment>

                {
                    (this.device) &&
                    (
                        <div>
                            <div className={styles.multiTitle}>
                                <span>{this.device.productGroupName}</span>
                                <span className={styles.deviceType}>{this.device.type}</span>
                                <span>{this.device.externalId}</span>
                            </div>
                        </div>
                    )
                }

            </React.Fragment>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer id={"save_page_container"} alertClassName={rentaToolsStyles.alert} className={this.css(rentaToolsStyles.pageContainer, styles.device)}>

                <PageHeader className={rentaToolsStyles.leftPageHeader} title={() => this.renderTitle()}>

                    <span className={rentaToolsStyles.rightPageHeader}>{this.rightTitle}</span>
                </PageHeader>

                <ArsenalPageRow className={savePageStyles.pageRow}>
                    <span className={savePageStyles.title}>{Localizer.savePageTitle}</span>

                    <div data-cy={"text_container"} className={savePageStyles.textContainer}>
                        {this.passedIcon}
                        {Localizer.summaryPageReturnInspectionSaved}
                    </div>

                    <div data-cy={"summary_container"} className={savePageStyles.textContainer}>
                        {this.maintenanceIcon}
                        {this.summaryMessage}
                    </div>

                    {
                        (this.inspectionError > InspectionErrors.None) && (
                            <div className={savePageStyles.textContainer}>
                                <Icon id={"failed_icon"} name={"far fa-times-circle"} className={savePageStyles.failedIcon}/>
                                {
                                    this.getInspectionError
                                }
                            </div>
                        )
                    }

                    <ArsenalButton block
                                   className={savePageStyles.actionBtn}
                                   type={ButtonType.Orange}
                                   label={Localizer.savePageShareReport}
                                   onClick={async () => await this.downloadReportPdfAsync()}/>

                    <ArsenalButton block
                                   id={"go_to_device_page"}
                                   className={savePageStyles.actionBtn}
                                   type={ButtonType.Orange}
                                   label={Localizer.devicePageToDevicePage}
                                   onClick={async () => await this.toDevicePage()}/>

                    <ArsenalButton block
                                   id={"go_to_front_page"}
                                   className={savePageStyles.actionBtn}
                                   type={ButtonType.Orange}
                                   label={Localizer.savePageToStart}
                                   onClick={async () => await this.closeAsync()}/>

                </ArsenalPageRow>

            </PageContainer>
        );
    }
}