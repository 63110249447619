import AuthorizedPage from "@/models/base/AuthorizedPage";
import React from "react";
import {PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import styles from "@/components/ReturnInspectionDevicesList/ReturnInspectionDevicesList.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import newStyles from "@/pages/NewUI.module.scss";
import pageStyles from "@/pages/DataManagementPage/DataManagementPage.module.scss";
import Depo from "@/models/server/Depo";
import DepoService from "@/services/DepoService";
import DepoSelection from "@/pages/DepoSelectionPage/components/DepoSelection";
import Show from "@/components/Show/Show";
import Loader from "@/components/Loader/Loader";
import {IDepoPageParameters} from "@/models/DepoPageParameters";
import {DeviceListOrderType} from "@/models/Enums";
import Localizer from "@/localization/Localizer";
import PageDefinitions from "@/providers/PageDefinitions";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";


interface IDepoSelectionPageState {
    depos: Depo[],
    isLoading: boolean;
    orderType: DeviceListOrderType,
}

interface IDepoSelectionPageProps {
}

export default class DepoSelectionPage extends AuthorizedPage<
    IDepoSelectionPageProps,
    IDepoSelectionPageState
> {
    public async initializeAsync(): Promise<void> {
        this.setState({
            isLoading: true,
        })

        const {orderType} = this.parameters as IDepoPageParameters;

        const depos = await DepoService.getUserDeposAsync();

        if (depos.length === 1) {
            await PageRouteProvider.redirectAsync(PageDefinitions.editOrderDeviceRoute(depos[0].id, orderType));
        }

        this.setState({
            depos,
            isLoading: false,
            orderType: orderType,
        })

        return super.initializeAsync();
    }

    public render(): React.ReactNode {
        return (
            <>
                <Loader isLoading={this.state.isLoading}/>
                <PageContainer
                    className={this.css(
                        styles.dashboard,
                        rentaToolsStyles.pageContainer,
                        newStyles.pageContainer,
                        pageStyles.searchResults,
                    )}
                >
                    <PageHeader title={Localizer.editDeviceOrderPageEdit}/>
                    <Show.When isTrue={this.state.depos?.length > 0}>
                        <DepoSelection depos={this.state.depos} orderType={this.state.orderType}/>
                    </Show.When>
                </PageContainer>
            </>
        );
    }
}