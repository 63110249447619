import BaseClassifier from "@/models/server/BaseClassifier";
import {ExpenseUnit} from "@/models/Enums";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";

export default class AdditionalExpense extends BaseClassifier {
    constructor(min?: number | null, max?: number | null, defaultValue?: number | null, step?: number | null) {
        super();

        this.min = min ?? AdditionalExpense.getSuggestedStep(this.unit);
        this.max = max ?? RentaToolsConstants.maxResourceValue;
        this.defaultValue = defaultValue ?? this.min;
        this.step = step ?? AdditionalExpense.getSuggestedStep(this.unit);
    }

    public unit: ExpenseUnit = ExpenseUnit.Piece;

    public min: number = 0;

    public max: number = 0;

    public defaultValue: number = 0;

    public step: number = 0;

    public isGeneric: boolean = false;

    public isUnassociatedWithReportDefinition: boolean = false;

    public isAdditionalExpense: true = true;

    public static getSuggestedStep(unit: ExpenseUnit): number {
        switch (unit) {
            case ExpenseUnit.Piece:
                return 1;
            case ExpenseUnit.Meter:
                return 0.01;
            case ExpenseUnit.Meter2:
                return 0.01;
            case ExpenseUnit.Kilometer:
                return 0.01;
            case ExpenseUnit.Liter:
                return 0.01;
            case ExpenseUnit.Box:
                return 1;
            case ExpenseUnit.Pair:
                return 1;
            case ExpenseUnit.Bottle:
                return 1;
            case ExpenseUnit.Can:
                return 1;
            case ExpenseUnit.Bag:
                return 1;
            case ExpenseUnit.Roll:
                return 1;
            case ExpenseUnit.Hour:
                return 0.01;
        }
    }
}