import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import ServicedDevicesPage from "@/tests/pages/ServicedDevicesPage";
import DeviceFiltersModal from "@/tests/pages/DeviceFiltersModal";
import CypressFeatureFlagService from "@/tests/CypressFeatureFlagService";
import DashboardPage from "@/tests/pages/DashboardPage";
import DevicePage from "@/tests/pages/DevicePage";
import DeviceServicePage from "@/tests/pages/DeviceServicePage";
import Localizer from "@/localization/Localizer";
import {executeWithIntercept} from "@/helpers/CypressHelper";

describe('Serviced devices list tests', () => {

    before(() => {
        CypressFeatureFlagService.enable(RentaToolsConstants.featureFlagServiceAndRepairEnabled);
        CypressFeatureFlagService.disable(RentaToolsConstants.featureFlagExtendedOphInformation);
    });

    beforeEach(() => {
        cy.login();
        cy.visit('/');
        cy.get('.athenaeum-top-nav-middle')
            .find('[href="/Dashboard"]')
            .realClick();

        DashboardPage.tryToStopService();
        DashboardPage.navigateToServices();
    })

    it('Device row should contain all related info', () => {
        ServicedDevicesPage.getDeviceRow('SRListDeviceId').find('[data-cy="deviceId"]').should('contain.text', 'SRListDeviceId');
        ServicedDevicesPage.getDeviceRow('SRListDeviceId').find('[data-cy="deviceId"]').should('contain.text', '101');
        ServicedDevicesPage.getDeviceRow('SRListDeviceId').find('[data-cy="deviceName"]').should('contain.text', 'S&R LIST TEST DEVICE TYPE');
        ServicedDevicesPage.getDeviceRow('SRListDeviceId').find('[data-cy="serviceDateInfo"]').should('contain.text', '(5)');
    });

    it('Device goes to service and priority bar changes to green', () => {

        // #fe5000 orange
        ServicedDevicesPage.getDeviceRow('SRListDeviceId').find('[data-cy="priorityBar"]').should('have.css', 'background-color', 'rgb(254, 80, 0)')

        executeWithIntercept(() => ServicedDevicesPage.getDeviceRow('SRListDeviceId').click(), [ServicedDevicesPage.routes.findServiceReportDefinition]);

        DevicePage.startService();

        cy.goToServicedDevicesPage();

        // #009522 green
        ServicedDevicesPage.getDeviceRow('SRListDeviceId').find('[data-cy="priorityBar_progress"]').should('have.css', 'background-color', 'rgb(0, 149, 34)')

        executeWithIntercept(() => ServicedDevicesPage.getDeviceRow('SRListDeviceId').click(), [ServicedDevicesPage.routes.findServiceReportDefinition]);
    });

    it('Filters work correctly', () => {
        DeviceFiltersModal.elements.filterButton().click();

        filtersVisible();

        filtersWork();
    });

    it('S&R works', () => {
        executeWithIntercept(() => ServicedDevicesPage.getDeviceRow('ThisIsId').click(), [ServicedDevicesPage.routes.findServiceReportDefinition]);

        DevicePage.startService();

        DeviceServicePage.selectServiceType('100 hours service');
        DeviceServicePage.saveServiceWithAction();

        cy.contains(Localizer.deviceServicePageAlertServiceReportSaved);

        DevicePage.assertToolsStatus(Localizer.enumMaintenanceReasonRepair);

        DevicePage.startService();

        DeviceServicePage.saveRepairWithAllFaultsFixed();

        DevicePage.assertInStockWithNoStatus();
    });

    it('Cannot save lesser operating hours for new service than in previous service', () => {
        executeWithIntercept(() => ServicedDevicesPage.getDeviceRow('TestDeviceOperatingHoursInService').click(), [ServicedDevicesPage.routes.findServiceReportDefinition]);

        DevicePage.openDeviceInfo();
        DevicePage.deviceAttribute(Localizer.dataServiceAttributesLastServiceOperatingHours)
            .siblings().first().contains(155);

        DevicePage.startService();

        DeviceServicePage.setOperatingHours('154');

        DeviceServicePage.selectServiceType('100 hours service');
        DeviceServicePage.saveServiceWithAction();

        DeviceServicePage.alertIsShown();
    });

    it('Can save lesser operating hours for a service with earlier date', () => {
        executeWithIntercept(() => ServicedDevicesPage.getDeviceRow('TestDeviceOperatingHoursInService').click(), [ServicedDevicesPage.routes.findServiceReportDefinition]);

        DevicePage.startService();

        const yesterday = Utility.today().addDays(-1).getDate();

        DeviceServicePage.setServiceDate(`${yesterday}`);

        DeviceServicePage.setOperatingHours('154');

        DeviceServicePage.selectServiceType('100 hours service');
        DeviceServicePage.saveServiceWithAction();

        DevicePage.textShownOnPage(Localizer.deviceServicePageAlertServiceReportSaved);

        cy.goToFrontPage();
    });

    it('Cannot add more hours than 24*days', () => {
        executeWithIntercept(() => ServicedDevicesPage.getDeviceRow('Another OpH test Device').click(), [ServicedDevicesPage.routes.findServiceReportDefinition]);

        DevicePage.startService();

        DeviceServicePage.setOperatingHours('180');

        DeviceServicePage.selectServiceType('100 hours service');
        DeviceServicePage.saveServiceWithAction();

        DeviceServicePage.alertIsShown();
    });

    it('Can add 24 hours', () => {
        executeWithIntercept(() => ServicedDevicesPage.getDeviceRow('Another OpH test Device').click(), [ServicedDevicesPage.routes.findServiceReportDefinition]);

        DevicePage.startService();

        DeviceServicePage.setOperatingHours('179');

        DeviceServicePage.selectServiceType('100 hours service');
        DeviceServicePage.saveServiceWithAction();

        DevicePage.textShownOnPage(Localizer.deviceServicePageAlertServiceReportSaved);

        cy.goToFrontPage();
    });

    function filtersVisible() {
        for (let elem of Object.values(DeviceFiltersModal.elements.inputs)) {
            elem().should('be.visible');
        }
    }

    function filtersWork() {
        const optionContainer = '.athenaeum-dropdown-scrollableContainer';

        ServicedDevicesPage.useFilter(
            () => DeviceFiltersModal.elements.inputs.deviceExternalId(),
            [ServicedDevicesPage.constants.testDeviceId]
        );
        ServicedDevicesPage.assertOnly();

        DeviceFiltersModal.clearAndOpenServiceFilters();
        ServicedDevicesPage.useFilter(
            () => DeviceFiltersModal.elements.inputs.productGroupIds(),
            [ServicedDevicesPage.constants.testDeviceGroup],
            optionContainer
        );
        ServicedDevicesPage.assertOnly();

        DeviceFiltersModal.clearAndOpenServiceFilters();
        ServicedDevicesPage.useFilter(
            () => DeviceFiltersModal.elements.inputs.depoCostPools(),
            [ServicedDevicesPage.constants.testDeviceDepo],
            optionContainer
        );
        ServicedDevicesPage.assertNotOnly();

        // ServicedDevicesPage.clearAndOpenFilters();
        // ServicedDevicesPage.useFilter(
        //     () => DeviceFiltersModal.elements.inputs.rentalObjectGroups(),
        //     [ServicedDevicesPage.constants.testRentalObjectGroup],
        //     optionContainer
        // );
        // ServicedDevicesPage.assertNotOnly();

        DeviceFiltersModal.clearAndOpenServiceFilters();
        ServicedDevicesPage.useFilter(
            () => DeviceFiltersModal.elements.inputs.servicesStatus(),
            [Localizer.servicedPageCompleted, Localizer.servicedPageInProgress, Localizer.servicedPageWaitingForServiceOrRepair],
            optionContainer
        );
        ServicedDevicesPage.assertNotInList();

        DeviceFiltersModal.clearAndOpenServiceFilters();
        ServicedDevicesPage.useFilter(
            () => DeviceFiltersModal.elements.inputs.servicesTypes(),
            [Localizer.servicedPageService],
            optionContainer
        );
        ServicedDevicesPage.assertNotInList();
    }
});