import DashboardPage from "@/tests/pages/DashboardPage";
import DeviceAnnualInspectionFormPage from "@/tests/pages/DeviceAnnualInspectionFormPage";
import {executeWithIntercept} from "@/helpers/CypressHelper";
import DevicePage from "@/tests/pages/DevicePage";

describe('Device existing annual inspection form tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.login();
        cy.goToDashboard();
    });

    // Device has been inspected with remarks. There should be a functioning option for marking remarks as repaired.
    it('Tests that remarks repaired works and banner is removed correctly', () => {
        DashboardPage.openDevice("9001");

        DevicePage.elements.banOfUseBanner().should('be.visible');

        executeWithIntercept(() => {
            DevicePage.elements.recordAnnualInspectionBtn().click();
        }, [DeviceAnnualInspectionFormPage.routes.getDeviceAnnualInspectionSetup])

        cy.wait(500);

        executeWithIntercept(() => {
            DeviceAnnualInspectionFormPage.attachFile();
            DeviceAnnualInspectionFormPage.submitFormWithConfirm();
        }, [DeviceAnnualInspectionFormPage.routes.saveRemarksRepaired])

        cy.wait(500);

        cy.goToDashboard();

        cy.wait(500);

        DashboardPage.openDevice("9001");

        DevicePage.assertNoBan();
    });
});