import {ButtonType, Dropdown, Modal, SelectListItem} from "@renta-apps/athenaeum-react-components";
import React, {useEffect} from "react";
import {MaintenanceReason} from "@/models/Enums";
import Localizer from "@/localization/Localizer";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import {useClearDropdownsFilters} from "@/components/DevicesFiltersModal/ClearDropdownsFilters";
import styles from './AddMaintenanceReasonModal.module.scss';
import EnumProvider from "@/providers/EnumProvider";
import ServiceReportDefinition from "@/pages/Models/ServiceReportDefinition";
import {DeviceMaintenanceReason} from "@/models/DeviceMaintenance";
import UnleashHelper from "@/helpers/UnleashHelper";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
export interface AddMaintenanceReasonModalProps {
    modalRef: React.RefObject<Modal>;
    onModalClose: (maintenanceStatus : DeviceMaintenanceReason | null) => void;
    reportDefinition : ServiceReportDefinition | null;
}

const AddMaintenanceReasonModal = ({ modalRef, onModalClose, reportDefinition } : AddMaintenanceReasonModalProps) => {
    const [ maintenanceReason, setMaintenanceReason] = React.useState<MaintenanceReason | null>(null);
    const [ definitionTypes, setDefinitionTypes] = React.useState<SelectListItem[]>([]);
    const [ programStep, setProgramStep] = React.useState<SelectListItem>();
    const [ maintenanceReasonItems, setMaintenanceReasonItems ] = React.useState<SelectListItem[]>(EnumProvider.getMaintenanceReasonItems());
    const { addRef, clearDropdowns } = useClearDropdownsFilters();
    
    const closeModal = () => {
        setMaintenanceReason(null);
        clearDropdowns();
        modalRef.current?.closeAsync();
    }

    useEffect(() => {
        if(!UnleashHelper.isEnabled(RentaToolsConstants.featureFlagAnnualInspectionsEnabled)){
            setMaintenanceReasonItems(EnumProvider.getMaintenanceReasonItems().filter((item) => item.value !== MaintenanceReason.AnnualInspection.toString()));
        }
    }, []);
    

    useEffect(() => {
        if(reportDefinition) {
            const types = reportDefinition.types.map((type) => (new SelectListItem(type.id, type.name)));
            setDefinitionTypes(types);
        }
    }, [reportDefinition]);
    
    const onSave = () => {
        if(maintenanceReason != null) {
            onModalClose({
                typeId: programStep?.value,
                definitionName: programStep?.text,
                reason: maintenanceReason,
                reasonText: ""
            })
        }
        closeModal()
    }
    
    const onProgramSelect = (item : SelectListItem | null) => {
        if (item) {
            setProgramStep(item);
        }
    }
    
    const setMaintenanceReasonValue = (item : SelectListItem | null) => {
        if (item) {
            setMaintenanceReason(parseInt(item.value));
        }
    }


    return (
        <Modal id={"addMaintenanceReasonModal"} ref={modalRef} >
            <div>
                <div>
                    <Dropdown
                        ref={addRef}
                        id={"maintenanceReason"}
                        name="maintenanceReason"
                        nothingSelectedText={"-"}
                        items={maintenanceReasonItems}
                        className={styles.dropdownContainer}
                        value={maintenanceReason?.toString()}
                        selectedItem={maintenanceReason?.toString()}
                        onChange={async (sender, item, userInteraction) =>
                            setMaintenanceReasonValue(item)
                        }
                    />
                </div>

                <div>
                    <Dropdown
                        ref={addRef}
                        id={"programStep"}
                        name="programStep"
                        nothingSelectedText={"-"}
                        items={definitionTypes}
                        className={styles.dropdownContainer}
                        value={programStep?.value}
                        selectedItem={programStep}
                        onChange={async (sender, item, userInteraction) => {
                            onProgramSelect(item);
                        }}
                    />
                </div>

                <ArsenalButton
                    block
                    big
                    type={ButtonType.Blue}
                    className={rentaToolsStyles.arsenalButton}
                    label={Localizer.genericSave}
                    onClick={async () => onSave()}
                />

                <ArsenalButton
                    block
                    big
                    type={ButtonType.Orange}
                    className={rentaToolsStyles.arsenalButton}
                    label={Localizer.genericExit}
                    onClick={async () => closeModal()}
                />
            </div>
        </Modal>
    )
}


export default AddMaintenanceReasonModal;