import {ch, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import FindDeviceResponse from "@/models/server/responses/FindDeviceResponse";
import Device from "@/pages/Models/Device";
import {DeviceStatus, MaintenanceStatus, ReportItemType, ResourceItemType, ReturnInspectionResponseType} from "@/models/Enums";
import ContinueReturnInspectionResponse from "@/models/server/responses/ContinueReturnInspectionResponse";
import PageDefinitions from "@/providers/PageDefinitions";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import Report from "@/pages/Models/Report";
import UpdateReturnInspectionRequest from "@/models/server/requests/UpdateReturnInspectionRequest";
import UpdateReturnInspectionResponse from "@/models/server/responses/UpdateReturnInspectionResponse";
import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";
import QuestionPicturesReportItem from "@/pages/Models/QuestionPicturesReportItem";
import StartReturnInspectionResponse from "@/models/server/responses/StartReturnInspectionResponse";
import UpdateReturnInspectionStepRequest from "@/models/server/requests/UpdateReturnInspectionStepRequest";
import SaveReturnInspectionStepResponse from "@/models/server/responses/SaveReturnInspectionStepResponse";
import CancelReturnInspectionResponse from "@/models/server/CancelReturnInspectionResponse";
import PreviewReturnInspectionResponse from "@/models/server/responses/PreviewReturnInspectionResponse";
import {IIconProps, IWizardStep, IWizardSteps} from "@renta-apps/athenaeum-react-components";
import Comparator from "@/helpers/Comparator";
import SkipReturnInspectionResponse from "@/models/server/responses/SkipReturnInspectionResponse";
import SkipNewReturnInspectionRequest from "@/models/server/requests/SkipNewReturnInspectionRequest";
import DeviceInfo from "@/models/server/DeviceInfo";
import ListUnInspectedDevicesRequest from "@/models/server/requests/ListUnInspectedDevicesRequest";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import UpdateReturnInspectionReportOperatingHoursRequest from "@/models/server/requests/UpdateReturnInspectionReportOperatingHoursRequest";
import IGetDeviceTelematicsResponse from "@/models/server/responses/IGetDeviceTelematicsResponse";
import ToolsUtility from "@/helpers/ToolsUtility";
import {GetDeviceCodeFaultsResponse} from "@/models/server/responses/GetDeviceCodeFaultsResponse";
import UserContext from "@/models/server/UserContext";
import BaseController from "@/pages/BaseController";
import EndpointPaths from "@/common/EndpointPaths";
import TrackUnitDeviceTelemetry from "@/pages/Models/TrackUnitDeviceTelemetry";
import BaseResponse from "@/models/server/responses/BaseResponse";
import RentaToolsController from "@/pages/RentaToolsController";
import RentaToolsStorage, {RentaToolsStorageTable} from "@/pages/RentaToolsStorage";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import UnleashHelper from "@/helpers/UnleashHelper";
import HttpClient from "@/common/HttpClient";
import Localizer from "@/localization/Localizer";

class ReturnInspectionController extends BaseController {
    private removePhotoSrcFromItems(item: ReportDefinitionItem): void {
        if (RentaToolsConstants.imageStepTypes.includes(item.type)) {
            let itemWithPictures = item as QuestionPicturesReportItem;
            if (itemWithPictures.pictures) {
                itemWithPictures.pictures.forEach(picture => {
                    picture.src = "";
                });
            }
        }
    }

    public async getReportsByDeviceExternalId(externalId: string | null = null, pageNumber: number, pageSize: number): Promise<Report[]> {
        return await HttpClient.getAsyncWithoutErrorHandling<Report[]>(EndpointPaths.ReturnInspectionPaths.GetReportsByDeviceExternalId(externalId ?? "", pageNumber, pageSize));
    }

    public async getReturnInspectionFaultCode(deviceId: string): Promise<GetDeviceCodeFaultsResponse> {
        return await HttpClient.getAsync<GetDeviceCodeFaultsResponse>(EndpointPaths.ReturnInspectionPaths.GetErrorCodesByDeviceId(deviceId));
    }

    public async continueInspectionAsync(externalId: string | null = null): Promise<void> {
        await ch.getLayout().setSpinnerAsync(true);

        let deviceExternalId: string = RentaToolsController.context.device?.externalId ?? "";

        if (externalId) {
            deviceExternalId = externalId;
        }

        const response: FindDeviceResponse = await RentaToolsController.findDeviceAsync(deviceExternalId);

        const device: Device | null = response.device;

        const ctx: UserContext = ch.getContext() as UserContext;

        const multiUserSupport = UnleashHelper.isEnabled(RentaToolsConstants.featureFlagRiMultiUser) || ctx.isAdmin;
        const supportInspection: boolean = (device != null);
        const inspectionIsNeeded: boolean = (device != null) && (device.status == DeviceStatus.ReturnInspectionIsNeeded || ToolsUtility.hasFlag(device.maintenanceStatus, MaintenanceStatus.RequiresReturnInspection));
        const underInspection: boolean = (device != null) && (UnleashHelper.isEnabled(RentaToolsConstants.featureFlagRiMultiUser) || device.underInspection);
        const canContinue = (supportInspection) && (underInspection) && (inspectionIsNeeded);

        if (canContinue) {

            const response: ContinueReturnInspectionResponse = await HttpClient.postAsync(EndpointPaths.ReturnInspectionPaths.ContinueReturnInspection, {
                deviceExternalId: device?.externalId
            })

            if (response.success) {

                RentaToolsController.context.device = device;
                RentaToolsController.context.report = response.report;
                RentaToolsController.context.preview = false;

                RentaToolsController.saveContext();

                await PageRouteProvider.redirectAsync(PageDefinitions.summaryRoute, true);
            } else if (response.type === ReturnInspectionResponseType.NotFound) {
                await ch.alertErrorAsync(Localizer.returnInspectionContinueErrorOldInpsection);
            } else if (response.type === ReturnInspectionResponseType.OvertakenByDifferentUser) {
                await ch.alertErrorAsync(Localizer.returnInspectionContinueErrorOvertakenByDifferentUser);
            }

        } else {

            RentaToolsController.context.report = null;
            RentaToolsController.context.device = null;

            RentaToolsController.saveContext();

            const message: string = (!supportInspection)
                ? Utility.format(Localizer.inspectionCannotBeContinueDeviceNotFound, deviceExternalId)
                : (!inspectionIsNeeded)
                    ? Utility.format(Localizer.inspectionCannotBeContinueDeviceDoesNotNeedReturnInspection, deviceExternalId, EnumProvider.getDeviceStatusText(device!.status))
                    : Utility.format(Localizer.inspectionCannotBeContinueDeviceAlreadyUnderInspection, deviceExternalId, TransformProvider.toString(device!.inspectionStartedBy));

            await ch.alertErrorAsync(message, true);

            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute);
        }

        await ch.getLayout().setSpinnerAsync(false);
    }

    public async saveReturnInspection(): Promise<void> {
        const report: Report | null = RentaToolsController.report;
        const device: Device | null = RentaToolsController.device;

        if ((report) && (device)) {

            const request = new UpdateReturnInspectionRequest();
            request.deviceExternalId = report.deviceExternalId;
            request.items = report.items;
            request.forceRequest = false;

            // No need to send image payload to backend/S3 again
            request.items.forEach(this.removePhotoSrcFromItems);

            const response: UpdateReturnInspectionResponse = await HttpClient.postAsync(EndpointPaths.ReturnInspectionPaths.SaveReturnInspection, request);

            await this.handleClosedContract(response);

            if (await this.handleReturnInspectionErrorAsync(response.type)) {
                return;
            }

            await this.handleInvalidOperatingHours(response);

            await this.handleCompletedInspection(response, report);
        }
    }

    private async handleClosedContract(response: UpdateReturnInspectionResponse): Promise<void> {
        if (response.contractIsClosed && response.unapprovedInvoiceId) {
            const handleClosedContract: boolean = await ch.confirmAsync(Localizer.invoiceDetailsPageAlertReopenContract);

            if (handleClosedContract) {
                await this.handleReturnInspectionInvoiceAsync(response.unapprovedInvoiceId);
            }
        }
    }

    private async handleInvalidOperatingHours(response: UpdateReturnInspectionResponse): Promise<void> {
        if (response.invalidOperatingHours) {
            const message = [];
            message.push(Localizer.serviceOperatingHoursWrongInput);

            if (response.operatingHoursBefore) {
                message.push(`${Localizer.servicePageMinOperatingHoursError} ${response.operatingHoursBefore}`);
            }

            if (response.operatingHoursAfter) {
                message.push(`${Localizer.servicePageMaxOperatingHoursError} ${response.operatingHoursAfter}`);
            }

            await ch.alertMessageAsync(message.join("\n"));

            return;
        }
    }

    private async handleCompletedInspection(response: UpdateReturnInspectionResponse, report: Report): Promise<void> {
        if (response.isCompleted) {

            if (response.wrongDeviceStatus) {
                await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute);
                await ch.alertMessageAsync(Localizer.saveReportAlertMessageInspectionCannotBeSaved);

                return;
            }

            report.id = response.returnInspectionId;
            report.completedAt = Utility.now();

            RentaToolsController.device = response.device;

            RentaToolsController.saveContext();

            RentaToolsStorage.clearData(RentaToolsStorageTable.Files);

            await PageRouteProvider.redirectAsync(PageDefinitions.saveRoute({
                inspectionError: response.error
            }), true);
        }
    }

    private startReturnInspectionAsync(deviceExternalId: string): Promise<StartReturnInspectionResponse> {
        return HttpClient.postAsync(EndpointPaths.ReturnInspectionPaths.StartReturnInspection, {deviceExternalId: deviceExternalId});
    }

    private async handleReturnInspectionInvoiceAsync(invoiceId: string): Promise<BaseResponse> {
        return await HttpClient.postAsync(EndpointPaths.InvoicePaths.HandleReturnInspectionInvoice, invoiceId);
    }

    public async startReturnInspection(deviceExternalId: string): Promise<void> {
        const response: StartReturnInspectionResponse = await this.startReturnInspectionAsync(deviceExternalId);

        if (response.responseType === ReturnInspectionResponseType.Success && response.report != null && response.device != null) {

            RentaToolsController.context.report = response.report;
            RentaToolsController.context.preview = false;
            RentaToolsController.context.device = response.device;

            RentaToolsController.saveContext();

            return;
        } else if (response.responseType === ReturnInspectionResponseType.ReportDefinitionNotFound) {
            await ch.alertMessageAsync(Localizer.devicePageAlertServiceTypeIsNotConfigured);
        } else {

            if (await this.handleReturnInspectionErrorAsync(response.responseType)) {
                return;
            }

            RentaToolsController.context.device = response.device;

            RentaToolsController.saveContext();
            return;
        }
    }

    public async startReturnInspectionFromStep(deviceExternalId: string, underInspection: boolean, fromPoint: ResourceItemType) {
        const ongoingReportDeviceExternalId = await RentaToolsController.loadOngoingReportDeviceExternalId();
        if (ongoingReportDeviceExternalId === deviceExternalId) {

            if (underInspection) {
                await this.continueInspectionAsync(deviceExternalId);
            } else {
                await this.startReturnInspection(deviceExternalId);
            }

            const step = RentaToolsController.report?.items.find(p => p.resourceType === fromPoint);

            if (step !== null) {
                const index = RentaToolsController.report?.items.indexOf(step!);

                if (index === null) {
                    return;
                }

                await this.openStepAsync(index!);
            }
        }
    }

    public async saveReturnInspectionStep(item: ReportDefinitionItem): Promise<void> {
        await ch.getLayout().setSpinnerAsync(true);

        const device: Device | null = RentaToolsController.device;

        if (device) {

            const request = new UpdateReturnInspectionStepRequest();
            request.deviceExternalId = device.externalId;
            request.item = item;

            // No need to send image payload to backend/S3 again
            this.removePhotoSrcFromItems(request.item);

            const response: SaveReturnInspectionStepResponse = await HttpClient.postAsync(EndpointPaths.ReturnInspectionPaths.SaveReturnInspectionStep, request);

            if (response.success) {
                RentaToolsController.context.report = response.report;
                RentaToolsController.saveContext();
            } else {
                await this.handleReturnInspectionErrorAsync(response.type)
            }
        }

        await ch.getLayout().setSpinnerAsync(false);
    }

    public async getTelematicsDataAsync(id: string): Promise<TrackUnitDeviceTelemetry> {
        const response: IGetDeviceTelematicsResponse = await HttpClient.postAsync(EndpointPaths.DevicePaths.GetDeviceTelematics, id);

        if (!response.success) {
            await ch.alertMessageAsync(Localizer.localizerTelematicsStepUnableToGetData);
        }

        return response?.telematics!;
    }

    public async updateReturnInspectionStepOperatingHours(request: UpdateReturnInspectionReportOperatingHoursRequest): Promise<boolean> {
        await ch.getLayout().setSpinnerAsync(true);

        const response: SaveReturnInspectionStepResponse = await HttpClient.postAsync(EndpointPaths.ReturnInspectionPaths.UpdateOperatingHours, request);

        if (!response.success) {
            const alertText = this.getReturnInspectionAlert(response);
            if (alertText) {
                await ch.alertMessageAsync(alertText);
            }
            await ch.getLayout().setSpinnerAsync(false);
            return false;
        }

        RentaToolsController.context.report = response.report;
        RentaToolsController.saveContext();
        await ch.getLayout().setSpinnerAsync(false);
        return true;
    }

    public async stopReturnInspectionAsync(deviceExternalId: string | null = null, items: ReportDefinitionItem[]): Promise<void> {
        await ch.getLayout().setSpinnerAsync(true);

        if ((!deviceExternalId) && (RentaToolsController.device)) {
            deviceExternalId = RentaToolsController.device.externalId;
        }

        if (deviceExternalId) {
            items.forEach(this.removePhotoSrcFromItems);

            await HttpClient.postAsync(EndpointPaths.ReturnInspectionPaths.StopReturnInspection, {
                deviceExternalId: deviceExternalId,
                items: items,
            });

            RentaToolsController.context.report = null;
            RentaToolsController.context.preview = false;
            RentaToolsController.context.device = null;

            RentaToolsController.saveContext();
        }

        await ch.getLayout().setSpinnerAsync(false);
    }

    public async cancelReturnInspectionAsync(deviceExternalId: string | null = null): Promise<void> {
        if (!deviceExternalId) {
            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute);
            return;
        }

        const response: CancelReturnInspectionResponse = await HttpClient.postAsync(EndpointPaths.ReturnInspectionPaths.CancelReturnInspection, {
            deviceExternalId: deviceExternalId
        });

        if (response.success) {

            if ((RentaToolsController.device) && (RentaToolsController.device.externalId == deviceExternalId)) {
                RentaToolsController.context.device = response.device;
            }

            RentaToolsController.context.report = null;
            RentaToolsController.context.preview = false;

            RentaToolsController.saveContext();
        }
    }

    public async previewReturnInspectionAsync(deviceExternalId: string | null = null): Promise<void> {
        if (!deviceExternalId && RentaToolsController.device) {
            deviceExternalId = RentaToolsController.device.externalId;
        }

        if (deviceExternalId) {
            const response: PreviewReturnInspectionResponse = await HttpClient.postAsync(EndpointPaths.ReturnInspectionPaths.PreviewReturnInspection, {
                deviceExternalId: deviceExternalId
            });

            if (response.success) {
                RentaToolsController.context.report = response.report;
                RentaToolsController.context.preview = true;
                RentaToolsController.saveContext();

                await PageRouteProvider.redirectAsync(PageDefinitions.returnInspectionPreviewPageRoute);
                return;

            } else if (!response.success && response.inProgress) {

                await ch.alertMessageAsync(Localizer.returnInspectionInProgressByDiffrentUser);
                RentaToolsController.context.device = response.device;

                RentaToolsController.saveContext();
                return;

            } else {
                await ch.alertMessageAsync(Localizer.devicePageAlertServiceTypeIsNotConfigured);
            }
        }
    }

    public async skipReturnInspectionAsync(comment: string): Promise<void> {

        if (RentaToolsController.device) {

            const request = new SkipNewReturnInspectionRequest();
            request.externalDeviceId = RentaToolsController.device.externalId;
            request.comment = comment;

            const response: SkipReturnInspectionResponse = await HttpClient.postAsync(EndpointPaths.ReturnInspectionPaths.SkipReturnInspection, request);

            if (response.success) {

                RentaToolsController.device = response.device;

                RentaToolsController.saveContext();
            } else {
                await ch.alertMessageAsync(Localizer.devicePageAlertErrorAsync, true);
            }
        }
    }

    public getSteps(): IWizardSteps | undefined {

        const question: IWizardStep = {route: PageDefinitions.questionRoute, title: Localizer.stepQuestionTitle, icon: {name: "fal tint"}};
        const questionPictures: IWizardStep = {route: PageDefinitions.questionPicturesRoute, title: Localizer.stepQuestionPicturesTitle, icon: {name: "fal camera"}};
        const resource: IWizardStep = {route: PageDefinitions.resourceRoute, title: Localizer.stepResourceTitle, icon: {name: "fal gas-pump"}};
        const questionResource: IWizardStep = {route: PageDefinitions.questionResourceRoute, title: Localizer.stepQuestionResourceTitle, icon: {name: "fas tools"}};
        const measuringResource: IWizardStep = {route: PageDefinitions.measuringResourceRoute, title: Localizer.stepMeasuringTitle, icon: {name: "fas tools"}};
        const checks: IWizardStep = {route: PageDefinitions.checksRoute, title: Localizer.stepChecksTitle, icon: {name: "fas tools"}};
        const pictures: IWizardStep = {route: PageDefinitions.picturesRoute, title: Localizer.stepPicturesTitle, icon: {name: "fal camera"}};
        const summary: IWizardStep = {route: PageDefinitions.summaryRoute, title: Localizer.stepSummaryTitle, icon: {name: "fal clipboard-list-check"}};
        const preview: IWizardStep = {route: PageDefinitions.returnInspectionPreviewPageRoute, title: null};
        const advancedChecks: IWizardStep = {route: PageDefinitions.advancedChecksRoute, title: Localizer.stepChecksTitle, icon: {name: "fas tools"}};
        const errorCodes: IWizardStep = {route: PageDefinitions.returnInspectionErrorCodeRoute, title: Localizer.errorCodesErrorCode};
        const last: IWizardStep = (RentaToolsController.preview) ? preview : summary;

        //steps:
        let steps: IWizardStep[] = [];

        const report: Report | null = RentaToolsController.report;

        if (report != null) {
            steps = report.items.map((reportDefinitionItem, index) => {
                let step: IWizardStep;
                switch (reportDefinitionItem.type) {
                    case ReportItemType.Question:
                        step = question;
                        break;
                    case ReportItemType.Resource:
                        step = resource;
                        break;
                    case ReportItemType.QuestionPictures:
                        step = questionPictures;
                        break;
                    case ReportItemType.QuestionResource:
                        step = questionResource;
                        break;
                    case ReportItemType.Pictures:
                        step = pictures;
                        break;
                    case ReportItemType.Checks:
                        step = checks;
                        break;
                    case ReportItemType.MeasuringResource:
                        step = measuringResource;
                        break;
                    case ReportItemType.ErrorsCodes:
                        step = errorCodes;
                        break;
                    case ReportItemType.AdvancedChecks:
                        step = advancedChecks;
                        break;
                    default:
                        throw Error(Localizer.get(Localizer.rentaToolsControllerGetStepsErrorUnknownReportDefinitionType, reportDefinitionItem.type));
                }

                const icon: IIconProps | undefined = (reportDefinitionItem.icon)
                    ? {name: reportDefinitionItem.icon}
                    : this.getResourceIcon(reportDefinitionItem) ?? step.icon;

                const title: string | null = reportDefinitionItem.name || step.title;

                step = {
                    ...step,
                    icon: icon,
                    title: title,
                    route: new PageRoute(step.route.name, index),
                };

                return step;
            });

            steps.push(last);
        }

        return {steps: steps};
    }

    public getFistStep(): PageRoute {
        let steps: IWizardStep[] = this.getSteps()!.steps;
        return steps[0].route;
    }

    private indexOfCurrentStep(route: PageRoute): number {
        const wizard: IWizardSteps | undefined = this.getSteps();
        return (wizard)
            ? wizard.steps.findIndex(step => Comparator.isEqual(step.route, route))
            : -1;
    }

    public getPrevStep(route: PageRoute): PageRoute {
        let index: number = this.indexOfCurrentStep(route);
        let steps: IWizardStep[] = this.getSteps()!.steps;
        if ((index === -1) || (index === 0)) {
            return PageDefinitions.dashboardRoute;
        }
        return steps[index - 1].route;
    }

    public getNextStep(route: PageRoute): PageRoute {
        let index: number = this.indexOfCurrentStep(route);
        let steps: IWizardStep[] = this.getSteps()!.steps;
        if ((index === -1) || (index === steps.length - 1)) {
            return PageDefinitions.dashboardRoute;
        }
        return steps[index + 1].route;
    }

    public async openStepAsync(index: number): Promise<void> {
        let steps: IWizardStep[] = this.getSteps()!.steps;
        if ((index >= 0) && (index < steps.length - 1)) {

            const route: PageRoute = steps[index].route;

            await PageRouteProvider.redirectAsync(route);
        }
    }

    public get reportItem(): ReportDefinitionItem | null {
        if (RentaToolsController.report != null) {
            const route: PageRoute = ch.getPage().route;
            const index: number = this.indexOfCurrentStep(route);
            if ((index >= 0) && (index < RentaToolsController.report.items.length)) {
                return RentaToolsController.report.items[index];
            }
        }

        return null;
    }

    public async listDevicesAsync(request: ListUnInspectedDevicesRequest): Promise<DeviceInfo[]> {
        return await HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ReturnInspectionPaths.ListDevices, request);
    }

    public async getReportAsync(reportId: string): Promise<Report> {
        return await HttpClient.getAsyncWithoutErrorHandling(EndpointPaths.ReturnInspectionPaths.GetReport(reportId));
    }

    private getResourceIcon(item: ReportDefinitionItem): IIconProps | null {
        const resourceTypes: ReportItemType[] = [ReportItemType.Resource, ReportItemType.QuestionResource, ReportItemType.MeasuringResource];

        if (!resourceTypes.some(t => t === item.type)) {
            return null;
        }

        switch (item.resourceType) {
            case ResourceItemType.AdBlue:
            case ResourceItemType.Fueling:
                return {name: "fal gas-pump"};
            case ResourceItemType.Washing:
                return {name: "fal shower"};
            case ResourceItemType.Operating:
                return {name: "fal hourglass-start"};
            default:
                return null;
        }
    }

    private async handleReturnInspectionErrorAsync(type: ReturnInspectionResponseType): Promise<boolean> {
        //return true only when you want to stop executing method

        switch (type) {
            case ReturnInspectionResponseType.NotFound: {
                RentaToolsController.context.report = null;
                await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute);
                await ch.alertMessageAsync(Localizer.returnInspectionErrorEntityNotFound);
                return true;
            }
            case ReturnInspectionResponseType.InProgress:
                await ch.alertMessageAsync(Localizer.returnInspectionInProgressByDiffrentUser);
                break;
            case ReturnInspectionResponseType.WrongDeviceStatus:
                await ch.alertMessageAsync(Localizer.returnInspectionWrongDeviceStatus);
                break;
            case ReturnInspectionResponseType.InvalidOperatingHours:
                await ch.alertErrorAsync(Localizer.serviceOperatingHoursWrongInput);
                break;
            case ReturnInspectionResponseType.OvertakenByDifferentUser:
                await ch.alertErrorAsync(Localizer.returnInspectionContinueErrorOvertakenByDifferentUser);
                break;
        }

        return false;
    }

    public getReturnInspectionAlert(response: SaveReturnInspectionStepResponse): string | null {
        switch (response.type) {
            case ReturnInspectionResponseType.InvalidOperatingHours:
                if (response.operatingHoursBefore && response.operatingHoursAfter) {
                    return Localizer.servicePageOperatingHoursRangeResponse.format(response.operatingHoursBefore, ToolsUtility.toDateString(response.before), response.operatingHoursAfter, ToolsUtility.toDateString(response.after))
                } else {
                    return Localizer.operatingHoursCannotBeLesserThan.format(response.operatingHoursAfter, ToolsUtility.toDateString(response.after));
                }
            case ReturnInspectionResponseType.InvalidSameDayOperatingHours:
                return Localizer.operatingHoursCannotBeDifferentInTheSameDay.format(response.operatingHoursBefore, ToolsUtility.toDateString(response.before));
            default:
                return null;
        }
    }
}

export default new ReturnInspectionController();