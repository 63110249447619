import BaseDeviceClearFilters from "./BaseDeviceClearFilters";
import { RequestRawData } from "@/models/RequestRawData";
import {AnnualInspectionDevicesType} from "@/models/Enums";

class AnnualInspectionsClearFilters extends BaseDeviceClearFilters {
  public override clear(data: RequestRawData): RequestRawData {
    const copy = super.clear(data);

    copy.deviceStatuses = [];
    copy.annualInspectionStatusFilter = "";
    copy.annualInspectionTypeFilter = [
        AnnualInspectionDevicesType.Upcoming.toString(),
        AnnualInspectionDevicesType.Remarks.toString(),
    ];

    return copy;
  }
}

export default new AnnualInspectionsClearFilters();
