export default class ListContractsRequest {
    public pageSize: number = 100;
    public pageNumber: number = 0;
    public modified: boolean = false;

    public deviceExternalId: string = "";
    public contractExternalId: string = "";
    public constructionSiteExternalId: string = "";
    public constructionSiteName: string = "";
    public customerId: string = "";
    public customerName: string = "";
    public depos: string[] = [];

    public nextPage() : void{
        this.pageNumber++;
    }
}