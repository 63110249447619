import React from "react";
import {FileModel, SortDirection, Utility} from "@renta-apps/athenaeum-toolkit";
import {ch, DialogResult, PageRouteProvider, ReactUtility} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, Modal, PageContainer} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import Device from "../Models/Device";
import PageDefinitions from "../../providers/PageDefinitions";
import {DeviceBanOfUse, DeviceStatus, MaintenanceStatus, ReturnInspectionStatus} from "@/models/Enums";
import FindDeviceResponse from "../../models/server/responses/FindDeviceResponse";
import RentaToolsConstants from "../../helpers/RentaToolsConstants";
import ServiceReportDefinition from "@/pages/Models/ServiceReportDefinition";
import SkipServiceModal, {SkipServiceResult} from "@/pages/DevicePage/SkipServiceModal/SkipServiceModal";
import DeviceInfo from "@/models/server/DeviceInfo";
import ListDeviceAnnualInspectionsRequest from "@/models/server/requests/ListDeviceAnnualInspectionsRequest";
import DeviceAnnualInspection from "@/models/server/DeviceAnnualInspection";
import DownloadReportPdfResponse from "@/models/server/responses/DownloadReportPdfResponse";
import UserContext from "@/models/server/UserContext";
import ToolsUtility from "@/helpers/ToolsUtility";
import EndpointPaths from "@/common/EndpointPaths";
import {ExtendedOperatingHoursResponse} from "@/models/server/ExtendedOperatingHoursResponse";
import ArsenalPageRow from "../../components/ArsenalPageRow/ArsenalPageRow";
import ArsenalButton from "../../components/ArsenalButton/ArsenalButton";
import DeviceInfoPanel from "@/components/DeviceInfoPanel/DeviceInfoPanel";
import Banner from "@/components/Alert/Banner";
import {FeatureSwitch} from "@/providers/FeatureSwitch";
import UnleashHelper from "@/helpers/UnleashHelper";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import AnnualInspectionController from "@/pages/AnnualInspectionController";
import RentaToolsController from "../RentaToolsController";
import HttpClient from "@/common/HttpClient";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./DevicePage.module.scss";
import ChangeOdometerModal from "@/components/ChangeOdometerModal/ChangeOdometerModal";
import Show from "@/components/Show/Show";

interface IDevicePageProps {
}

interface IDevicePageState {
  info: boolean;
  serviceDefinition: ServiceReportDefinition | null;
  annualInspectionReports: DeviceAnnualInspection[],
  hideBanOfUseBanner: boolean,
  lastReportId: string | null,
  deviceLocation: google.maps.LatLngLiteral | null,
  ongoingReportDeviceExternalId: string | null,
  extendedOperatingHoursResponse : ExtendedOperatingHoursResponse | null,
}

export default class DevicePage extends AuthorizedPage<IDevicePageProps, IDevicePageState> {

  state: IDevicePageState = {
    info: true,
    serviceDefinition: null,
    annualInspectionReports: [],
    hideBanOfUseBanner: false,
    lastReportId: null,
    deviceLocation: null,
    ongoingReportDeviceExternalId: null,
    extendedOperatingHoursResponse: null,
  };

  private readonly _skipServiceModalRef: React.RefObject<SkipServiceModal> = React.createRef();
  private readonly _topContainer: React.RefObject<HTMLDivElement> = React.createRef();
  private readonly _changeOdometerModal: React.RefObject<Modal> = React.createRef();
  private get canChangeOdometer() : boolean {
    if(this.device?.supportsOperatingHours === true){
      if(ch.isDenmark){
        return true;
      }else {
        return this.isAdminOrManager;
      }
    }
    
    return false;
  }

  private async startAction(): Promise<boolean> {
    if (this.deviceAboutToBeLocked) {
      const result = await this.messageBoxAsync(
          {
            title: Localizer.devicePageDeviceWillBeLocked,
            okButton: Localizer.genericContinue,
            cancelButton: Localizer.genericCancel
          }
      );

      if (result == DialogResult.Cancel) {
        return false;
      }
    }

    return true;
  }

  private async getDeviceLocation(): Promise<google.maps.LatLngLiteral | null> {
    const response = await RentaToolsController.getDeviceLocationAsync(this.device?.externalId!);

    return (response.locationAvailable)
        ? response as google.maps.LatLngLiteral
        : null;
  }

  private async startReturnInspectionAsync(): Promise<void> {
    await ReturnInspectionController.previewReturnInspectionAsync(this.device?.externalId);
  }

  private async startServiceAsync(): Promise<void> {

    const start: boolean = await this.startAction();

    if (!start) {
      return;
    }

    const serviceReportDefinition: ServiceReportDefinition | null = this.state.serviceDefinition;

    if (!serviceReportDefinition) {
      await this.alertErrorAsync(Localizer.devicePageAlertServiceTypeIsNotConfigured, true);
      return;
    }

    await RentaToolsController.startServiceAsync(this.device!, serviceReportDefinition);
  }

  private async refreshAsync(): Promise<void> {
    const externalDeviceId: string = this.device!.externalId;
    const response: FindDeviceResponse = await RentaToolsController.refreshDeviceAsync(externalDeviceId);

    if (response.device == null) {
      await ch.alertErrorAsync(Localizer.dashboardPageNotFound);
    } else {
      RentaToolsController.device = response.device;
      await this.reRenderAsync();
    }
  }

  private async resetStatusAsync(): Promise<void> {
    const externalDeviceId: string = this.device!.externalId;
    await RentaToolsController.resetStatusAsync(externalDeviceId);
    const response: FindDeviceResponse = await RentaToolsController.findDeviceAsync(externalDeviceId);
    RentaToolsController.device = response.device;
    await this.reRenderAsync();
  }

  private async skipInspectionAsync(data: string): Promise<void> {
    await ReturnInspectionController.skipReturnInspectionAsync(data);
    await this.reRenderAsync();
  }

  private async openSkipServiceModalAsync(): Promise<void> {

    const serviceReportDefinition: ServiceReportDefinition | null = (this.device?.serviceReportDefinitionId)
        ? await RentaToolsController.findServiceReportDefinitionAsync(this.device?.serviceReportDefinitionId)
        : null;

    if (!serviceReportDefinition) {
      await this.alertErrorAsync(Localizer.devicePageAlertErrorAsync, true);
      return;
    }

    const result: SkipServiceResult | null = await this.skipServiceModal.confirmAsync(this.device!);

    if (result) {

      await RentaToolsController.skipServiceAsync(serviceReportDefinition, result.comment, result.lastServiceOperatingHours, result.lastServiceDate);

      await this.reRenderAsync();
    }
  }

  private async continueInspectionAsync(): Promise<void> {
    await ReturnInspectionController.continueInspectionAsync();
  }

  private async continueServiceAsync(): Promise<void> {
    await RentaToolsController.continueServiceAsync(this.state.serviceDefinition);
  }

  private async cancelInspectionAsync(): Promise<void> {
    await ReturnInspectionController.cancelReturnInspectionAsync(this.device?.externalId);

    this.setState({
      ongoingReportDeviceExternalId: null,
    })

    await this.reRenderAsync();
  }

  private async stopServiceAsync(): Promise<void> {
    await RentaToolsController.stopServiceAsync(this.device?.id);
    await this.reRenderAsync();
  }

  private async getServiceReportDefinitionAsync(): Promise<ServiceReportDefinition | null> {
    return (this.device?.serviceReportDefinitionId)
        ? await RentaToolsController.findServiceReportDefinitionAsync(this.device?.serviceReportDefinitionId)
        : null;
  }

  private get banOfUseAlertText(): string {
    return this.device?.annualInspectionRemarksOverdue
        ? Localizer.bannerDeviceUseBanRemarks
        : Localizer.bannerDeviceUseBan;
  };

  private get device(): Device | null {
    return RentaToolsController.device;
  }

  private get deleted(): boolean {
    return this.device?.deleted ?? false;
  }

  private get hasAnnualInspectionRemarks(): boolean {
    let latestInspection = this.latestAnnualInspection;
    return !!latestInspection?.remarks && !latestInspection.remarksCompletedDate;
  }

  private get hasRemarksRepairDueDate(): boolean {
    let latestInspection = this.latestAnnualInspection;
    return !!latestInspection?.remarksDueDate;
  }

  private get latestAnnualInspection(): DeviceAnnualInspection | null {
    const inspections: DeviceAnnualInspection[] = this.state.annualInspectionReports;

    if (!inspections || inspections.length <= 0) {
      return null;
    }

    return inspections[0];
  }

  private get lastInspectionRemarks(): string {
    const inspections: DeviceAnnualInspection[] = this.state.annualInspectionReports;

    if (!inspections || inspections.length <= 0) {
      return "";
    }

    const latestInspection = inspections[0];

    if (this.hasRemarksRepairDueDate) {
      return latestInspection.remarks + " " + ToolsUtility.toDateString(latestInspection.remarksDueDate);
    }

    return latestInspection.remarks ?? "";
  }

  private get deviceSupportsAnnualInspections(): boolean {
    return this.device?.supportsAnnualInspections ?? false;
  }

  private get hasOngoingInspection(): boolean {
    return (this.state.ongoingReportDeviceExternalId === this.device?.externalId);
  }

  private get hasOngoingService(): boolean {
    return (RentaToolsController.ongoingService != null);
  }

  private get underInspection(): boolean {
    if (this.device) {
      return this.device.underInspection
    }
    return false;
  }

  private get underExternalInspection(): boolean {
    return (this.device?.underInspection === true) && (this.device?.inspectionStartedById != ch.getUserId());
  }

  private get underExternalService(): boolean {
    return (this.device?.underService === true) && (this.device?.serviceStartedById != ch.getUserId());
  }

  private get underMyInspection(): boolean {
    return (this.device?.underInspection === true) && (this.device.currentInspectionUser?.id == ch.getUserId())
  }

  private get underMyService(): boolean {
    if (!this.device) {
      return false;
    }
    
    if(UnleashHelper.isEnabled(RentaToolsConstants.featureFlagPausedService)) {
      return (this.device?.underService) &&
          (this.device.paused || ((this.device?.serviceStartedById == ch.getUserId())
              && (RentaToolsController.ongoingService != null)
              && (RentaToolsController.ongoingService.deviceId == this.device?.id)));
    }
    
    return (this.device?.underService)
         && (this.device?.serviceStartedById == ch.getUserId())
        && (RentaToolsController.ongoingService != null)
        && (RentaToolsController.ongoingService.deviceId == this.device?.id);
  }

  private get underService(): boolean {
    return this.device?.underService === true;
  }

  private get canStartInspection(): boolean {
    if (!this.device || this.deleted) {
      return false;
    }

    // if (ch.isNorway) {
    //   const maintenanceAttribute = this.device?.attributes?.firstOrDefault(a => a.name === RentaToolsConstants.MaintenanceTaskIdAttributeName);
    //
    //   if (!maintenanceAttribute || maintenanceAttribute.value === null || maintenanceAttribute.value === undefined) {
    //     return false;
    //   }
    // }

    if (this.device.status === DeviceStatus.InRent || !ToolsUtility.isNullOrEmpty(this.device.currentContractExternalId)) {
      return false;
    }

    return (ToolsUtility.hasFlag(this.device.maintenanceStatus, MaintenanceStatus.RequiresReturnInspection) || this.device.status === DeviceStatus.ReturnInspectionIsNeeded)
        && (!this.underMyInspection)
        && (!this.underExternalInspection)
        && (!this.hasOngoingInspection)
        && (!this.hasCurrentlyActiveInspection);
  }

  private get canSkipInspection(): boolean {
    if (!this.device || this.device.deleted) {
      return false;
    }

    if (this.underService) {
      return false;
    }

    if (this.device.status === DeviceStatus.InRent || !ToolsUtility.isNullOrEmpty(this.device.currentContractExternalId)) {
      return false;
    }

    return (ToolsUtility.hasFlag(this.device.maintenanceStatus, MaintenanceStatus.RequiresReturnInspection) || this.device.status === DeviceStatus.ReturnInspectionIsNeeded)
        && (!this.underMyInspection)
        && (!this.underExternalInspection)
        && (this.isAdminOrManager || ch.isNorway)
        && (!this.underInspection);
  }

  public get hasCurrentlyActiveInspection(): boolean {
    return this.device?.returnInspectionStatus === ReturnInspectionStatus.InProgress
        || this.device?.returnInspectionStatus === ReturnInspectionStatus.PartlyCompleted
        || this.device?.returnInspectionStatus === ReturnInspectionStatus.Waiting
  }

  public get hasCurrentlyActiveContinuableInspection(): boolean {
    return this.device?.returnInspectionStatus === ReturnInspectionStatus.PartlyCompleted
        || this.device?.returnInspectionStatus === ReturnInspectionStatus.Waiting;
  }

  private get canSkipService(): boolean {
    if (!this.device || this.deleted) {
      return false;
    }

    return (!RentaToolsController.isDeviceInBanOfUse())
        && !DeviceInfo.maintenanceReasonIsAnnualInspection(this.device as DeviceInfo)
        && (ToolsUtility.hasFlag(this.device.maintenanceStatus, MaintenanceStatus.RequiresService))
        && (!this.underMyService)
        && (!this.underExternalService)
        && (!this.underInspection);
  }

  private get multiUserCanContinue(): boolean {
    if (!this.device || this.device.deleted) {
      return false;
    }

    return this.device.status === DeviceStatus.ReturnInspectionIsNeeded
        && this.hasCurrentlyActiveContinuableInspection
        && (UnleashHelper.isEnabled(RentaToolsConstants.featureFlagRiMultiUser) || this.device.currentInspectionUser === null)
  }

  private get canContinueInspection(): boolean {
    const ctx = this.getContext();
    const roleIsEnough = ctx.isAdmin || ctx.isDepoManager;
    return (!this.deleted) && (this.underMyInspection || this.multiUserCanContinue || (roleIsEnough && this.hasCurrentlyActiveInspection));
  }

  private get canContinueService(): boolean {
    return (!this.deleted)
        && (this.underMyService);
  }

  private get canStopInspection(): boolean {
    return (this.underMyInspection) || ((this.isAdminOrManager) && (this.hasCurrentlyActiveInspection));
  }

  private get canStopService(): boolean {
    return (this.underMyService)
        || (this.isAdminOrManager && this.underExternalService);
  }

  private get canStartService(): boolean {
    return (!this.deleted)
        && (!this.underMyService)
        && (!this.underExternalService)
        && (!this.hasOngoingService)
        && (!this.underInspection);
  }

  private get hasAnnualInspectionDate(): boolean {
    return (this.device?.nextAnnualReportDate != null);
  }

  private get hasReturnInspectionDate(): boolean {
    return (this.device?.lastReturnReportDate != null);
  }

  private get hasPreviousAnnualInspectionDate(): boolean {
    return (this.device?.lastAnnualReportDate != null);
  }

  private get skipServiceModal(): SkipServiceModal {
    return this._skipServiceModalRef.current!;
  }

  private get annualInspectionOrRemarksOverDue(): boolean {
    if (!this.device) {
      return false;
    }

    return ((this.device?.nextAnnualReportDate != null
        && this.device?.nextAnnualReportDate < Utility.now()) || (this.device?.annualInspectionRemarksOverdue));
  }

  private get isBanOfUseEnabled(): boolean {
    const userContext: UserContext = this.getContext();
    return userContext.settings.isAnnualInspectionsBanOfUseEnabled;
  }

  private get isDeviceInBanOfUse(): boolean {
    return RentaToolsController.isDeviceInBanOfUse();
  }

  private get isServiceRequired(): boolean {
    if (!this.device || this.device.deleted) {
      return false;
    }

    return ToolsUtility.hasFlag(this.device.maintenanceStatus, MaintenanceStatus.RequiresService);
  }

  private get deviceAboutToBeLocked(): boolean {
    return (
        this.isBanOfUseEnabled
        &&
        (
            (this.device?.deviceBanOfUse == DeviceBanOfUse.NeedBan || this.device?.deviceBanOfUse == DeviceBanOfUse.Banned)
            || (this.annualInspectionOrRemarksOverDue && this.device?.supportsAnnualInspections == true)
        ) // Check these in case DeviceBanOfUse has not been updated yet
    );
  }

  private get serviceButtonLabel(): string {
    return this.device && ToolsUtility.hasFlag(this.device.maintenanceStatus, MaintenanceStatus.RequiresRepair)
        ? Localizer.devicePageStartRepair
        : Localizer.devicePageStartService;
  }

  private get hasPendingActions(): boolean {
    if (!this.device) {
      return false;
    }

    const serviceAndRepairEnabled = UnleashHelper.isEnabled(RentaToolsConstants.featureFlagServiceAndRepairEnabled);
    if (!serviceAndRepairEnabled && this.device.status !== DeviceStatus.ReturnInspectionIsNeeded) {
      return false;
    }

    const annualInspectionsEnabled = UnleashHelper.isEnabled(RentaToolsConstants.featureFlagAnnualInspectionsEnabled);
    if (this.device.maintenanceStatus === MaintenanceStatus.RequiresAnnualInspection && !annualInspectionsEnabled) {
      return false;
    }

    return this.device.maintenanceStatus > MaintenanceStatus.None;
  }

  private async returnBackAsync(): Promise<void> {
    PageRouteProvider.back();
  }

  private async openOperatingHoursAsync() {
    await PageRouteProvider.redirectAsync(PageDefinitions.operatingHoursRecordRoute);
  }

  private async hideDeviceBanOfUseBannerAsync(): Promise<void> {
    this.setState({hideBanOfUseBanner: true});

    await this.reRenderAsync();
  }

  public getManual(): string {
    return Localizer.dashboardPageGetManual;
  }

  private get canEditDevice() {
    const ctx = this.getContext();
    return ctx.isAdmin && !this.underInspection && !this.underService;
  }

  private async openEditDeviceAsync(): Promise<void> {
    await PageRouteProvider.redirectAsync(PageDefinitions.editDeviceRoute)
  }

  public getMaintenanceFrom(): string {
    const totalMaintenanceDays: number = Utility.diff(Utility.today(), this.device!.statusChangedAt).days;
    const maintenanceFrom: string = Localizer.devicePageMaintenanceFrom.format(this.device!.statusChangedAt);
    const daysInMaintenance: string = Localizer.devicePageMaintenanceFromTotalDays.format(totalMaintenanceDays);

    return (totalMaintenanceDays > 0)
        ? maintenanceFrom + daysInMaintenance
        : maintenanceFrom;
  }

  public async initializeAsync(): Promise<void> {
    await super.initializeAsync();

    if (!RentaToolsController.device) {
      await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute, true);
      return;
    }

    // Hack to get device page to load to top position if navigating from list where scroll position is at bottom
    if (this._topContainer.current) {
      this._topContainer.current.scrollIntoView({block: "end", behavior: 'auto'});
    }

    const serviceDefinition: ServiceReportDefinition | null = await this.getServiceReportDefinitionAsync();

    if (serviceDefinition != null) {
      await this.setState({serviceDefinition})
    }

    const request: ListDeviceAnnualInspectionsRequest = {
      deviceId: this.device!.id,
      sortDirection: SortDirection.Desc,
      amount: 10,
    }

    const inspections: DeviceAnnualInspection[] = await this.postAsync("api/AnnualInspection/ListDeviceAnnualInspections", request);

    if (inspections.length > 0) {
      await this.setState({annualInspectionReports: inspections});
    }

    const latestReturnInspectionId: string | null = await RentaToolsController.getLastReportIdAsync(this.device!.id);

    let deviceLocation = this.state.deviceLocation;

    if (!deviceLocation && UnleashHelper.isEnabled(RentaToolsConstants.featureFlagLocationEnabled)) {
      deviceLocation = await this.getDeviceLocation();
    }

    const ongoingReportDeviceExternalId: string | null = await RentaToolsController.loadOngoingReportDeviceExternalId();

    if (UnleashHelper.isEnabled(RentaToolsConstants.featureFlagExtendedOphInformation)) {

      const extendedOperatingHoursResponse = await HttpClient.getAsync<ExtendedOperatingHoursResponse>(EndpointPaths.DevicePaths.GetExtendedOperatingHoursInfo(this.device!.externalId));

      this.setState({
        lastReportId: latestReturnInspectionId,
        deviceLocation,
        extendedOperatingHoursResponse,
        ongoingReportDeviceExternalId: ongoingReportDeviceExternalId,
      });
    } else {
      this.setState({
        lastReportId: latestReturnInspectionId,
        deviceLocation,
        ongoingReportDeviceExternalId: ongoingReportDeviceExternalId,
      });
    }
  }

  private get annualInspectionReportsWithFiles(): DeviceAnnualInspection[] {
    return this.state.annualInspectionReports.filter((inspection) => inspection.file != null);
  }

  private async downloadLatestAnnualInspectionPdfReportAsync(): Promise<void> {
    const lastInspectionId: string = this.annualInspectionReportsWithFiles[0].id;
    const file: FileModel = await AnnualInspectionController.downloadDeviceAnnualInspectionPdfAsync(lastInspectionId);

    if (!file) {
      await ch.alertErrorAsync(Localizer.genericFileNotFound);
    } else {
      ch.download(file);
    }
  }

  private async downloadReturnInspectionPdfReportAsync(): Promise<void> {

    const latestReturnInspectionReportId: string = this.state.lastReportId!;
    const response: DownloadReportPdfResponse = await RentaToolsController.downloadReportPdfAsync(latestReturnInspectionReportId);

    if (response.reportIsTooBig) {
      await ch.alertErrorAsync(Localizer.reportPreviewPageAlertErrorReportIsTooBig);
    } else {
      ch.download(response.pdf!);
    }
  }

  private get canOpenOperatingHours() {
    return this.isAdminOrManager && this.device?.supportsOperatingHours;
  }

  private renderInfo(): React.ReactNode {
    return (
        <div className={styles.info} id="device_page_info">
          {
              (this.device?.bookingDate) &&
              (
                  <p>
                    {Localizer.devicePageDeviceBookingDate}
                    {
                      <span>
                                    {
                                      ToolsUtility.toDateString(this.device?.bookingDate)
                                    }
                                </span>
                    }
                  </p>
              )
          }

          {this.deviceSupportsAnnualInspections &&
              (
                  <FeatureSwitch flagName={RentaToolsConstants.featureFlagAnnualInspectionsEnabled}>
                    {
                        (this.hasAnnualInspectionDate) &&
                        (
                            <p>
                              {Localizer.devicePageNextAnnualInspectionDate}
                              {
                                <span className={this.css(DeviceInfo.getNextAnnualReportDateStyle(this.device))}>
                                        {
                                          ToolsUtility.toDateString(this.device?.nextAnnualReportDate)
                                        }
                                    </span>
                              }
                            </p>
                        )
                    }

                    {
                        (this.hasPreviousAnnualInspectionDate) &&
                        (
                            <div>
                              {Localizer.devicePagePreviousAnnualInspectionDate}
                              {
                                ToolsUtility.toDateString(this.device?.lastAnnualReportDate)
                              }

                              {this.annualInspectionReportsWithFiles.length > 0 && (
                                  <span className={styles.downloadButtonWrapper}>
                                        <Button id={"annual_download_pdf_button"}
                                                type={ButtonType.Orange}
                                                label={Localizer.deviceAnnualInspectionPageDownloadPdf}
                                                onClick={async () => this.downloadLatestAnnualInspectionPdfReportAsync()}
                                        />
                                    </span>
                              )}

                            </div>
                        )
                    }
                  </FeatureSwitch>
              )
          }

          {
              (this.hasReturnInspectionDate) &&
              (
                  <div>
                    {Localizer.devicePageLastReturnInspectionDate}
                    {
                      ToolsUtility.toDateString(this.device?.lastReturnReportDate)
                    }
                    {this.state.lastReportId && (
                        <span className={styles.downloadButtonWrapper}>
                                        <Button id={"return_download_pdf_button"}
                                                type={ButtonType.Orange}
                                                label={Localizer.deviceAnnualInspectionPageDownloadPdf}
                                                onClick={async () => this.downloadReturnInspectionPdfReportAsync()}
                                        />
                                </span>
                    )}
                  </div>
              )
          }
          <p id="device_rental_status" data-cy="device_rental_status">
            {Localizer.devicePageMaintenanceStatus}
            {
              ReactUtility.toMarks(DeviceInfo.getRentalStatusAndCostPool(this.device))
            }
          </p>

          {
            ((this.hasPendingActions) &&
                <p id="device_status" data-cy="device_status">
                  {
                    ReactUtility.toMarks(DeviceInfo.getMaintenanceStatus(this.device))
                  }
                </p>
            )
          }


          {
              (this.device?.inspectionStartedBy) &&
              (
                  <p data-cy={"returnInspectionStartedBy"}>
                    {`${Localizer.inspectionStartedBy} ${TransformProvider.userToString(this.device?.inspectionStartedBy)}`}
                  </p>
              )
          }

          {
            <FeatureSwitch flagName={RentaToolsConstants.featureFlagServiceAndRepairEnabled}>
              {
                  (this.isServiceRequired) &&
                  (
                      <>

                        {
                            (this.device && this.device.maintenance.length > 0) &&
                            (

                                <p data-cy={"maintenance_reasons"}>
                                  {Localizer.devicePageMaintenanceReasons}
                                  {
                                    DeviceInfo.maintenanceReasons(this.device)
                                  }
                                </p>
                            )
                        }

                        <p>
                          {this.getMaintenanceFrom()}
                        </p>

                        {(this.device?.serviceStartedBy) &&
                            (
                                <p>
                                  {`${Localizer.serviceStartedBy} `}
                                  {
                                    TransformProvider.userToString(this.device?.serviceStartedBy)
                                  }
                                </p>
                            )
                        }

                      </>
                  )}
            </FeatureSwitch>
          }

          <FeatureSwitch flagName={RentaToolsConstants.featureFlagAnnualInspectionsEnabled}>
            {
                (this.hasAnnualInspectionRemarks) &&
                (
                    <p>
                      {Localizer.devicePageDeviceInfoAnnualInspectionStatus}
                    </p>
                )
            }

            {
                (this.hasAnnualInspectionRemarks) &&
                (
                    <p>
                      {Localizer.deviceAnnualInspectionPageRemarks}
                      {
                        this.lastInspectionRemarks
                      }
                    </p>
                )
            }
          </FeatureSwitch>

          {
            (
                <FeatureSwitch flagName={RentaToolsConstants.featureFlagBanOfUseEnabled}>
                  <p id="device_ban_of_use_info">
                    {
                      `${Localizer.deviceAnnualInspectionPageDeviceUseBan}: ${this.isDeviceInBanOfUse ? Localizer.yes : Localizer.no}`
                    }
                  </p>
                </FeatureSwitch>
            )
          }
        </div>
    );
  }

  public render(): React.ReactNode {
    return (
        <PageContainer alertClassName={rentaToolsStyles.alert}
                       className={this.css(rentaToolsStyles.pageContainer, styles.device)}>

          <div ref={this._topContainer}/>

          <ChangeOdometerModal modalRef={this._changeOdometerModal} device={this.device} />

          {
            <FeatureSwitch flagName={RentaToolsConstants.featureFlagBanOfUseEnabled}>
              {
                  (this.isDeviceInBanOfUse)
                  && !this.state.hideBanOfUseBanner && (

                      <Banner id={"device_ban_of_use_banner"}
                              dataCy="ban_of_use_banner"
                              text={this.banOfUseAlertText}
                              closeBanner={async () => await this.hideDeviceBanOfUseBannerAsync()}/>
                  )
              }
            </FeatureSwitch>
          }

          <DeviceInfoPanel device={this.device!}
                           renderInfo={() => this.renderInfo()}
                           deviceLocation={this.state.deviceLocation}
                           lastReportId={this.state.lastReportId}
                           extendedOperatingHoursResponse={this.state.extendedOperatingHoursResponse}
          />

          <ArsenalPageRow>
            <div className={this.css("col", rentaToolsStyles.noPadding)}
                 data-cy={"actionButtonsContainer"}>

              <div className={styles.inspectionActions}>
                {
                  <>
                    {
                      //Cancel on going inspection
                        (this.canStopInspection) &&
                        (
                            <ArsenalButton block big
                                           id={'stop_return_inspection_button'}
                                           className={rentaToolsStyles.inspectionActionButton}
                                           type={ButtonType.Warning}
                                           label={Localizer.devicePageStopInspection}
                                           confirm={Localizer.summaryCancelInspectionConfirmation}
                                           onClick={async () => this.cancelInspectionAsync()}
                            />
                        )
                    }

                    {
                      //Continue inspection
                        (this.canContinueInspection) &&
                        (
                            <ArsenalButton block big
                                           id={"continue_return_inspection"}
                                           type={ButtonType.Success}
                                           className={rentaToolsStyles.inspectionActionButton}
                                           label={Localizer.devicePageContinueInspection}
                                           onClick={async () => await this.continueInspectionAsync()}
                            />
                        )
                    }
                  </>
                }
              </div>

              {
                //Start inspection
                  (this.canStartInspection) &&
                  <ArsenalButton block big
                                 id={"device_page_start_inspection_button"}
                                 type={ButtonType.Orange}
                                 className={rentaToolsStyles.arsenalButton}
                                 label={Localizer.devicePageStartInspection}
                                 onClick={async () => await this.startReturnInspectionAsync()}
                  />
              }

              {   //Skip inspection
                  (this.canSkipInspection) &&
                  <ArsenalButton block big
                                 id={"skip_inspection_button"}
                                 data-cy="skip_inspection_button"
                                 type={ButtonType.Orange}
                                 className={rentaToolsStyles.arsenalButton}
                                 label={Localizer.devicePageSkip}
                                 onClick={async (sender, comment) => await this.skipInspectionAsync(comment!)}
                                 confirm={{
                                   title: Localizer.devicePageSkipConfirm,
                                   comment: true,
                                   minLength: RentaToolsConstants.minimumCommentLength,
                                   placeholder: Localizer.devicePageSkipInspectionCommentPlaceholder
                                 }}
                  />
              }

              {
                <FeatureSwitch flagName={RentaToolsConstants.featureFlagServiceAndRepairEnabled}>
                  {   //Continue service
                      (this.canContinueService) &&
                      <ArsenalButton block big
                                     id={"device_page_continue_service_button"}
                                     type={ButtonType.Success}
                                     className={rentaToolsStyles.arsenalButton}
                                     label={Localizer.devicePageContinueService}
                                     onClick={async () => await this.continueServiceAsync()}
                      />
                  }

                  {   //Stop service
                      (this.canStopService) &&
                      <ArsenalButton block big
                                     id={"device_page_stop_service_button"}
                                     data-cy="device_page_stop_service_button"
                                     type={ButtonType.Warning}
                                     className={rentaToolsStyles.arsenalButton}
                                     label={Localizer.devicePageStopService}
                                     onClick={async () => this.stopServiceAsync()}
                                     confirm={Localizer.devicePageStopServiceConfirmation}
                      />
                  }

                  {   //Start service
                      (this.canStartService) &&
                      <ArsenalButton block big
                                     id={"device_page_start_service_button"}
                                     data-cy="device_page_start_service_button"
                                     type={ButtonType.Orange}
                                     className={rentaToolsStyles.arsenalButton}
                                     label={this.serviceButtonLabel}
                                     onClick={async () => await this.startServiceAsync()}
                      />
                  }

                  {   //Skip service
                      (this.canSkipService) &&
                      (
                          <>
                            <ArsenalButton block big
                                           id={"skip_service_button"}
                                           type={ButtonType.Orange}
                                           className={rentaToolsStyles.arsenalButton}
                                           label={Localizer.skipServiceModalTitle}
                                           onClick={async () => await this.openSkipServiceModalAsync()}
                            />

                            <SkipServiceModal ref={this._skipServiceModalRef}/>
                          </>
                      )
                  }

                </FeatureSwitch>
              }

              {
                <FeatureSwitch flagName={RentaToolsConstants.featureFlagAnnualInspectionsEnabled}>
                  {  //Record annual inspection
                      this.deviceSupportsAnnualInspections &&
                      <ArsenalButton block big
                                     id={"record_annual_inspection_button"}
                                     data-cy="record_annual_button"
                                     type={ButtonType.Orange}
                                     className={rentaToolsStyles.arsenalButton}
                                     label={this.hasAnnualInspectionRemarks ? Localizer.genericRecordAnnualInspectionRemarksRepaired : Localizer.genericRecordAnnualInspection}
                                     onClick={async () => {
                                       await PageRouteProvider.redirectAsync(PageDefinitions.deviceAnnualInspectionFormRoute({
                                         deviceId: this.device?.id
                                       }))
                                     }}
                      />
                  }
                </FeatureSwitch>
              }

              <ArsenalButton block big
                             id={"device_page_record_observation_button"}
                             data-cy="device_page_record_observation_button"
                             type={ButtonType.Orange}
                             className={rentaToolsStyles.arsenalButton}
                             label={Localizer.devicePageRecordObservation}
                             route={PageDefinitions.recordObservationRoute}
              />

              <FeatureSwitch flagName={RentaToolsConstants.featureFlagFuelingAndWashingEnabled}>
                <ArsenalButton block big
                               id={"device_page_fuel_and_clean_button"}
                               data-cy="device_page_fuel_and_clean_button"
                               type={ButtonType.Orange}
                               className={rentaToolsStyles.arsenalButton}
                               label={Localizer.devicePageFuelingAndCleaning}
                               route={PageDefinitions.invoiceDetailsRoute}
                />

              </FeatureSwitch>

              <br/>
              <br/>

              <ArsenalButton block big
                             id={"device_page_inspections_button"}
                             data-cy="device_page_inspections_button"
                             type={ButtonType.Orange}
                             className={rentaToolsStyles.arsenalButton}
                             label={Localizer.devicePageInspections}
                             route={PageDefinitions.inspectionReportsRoute}
              />

              <FeatureSwitch flagName={RentaToolsConstants.featureFlagServiceAndRepairEnabled}>
                {
                  <ArsenalButton block big
                                 id={"device_page_previous_services_button"}
                                 data-cy="device_page_previous_services_button"
                                 type={ButtonType.Orange}
                                 className={rentaToolsStyles.arsenalButton}
                                 label={Localizer.devicePagePreviousServices}
                                 route={PageDefinitions.serviceReportsRoute}
                  />
                }
              </FeatureSwitch>

              <ArsenalButton block big
                             id={"device_page_previous_inspections_pictures_button"}
                             data-cy="device_page_previous_inspections_pictures_button"
                             type={ButtonType.Orange}
                             className={rentaToolsStyles.arsenalButton}
                             label={Localizer.devicePagePreviousInspectionPictures}
                             route={PageDefinitions.pictureEventsRoute}
              />

              <FeatureSwitch flagName={RentaToolsConstants.featureFlagAnnualInspectionsEnabled}>
                {  //Annual inspections history
                    this.deviceSupportsAnnualInspections &&
                    <ArsenalButton block big
                                   id={"annual_inspection_history_button"}
                                   data-cy="annual_details_button"
                                   type={ButtonType.Orange}
                                   className={rentaToolsStyles.arsenalButton}
                                   label={Localizer.devicePageAnnualInspectionHistory}
                                   route={PageDefinitions.devicePreviousAnnualInspectionsRoute}
                    />
                }
              </FeatureSwitch>

              {
                  this.canOpenOperatingHours &&
                  <ArsenalButton block big
                                 id={'operatingHours'}
                                 className={rentaToolsStyles.arsenalButton}
                                 type={ButtonType.Orange}
                                 label={Localizer.devicePageOperatingHoursHistory}
                                 onClick={async () => this.openOperatingHoursAsync()}
                  />
              }

              <br/>
              <br/>

              {
                <FeatureSwitch flagName={RentaToolsConstants.featureChangeOdometer}>
                  <Show.When isTrue={this.canChangeOdometer}>
                    <ArsenalButton block big
                                   id={'changeOdometer'}
                                   className={rentaToolsStyles.arsenalButton}
                                   type={ButtonType.Orange}
                                   label={Localizer.devicePageOdometerChange}
                                   onClick={async () => this._changeOdometerModal.current?.openAsync()}
                    />
                  </Show.When>
                </FeatureSwitch>
              }

              {
                <FeatureSwitch flagName={RentaToolsConstants.featureEditDevice}>
                  <Show.When isTrue={this.canEditDevice}>
                    <ArsenalButton
                        block
                        big
                        id={"editDevice"}
                        type={ButtonType.Success}
                        className={rentaToolsStyles.arsenalButton}
                        label={Localizer.devicePageEditDevice}
                        onClick={async () => this.openEditDeviceAsync()}
                    />
                  </Show.When>
                </FeatureSwitch>
              }

              {    //Previous
                <ArsenalButton block big
                               type={ButtonType.Orange}
                               className={rentaToolsStyles.arsenalButton}
                               label={Localizer.devicePageReturnBack}
                               onClick={async () => await this.returnBackAsync()}
                />
              }
              {   //Reset status
                  (RentaToolsController.isDevelopment() || RentaToolsController.isStaging() || RentaToolsController.isAutomatedTestEnvironment()) &&
                  <ArsenalButton block big
                                 id={"reset_button"}
                                 type={ButtonType.Blue}
                                 className={rentaToolsStyles.arsenalButton}
                                 disabled={this.underInspection}
                                 label={Localizer.devicePageReset}
                                 onClick={async () => await this.resetStatusAsync()}
                  />
              }
              {   //Refresh devices
                  (!ch.isDenmark) &&
                  (<ArsenalButton block big
                                  type={ButtonType.Blue}
                                  className={rentaToolsStyles.arsenalButton}
                                  label={Localizer.devicePageRefresh}
                                  confirm={Localizer.devicePageRefreshConfirm}
                                  onClick={async () => await this.refreshAsync()}
                  />)
              }
            </div>

          </ArsenalPageRow>

        </PageContainer>
    );
  }
}