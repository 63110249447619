import BaseListDevicesRequest from "./BaseListDevicesRequest";
import DeviceInfo from "../DeviceInfo";
import {
    AnnualInspectionDevicesType,
    AnnualInspectionStatusFilterType,
    DeviceListOrder,
    DeviceStatus
} from "../../Enums";
import {RequestRawData} from "@/models/RequestRawData";

export default class ListAnnualInspectionDevicesRequest extends BaseListDevicesRequest {
    
    public deviceStatuses: DeviceStatus[] = [];

    public inspectionType: AnnualInspectionDevicesType | null = null;

    public annualInspectionStatusFilter: AnnualInspectionStatusFilterType | null = null;

    public pageNumber : number = 0;

    public pageSize : number = 0;
    
    public annualInspectionTypeFilter: string[] = [
        AnnualInspectionDevicesType.Upcoming.toString(),
        AnnualInspectionDevicesType.Remarks.toString(),
    ];

    public rentOnly: boolean = false;
    
    public getLastAnnualInspectionDate(device: DeviceInfo): Date | null {
        return device.lastAnnualReportDate;
    }

    public getNextAnnualInspectionDate(device: DeviceInfo): Date | null {
        return device.nextAnnualReportDate;
    }

    public getRemarksDueDate(device: DeviceInfo): Date | null {
        return device.remarksDueDate;
    }

    public isEmpty(): boolean {
        return (this.deviceStatuses.length == 0) && (super.isEmpty());
    }

    public clear(): void {
        this.deviceStatuses = [];
        this.annualInspectionStatusFilter = null;
        super.clear();
    }

    public nextPage() : void{
        this.pageNumber++;
    }

    static create(requestRawData: RequestRawData) : ListAnnualInspectionDevicesRequest {
        const request = new ListAnnualInspectionDevicesRequest();
        request.deviceTypes = requestRawData.deviceTypes;
        request.deviceExternalId = requestRawData.deviceExternalId || "";
        request.productGroupIds = requestRawData.productGroupIds || [];
        request.rentalObjectGroups = requestRawData.rentalObjectGroups || [];
        request.date = requestRawData.date || null;
        request.from = requestRawData.from || null;
        request.to = requestRawData.to || null;
        request.showDeleted = requestRawData.showDeleted || "active";
        request.orderBy = requestRawData.orderBy || DeviceListOrder.ByPriority;
        request.deviceStatuses = requestRawData.deviceStatuses.map((status: string) => Number.parseInt(status));
        request.inspectionType = Number.parseInt(requestRawData.inspectionType);
        request.annualInspectionStatusFilter = Number.parseInt(requestRawData.annualInspectionStatusFilter);
        request.pageNumber = requestRawData.pageNumber || 0;
        request.pageSize = requestRawData.pageSize || 0;
        request.annualInspectionTypeFilter = requestRawData.annualInspectionTypeFilter || [];
        request.rentOnly = requestRawData.rentOnly;
        
        return request;
    }
}