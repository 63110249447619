import React, {useState} from "react";
import {Button, ButtonContainer, ButtonType, Checkbox, DateInput, Dropdown, DropdownOrderBy, Form, Inline, Modal, SelectListItem, TextInput} from "@renta-apps/athenaeum-react-components";
import ListInvoicesRequest from "@/models/server/requests/ListInvoicesRequest";
import InvoiceFiltersData from "@/models/server/InvoiceFiltersData";
import {Dictionary} from "typescript-collections";
import {InvoiceStatusFilter} from "@/models/Enums";
import Depo from "@/models/server/Depo";
import User from "@/models/server/User";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./InvoicesListFilters.module.scss";

interface IInvoicesListFiltersProps {
    request: ListInvoicesRequest;
    filtersData: InvoiceFiltersData;
    title: string;
    isManager: boolean;
    modalRef: React.RefObject<Modal>;
    onSubmit(request: ListInvoicesRequest): Promise<void>;
}

const InvoicesListFilters = (props: IInvoicesListFiltersProps) => {

    const _deviceExternalIdRef: React.RefObject<TextInput> = React.createRef();
    const _statusRef: React.RefObject<Dropdown<InvoiceStatusFilter>> = React.createRef();
    const _depotsIdsRef: React.RefObject<Dropdown<Depo>> = React.createRef();
    const _contractsIdsRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    const _inspectorsIdsRef: React.RefObject<Dropdown<User>> = React.createRef();
    const _customersIdsRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    const _customersRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    const _constructionSitesIdsRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    const _constructionSitesRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    const _fromRef: React.RefObject<DateInput> = React.createRef();
    const _toRef: React.RefObject<DateInput> = React.createRef();

    const id = new Date().getTime();

    const [request, setRequest] = useState<ListInvoicesRequest>(props.request);
    const [deposForUsersList, setDeposForUsersList] = useState<Depo[] | null>([]);
    const [usersForDepoList, setUsersForDepoList] = useState<User[] | null>([]);
    const [includeDeleted, setIncludeDeleted] = useState<boolean>(false);

    const setDepotsForUsersListAsync = async (selectedDepos: Depo[]): Promise<void> => {
        setDeposForUsersList((selectedDepos.length > 0) ? selectedDepos : null)
    }

    const setUsersForDepoListAsync = async (selectedUsers: User[]): Promise<void> => {
        setUsersForDepoList((selectedUsers.length > 0) ? selectedUsers : null);
    }

    const setDeviceExternalId = async (value: string): Promise<void> => {
        setRequest({ ...request, deviceExternalId: value } as ListInvoicesRequest);
    }

    const setFromDate = async (value: Date | null): Promise<void> => {
        setRequest({ ...request, from: value } as ListInvoicesRequest);
    }

    const setToDate = async (value: Date | null): Promise<void> => {
        setRequest({ ...request, to: value } as ListInvoicesRequest);
    }

    const setIncludeDelete = async (checked: boolean): Promise<void> => {
        setIncludeDeleted(checked);
    }

    const clearAsync = async (): Promise<void> => {
        const newRequest = new ListInvoicesRequest();
        setDeposForUsersList(null);
        setUsersForDepoList(null);
        setIncludeDeleted(false);
        setRequest(newRequest);
    }

    const submitAsync = async (data: Dictionary<string, any>): Promise<void> => {
        const req = new ListInvoicesRequest();

        Utility.copyTo(data, req);

        setRequest(req);

        await props.modalRef?.current!.closeAsync();

        await props.onSubmit(req);
    }

    const contractsIds = (): SelectListItem[] => {
        return props
            .filtersData
            .deviceContracts
            .filter(item => !!item.contractExternalId)
            .distinct(item => item.contractExternalId)
            .map(item => new SelectListItem(item.contractExternalId, item.contractExternalId));
    }

    const customersIds = (): SelectListItem[] => {
        return props
            .filtersData
            .deviceContracts
            .filter(item => !!item.customerExternalId)
            .distinct(item => item.customerExternalId)
            .map(item => new SelectListItem(item.customerExternalId, item.customerExternalId));
    }

    const constructionSitesIds = (): SelectListItem[] => {
        return props
            .filtersData
            .deviceContracts
            .filter(item => !!item.constructionSiteExternalId)
            .distinct(item => item.constructionSiteExternalId)
            .map(item => new SelectListItem(item.constructionSiteExternalId, item.constructionSiteExternalId));
    }

    const customers = (): SelectListItem[] => {
        return props
            .filtersData
            .deviceContracts
            .filter(item => !!item.customerName)
            .distinct(item => item.customerName)
            .map(item => new SelectListItem(item.customerName, item.customerName));
    }

    const constructionSites = (): SelectListItem[] => {
        return props
            .filtersData
            .deviceContracts
            .filter(item => !!item.constructionSiteName)
            .distinct(item => item.constructionSiteName)
            .map(item => new SelectListItem(item.constructionSiteName, item.constructionSiteName));
    }

    const inspectors = (): User[] => {
        let users: User[] = props
            .filtersData
            .users;

        if (deposForUsersList) {
            users = users.filter(user => deposForUsersList?.some(depo => depo.id == user.depoId));
        }
        //Keep already selected users in the list
        if (usersForDepoList) {
            users = users.concat(usersForDepoList)
        }

        return users.distinct(user => user.id);
    }

    const depots = (): Depo[] => {
        let depos = props
            .filtersData
            .depots;

        if (usersForDepoList) {
            depos = depos.filter(depo => usersForDepoList?.some(user => user.depoId == depo.id));
        }
        //Keep already selected depos in the list
        if (deposForUsersList) {
            depos = depos.concat(deposForUsersList);
        }

        return depos.distinct(depo => depo.id);
    }

    const onOpen = async (): Promise<void> => {
        await setDepotsForUsersListAsync(depots());
        await setUsersForDepoListAsync(inspectors());
    }

    return (
        <Modal id={`invoices_list_filters_modal_${id}`}
               ref={props.modalRef}
               className={styles.returnInspectionInvoicesListFilters}
               title={props.title}
               onOpen={async () => await onOpen()}>

            <Form className={styles.form} onSubmit={async (sender, data) => await submitAsync(data)}>

                <Inline className={styles.inline}>
                    <TextInput id={`invoices_filter_deviceExternalId_${id}`}
                               name="deviceExternalId"
                               ref={_deviceExternalIdRef}
                               label={Localizer.devicesFiltersModalLabelDeviceId}
                               value={request.deviceExternalId}
                               onChange={(_, value) => setDeviceExternalId(value)}
                    />

                    <Dropdown autoCollapse noSubtext required
                              id={`invoices_filter_status_${id}`}
                              name={`status`}
                              ref={_statusRef}
                              label={Localizer.devicesFiltersModalLabelInvoiceStatus}
                              transform={(value) => EnumProvider.getInvoiceStatusFilterItem(value)}
                              orderBy={DropdownOrderBy.None}
                              items={[InvoiceStatusFilter.NotProcessed, InvoiceStatusFilter.Approved, InvoiceStatusFilter.Declined]}
                              selectedItem={request.status}
                    />
                </Inline>

                <Inline className={styles.inline}>
                    <Dropdown multiple clearButton
                              name={`depotsIds`}
                              id={`invoices_filter_depot_${id}`}
                              ref={_depotsIdsRef}
                              label={Localizer.devicesFiltersModalLabelDepots}
                              nothingSelectedText={"-"}
                              items={depots()}
                              selectedItems={request.depotsIds.length > 0 ? request.depotsIds : undefined}
                              onChange={async (_) => {
                                  await setDepotsForUsersListAsync(_.selectedItems)
                              }}
                    />

                    <Dropdown noWrap noSubtext clearButton
                              name={`contractExternalId`}
                              id={`invoices_filter_contractId_${id}`}
                              ref={_contractsIdsRef}
                              label={Localizer.devicesFiltersModalLabelContractsIds}
                              nothingSelectedText={"-"}
                              items={contractsIds()}
                              selectedItem={request.contractExternalId || undefined}
                    />
                </Inline>

                <Inline className={styles.inline}>
                    <Dropdown noWrap noSubtext multiple clearButton
                              name={`inspectorIds`}
                              id={`invoices_filter_inspectorId_${id}`}
                              ref={_inspectorsIdsRef}
                              label={Localizer.invoiceDetailsPageInspectedBy.replace(":", "")}
                              nothingSelectedText={"-"}
                              items={inspectors()}
                              selectedItems={request.inspectorIds.length > 0 ? request.inspectorIds : undefined}
                              onChange={async (_) => {
                                  await setUsersForDepoListAsync(_.selectedItems)
                              }}
                    />
                </Inline>

                <Inline className={styles.inline}>
                    <Dropdown noWrap noSubtext clearButton
                              name={`customerExternalId`}
                              id={`invoices_filter_customerId_${id}`}
                              ref={_customersIdsRef}
                              label={Localizer.devicesFiltersModalLabelCustomersIds}
                              nothingSelectedText={"-"}
                              items={customersIds()}
                              selectedItem={request.customerExternalId || undefined}
                    />

                    <Dropdown noWrap noSubtext clearButton
                              name={`customerName`}
                              id={`invoices_filter_customerName_${id}`}
                              ref={_customersRef}
                              label={Localizer.devicesFiltersModalLabelCustomers}
                              nothingSelectedText={"-"}
                              items={customers()}
                              selectedItem={request.customerName || undefined}
                    />
                </Inline>

                <Inline className={styles.inline}>
                    <Dropdown noWrap noSubtext clearButton
                              name={`constructionSiteExternalId`}
                              id={`invoices_filter_constructionSiteExternalId_${id}`}
                              ref={_constructionSitesIdsRef}
                              label={Localizer.devicesFiltersModalLabelConstructionSitesIds}
                              nothingSelectedText={"-"}
                              items={constructionSitesIds()}
                              selectedItem={request.constructionSiteExternalId || undefined}
                    />

                    <Dropdown noWrap noSubtext clearButton
                              name={`constructionSiteName`}
                              id={`invoices_filter_constructionSiteName_${id}`}
                              ref={_constructionSitesRef}
                              label={Localizer.devicesFiltersModalLabelConstructionSites}
                              nothingSelectedText={"-"}
                              items={constructionSites()}
                              selectedItem={request.constructionSiteName || undefined}
                    />
                </Inline>

                <Inline className={styles.inline}>
                    <DateInput popup clearButton
                               id={`invoices_filter_from_${id}`}
                               name="from"
                               ref={_fromRef}
                               label={Localizer.devicesFiltersModalLabelInspectedFrom}
                               value={request.from || undefined}
                               onChange={(value) => setFromDate(value)}
                    />

                    <DateInput popup clearButton
                               id={`invoices_filter_to_${id}`}
                               name="to"
                               ref={_toRef}
                               label={Localizer.devicesFiltersModalLabelInspectedTo}
                               value={request.to || undefined}
                               onChange={(value) => setToDate(value)}
                    />
                </Inline>

                {
                    (props.isManager) && (
                        <Inline>
                            <Checkbox id={`include_deleted`}
                                      name={"includeDeleted"}
                                      label={Localizer.genericShowDeleted}
                                      value={includeDeleted}
                                      onChange={async (_, value) => await setIncludeDelete(value)}
                            />
                        </Inline>
                    )
                }


                <ButtonContainer className={Utility.css(styles.buttons)}>
                    <Button submit
                            type={ButtonType.Orange}
                            label={Localizer.devicesFiltersModalButtonApply}
                    />

                    <Button type={ButtonType.Blue}
                            label={Localizer.devicesFiltersModalButtonClear}
                            onClick={async () => await clearAsync()}
                    />
                </ButtonContainer>

            </Form>

        </Modal>
    );
}

export default InvoicesListFilters;