import GridHelper from "@/cypressHelpers/GridHelper";
import moment from "moment";
import Localizer from "../../localization/Localizer";

class DeviceAnnualInspectionHistoryPage {
    public elements = {
        inspectionsTable: () => cy.get('table'),
        backBtn: () => cy.get("#backButton"),
        editBtn: () => cy.get("#toggleEditModeButton"),
        editPen: () => cy.get("[class*=fa-pen]"),
        editCost: () => cy.get("#input_inspectionCost"),
        editInvoiceNumber: () => cy.get("#input_inspectionInvoiceNumber"),
        editPdf: () => cy.get("#input_pdf_input"),
        editForm: () => cy.get('form'),
        inspectionsGrid: () => cy.get("#table_annualInspectionsReportsGrid"),
        getCell: (cellContent: string, columnNumber: number) => GridHelper.getGridCell(this.elements.inspectionsGrid(), cellContent, columnNumber, true),
    }

    public routes = {
    };

    public assertTableRows() {
        cy.wait(200);

        this.elements.inspectionsTable().then(($table) => {
            const $rows = $table
                .find('tbody')
                .first()
                .find('[class*=athenaeum-grid-gridRow]')
                .not('[class*=athenaeum-grid-details]') //CL Grid component has these "extra" rows between the actual rows;

            const $thList = $table
                .find('thead')
                .find('th');

            cy.wrap($table).should('be.visible');

            this.assertTableHeaders($thList);

            this.assertRows($rows);
        })
    }

    public testTechnicianEditRemarksFixed() {
        this.elements.editBtn().should('not.exist');
    }

    public testEditingRemarksFixed(invoiceNumber: string, inspectionCost: string, shouldPdfInputBeDisabled: boolean) {
        this.elements.editBtn().click();

        this.elements.editPen().should('have.length', 1);

        this.elements.editPen().first().click();

        if (shouldPdfInputBeDisabled) {
            this.elements.editPdf().should('be.disabled');
        } else {
            this.elements.editPdf().should('be.enabled');
        }

        this.elements.editCost().clear().type(inspectionCost);

        this.elements.editInvoiceNumber().clear().type(invoiceNumber);

        this.elements.editForm().submit();

        this.elements.getCell(Localizer.deviceAnnualInspectionHistoryPageInspectionInvoiceNumber, 4)
            .contains(Localizer.deviceAnnualInspectionHistoryPageInspectionInvoiceNumber);

        this.elements.getCell(invoiceNumber, 4);

        this.elements.getCell(Localizer.deviceAnnualInspectionHistoryPageInspectionCost, 5)
            .contains(Localizer.deviceAnnualInspectionHistoryPageInspectionCost);

        this.elements.getCell(inspectionCost, 5);
    }

    private assertRows($rows: JQuery<HTMLElement>) {
        const today = moment(new Date()).format("DD-MM-YYYY");

        cy.wrap($rows).should('have.length', 3);

        const $firstRowColumns = $rows.first().find('td');
        const $secondRowColumns = $rows.eq(1).find('td');
        const $thirdRowColumns = $rows.eq(2).find('td');

        // First column is expand button
        const dateColumnIndex: number = 0;

        const inspectorColumnIndex: number = 2;
        const invoiceNumberColumnIndex: number = 3;
        const invoiceCostColumnIndex: number = 4;
        const invoiceTypeColumnIndex: number = 5;

        cy.wrap($firstRowColumns).eq(dateColumnIndex).should('have.text', today);
        cy.wrap($firstRowColumns).eq(invoiceTypeColumnIndex).should('have.text', Localizer.enumAnnualInspectionTypeCalibration);
        cy.wrap($firstRowColumns).eq(invoiceNumberColumnIndex).should('have.text', '1010110');
        cy.wrap($firstRowColumns).eq(invoiceCostColumnIndex).should('have.text', '20');
        cy.wrap($firstRowColumns).eq(inspectorColumnIndex).should('have.text', 'Vendor 303');
        this.assertActionIcons($firstRowColumns);

        cy.wrap($secondRowColumns).eq(dateColumnIndex).should('contain', today);
        cy.wrap($secondRowColumns).eq(invoiceTypeColumnIndex).should('have.text', Localizer.enumAnnualInspectionTypeInspection);
        cy.wrap($secondRowColumns).eq(invoiceNumberColumnIndex).should('have.text', '101010');
        cy.wrap($secondRowColumns).eq(invoiceCostColumnIndex).should('have.text', '20');
        cy.wrap($secondRowColumns).eq(inspectorColumnIndex).should('have.text', 'Vendor 303');
        this.assertActionIcons($secondRowColumns);

        cy.wrap($thirdRowColumns).eq(dateColumnIndex).should('have.text', '06-10-2023');
        cy.wrap($thirdRowColumns).eq(invoiceTypeColumnIndex).should('have.text', Localizer.enumAnnualInspectionTypeAnniversary10Years);
        cy.wrap($thirdRowColumns).eq(invoiceNumberColumnIndex).should('have.text', '101011');
        cy.wrap($thirdRowColumns).eq(invoiceCostColumnIndex).should('have.text', '20');
        cy.wrap($thirdRowColumns).eq(inspectorColumnIndex).should('have.text', 'Vendor 303');
        this.assertActionIcons($thirdRowColumns);
    }

    private assertTableHeaders($thList: JQuery<HTMLElement>) {
        cy.wrap($thList).should('have.length', 10);
        cy.wrap($thList).first().should('have.text', Localizer.deviceAnnualInspectionHistoryPageInspectionDate);

        cy.wrap($thList).eq(2).should('have.text', Localizer.deviceAnnualInspectionHistoryPageInspectionVendor);
        cy.wrap($thList).eq(3).should('have.text', Localizer.deviceAnnualInspectionHistoryPageInspectionInvoiceNumber);
        cy.wrap($thList).eq(1).should(($th) => {
            expect($th.text().trim()).equal(Localizer.deviceAnnualInspectionHistoryPageInspectionAttachment);
        });

        cy.wrap($thList).eq(4).should('have.text', Localizer.deviceAnnualInspectionHistoryPageInspectionCost);
        cy.wrap($thList).eq(5).should('have.text', Localizer.deviceAnnualInspectionHistoryPageInspectionType);
    }

    private assertActionIcons(row: JQuery<HTMLElement>) {

        cy.get('#toggleEditModeButton').click();
        cy.wrap(row).find('[class*=fa-pen]').click();
        cy.get("#_annualInspectionToEditModal").find('[type=submit]').click();
        cy.contains(Localizer.annualInspectionHistoryPageInspectionNotModified);

        // cy.wrap(row).find('[class*=fa-trash]').click();
        // cy.get('[class*=athenaeum-confirmation-dialog-dialogContent]')
        //     .contains(Localizer.annualInspectionHistoryPageConfirmationDeleteInspection);

        cy.get('#toggleEditModeButton').click();
    }
}

 export default new DeviceAnnualInspectionHistoryPage();