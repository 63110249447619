import React, {useEffect, useState} from "react";
import {Button, ButtonType, Icon, IconSize, IconStyle, Modal} from "@renta-apps/athenaeum-react-components";
import ListDeviceObservationsRequest from "@/models/server/requests/ListDeviceObservationsRequest";
import DeviceObservation from "@/models/server/DeviceObservation";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./ObservationsList.module.scss";
import newStyles from "@/pages/NewUI.module.scss";
import ToolsUtility from "@/helpers/ToolsUtility";
import {DevicePictureType} from "@/models/Enums";
import Report from "@/pages/Models/Report";
import QuestionPicturesReportItem from "@/pages/Models/QuestionPicturesReportItem";
import ArsenalPagination from "@/components/ArsenalPagination/ArsenalPagination";
import {IPagedList, Utility} from "@renta-apps/athenaeum-toolkit";
import ObservationsFiltersData from "@/models/server/ObservationsFiltersData";
import DeviceController from "@/pages/DeviceController";
import ObservationsFiltersModal from "@/components/ObservationsFiltersModal/ObservationsFiltersModal";

interface IObservationsListProps {
    deviceId: string;
    onOpenPictureListItem(item: DeviceObservation): Promise<void>;
}

const ObservationsList = (props: IObservationsListProps) => {

    const _picturesFiltersModalRef: React.RefObject<Modal> = React.createRef();

    const [deviceId, setDeviceId] = useState<string>(props.deviceId);
    const [observationData, setObservationData] = useState<IPagedList<DeviceObservation>>();
    const [observationsRequest, setRequest] = useState<ListDeviceObservationsRequest>(new ListDeviceObservationsRequest());
    const [filters, setFilters] = useState<ObservationsFiltersData>(new ObservationsFiltersData());

    useEffect(() => {
        fetchDataAsync(observationsRequest).then(data => {
            setObservationData(data);
        })

        return () => {}
    }, [observationsRequest]);

    const fetchDataAsync = async (request: ListDeviceObservationsRequest): Promise<IPagedList<DeviceObservation>> => {
        const req = {
            ...request,
            deviceId: deviceId,
            pageSize: 5,
            pageNumber: request.pageNumber,
        } as ListDeviceObservationsRequest;

        return await DeviceController.listDeviceObservationsAsync(req);
    }

    const isManualPictureType = (item: DeviceObservation): boolean => {
        return item.type == DevicePictureType.Manual;
    }

    const getReportItemName = (stepId: string, report: Report): string => {
        if (!report) {
            return "";
        }

        const pictureItem: QuestionPicturesReportItem = Report.getQuestionPicturesReportItemByStepId(stepId, report);

        if (pictureItem && report.items.length > 0) {
            const stepIndex = report.items.indexOf(pictureItem);
            const stepAmount = report.items.length;

            if (pictureItem.name && !pictureItem.title) {
                return `${pictureItem.name} (${stepIndex}/${stepAmount})`
            }

            const name = pictureItem.name === pictureItem.title ? pictureItem.name : `${pictureItem.name} - ${pictureItem.title}`;

            return `${name} (${stepIndex}/${stepAmount})`;
        }

        if (!pictureItem) {
            return Localizer.picturesPreviewPagePicturesListNoItemData;
        }

        return pictureItem.title || pictureItem.name || Localizer.picturesPreviewPagePicturesListNoItemData;
    }

    const openFiltersAsync = async (): Promise<void> => {
        await _picturesFiltersModalRef?.current!.openAsync();
    }

    const submitFiltersAsync = async (request: ListDeviceObservationsRequest): Promise<void> => {
        const req = {
            ...request,
            pageNumber: 1,
        } as ListDeviceObservationsRequest;

        setRequest(req);
    }

    const onItemClickAsync = async (item: DeviceObservation): Promise<void> => {
        await props.onOpenPictureListItem(item);
    }

    const changePageNumberAsync = async (pageNumber: number): Promise<void> => {
        const req = {
            ...observationsRequest,
            pageNumber: pageNumber,
        } as ListDeviceObservationsRequest;

        setRequest(req);
    }

    const getIconName = (): string => {
        const isEmpty: boolean = observationsRequest.type == null
            && !observationsRequest.date
            && !observationsRequest.from
            && !observationsRequest.to;

        return isEmpty
            ? "far filter"
            : "fas filter";
    }

    const getItemIcons = (item: DeviceObservation): React.ReactNode => {
        const commented = !ToolsUtility.isNullOrEmpty(item.comment);
        const hasPictures = !ToolsUtility.isNullOrEmpty(item.groupKey) || !!item.fileId;
        const hasLocation = !!item.latitude && !!item.longitude;
        const hasFaults = item.hasFaults;
        return (
            <div className={styles.icons}>
                {
                    (commented) && (
                        <Icon className={styles.itemIcon} name="fa-comment-alt-lines" style={IconStyle.Light} size={IconSize.Large} tooltip={Localizer.enumSearchItemTypePicture}/>
                    )
                }

                {
                    (hasPictures) && (
                        <Icon className={styles.itemIcon} name="fa-camera" style={IconStyle.Light} size={IconSize.Large} tooltip={Localizer.enumSearchItemTypePicture}/>

                    )
                }

                {
                    (hasLocation) && (
                        <Icon className={styles.itemIcon} name="fa-map-marker-alt" style={IconStyle.Light} size={IconSize.Large} tooltip={Localizer.enumSearchItemTypePicture}/>
                    )
                }

                {
                    (hasFaults) && (
                        <Icon className={styles.itemIcon} name="fa-tools" style={IconStyle.Light} size={IconSize.Large} tooltip={Localizer.enumSearchItemTypePicture}/>
                    )
                }

            </div>
        )
    }

    const renderItems = (): React.ReactNode => {
        return (
            <table className={styles.table}>
                <tbody>
                {
                    observationData?.items?.map((item, index) =>
                        (
                            <tr key={index}>

                                <td className={styles.info}>

                                        <span className={"align-items-center"}
                                              onClick={async () => await onItemClickAsync(item)}>

                                            <div className={styles.report}>

                                                <div className={Utility.css((!isManualPictureType(item)) && styles.twoLines, styles.topSpace)}>
                                                    <span>{EnumProvider.getDevicePictureTypeText(item.type)}</span>
                                                    {
                                                        (item.returnInspectionReportId && item.returnInspectionStepId) &&
                                                        (
                                                            <span>{getReportItemName(item.returnInspectionStepId, item.returnInspectionReport!)}</span>
                                                        )
                                                    }
                                                </div>

                                            </div>

                                            {
                                                getItemIcons(item)
                                            }

                                            <div className={Utility.css(styles.infoColumn)}>

                                                <div className={Utility.css(styles.user, styles.twoLines, styles.topSpace)}>
                                                    <span>{ToolsUtility.toDateString(item.createdAt)}</span>
                                                    <span>{item.userName}</span>
                                                </div>

                                                 <div className={styles.depo}>
                                                     {item.costPool}
                                                </div>
                                            </div>

                                        </span>
                                </td>

                            </tr>
                        ))
                }
                </tbody>
            </table>
        );
    }


    return (
        <div className={styles.picturesList}>

            <div className={newStyles.col}>
                <div className={Utility.css(styles.header, newStyles.header)}>

                    <h4>{Localizer.picturesPreviewPagePicturesListPictureEvents}</h4>

                    <Button small
                            icon={{name: getIconName(), size: IconSize.X2}}
                            type={ButtonType.Blue}
                            className={Utility.css(styles.filter, newStyles.filter)}
                            onClick={async () => await openFiltersAsync()}
                    />

                </div>
            </div>

            <div className={"mt-4 mb-3"}>
                <h5>{Localizer.picturesListObservations.toUpperCase()}</h5>
            </div>

            {
                ((observationData == null) || (observationData?.items?.length == 0)) &&
                (
                    <div className={Utility.css(styles.pictureListItem, styles.noItems, newStyles.deviceListItem)}>
                        {Localizer.genericNoData}
                    </div>
                )
            }

            {
                (observationData) &&
                (
                    <React.Fragment>

                        {renderItems()}

                        <ArsenalPagination pageNumber={observationsRequest.pageNumber}
                                           pageCount={observationData.pageCount}
                                           onChange={async (pageNumber) => await changePageNumberAsync(pageNumber)}
                        />

                    </React.Fragment>
                )
            }

            <ObservationsFiltersModal modalRef={_picturesFiltersModalRef}
                                      title={Localizer.picturesPreviewPagePicturesFiltersModalTitle}
                                      request={observationsRequest}
                                      filtersData={filters}
                                      onSubmit={async (request) => await submitFiltersAsync(request)}
            />

        </div>
    )
}

export default ObservationsList;