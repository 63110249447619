import React, {useState} from "react";
import {Button, ButtonContainer, ButtonType, Checkbox, DateInput, Dropdown, DropdownAlign, Form, Inline, Modal, SelectListItem, TextInput,} from "@renta-apps/athenaeum-react-components";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import FiltersData from "@/pages/Models/FiltersData";
import {ch} from "@renta-apps/athenaeum-react-common";
import {RequestRawData} from "@/models/RequestRawData";
import DeviceClearFiltersFactory from "@/services/clearFilters/DeviceClearFiltersFactory";
import {FiltersType, ServiceAndRepairsStatuses} from "@/models/Enums";
import useFiltersData, {itemShowActive} from "@/components/DevicesFiltersModal/DeviceFilterDataState";
import {useClearDropdownsFilters} from "@/components/DevicesFiltersModal/ClearDropdownsFilters";
import RentaToolsController from "@/pages/RentaToolsController";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "@/localization/Localizer";

import styles from "./DevicesFiltersModal.module.scss";

interface IDevicesFiltersModalProps {
    modalRef: React.RefObject<Modal>;
    request: RequestRawData;
    filtersData: FiltersData;
    title: string;
    deviceStatusSelection?: boolean | null;
    priorityOrder?: boolean | null;
    filtersType: FiltersType;

    onSubmit(request: RequestRawData): Promise<void>;
}

const DevicesFiltersModal: React.FC<IDevicesFiltersModalProps> = (props) => {
    const [request, setRequest] = useState<RequestRawData>(props.request);
    const filtersData = useFiltersData(props.filtersData, props.filtersType);

    const {addRef, clearDropdowns} = useClearDropdownsFilters();

    const [upcomingFilterSelected, setUpcomingFilterSelected] = useState(false);

    const isRentalGroupFilterEnabled = (): boolean => {
        return (
            ch.isSweden ||
            ch.isDevelopment ||
            RentaToolsController.userContext.IsAutomatedTestEnv
        );
    };
    const getDateFromFilterTitle = (): string => {
        return isReturnInspectionFilter()
            ? Localizer.devicesFiltersModalLabelReturnedFrom
            : Localizer.devicesFiltersModalLabelFrom;
    };

    const getDateToFilterTitle = (): string => {
        return isReturnInspectionFilter()
            ? Localizer.devicesFiltersModalLabelReturnedTo
            : Localizer.devicesFiltersModalLabelTo;
    };

    const getDateAtFilterTitle = (): string => {
        return isReturnInspectionFilter()
            ? Localizer.devicesFiltersModalLabelReturnedAt
            : Localizer.devicesFiltersModalLabelDate;
    };

    const hasDeviceStatuses = (): boolean => {
        return (
            RentaToolsController.supportUnServicedDevicesRequest || isServiceFilter()
        );
    };

    const clearAsync = async (): Promise<void> => {
        const clearFilters = DeviceClearFiltersFactory.create(props.filtersType);
        const newRequest = clearFilters.clear(request);

        setRequest(newRequest);
        setUpcomingFilterSelected(false);
        clearDropdowns();
    };

    const submitAsync = async (): Promise<void> => {
        await props.onSubmit(request);
    };

    const handleUpcoming = (sender: Dropdown<SelectListItem>): void => {
        const count = sender.selectedItems.filter(
            (p) => p.value === ServiceAndRepairsStatuses.UpcomingService.toString(),
        );

        if (count.length > 0) {
            setUpcomingFilterSelected(true);
        } else {
            setUpcomingFilterSelected(false);
        }
    };

    const isReturnInspectionFilter = (): boolean => {
        return props.filtersType === FiltersType.ReturnInspectionList;
    };

    const isServiceFilter = (): boolean => {
        return props.filtersType === FiltersType.ServiceList;
    };

    const isAnnualInspectionFilter = (): boolean => {
        return props.filtersType === FiltersType.AnnualInspectionList;
    };

    const handleDropdownChange = async (sender: Dropdown<SelectListItem>): Promise<void> => {
        const newRequest = {...request};

        if (sender.props.multiple) {
            const key = sender.id as keyof RequestRawData;
            (newRequest[key] as string[]) = sender.selectedListItems.map(
                (p) => p.value,
            );
        } else {
            const key = sender.id as keyof RequestRawData;
            (newRequest[key] as string) = sender.selectedItem?.value ?? "";
        }

        setRequest(newRequest);
    };

    const handleInRentOnly = async (value: boolean): Promise<void> => {
        const newRequest: RequestRawData = {...request};
        newRequest.rentOnly = value;
        setRequest(newRequest);
    }

    return (
        <Modal
            id={"devicesFiltersModal"}
            title={props.title}
            ref={props.modalRef}
            className={styles.devicesFiltersModal}
        >
            <Form
                className={styles.form}
                onSubmit={async (_, data) => await submitAsync()}
            >
                <Inline className={styles.inline}>
                    <TextInput
                        id="deviceExternalId"
                        name="deviceExternalId"
                        label={Localizer.devicesFiltersModalLabelDeviceId}
                        value={request.deviceExternalId}
                        onChange={async (sender, value) =>
                            setRequest({...request, deviceExternalId: value})
                        }
                    />

                    <Dropdown
                        multiple
                        clearButton
                        ref={addRef}
                        id={"depoCostPools"}
                        name={`depoCostPools`}
                        label={Localizer.devicesFiltersModalLabelDepots}
                        nothingSelectedText={"-"}
                        items={filtersData.depos}
                        selectedItems={filtersData.depos.filter((item) =>
                            request.depoCostPools.includes(item.value),
                        )}
                        onChange={handleDropdownChange}
                    />
                </Inline>

                <Inline className={styles.inline}>
                    <Dropdown
                        multiple
                        clearButton
                        ref={addRef}
                        id={"productGroupIds"}
                        name={`productGroupIds`}
                        label={Localizer.devicesFiltersModalLabelProductGroups}
                        nothingSelectedText={"-"}
                        items={filtersData.productGroups}
                        selectedItems={filtersData.productGroups.filter((item) =>
                            request.productGroupIds.includes(item.value),
                        )}
                        value={request.productGroupIds}
                        align={DropdownAlign.Left}
                        onChange={handleDropdownChange}
                    />

                    <Dropdown
                        multiple
                        clearButton
                        ref={addRef}
                        id={"deviceTypes"}
                        name={`deviceTypes`}
                        label={Localizer.devicesFiltersModalLabelDeviceTypes}
                        nothingSelectedText={"-"}
                        items={filtersData.deviceTypes}
                        selectedItems={filtersData.deviceTypes.filter((item) =>
                            request.deviceTypes.includes(item.value),
                        )}
                        onChange={handleDropdownChange}
                    />
                </Inline>

                <Inline className={styles.inline}>
                    <DateInput
                        popup
                        clearButton
                        id={"from"}
                        name="from"
                        label={getDateFromFilterTitle()}
                        value={request.from}
                        onChange={async (value) =>
                            setRequest({...request, from: value})}
                    />

                    <DateInput
                        popup
                        clearButton
                        id={"to"}
                        name="to"
                        label={getDateToFilterTitle()}
                        value={request.to}
                        onChange={async (value) =>
                            setRequest({...request, to: value})
                        }
                    />
                </Inline>

                <Inline className={styles.inline}>
                    <DateInput
                        popup
                        clearButton
                        id={"date"}
                        name="date"
                        label={getDateAtFilterTitle()}
                        value={request.date}
                        onChange={async (value) =>
                            setRequest({
                                ...request,
                                date: value,
                                from: null,
                                to: null,
                            })
                        }
                    />

                    {isRentalGroupFilterEnabled() && (
                        <Dropdown
                            multiple
                            clearButton
                            ref={addRef}
                            id={"rentalObjectGroups"}
                            name={"rentalObjectGroups"}
                            items={filtersData.rentalObjectGroups}
                            label={Localizer.devicesFiltersModalLabelRentalObjectGroups}
                            selectedItems={filtersData.rentalObjectGroups.filter((item) =>
                                request.rentalObjectGroups.includes(item.value),
                            )}
                            onChange={handleDropdownChange}
                            nothingSelectedText={"-"}
                        />
                    )}
                </Inline>

                {hasDeviceStatuses() && (
                    <Inline className={styles.inline}>
                        <Dropdown multiple clearButton
                                  ref={addRef}
                                  id={"deviceStatuses"}
                                  name="deviceStatuses"
                                  label={Localizer.devicesFiltersModalLabelStatus}
                                  nothingSelectedText={"-"}
                                  selectedItems={filtersData.deviceStatuses.filter((item) => request.deviceStatuses.includes(item.value))}
                                  onChange={handleDropdownChange}
                                  items={filtersData.deviceStatuses}
                        />
                    </Inline>
                )}

                {isAnnualInspectionFilter() && (
                    <Inline className={styles.inline}>
                        <Checkbox inline
                                  id={"rentOnly"}
                                  name="rentOnly"
                                  label={Localizer.genericFilterInRentOnly}
                                  value={request.rentOnly}
                                  onChange={async (_, value) => handleInRentOnly(value)}
                        />
                    </Inline>
                )}

                {isAnnualInspectionFilter() && (
                    <Inline className={styles.inline}>
                        <Dropdown autoCollapse clearButton
                                  ref={addRef}
                                  id="annualInspectionStatusFilter"
                                  name="annualInspectionStatusFilter"
                                  label={Localizer.devicesFiltersModalLabelAnnualInspectionStatus}
                                  nothingSelectedText={"-"}
                                  value={request.annualInspectionStatusFilter}
                                  onChange={handleDropdownChange}
                                  items={filtersData.annualInspectionStatusFilter}
                        />

                        <Dropdown autoCollapse clearButton multiple
                                  ref={addRef}
                                  id="annualInspectionTypeFilter"
                                  name="annualInspectionTypeFilter"
                                  label={Localizer.devicesFiltersModalLabelAnnualInspectionReason}
                                  selectedItems={filtersData.annualInspectionTypeFilter.filter((item) => request.annualInspectionTypeFilter.includes(item.value))}
                                  nothingSelectedText={"-"}
                                  onChange={handleDropdownChange}
                                  items={filtersData.annualInspectionTypeFilter}
                        />
                    </Inline>
                )}
                {props.deviceStatusSelection && (
                    <Inline className={styles.inline}>
                        <Dropdown
                            clearButton
                            ref={addRef}
                            items={filtersData.showDeleted}
                            id={"showDeleted"}
                            name={"showDeleted"}
                            label={
                                Localizer.devicesFiltersModalLabelShowDevicesByDeletedStatus
                            }
                            value={request.showDeleted}
                            onChange={handleDropdownChange}
                            selectedItem={itemShowActive}
                        />
                    </Inline>
                )}
                {isReturnInspectionFilter() && (
                    <Inline className={styles.inline}>
                        <Dropdown
                            clearButton
                            ref={addRef}
                            items={filtersData.returnInspectionDetails}
                            id={"returnInspectionDetails"}
                            name={"returnInspectionDetails"}
                            label={Localizer.devicesFiltersModalLabelWashingAndCleaning}
                            onChange={handleDropdownChange}
                            value={request.returnInspectionDetails}
                        />

                        <Dropdown
                            clearButton
                            multiple
                            ref={addRef}
                            id={"returnInspectionStatus"}
                            items={filtersData.returnInspectionStatus}
                            name={"returnInspectionStatus"}
                            label={Localizer.devicesFiltersModalLabelReturnInspectionStatus}
                            selectedItems={filtersData.returnInspectionStatus.filter((item) =>
                                request.returnInspectionStatus.includes(item.value),
                            )}
                            onChange={handleDropdownChange}
                        />
                    </Inline>
                )}

                {isReturnInspectionFilter() &&
                    UnleashHelper.isEnabled(
                        RentaToolsConstants.featureRiCompletedAtFilterEnabled,
                    ) && (
                        <Inline className={styles.inline}>
                            <DateInput
                                popup
                                clearButton
                                id={"completedAtFrom"}
                                name="completedAtFrom"
                                label={Localizer.devicesFiltersModalLabelCompletedAtFrom}
                                value={request.completedAtFrom}
                                onChange={async (value) =>
                                    setRequest({...request, completedAtFrom: value})
                                }
                            />

                            <DateInput
                                popup
                                clearButton
                                id={"completedAtTo"}
                                name="completedAtTo"
                                label={Localizer.devicesFiltersModalLabelCompleteAtTo}
                                value={request.completedAtTo}
                                onChange={async (value) =>
                                    setRequest({...request, completedAtTo: value})
                                }
                            />
                        </Inline>
                    )}

                {isServiceFilter() && (
                    <Inline className={styles.inline}>
                        <Dropdown
                            multiple
                            clearButton
                            ref={addRef}
                            id={"servicesStatus"}
                            name={"serviceStatus"}
                            items={filtersData.servicesStatus}
                            label={Localizer.servicePageFiltersServiceStatus}
                            selectedItems={filtersData.servicesStatus.filter((item) =>
                                request.servicesStatus.includes(item.value),
                            )}
                            onChange={async (sender) => {
                                await handleDropdownChange(sender);
                                handleUpcoming(sender);
                            }}
                        />

                        <Dropdown
                            multiple
                            clearButton
                            ref={addRef}
                            id={"servicesTypes"}
                            name={"servicesTypes"}
                            items={filtersData.servicesTypes}
                            label={Localizer.servicePageFiltersServiceAndRepairFilter}
                            selectedItems={filtersData.servicesTypes.filter((item) =>
                                request.servicesTypes.includes(item.value),
                            )}
                            onChange={handleDropdownChange}
                        />
                    </Inline>
                )}

                {isServiceFilter() &&
                    UnleashHelper.isEnabled(
                        RentaToolsConstants.featureFlagServiceForecastList,
                    ) && (
                        <Inline className={styles.inline}>
                            <Dropdown
                                clearButton
                                ref={addRef}
                                id={"predictionTime"}
                                name={"predictionTime"}
                                items={filtersData.predictionTime}
                                label={Localizer.servicedPageUpcomingFilter}
                                value={request.predictionTime}
                                disabled={!upcomingFilterSelected}
                                onChange={handleDropdownChange}
                            />
                        </Inline>
                    )}

                {props.priorityOrder && (
                    <Inline className={styles.inline}>
                        <Dropdown
                            required
                            ref={addRef}
                            id={"orderBy"}
                            name={"orderBy"}
                            items={filtersData.orderBy}
                            label={Localizer.deviceFiltersModalPriorityOrder}
                            value={request.orderBy.toString()}
                            onChange={handleDropdownChange}
                        />
                    </Inline>
                )}

                <ButtonContainer className={styles.buttons}>
                    <Button
                        id={"applyButton"}
                        submit
                        type={ButtonType.Orange}
                        label={Localizer.devicesFiltersModalButtonApply}
                    />
                    <Button
                        id={"clearButton"}
                        type={ButtonType.Blue}
                        label={Localizer.devicesFiltersModalButtonClear}
                        onClick={async () => await clearAsync()}
                    />
                </ButtonContainer>
            </Form>
        </Modal>
    );
};

export default DevicesFiltersModal;
