import React from "react";
import { ReturnInspectionStatus, MaintenanceStatus, DeviceStatus } from "@/models/Enums";
import ToolsUtility from "@/helpers/ToolsUtility";
import Localizer from "@/localization/Localizer";
import styles from "./ReturnInspectionDevicesList.module.scss";

interface StatusNameProps {
    status: ReturnInspectionStatus | null;
    maintenanceStatus: MaintenanceStatus;
    underInspection: boolean;
    deviceStatus: DeviceStatus;
}

const StatusName: React.FC<StatusNameProps> = ({ status, maintenanceStatus, underInspection, deviceStatus }) => {
    const getStatusName = (): string => {
        if (status === ReturnInspectionStatus.NotCompleted && deviceStatus !== DeviceStatus.ReturnInspectionIsNeeded) {
            return Localizer.returnInspectionNotCompleted;
        }
        if (status !== null) {
            switch (status) {
                case ReturnInspectionStatus.PartlyCompleted:
                    return Localizer.returnInspectionPartlyCompleted;
                case ReturnInspectionStatus.InProgress:
                    return Localizer.returnInspectionInProgress;
            }
        }
        if (!ToolsUtility.hasFlag(maintenanceStatus, MaintenanceStatus.RequiresReturnInspection)) {
            return Localizer.returnInspectionCompleted;
        }
        if (!underInspection) {
            return Localizer.returnInspectionWaitingForReturnInspection;
        }
        if (status === ReturnInspectionStatus.Completed) {
            return Localizer.returnInspectionCompleted;
        }
        return Localizer.returnInspectionWaitingForReturnInspection;
    };

    return <p className={`${styles.fontBold} ${styles.mobileTopMargin}`} id={"inspection_status_text"}>{getStatusName()}</p>;
};

export default StatusName;