import BaseDeviceClearFilters from "./BaseDeviceClearFilters";
import { RequestRawData } from "@/models/RequestRawData";
import UnleashHelper from "../../helpers/UnleashHelper";
import RentaToolsConstants from "../../helpers/RentaToolsConstants";
import { ReturnInspectionStatus } from "@/models/Enums";

class ReturnInspectionClearFilters extends BaseDeviceClearFilters {
  public override clear(data: RequestRawData): RequestRawData {
    const copy = super.clear(data);

    copy.returnInspectionDetails = "";

    if (UnleashHelper.isEnabled(RentaToolsConstants.featureFlagRiMultiUser)) {
      copy.returnInspectionStatus = [
        ReturnInspectionStatus.Waiting.toString(),
        ReturnInspectionStatus.PartlyCompleted.toString(),
        ReturnInspectionStatus.InProgress.toString(),
      ];
    } else {
      copy.returnInspectionStatus = [
        ReturnInspectionStatus.Waiting.toString(),
        ReturnInspectionStatus.InProgress.toString(),
      ];
    }

    copy.completedAtFrom = null;
    copy.completedAtTo = null;

    return copy;
  }
}

export default new ReturnInspectionClearFilters();
