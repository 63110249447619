import React from "react";
import {PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import Localizer from "../../localization/Localizer";
import Account from "@/components/Account/Account";


export default class AccountPage extends AuthorizedPage {

    public render(): React.ReactNode {
        return (
            <PageContainer>

                <PageHeader title={Localizer.myAccountPageTitle}/>

                <Account />

            </PageContainer>
        );
    }
}