import {RequestRawData} from "@/models/RequestRawData";
import {IDeviceClearFilters} from "./IDeviceClearFilters";
import {DeviceListOrder} from "@/models/Enums";

export default abstract class BaseDeviceClearFilters implements IDeviceClearFilters {
    public clear(data: RequestRawData): RequestRawData {
        const copy = {...data};

        copy.depoCostPools = [];
        copy.productGroupIds = [];
        copy.deviceExternalId = "";
        copy.deviceTypes = [];
        copy.date = null;
        copy.from = null;
        copy.to = null;
        copy.rentalObjectGroups = [];
        copy.orderBy = DeviceListOrder.ByPriority;

        return copy;
    }
}