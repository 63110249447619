import BaseListDevicesRequest from "./BaseListDevicesRequest";
import DeviceInfo from "../DeviceInfo";
import {DeviceListOrder, DeviceStatus, PredictionTime, ServiceAndRepairsStatuses, ServiceTypeFilter} from "@/models/Enums";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import {RequestRawData} from "@/models/RequestRawData";

export default class ListUnServicedDevicesRequest extends BaseListDevicesRequest {

    public deviceStatuses: DeviceStatus[] = [];
    
    public pageNumber : number = 0;

    public pageSize : number = 0;

    public serviceStatus : string[] = [];
    
    public servicesTypes : string[] = [];

    public modified : boolean = false;
    
    public predictionTime : PredictionTime = PredictionTime.OneMonth;
    
    public getDate(device: DeviceInfo): Date {
        return device.statusChangedAt;
    }
    
    constructor() {
        super();
        this.serviceStatus = [
            ServiceAndRepairsStatuses.Waiting.toString(),
            ServiceAndRepairsStatuses.InProgress.toString()
        ];
        
        this.servicesTypes = [
            ServiceTypeFilter.Service.toString(),
            ServiceTypeFilter.Repair.toString(),
        ]
    }

    public clear(): void {
        this.deviceStatuses = [];
        this.serviceStatus = [
            ServiceAndRepairsStatuses.Waiting.toString(),
            ServiceAndRepairsStatuses.InProgress.toString()
        ];

        this.servicesTypes = [
            ServiceTypeFilter.Service.toString(),
            ServiceTypeFilter.Repair.toString(),
        ]
        super.clear();
    }

    public nextPage() : void{
        this.pageNumber++;
    }

    public resetPage() : void{
        this.pageNumber = 0;
        this.pageSize = RentaToolsConstants.itemsPerReturnInspectionPage;
    }

    static create(request: RequestRawData) {
        const listUnServicedDevicesRequest = new ListUnServicedDevicesRequest();
        
        listUnServicedDevicesRequest.deviceStatuses =request.deviceStatuses.map(status =>  Number.parseInt(status));
        listUnServicedDevicesRequest.pageNumber = request.pageNumber;
        listUnServicedDevicesRequest.pageSize = request.pageSize;
        listUnServicedDevicesRequest.serviceStatus = request.servicesStatus;
        listUnServicedDevicesRequest.servicesTypes = request.servicesTypes;
        listUnServicedDevicesRequest.modified = request.modified;
        listUnServicedDevicesRequest.predictionTime = request.predictionTime ? Number.parseInt(request.predictionTime) : PredictionTime.OneMonth;
        listUnServicedDevicesRequest.pageNumber = request.pageNumber || 0;
        listUnServicedDevicesRequest.pageSize = request.pageSize || 0;
        listUnServicedDevicesRequest.modified = false;
        listUnServicedDevicesRequest.depoCostPools = request.depoCostPools;
        listUnServicedDevicesRequest.productGroupIds = request.productGroupIds || [];
        listUnServicedDevicesRequest.rentalObjectGroups = request.rentalObjectGroups || [];
        listUnServicedDevicesRequest.deviceExternalId = request.deviceExternalId || "";
        listUnServicedDevicesRequest.deviceTypes = request.deviceTypes || [];
        listUnServicedDevicesRequest.date = request.date || null;
        listUnServicedDevicesRequest.from = request.from || null;
        listUnServicedDevicesRequest.to = request.to || null;
        listUnServicedDevicesRequest.showDeleted = request.showDeleted || "active";
        listUnServicedDevicesRequest.orderBy = request.orderBy || DeviceListOrder.ByPriority;

        return listUnServicedDevicesRequest;
        
    }
}