import { DeviceListOrder } from "@/models/Enums";
import ListUnInspectedDevicesRequest from "@/models/server/requests/ListUnInspectedDevicesRequest";
import ListUnServicedDevicesRequest from "@/models/server/requests/ListUnServicedDevicesRequest";
import ListAnnualInspectionDevicesRequest from "@/models/server/requests/ListAnnualInspectionDevicesRequest";

export interface RequestRawData {
  annualInspectionTypeFilter: string[];
  deviceExternalId: string;
  depoCostPools: string[];
  productGroupIds: string[];
  deviceTypes: string[];
  from: Date | null | undefined;
  to: Date | null | undefined;
  date: Date | null | undefined;
  rentalObjectGroups: string[];
  deviceStatuses: string[];
  annualInspectionStatusFilter: string;
  showDeleted: string;
  returnInspectionDetails: string;
  returnInspectionStatus: string[];
  completedAtFrom: Date | null | undefined;
  completedAtTo: Date | null | undefined;
  servicesStatus: string[];
  servicesTypes: string[];
  predictionTime: string | null;
  orderBy: DeviceListOrder;
  pageSize: number;
  pageNumber: number;
  modified: boolean;
  inspectionType: string;
  rentOnly: boolean;
}

export const emptyRequestRawData: RequestRawData = {
  deviceExternalId: "",
  depoCostPools: [],
  productGroupIds: [],
  deviceTypes: [],
  from: null,
  to: null,
  date: null,
  rentalObjectGroups: [],
  deviceStatuses: [],
  annualInspectionStatusFilter: "",
  showDeleted: "",
  returnInspectionDetails: "",
  returnInspectionStatus: [],
  completedAtFrom: null,
  completedAtTo: null,
  servicesStatus: [],
  servicesTypes: [],
  predictionTime: null,
  orderBy: DeviceListOrder.ByPriority,
  pageNumber: 0,
  pageSize: 0,
  modified: false,
  inspectionType: "",
  annualInspectionTypeFilter: [],
  rentOnly: false
};

export const convertReturnInspectionRequest = (
  request: ListUnInspectedDevicesRequest,
): RequestRawData => {
  const requestData = { ...emptyRequestRawData };

  requestData.deviceExternalId = request.deviceExternalId;
  requestData.depoCostPools = request.depoCostPools;
  requestData.productGroupIds = request.productGroupIds;
  requestData.deviceTypes = request.deviceTypes;
  requestData.from = request.from;
  requestData.to = request.to;
  requestData.date = request.date;
  requestData.rentalObjectGroups = request.rentalObjectGroups;
  requestData.showDeleted = request.showDeleted;
  requestData.returnInspectionDetails = request.returnInspectionDetails;
  requestData.returnInspectionStatus = request.returnInspectionStatus;
  requestData.completedAtFrom = request.completedAtFrom;
  requestData.completedAtTo = request.completedAtTo;
  requestData.orderBy = request.orderBy;
  requestData.pageNumber = request.pageNumber;
  requestData.pageSize = request.pageSize;
  requestData.modified = request.modified;

  return requestData;
};

export const convertUnServicedRequest = (
  request: ListUnServicedDevicesRequest,
): RequestRawData => {
  const requestData = { ...emptyRequestRawData };

  requestData.deviceExternalId = request.deviceExternalId;
  requestData.depoCostPools = request.depoCostPools;
  requestData.productGroupIds = request.productGroupIds;
  requestData.deviceTypes = request.deviceTypes;
  requestData.from = request.from;
  requestData.to = request.to;
  requestData.date = request.date;
  requestData.rentalObjectGroups = request.rentalObjectGroups;
  requestData.showDeleted = request.showDeleted;
  requestData.servicesStatus = request.serviceStatus;
  requestData.servicesTypes = request.servicesTypes;
  requestData.predictionTime = request.predictionTime?.toString();
  requestData.orderBy = request.orderBy;
  requestData.pageNumber = request.pageNumber;
  requestData.pageSize = request.pageSize;
  requestData.modified = request.modified;
  requestData.deviceStatuses = request.deviceStatuses.map((status) =>
    status.toString(),
  );

  return requestData;
};

export const convertAnnualInspectionRequest = (
  request: ListAnnualInspectionDevicesRequest,
): RequestRawData => {
  const requestData = { ...emptyRequestRawData };

  requestData.deviceExternalId = request.deviceExternalId;
  requestData.depoCostPools = request.depoCostPools;
  requestData.productGroupIds = request.productGroupIds;
  requestData.deviceTypes = request.deviceTypes;
  requestData.from = request.from;
  requestData.to = request.to;
  requestData.date = request.date;
  requestData.rentalObjectGroups = request.rentalObjectGroups;
  requestData.showDeleted = request.showDeleted;
  requestData.orderBy = request.orderBy;
  requestData.pageNumber = request.pageNumber;
  requestData.pageSize = request.pageSize;
  requestData.deviceStatuses = request.deviceStatuses.map((status) =>
    status.toString(),
  );
  requestData.inspectionType = request.inspectionType?.toString() ?? "";
  requestData.annualInspectionStatusFilter =
    request.annualInspectionStatusFilter?.toString() ?? "";
  
  requestData.annualInspectionTypeFilter = request.annualInspectionTypeFilter;
  requestData.rentOnly = request.rentOnly;

  return requestData;
};