import {Button, ButtonContainer, ButtonType, EmailInput, Form, IStringInputModel, PageRow, TextAreaInput, TextInput} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import React, {useEffect} from "react";
import User from "@/models/server/User";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import HttpClient from "@/common/HttpClient";
import {ch} from "@renta-apps/athenaeum-react-common";
import {IContactSupportPageParameters} from "@/models/IContactSupportPageParameters";
import ToolsUtility from "@/helpers/ToolsUtility";
import EndpointPaths from "@/common/EndpointPaths";

const ContactSupport = (props: IContactSupportPageParameters) => {

    const email: IStringInputModel = {value: ""};
    const phone: IStringInputModel = {value: ""};
    const firstName: IStringInputModel = {value: ""};
    const lastname: IStringInputModel = {value: ""};
    const message: IStringInputModel = {value: ""};
    const formRef: React.RefObject<any> = React.createRef();

    useEffect(() => {
        const user: User | null = ch.findUser();
        if (user) {
            email.value = user.email || "";
            phone.value = user.phone || "";
            firstName.value = user.firstName || "";
            lastname.value = user.lastName || "";
        }
    }, []);

    const handleSubmitAsync = async (data: Dictionary<string, any>): Promise<void> => {
        let contactSupportRequest = {} as any;
        data.keys().map(key => {
            return contactSupportRequest[key] = data.getValue(key);
        });

        if (!ToolsUtility.isNullOrEmpty(props.requestId)) {
            contactSupportRequest.requestId = props.requestId;
        }

        await HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.Support.ContactSupport, contactSupportRequest);
    }

    return (
        <PageRow>
            <div className="col-lg-12">
                <Form id="form"
                      ref={formRef}
                      onSubmit={async (_, data) => await handleSubmitAsync(data)}>

                    <EmailInput required
                                id="email"
                                label={Localizer.componentFormEmail}
                                model={email}
                                readonly={!!email.value}
                    />

                    <TextInput id="phone"
                               label={Localizer.componentFormPhone}
                               model={phone}
                    />

                    <TextInput id="firstName"
                               label={Localizer.componentFormFirstName}
                               model={firstName}
                    />

                    <TextInput id="lastname"
                               label={Localizer.componentFormLastname}
                               model={lastname}
                    />

                    <TextAreaInput required
                                   id="message"
                                   label={Localizer.componentFormMessage}
                                   model={message}
                                   rows={6}
                    />

                    <ButtonContainer>

                        <Button submit
                                type={ButtonType.Orange}
                                label={Localizer.componentFormSend} />

                    </ButtonContainer>
                </Form>
            </div>
        </PageRow>
    )
}

export default ContactSupport;