class ApplicationSettings {
    private readonly Base = "api/applicationSettings/"
    public readonly SaveSettings = this.Base + "saveSettings";
    public readonly ListSettings = this.Base + "listSettings";
}

class OperatingHoursPaths {
    private readonly Base = "api/OperatingHourRecord/";
    public readonly GetOperatingHoursByDeviceId = this.Base + "GetManyByDeviceId";
}

class FeatureFlagsPaths {
    private readonly Base = "api/LocalFeatureFlag/"
    public readonly GetFeatureToggle = this.Base + "FeatureFlags";
    public readonly UpdateFeatureFlags = this.Base + "UpdateFeatureFlag";
}

class ReportDefinitionPaths {
    private readonly Base = "api/reportDefinition/";
    public readonly Copy = this.Base + "CopyReportDefinition";
    public readonly FindReportDefinition = this.Base + "findReportDefinition";
    public readonly FindServiceReportDefinition = this.Base + "findServiceReportDefinition";
    public readonly GetReportDefinitions = this.Base + "getReportDefinitions";
}

class FilePaths {
    private readonly Base = "/files/";
    public readonly CreatePreSignedUrl = this.Base + "createPreSignedUrl";
    public readonly ConvertImage = this.Base  + "convertImage";
    public readonly FileUploadFailed = this.Base + "fileUploadFailed";
    public readonly FileUploadEnd = (fileName : string) => this.Base + `FileUploadEnd?fileName=${encodeURIComponent(fileName)}`;
}

class AccountPaths {
    private readonly Base = "api/account/";
    public readonly GetNotificationTypes = this.Base + "getUserAvailableNotificationTypes";
    public readonly SaveAccount = this.Base + "saveUserAccount";
}

class Support {
    private readonly Base = "api/contactSupport/";
    public readonly ContactSupport = this.Base + "contactSupport";
}

class ReturnInspectionPaths {
    private readonly Base = "api/ReturnInspection/";
    public readonly SaveReturnInspection = this.Base + "SaveReturnInspection";
    public readonly StartReturnInspection = this.Base + "StartReturnInspection";
    public readonly UpdateOperatingHours = this.Base + "UpdateOperatingHours";
    public readonly StopReturnInspection = this.Base + "StopReturnInspection";
    public readonly CancelReturnInspection = this.Base + "CancelReturnInspection";
    public readonly PreviewReturnInspection = this.Base + "PreviewReturnInspection";
    public readonly SkipReturnInspection = this.Base + "skipReturnInspection";
    public readonly SaveReturnInspectionStep = this.Base + "SaveReturnInspectionStep";
    public readonly ContinueReturnInspection = this.Base + "ContinueReturnInspection";
    public readonly ListDevices = this.Base + "ListDevices";
    public readonly GetUnInspectedDeviceCount = this.Base + "GetUnInspectedDeviceCount";
    public readonly GetOngoingReport = this.Base + "GetOngoingReport";
    public readonly GetReportsByDeviceExternalId = (externalId: string, pageNumber: number, pageSize: number) => this.Base + `GetReportsByDeviceExternalId?deviceExternalId=${externalId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    public readonly GetErrorCodesByDeviceId = (deviceId: string) => this.Base + `GetErrorCodesByDeviceId?deviceExternalId=${deviceId}`;
    public readonly GetReport = (reportId : string) => this.Base + `GetReport?reportId=${reportId}`;
}

class DevicePaths {
    private readonly Base = "api/device/";

    public readonly ListDevices = this.Base + "listdevices";
    public readonly GetDeviceTelematics = this.Base + "GetDeviceTelematics";
    public readonly FindDevice = this.Base + "findDevice";
    public readonly RefreshDevice = this.Base + "refreshDevice";
    public readonly FindDeviceByImage = this.Base + "findDeviceByImage";
    public readonly FindDeviceById = this.Base + "findDeviceById";
    public readonly GetDeviceContracts = this.Base + "getDeviceContracts";
    public readonly GetMaintenanceDevicesFilters = this.Base + "getMaintenanceDevicesFilters";
    public readonly GetServiceDevicesFilters = this.Base + "getServiceDevicesFilters";
    public readonly GetAnnualInspectionDeviceFilters = this.Base + "getAnnualInspectionDeviceFilters";
    public readonly ListUnServicedDevices = this.Base + "listUnServicedDevices";
    public readonly GetUnServicedDeviceCount = this.Base + "GetUnServicedDeviceCount";
    public readonly ListAnnualInspectionDevices = this.Base + "listAnnualInspectionDevices";
    public readonly GetDeviceTypes = this.Base + "getDeviceTypes";
    public readonly ResetDeviceStatus = this.Base + "resetDeviceStatus";
    public readonly GetDeviceFaultsCode = this.Base + "GetDeviceFaultsCode";
    public readonly GetUnInspectedDeviceCount = this.Base + "GetUnInspectedDeviceCount";
    public readonly GetDeviceLocation = this.Base + "getDeviceLocation";
    public readonly GetDevicesInfoToOrder = this.Base + "GetDevicesInfoToOrder";
    public readonly GetInitialServiceDetails = this.Base + "GetInitialServiceDetails";
    public readonly ListDeviceObservations = this.Base + "ListDeviceObservations";
    public readonly GetObservationDetails = this.Base + "GetObservationDetails";
    public readonly GetDeviceMaintenanceDetails = this.Base + "GetDeviceMaintenanceDetails";
    public readonly ChangeOdometer = this.Base + "ChangeOdometer";
    public readonly EditDevice = this.Base + "EditDevice";
    public readonly GetExtendedOperatingHoursInfo = (externalId: string) => this.Base + `GetExtendedOperatingHoursInfo?deviceExternalId=${externalId}`;
}

class AnnualInspectionPaths {
    private readonly Base = "api/annualInspection/";
    public readonly Mappings =  this.Base + "GetAnnualInspectionTypeMappings";
    public readonly VendorInspections = this.Base +  "ListVendorInspections";
    public readonly VendorInspectors = this.Base +  "ListVendorInspectors";
    public readonly ListVendors = this.Base + "ListAnnualInspectionVendors";
    public readonly AddVendor = this.Base + "AddAnnualInspectionVendor";
    public readonly SaveVendor = this.Base + "SaveAnnualInspectionVendor";
    public readonly DeleteVendor = this.Base + "DeleteAnnualInspectionVendor";
    public readonly RestoreVendor = this.Base + "RestoreAnnualInspectionVendor";
    public readonly EditInspection = this.Base + "EditDeviceAnnualInspection";
    public readonly SaveRemarks = this.Base + "SaveRemarksRepaired";
    public readonly DownloadPdf = this.Base + "DownloadInspectionPdf";
    public readonly RemoveBanOfUse = this.Base + "RemoveDeviceBanOfUse";
    public readonly SaveInspection = this.Base + "SaveDeviceAnnualInspection";
    public readonly DeleteInspection = this.Base + "DeleteDeviceAnnualInspection";
    public readonly ListDeviceInspections = this.Base + "ListDeviceAnnualInspections";
    public readonly GetAnnualInspectionSetup = this.Base + "GetDeviceAnnualInspectionSetup";
}

class DepoPaths {
    private readonly Base = "api/depo/";
    public readonly GetUserDepos = this.Base + "getUserDepos";
    public readonly RestoreDepo = this.Base + "restoreDepo";
    public readonly DeleteDepo = this.Base + "deleteDepo";
    public readonly SaveDepo = this.Base + "saveDepo";
    public readonly AddDepo = this.Base + "addDepo";
    public readonly GetDepos = this.Base + "getDepos";
}

class OrderPaths {
    private readonly Base = "api/DeviceOrder/";
    public readonly SaveDeviceOrder = this.Base + "SaveDeviceOrder";
}

class ApplicationPaths {
    private readonly Base = "api/Application/";
    public readonly Logout = this.Base + "Logout";
    public readonly ForgotPassword = this.Base + "ForgotPassword";

}

class ReportPaths {
    private readonly Base = "api/report/";
    public readonly GetFailedReportItems = this.Base + "getFailedReportItems";
    public readonly StartService = this.Base + "startService";
    public readonly StopService = this.Base + "stopService";
    public readonly SaveService = this.Base + "saveService";
    public readonly EditServiceInvoiceDetails = this.Base + "editServiceInvoiceDetails";
    public readonly SkipService = this.Base + "skipService";
    public readonly GetReport = this.Base + "getReport";
    public readonly GetServiceReport = this.Base + "getServiceReport";
    public readonly DownloadReportPdf = this.Base + "downloadReportPdf";
    public readonly ListServiceReports = this.Base + "listServiceReports";
    public readonly GetLastReportId = this.Base + "getLastReportId";
    public readonly GetLastReports = this.Base + "getLastReports";
    public readonly GetOperatingHoursLimits = this.Base + "getOperatingHoursLimits";
    public readonly ListAddableServiceAdditionalExpenses = this.Base + "listAddableServiceAdditionalExpenses";
    public readonly PauseService = this.Base + "pauseService";
    public readonly ContinueService = this.Base + "continueService";
    public readonly CancelPausedService = this.Base + "cancelPausedService";
}

class AdminConsolePaths {
    private readonly Base = "api/adminConsole/";
    public readonly DeleteDatasets = this.Base + "deleteDatasets";
    public readonly DeleteImportJobs = this.Base + "deleteImportJobs";
}

class MappingsPaths {
    private readonly Base = "api/mappings/";
    public readonly GetMappings = this.Base + "getMappings";
    public readonly AddCategoryMapping = this.Base + "addCategoryMapping";
    public readonly DeleteCategoryMapping = this.Base + "deleteCategoryMapping";
    public readonly GetAnnualInspectionTypeMappings = this.Base + "GetAnnualInspectionTypeMappings";
    public readonly AddAnnualInspectionTypeMapping = this.Base + "addAnnualInspectionTypeMapping";
    public readonly DeleteAnnualInspectionTypeMapping = this.Base + "deleteAnnualInspectionTypeMapping";
    public readonly GetAnnualInspectionTypeItemsByProductGroup = this.Base + "getAnnualInspectionTypeItemsByProductGroup";
}

class ClassifierPaths {
    private readonly Base = "api/classifier/";
    public readonly GetServiceExpenses = this.Base + "getServiceExpenses";
    public readonly GetWashingTypes = this.Base + "GetWashingTypes";
    public readonly GetAdBlues = this.Base + "GetAdBlues";
    public readonly GetAdditionalExpenses = this.Base + "GetAdditionalExpenses";
    public readonly GetAdditionalExpensesById = this.Base + "GetAdditionalExpensesById";
    public readonly GetFuelTypes = this.Base + "GetFuelTypes";
    public readonly GetManualFuelStep = this.Base + "GetManualFuelStep";
}

class ChartsPaths {
    private readonly Base = "api/charts/";
    public readonly GetInspectionPassThroughChart = this.Base + "GetInspectionPassThroughChart";
    public readonly GetDevicesInMaintenanceChart = this.Base + "GetDevicesInMaintenanceChart";
}

class ActionPaths {
    private readonly Base = "api/action/";
    public readonly GetServiceActions = this.Base + "getServiceActions";
}

class PhasePaths {
    private readonly Base = "api/Phase/";
    public readonly GetPhases = this.Base + "GetPhases";
}

class InvoicePaths {
    private readonly Base = "api/invoice/";

    public readonly SaveInvoice = this.Base + "SaveInvoice";

    public readonly ProcessReturnInspectionInvoice = this.Base + "processReturnInspectionInvoice";

    public readonly HandleReturnInspectionInvoice = this.Base + "HandleReturnInspectionInvoice";

    public readonly HandleServiceInvoice = this.Base + "HandleServiceInvoice";

    public readonly ProcessServiceInvoice = this.Base + "ProcessServiceInvoice";

    public readonly ListContractInvoices = this.Base + "ListContractInvoices";
}

class ContractPaths {
    private readonly Base = "api/contracts/";

    public readonly ListContracts = this.Base + "ListContracts";

    public readonly ContractDetails = this.Base + "ContractDetails";

    public readonly EditContract = this.Base + "EditContract";

    public readonly SyncContract = this.Base + "SyncContract";
}

class EndpointPaths {
    public static readonly Account = new AccountPaths();
    public static readonly OperatingHours = new OperatingHoursPaths();
    public static readonly LocalFeatureFlags = new FeatureFlagsPaths();
    public static readonly ReportDefinition = new ReportDefinitionPaths();
    public static readonly FilePaths = new FilePaths();
    public static readonly AnnualInspectionPaths = new AnnualInspectionPaths();
    public static readonly DepoPaths = new DepoPaths();
    public static readonly DevicePaths = new DevicePaths();
    public static readonly OrderPaths = new OrderPaths();
    public static readonly ApplicationPaths = new ApplicationPaths();
    public static readonly ReturnInspectionPaths = new ReturnInspectionPaths();
    public static readonly ReportPaths = new ReportPaths();
    public static readonly AdminConsolePaths = new AdminConsolePaths();
    public static readonly MappingsPaths = new MappingsPaths();
    public static readonly ClassifierPaths = new ClassifierPaths();
    public static readonly ChartsPaths = new ChartsPaths();
    public static readonly ActionPaths = new ActionPaths();
    public static readonly PhasePaths = new PhasePaths();
    public static readonly InvoicePaths = new InvoicePaths();
    public static readonly Contracts = new ContractPaths();
    public static readonly Support = new Support();
    public static readonly Settings = new ApplicationSettings();
}

export default EndpointPaths;