import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import Report from "../../pages/Models/Report";
import QuestionReportItem from "../../pages/Models/QuestionReportItem";
import ReportDefinitionItem from "../../pages/Models/ReportDefinitionItem";
import QuestionPicturesReportItem from "../../pages/Models/QuestionPicturesReportItem";
import QuestionResourceReportItem from "../../pages/Models/QuestionResourceReportItem";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import ResourceReportItem from "../../pages/Models/ResourceReportItem";
import PicturesReportItem from "../../pages/Models/PicturesReportItem";
import MeasuringReportItem from "@/pages/Models/MeasuringReportItem";
import {MeasuringOperators, ReportItemType, ResourceItemType} from "@/models/Enums";
import ChecksReportItem from "@/pages/Models/ChecksReportItem";
import CheckItem from "@/pages/Models/CheckItem";
import WashingType from "@/models/server/WashingType";
import FuelType from "@/models/server/FuelType";
import {ButtonType, Icon, IconSize, IconStyle, TextAreaInput} from "@renta-apps/athenaeum-react-components";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import Phase from "@/models/server/Phase";
import Device from "@/pages/Models/Device";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import ToolsUtility from "@/helpers/ToolsUtility";
import AdvancedChecksReportItem from "@/pages/Models/AdvancedChecksReportItem";
import ArsenalPicturesCarousel from "@/components/ArsenalPicturesCarousel/ArsenalPicturesCarousel";
import AdvanceCheckSummary from "@/components/AdvanceCheckSummary/AdvanceCheckSummary";
import Show from "@/components/Show/Show";
import RentaToolsController from "../../pages/RentaToolsController";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "@/localization/Localizer";

import styles from "./SummaryTable.module.scss";

export interface ISummaryTableProps {
    className?: string;
    model: Report;
    fuelTypes: FuelType[];
    washingTypes: WashingType[];
    noFooter?: boolean;
    previewReturnInspection? : boolean;
    viewOnly? : boolean;
    additionalExpensesValid?(item: ReportDefinitionItem): boolean;
}

interface ISummaryTableState {
    containerStates: Dictionary<number, boolean>;
    phases : Phase[];
    groupedItems : (ReportDefinitionItem[])[];
}

export default class NewSummaryTable extends BaseComponent<ISummaryTableProps, ISummaryTableState> {

    state: ISummaryTableState = {
        containerStates: new Dictionary<number, boolean>(),
        phases: [],
        groupedItems: [],

    };

    protected get device(): Device | null {
        return RentaToolsController.device;
    }

    private prepareData(): void {
        if (this.props.viewOnly) {

            //if it's null it's old inspection model
            if (this.props.model.stepFinishedInfo === null) {
                this.props.model.items.forEach(p => p.completed = true);
            }
        }
    }

    async initializeAsync(): Promise<void> {
        this.prepareData();
        if (UnleashHelper.isEnabled(RentaToolsConstants.featureFlagShowPhasesEnabled)) {
            const itemsGroupedBy = this.props.model.items.groupBy(p => p.phase?.name);

            let phases: Phase[] = [];

            itemsGroupedBy.forEach(p => phases.push(p[0].phase!))

            this.setState({
                ...this.state,
                phases: phases,
                groupedItems: itemsGroupedBy
            });
        } else {
            this.setState({
                ...this.state,
                phases: [],
                groupedItems: [this.props.model.items]
            })
        }
    }

    private get report(): Report {
        return this.props.model;
    }

    private get preview(): boolean {
        return RentaToolsController.preview;
    }

    private additionalExpensesValidAsync(item: ReportDefinitionItem): boolean {
        return (this.props.additionalExpensesValid)
            ? this.props.additionalExpensesValid(item)
            : true;
    }

    private getName(stepId: string): string | null {
        const step = this.props.model.stepFinishedInfo?.firstOrDefault(p => p.stepId == stepId);

        if (step) {
            return step.userName;
        }

        return null;
    }

    private getFinishDate(stepId: string): string | null {
        const step = this.props.model.stepFinishedInfo?.firstOrDefault(p => p.stepId == stepId);

        if (step?.finished) {
            return ToolsUtility.toDateString(step.finished);
        }

        return null;
    }

    private async toggleContainerAsync(index: number): Promise<void> {
        const expanded: boolean = (!this.isContainerExpanded(index));
        this.state.containerStates.setValue(index, expanded);
        await this.setState({containerStates: this.state.containerStates});
    }

    private async onCommentClickAsync(index: number, pictures: boolean = false): Promise<void> {
        if (pictures) {
            const item = this.report.items[index] as QuestionPicturesReportItem;
            await RentaToolsController.previewPicturesAsync(this.report, item);
        } else {
            await this.toggleContainerAsync(index);
        }
    }

    private isContainerExpanded(index: number): boolean {
        return (this.state.containerStates.getValue(index) === true);
    }

    private hasPicture(item: PicturesReportItem): boolean {
        return PicturesReportItem.hasPicture(item);
    }

    private formatResourceValue(value: number | null): string {
        if (!value) {
            return '0';
        }

        return Utility.format(`{0:0.0}`, value)
    }

    private async openStepAsync(index: number): Promise<void> {
        if (this.props.previewReturnInspection) {
            await ReturnInspectionController.startReturnInspection(this.device?.externalId!);
        }

        await ReturnInspectionController.openStepAsync(index);
    }

    private get reportingItems(): ReportDefinitionItem[] {
        return this.report.items;
    }

    private renderQuestion(item: QuestionReportItem, index: number): React.ReactNode {
        const title: string = item.title;
        const iconName: string = (item.fixed)
            ? "fas tools"
            : (item.pictures && item.pictures.length > 0)
                ? "fal fa-camera"
                : "far fa-comment-alt-dots";

        let isOkay: boolean = item.ok === true;

        if (item instanceof QuestionResourceReportItem) {
            isOkay = isOkay && QuestionResourceReportItem.isValid(item);
        }

        return (
            <div id={`summary_item_${index}`} data-cy={"summary_item"} className={styles.stepContainer} key={`item_${index}-${item.id}`}>
                <div id={`step_status_${index}`} data-cy={`step_status_${index}`} className={this.css(styles.statusBar,
                    (isOkay && item.completed) && styles.okay,
                    (!isOkay && item.completed) && styles.failed)}
                />
                <div className={styles.summaryItem}>
                    <div onClick={async () => await this.openStepAsync(index)}>
                        <p id="item_title" className={styles.title}> {title.toUpperCase()} </p>
                        <div className={styles.itemDetails}>
                            <p>{this.getName(item.id)}</p>
                            <p>{this.getFinishDate(item.id)}</p>
                        </div>
                    </div>
                    <div className={this.css(styles.iconContainer)}>
                        {(item.isTelematicsStep) && (
                            <Icon name={"fas signal-stream"}
                                  size={IconSize.X2}
                            />
                        )}
                        {(item.commented || item.fixed) && (
                            <Icon name={iconName}
                                  size={IconSize.X2}
                                  onClick={async () => await this.onCommentClickAsync(index)}/>
                        )}
                    </div>
                    <div className={this.css(styles.iconContainer)}>
                        {item.completed && (
                            <Icon name={isOkay ? "far fa-check-circle" : "far fa-times-circle"}
                                  size={IconSize.X2}
                                  className={this.css(styles.checkMarkIcon, !isOkay && styles.checkFailed)}
                            />
                        )}
                        {!item.completed && (
                            <Icon name={"fa-solid fa-hourglass-start"}
                                  size={IconSize.X2}
                                  className={this.css(styles.notCompletedIcon)}
                            />
                        )}
                    </div>

                    {this.renderAdditionalExpenses(item, index)}

                </div>
                {(item.commented) && (this.isContainerExpanded(index)) && (
                    <div className={this.css(styles.extraInfo)}>
                            <Show.When isTrue={Boolean(item.pictures && item.pictures.length > 0)}>
                                <div className={styles.pictureWrapper}>
                                    <ArsenalPicturesCarousel items={item.pictures || []} />
                                </div>
                            </Show.When>
                        <TextAreaInput value={item.comment} readonly/>
                    </div>
                )}
            </div>
        );
    }

    private renderMeasuringResource(item: ReportDefinitionItem, index: number): React.ReactNode {

        const name: string | null = item.name;
        const value: number = (item as ResourceReportItem).value;

        let isOkay = true;

        let comparisonRule = "";
        if (item.type === ReportItemType.MeasuringResource) {
            const measuringItem = item as MeasuringReportItem;
            measuringItem.ok = MeasuringReportItem.isOk(measuringItem);
            let icon = "";
            switch (item.passedValueOperator) {
                case MeasuringOperators.Bigger:
                    icon = ">";
                    break;
                case MeasuringOperators.BiggerOrEqual:
                    icon = "≥";
                    break;
                case MeasuringOperators.Less:
                    icon = "<";
                    break;
                case MeasuringOperators.LessOrEqual:
                    icon = "≤";
                    break;
                case MeasuringOperators.Equal:
                    icon = "=";
                    break;
                case MeasuringOperators.NotEqual:
                    icon = "≠";
                    break;
            }

            comparisonRule = `(${measuringItem.value} ${icon} ${item.passedValue!})`;

            isOkay = measuringItem.ok;
        }

        return (
            <div id={`summary_item_${index}`} data-cy="summary_item" className={styles.stepContainer} key={`item_${index}-${item.id}`}>
                <div id={`step_status_${index}`} data-cy={`step_status_${index}`} className={this.css(styles.statusBar,
                    (item.completed && isOkay) && styles.okay,
                    (item.completed && !isOkay) && styles.failed)}/>
                <div className={styles.summaryItem}>
                    <div onClick={async () => await this.openStepAsync(index)}>
                        <p id="item_title" className={styles.title}> {name?.toUpperCase()} {comparisonRule.toUpperCase()} </p>
                        <div className={styles.itemDetails}>
                            <p>{this.getName(item.id)}</p>
                            <p>{this.getFinishDate(item.id)}</p>
                        </div>
                    </div>
                    <div className={this.css(styles.iconContainer)}>
                        <span>{this.formatResourceValue(value)}</span>
                    </div>
                    <div className={this.css(styles.iconContainer)}>
                        {(item.isTelematicsStep) && (
                            <Icon name={"fas signal-stream"}
                                  size={IconSize.X2}
                            />
                        )}
                        {item.completed && (
                            <Icon name={item.completed ? "far fa-check-circle" : "far fa-times-circle"}
                                  size={IconSize.X2}
                                  className={this.css(styles.checkMarkIcon)}
                            />
                        )}
                        {!item.completed && (
                            <Icon name={"fa-solid fa-hourglass-start"}
                                  size={IconSize.X2}
                                  className={this.css(styles.notCompletedIcon)}
                            />
                        )}
                    </div>

                    {this.renderAdditionalExpenses(item, index)}

                </div>
            </div>
        );
    }

    private getResourceIcon(item: ReportDefinitionItem, isOkay: boolean): string {

        switch (item.resourceType) {
            case ResourceItemType.Fueling:
                return "fas fa-gas-pump";
            case ResourceItemType.Washing:
                return "fas fa-shower";
            default:
                if (!item.completed)
                    return "fa-solid fa-hourglass-start";

                return isOkay ? "far fa-check-circle" : "far fa-times-circle";

        }
    }

    private renderResource(item: ReportDefinitionItem, index: number): React.ReactNode {

        const name: string | null = item.name;
        const value: number = (item as ResourceReportItem).value;

        let isOkay: boolean = ResourceReportItem.isValid(item as ResourceReportItem);

        let valueTypeName: string = this.getValueTypeName(item);

        if (item.type === ReportItemType.QuestionResource) {
            const questionItem = item as QuestionResourceReportItem;
            isOkay = isOkay && questionItem.ok === true;
        }

        return (
            <div id={`summary_item_${index}`} data-cy={"summary_item"} className={styles.stepContainer} key={`item_${index}-${item.id}`}>
                <div id={`step_status_${index}`} data-cy={`step_status_${index}`} className={this.css(styles.statusBar,
                    (isOkay && item.completed) && styles.okay,
                    (!isOkay && item.completed) && styles.failed)}/>
                <div className={styles.summaryItem}>
                    <div onClick={async () => await this.openStepAsync(index)}>
                        <p id="item_title" className={styles.title}> {name?.toUpperCase()} {valueTypeName.toUpperCase()} </p>
                        <div className={styles.itemDetails}>
                            <p>{this.getName(item.id)}</p>
                            <p>{this.getFinishDate(item.id)}</p>
                        </div>
                    </div>
                    <div className={this.css(styles.iconContainer)}>
                        {(item.isTelematicsStep) && (
                            <Icon name={"fas signal-stream"}
                                  size={IconSize.X2}
                            />
                        )}
                        <span>{this.formatResourceValue(value)}</span>
                    </div>
                    <div className={this.css(styles.iconContainer)}>
                        {item.completed && (
                            <Icon name={this.getResourceIcon(item, isOkay)}
                                  size={IconSize.X2}
                                  className={this.css(styles.checkMarkIcon, !isOkay && styles.checkFailed)}
                            />
                        )}
                        {!item.completed && (
                            <Icon name={this.getResourceIcon(item, isOkay)}
                                  size={IconSize.X2}
                                  className={this.css(styles.notCompletedIcon)}
                            />
                        )}
                    </div>

                    {this.renderAdditionalExpenses(item, index)}

                </div>
            </div>
        );
    }

    private renderPictures(item: ReportDefinitionItem, index: number): React.ReactNode {
        const pictureItem = item as PicturesReportItem;
        const isQuestionPicturesItem: boolean = (item.type === ReportItemType.QuestionPictures);

        const title: string = (isQuestionPicturesItem)
            ? item.title || ""
            : item.name || "";

        let iconStyle: string = ((isQuestionPicturesItem) && (item as QuestionPicturesReportItem).fixed)
            ? "fas tools"
            : (this.hasPicture(pictureItem))
                ? "fal fa-camera"
                : "";

        let isOkay = true;

        if (isQuestionPicturesItem) {
            const questionPicturesItem = item as QuestionPicturesReportItem;

            isOkay = questionPicturesItem.ok === true;
        }

        return (
            <div id={`summary_item_${index}`} data-cy={"summary_item"} className={styles.stepContainer} key={`item_${index}-${item.id}`}>
                <div id={`step_status_${index}`} data-cy={`step_status_${index}`} className={this.css(styles.statusBar,
                    (isOkay && item.completed) && styles.okay,
                    (!isOkay && item.completed) && styles.failed)}
                />
                <div className={styles.summaryItem}>
                    <div onClick={async () => await this.openStepAsync(index)}>
                        <p id="item_title" className={styles.title}> {title.toUpperCase()} </p>
                        <div className={styles.itemDetails}>
                            <p>{this.getName(item.id)}</p>
                            <p>{this.getFinishDate(item.id)}</p>
                        </div>
                    </div>
                    <div className={this.css(styles.iconContainer)}>
                        <Icon name={iconStyle}
                              size={IconSize.X2}
                              onClick={async () => await this.onCommentClickAsync(index, true)}/>
                    </div>
                    <div className={this.css(styles.iconContainer)}>
                        <div className={this.css(styles.iconContainer)}>
                            {(item.isTelematicsStep) && (
                                <Icon name={"fas signal-stream"}
                                      size={IconSize.X2}
                                />
                            )}
                            {item.completed && (
                                <Icon name={isOkay ? "far fa-check-circle" : "far fa-times-circle"}
                                      size={IconSize.X2}
                                      className={this.css(styles.checkMarkIcon, !isOkay && styles.checkFailed)}
                                />
                            )}
                            {!item.completed && (
                                <Icon name={"fa-solid fa-hourglass-start"}
                                      size={IconSize.X2}
                                      className={this.css(styles.notCompletedIcon)}
                                />
                            )}
                        </div>
                    </div>

                    {this.renderAdditionalExpenses(item, index)}

                </div>
            </div>
        );
    }

    private renderErrorsCode(item: ReportDefinitionItem, index: number): React.ReactNode {
        const title: string = item.title;

        let isOkay = true;

        return (
            <div id={`summary_item_${index}`} data-cy={"summary_item"} className={styles.stepContainer} key={`item_${index}`}>
                <div id={`step_status_${index}`} data-cy={`step_status_${index}`} className={this.css(styles.statusBar,
                    (isOkay && item.completed) && styles.okay,
                    (!isOkay && item.completed) && styles.failed)}
                />
                <div className={styles.summaryItem}>
                    <div onClick={async () => await this.openStepAsync(index)}>
                        <p id="item_title" className={styles.title}> {title.toUpperCase()} </p>
                        <div className={styles.itemDetails}>
                            <p>{this.getName(item.id)}</p>
                            <p>{this.getFinishDate(item.id)}</p>
                        </div>
                    </div>
                    <div></div>
                    <div className={this.css(styles.iconContainer)}>
                        <div className={this.css(styles.iconContainer)}>
                            <div className={this.css(styles.iconContainer)}>
                                {item.completed && (
                                    <Icon name={isOkay ? "far fa-check-circle" : "far fa-times-circle"}
                                          size={IconSize.X2}
                                          className={this.css(styles.checkMarkIcon, !isOkay && styles.checkFailed)}
                                    />
                                )}
                                {!item.completed && (
                                    <Icon name={"fa-solid fa-hourglass-start"}
                                          size={IconSize.X2}
                                          className={this.css(styles.notCompletedIcon)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    {this.renderAdditionalExpenses(item, index)}

                </div>
            </div>
        );
    }

    private renderChecks(item: ChecksReportItem, index: number): React.ReactNode {
        const title: string = item.title || item.name || "Checks";

        const checks: CheckItem[] = item.checks || [];

        const expanded: boolean = this.isContainerExpanded(index);

        const isOkay = item.checks?.find(p => !p.ok) == null;

        return (
            <div id={`summary_item_${index}`} data-cy={"summary_item"} className={styles.stepContainer} key={`item_${index}-${item.id}`}>
                <div id={`step_status_${index}`} data-cy={`step_status_${index}`} className={this.css(styles.statusBar,
                    (isOkay && item.completed) && styles.okay,
                    (!isOkay && item.completed) && styles.failed)}
                />
                <div className={styles.summaryItem}>
                    <div onClick={async () => await this.openStepAsync(index)}>
                        <p id="item_title" className={styles.title}> {title.toUpperCase()} </p>
                        <div className={styles.itemDetails}>
                            <p>{this.getName(item.id)}</p>
                            <p>{this.getFinishDate(item.id)}</p>
                        </div>
                    </div>
                    <div className={this.css(styles.iconContainer)}>
                        <Icon name={(expanded) ? "fa-chevron-up" : "fa-chevron-down"}
                              size={IconSize.X2}
                              onClick={async () => await this.toggleContainerAsync(index)}/>
                    </div>
                    <div className={this.css(styles.iconContainer)}>
                        <div className={this.css(styles.iconContainer)}>
                            {(item.isTelematicsStep) && (
                                <Icon name={"fas signal-stream"}
                                      size={IconSize.X2}
                                />
                            )}
                            {item.completed && (
                                <Icon name={isOkay ? "far fa-check-circle" : "far fa-times-circle"}
                                      size={IconSize.X2}
                                      className={this.css(styles.checkMarkIcon, !isOkay && styles.checkFailed)}
                                />
                            )}
                            {!item.completed && (
                                <Icon name={"fa-solid fa-hourglass-start"}
                                      size={IconSize.X2}
                                      className={this.css(styles.notCompletedIcon)}
                                />
                            )}
                        </div>

                        {this.renderAdditionalExpenses(item, index)}

                    </div>
                </div>
                {
                    (expanded) &&
                    (
                        <React.Fragment>
                            {
                                checks.map(check => (
                                    <tr>
                                        <td>{check.name}</td>
                                        <td>
                                            {
                                                (check.ok)
                                                    ? <Icon name="fa-check"
                                                            className={styles.ok}
                                                            size={IconSize.Large}/>
                                                    : <Icon name="fa-times"
                                                            className={styles.notOk}
                                                            size={IconSize.Large}/>
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                        </React.Fragment>
                    )
                }
            </div>
        );
    }

    private renderAdvancedChecks(item: AdvancedChecksReportItem, index: number): React.ReactNode {
        const title: string = item.title || item.name || "Checks";

        const checks: CheckItem[] = item.checks || [];

        const expanded: boolean = this.isContainerExpanded(index);

        return (
            <div id={`summary_item_${index}`} data-cy={"summary_item"} className={styles.stepContainer} key={`item_${index}-${item.id}`}>
                <div id={`step_status_${index}`} data-cy={`step_status_${index}`} className={this.css(styles.statusBar,
                    AdvancedChecksReportItem.isOkay(item) && styles.okay,
                    AdvancedChecksReportItem.isFailed(item) && styles.failed,
                    AdvancedChecksReportItem.isSkipped(item) && styles.skipped)}
                />
                <div className={styles.summaryItem}>
                    <div onClick={async () => await this.openStepAsync(index)}>
                        <p id="item_title" className={styles.title}> {title.toUpperCase()} </p>
                        <div className={styles.itemDetails}>
                            <p>{this.getName(item.id)}</p>
                            <p>{this.getFinishDate(item.id)}</p>
                        </div>
                    </div>
                    <div className={this.css(styles.iconContainer)}>
                        <Icon name={(expanded) ? "fa-chevron-up" : "fa-chevron-down"}
                              size={IconSize.X2}
                              id={"extend_status_advancedChecks"}
                              onClick={async () => await this.toggleContainerAsync(index)}/>
                    </div>
                    <div className={this.css(styles.iconContainer)}>
                        <div className={this.css(styles.iconContainer)}>
                            {AdvancedChecksReportItem.isCompleted(item) && (
                                <Icon name={AdvancedChecksReportItem.isOkay(item) ?  "far fa-check-circle" : "far fa-times-circle"}
                                      size={IconSize.X2}
                                      className={this.css(styles.checkMarkIcon, !AdvancedChecksReportItem.isOkay(item) && styles.checkFailed)}
                                />
                            )}
                            {AdvancedChecksReportItem.isNotCompleted(item) && (
                                <Icon name={"fa-solid fa-hourglass-start"}
                                      size={IconSize.X2}
                                      className={this.css(styles.notCompletedIcon)}
                                />
                            )}
                            {AdvancedChecksReportItem.isSkipped(item) && (
                                <Icon name={"fas fa-arrow-to-right"}
                                      size={IconSize.X2}
                                      className={this.css(styles.notCompletedIcon)}
                                />
                            )}
                        </div>
                    </div>

                    {this.renderAdditionalExpenses(item, index)}

                </div>
                {
                    (expanded) &&
                    (
                        <div className={styles.advanceCheckWrapper}>
                            {
                                checks.map((check, index) => (
                                    <AdvanceCheckSummary check={check} key={`${item.id}-${index}`}/>
                                ))
                            }
                        </div>
                    )
                }
            </div>
        );
    }

    private renderAdditionalExpenses(item: ReportDefinitionItem, index: number): React.ReactNode {
        const isValidItem: boolean = this.additionalExpensesValidAsync(item);

        return (
            <>
                {
                    (item.additionalExpenseValues != null && item.additionalExpenseValues.length > 0) &&
                    (
                        <>
                            <div></div>
                                <div id={`additionalExpensesAmount_${index}`} className={this.css(styles.iconContainer)}>
                                    <span className={styles.expensesCount}>{Utility.format(`{0:0.0}`, item.additionalExpenseValues.length)}</span>
                                </div>
                                <div id={`additionalExpensesIconContainer_${index}`} className={this.css(styles.iconContainer)}>

                                    <Icon name={"fal fa-boxes"}
                                          style={IconStyle.Duotone}
                                          size={IconSize.Large}
                                          className={this.css(styles.additionalExpensesIcon, (!isValidItem) && styles.invalid)}
                                    />
                                    {
                                        (!isValidItem) &&
                                        (
                                            <i className={this.css("fas fa-exclamation", styles.invalidAdditionalExpensesExclMark)}></i>
                                        )
                                    }
                                </div>
                        </>
                    )
                }
            </>
        )
    }

    private renderRow(item: ReportDefinitionItem, questionMode: boolean): React.ReactNode {
        const index: number = this.reportingItems.indexOf(item);

        let node: React.ReactNode = (<React.Fragment key={ch.getId()}/>);

        switch (item.type) {
            case ReportItemType.Question:
                node = this.renderQuestion(item as QuestionReportItem, index);
                break;
            case ReportItemType.Resource:
                const resourceItem = item as ResourceReportItem;
                node = this.renderResource(resourceItem, index);
                break;
            case ReportItemType.MeasuringResource:
                const measuringReportItem = item as MeasuringReportItem;
                node = this.renderMeasuringResource(measuringReportItem, index);
                break;
            case ReportItemType.Checks:
                const checksReportItem = item as ChecksReportItem;
                node = this.renderChecks(checksReportItem, index);
                break;
            case ReportItemType.QuestionResource:
                if (questionMode) {
                    node = this.renderQuestion(item as QuestionReportItem, index);
                } else {
                    const questionResourceItem = item as QuestionResourceReportItem;
                    node = this.renderResource(questionResourceItem, index);
                }
                break;
            case ReportItemType.Pictures:
            case ReportItemType.QuestionPictures:
                node = this.renderPictures(item, index);
                break;
            case ReportItemType.ErrorsCodes:
                node = this.renderErrorsCode(item, index);
                break;
            case ReportItemType.AdvancedChecks:
                const advancedChecksReportItem = item as AdvancedChecksReportItem;
                node = this.renderAdvancedChecks(advancedChecksReportItem, index)
                break;
            default:
                break;
        }

        return node;
    }

    private getCompletedItems = (items: ReportDefinitionItem[]) => {
        const completedItems = items.filter(p => p.completed === true);
        return completedItems.length;
    }

    private getProgressStyles = (items: ReportDefinitionItem[]) => {
        const completedItems = this.getCompletedItems(items);
        if (completedItems === items.length) {
            return styles.progressBar;
        }

        return styles.progressBarYellow;
    }

    private getButtonState = (phase: ReportDefinitionItem[]) => {
        const completed = phase.filter(p => p.completed);
        if (completed.length === 0) {
            return Localizer.genericStart;
        }

        if (completed.length === phase.length) {
            return Localizer.genericEdit;
        }

        return Localizer.genericContinue;

    }

    private openPhase = async (phase: ReportDefinitionItem[]) => {
        if (this.preview) {
            await ReturnInspectionController.startReturnInspection(this.device?.externalId!);
        }

        let index = 0;
        const stepNotCompleted = phase.firstOrDefault(p => !p.completed);
        if (stepNotCompleted) {
            index = this.report.items.indexOf(stepNotCompleted);
        } else {
            const firstStep = phase.firstOrDefault();
            if (firstStep) {
                index = this.report.items.indexOf(firstStep);
            }
        }

        await ReturnInspectionController.openStepAsync(index);
    }

    public get isPhaseStartAvailable(): boolean {
        return this.state.phases.length > RentaToolsConstants.phaseMinimalNumber && !this.preview;
    }

    render(): React.ReactNode {
        return (
            <React.Fragment>
                {this.state.groupedItems.map((phase, index) => (
                    <div className={this.css(styles.tableContainer)} key={`phase_${index}`}>
                        <div className={this.css(styles.phase, this.props.className)}>
                            {
                                (this.isPhaseStartAvailable) &&
                                (
                                    <div>
                                        <ArsenalButton block big
                                                       id={"phase_action_button"}
                                                       className={styles.editButton}
                                                       type={ButtonType.Orange}
                                                       label={this.getButtonState(phase)}
                                                       onClick={async () => await this.openPhase(phase)}
                                        />
                                    </div>
                                )
                            }
                            <div className={this.css(styles.phaseTitle)}>
                                {this.state.phases[index]?.name}
                            </div>
                            <div className={this.css(styles.progressInfo)}>
                                {Localizer.summaryPageCompletedSteps} {this.getCompletedItems(phase)}/{phase.length}
                            </div>
                            <progress max={phase.length} value={this.getCompletedItems(phase)} className={this.getProgressStyles(phase)}/>
                            {
                                (phase.map((item) => this.renderRow(item, false)))
                            }
                        </div>
                    </div>
                ))}
            </React.Fragment>
        );
    }

    private getValueTypeName(item: ReportDefinitionItem): string {
        let valueTypeId = item.valueTypeId;
        let valueTypeName: string = "";
        let washingType: WashingType = this.props.washingTypes.filter(washingType => washingType.id == valueTypeId)[0];
        let fuelType: FuelType = this.props.fuelTypes.filter(fuelType => fuelType.id == valueTypeId)[0];

        if (washingType != null) {
            valueTypeName = `(${washingType.name})`;
        }
        if (fuelType != null) {
            valueTypeName = `(${fuelType.name})`;
        }

        return valueTypeName;
    }
};