import React, {useEffect, useState} from "react";
import {ChangeOdometerModalProps} from "@/components/ChangeOdometerModal/ChangeOdometerModal.types";
import {Button, ButtonType, DateInput, Modal, NumberInput} from "@renta-apps/athenaeum-react-components";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {DeviceCounterType} from "@/models/Enums";
import Localizer from "@/localization/Localizer";
import styles from './ChangeOdometerModal.module.scss';
import {ChangeOdometerRequest} from "@/models/server/requests/ChangeOdometerRequest";
import HttpClient from "@/common/HttpClient";
import EndpointPaths from "@/common/EndpointPaths";
import OperatingHoursService from "@/services/OperatingHoursService";
import {ChangeOdometerResponse} from "@/models/server/responses/ChangeOdometerResponse";
import RentaToolsController from "@/pages/RentaToolsController";

const ChangeOdometerModal = ({ modalRef, device } : ChangeOdometerModalProps) => {
    
    const [totalOperatingHours, setTotalOperatingHours] = useState<number>(0);
    
    const [odometerChangeDate, setOdometerChangeDate] = useState<Date>(Utility.today());

    const [odometerChange, setOdometerChange] = useState<number>(0);

    useEffect(() => {
        if(device) {
            const totalOperatingHours = device.counters?.find(p=>p.type === DeviceCounterType.TotalOperatingHours)?.value;
            
            if(totalOperatingHours) {
                setTotalOperatingHours(OperatingHoursService.getLatestValue(device));
            }
        }
    }, []);
    
    const onSave = async () => {
        if(device) {
            const request: ChangeOdometerRequest = {
                deviceExternalId: device.externalId,
                lastOdometerRead: totalOperatingHours,
                newOdometerRead: odometerChange,
                recordedDate: odometerChangeDate
            }
            
            const response = await HttpClient.postAsync<ChangeOdometerResponse>(EndpointPaths.DevicePaths.ChangeOdometer, request);
            
            if(response.success) {
                RentaToolsController.device = response.device;
                RentaToolsController.saveContext();
            }

            onClose();
        }
    }
    
    const onClose = () => {
        modalRef.current?.closeAsync();
    }
    
    return (
        <Modal id={"changeOdometerModal"}
                title={Localizer.odometerChangeModalTitle}
                ref={modalRef}
                closeConfirm={false}
        >
            <div className={styles.inputContainer}>
                <DateInput popup
                           className={styles.date}
                           label={Localizer.odometerChangeModalDate}
                           value={odometerChangeDate}
                           onChange={async (value) => setOdometerChangeDate(value)}
                />
            </div>
            
            <div className={styles.inputContainer}>
                <NumberInput required
                             label={Localizer.odometerChangeModalLastReading}
                             format={"0.0"}
                             step={0.5}
                             min={0}
                             max={999999}
                             value={totalOperatingHours}
                             onChange={async (sender, value, userInteraction, done) => setTotalOperatingHours(value)}
                />
            </div>
            
            <div className={styles.inputContainer}>
                <NumberInput required
                             label={Localizer.odometerChangeModalCurrentReading}
                             format={"0.0"}
                             step={0.5}
                             min={0}
                             max={999999}
                             value={odometerChange}
                             onChange={async (sender, value, userInteraction, done) => setOdometerChange(value)}
                />
            </div>    

                <div className={styles.buttonContainer}>
                    <Button
                        id={"saveButton"}
                        type={ButtonType.Orange}
                        label={Localizer.genericSave}
                        onClick={async () => onSave()}
                    />

                    <Button
                        id={"closeButton"}
                        type={ButtonType.Blue}
                        label={Localizer.genericCancel}
                        onClick={async () => onClose()}
                    />
                </div>

        </Modal>
    )
}


export default ChangeOdometerModal;