import Localizer from "../../../localization/Localizer";
import DashboardPage from "@/tests/pages/DashboardPage";
import DevicePage from "@/tests/pages/DevicePage";
import DeviceAnnualInspectionFormPage from "@/tests/pages/DeviceAnnualInspectionFormPage";
import {executeWithIntercept} from "@/helpers/CypressHelper";
import AdminPage from "@/tests/pages/AdminPage";
import DataManagementPage from "@/tests/pages/DeviceManagementPage";
import DeviceManagementPage from "@/tests/pages/DeviceManagementPage";

describe('Device new annual inspection form tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.login();
        cy.goToDashboard();
    });

    it('Accept test, second time prompts confirm', () => {

        DashboardPage.openDevice("90011");

        executeWithIntercept(() => {
            DevicePage.elements.recordAnnualInspectionBtn().click();
        }, [DeviceAnnualInspectionFormPage.routes.getDeviceAnnualInspectionSetup])


        executeWithIntercept(() => {
            DeviceAnnualInspectionFormPage.submitAnnualInspection();
        }, [DeviceAnnualInspectionFormPage.routes.saveAnnualInspection, DeviceAnnualInspectionFormPage.routes.findDevice])

        cy.wait(1500);

        executeWithIntercept(() => {
            DevicePage.elements.recordAnnualInspectionBtn().click();
        }, [DeviceAnnualInspectionFormPage.routes.getDeviceAnnualInspectionSetup])

        DeviceAnnualInspectionFormPage.submitAnnualInspection();

        cy.wait(1500);

        DeviceAnnualInspectionFormPage.elements.confirmationDialogConfirmBtn().should('be.visible');
    });

    it('Accept with remarks test', () => {
        DashboardPage.openDevice("90011");

        executeWithIntercept(() => {
            DevicePage.elements.recordAnnualInspectionBtn().click();
        }, [DeviceAnnualInspectionFormPage.routes.getDeviceAnnualInspectionSetup])

        DeviceAnnualInspectionFormPage.assertAndFillForm();
        DeviceAnnualInspectionFormPage.assertAndFillRemarks();
        DeviceAnnualInspectionFormPage.submitForm();
    });

    it('Accept with remarks, fixing remarks clears ban of use', () => {
        DashboardPage.openDevice("900112");

        executeWithIntercept(() => {
            DevicePage.elements.recordAnnualInspectionBtn().click();
        }, [DeviceAnnualInspectionFormPage.routes.getDeviceAnnualInspectionSetup])

        DeviceAnnualInspectionFormPage.assertAndFillForm();
        DeviceAnnualInspectionFormPage.assertAndFillRemarks();

        DeviceAnnualInspectionFormPage.elements.banOfUseCheckbox().click();

        executeWithIntercept(() => {
            DeviceAnnualInspectionFormPage.submitForm();
        }, [DeviceAnnualInspectionFormPage.routes.saveAnnualInspection])

        cy.wait(1500);

        DevicePage.elements.banOfUseInfo().contains(`${Localizer.deviceAnnualInspectionPageDeviceUseBan}: ${Localizer.yes}`);
        DevicePage.elements.banOfUseBanner().contains(Localizer.bannerDeviceUseBanRemarks)

        cy.goToDashboard();
        cy.goToAdminPage();

        executeWithIntercept(() => {

            AdminPage.elements.deviceManagement().click();

        }, [DataManagementPage.routes.listDevices]);

        DeviceManagementPage.searchDevice("900112");

        cy.wait(500);

        DeviceManagementPage.elements.removeBanOfUse().trigger('click');

        DeviceManagementPage.elements.removeBanOfUseConfirmDialog()
            .should('be.visible')
            .find('[data-dismiss]')
            .eq(2)
            .trigger('click');

        cy.goToDashboard();

        DashboardPage.openDevice("900112");

        DevicePage.assertNoBan();
        DevicePage.elements.banOfUseBanner().should('not.exist');
    });
});