import styles from './BadgeContainer.module.scss';
import {Icon} from "@renta-apps/athenaeum-react-components";
import React, {PropsWithChildren} from "react";
import Show from "@/components/Show/Show";
export interface BadgeContainerProps
{
    addBadge: () => void;
    renderAddBadge: boolean
}


const BadgeContainer = ({addBadge, children,renderAddBadge} : PropsWithChildren<BadgeContainerProps>) => (
    <div className={styles.badgeContainer}>
        {children}
        <Show.When isTrue={renderAddBadge}>
            <div className={styles.addIcon} onClick={addBadge}>
                <Icon
                    name={"fal fa-plus"}
                />
            </div>
        </Show.When>
    </div>
)
export default BadgeContainer;

