import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {PageContainer} from "@renta-apps/athenaeum-react-components";
import ContractDetailsPageParameters from "@/models/ContractDetailsPageParameters";
import ToolsUtility from "@/helpers/ToolsUtility";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import ContractDetails from "@/components/ContractDetails/ContractDetails";

interface IContractDetailsPageProps {
}

interface IContractDetailsPageState {
    contractId: string | null;
    contractExternalId: string | null;
}

export default class ContractDetailsPage extends AuthorizedPage<IContractDetailsPageProps, IContractDetailsPageState> {
    state: IContractDetailsPageState = {
        contractId: null,
        contractExternalId: null,
    };

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const params = this.parameters as ContractDetailsPageParameters;

        if (ToolsUtility.isNullOrEmpty(params?.contractExternalId)) {
            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute);
        }
    }

    private get contractId(): string {
        const params = this.parameters as ContractDetailsPageParameters;
        return params.contractId;
    }

    private get contractExternalId(): string {
        const params = this.parameters as ContractDetailsPageParameters;
        return params.contractExternalId;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>

                <ContractDetails contractId={this.contractId} contractExternalId={this.contractExternalId}/>

            </PageContainer>
        )
    }
}