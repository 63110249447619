import React from "react";
import {
    PageContainer,
    PageHeader,
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import Localizer from "../../localization/Localizer";
import rentaToolsStyles from "../RentaTools.module.scss";
import DeposList from "@/components/DeposList/DeposList";

export default class DeposPage extends AuthorizedPage {
    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert}>
                <PageHeader title={Localizer.deposPageTitle} subtitle={Localizer.deposPageSubtitle}/>

                <DeposList />
            </PageContainer>
        );
    }
}