import React, {useEffect, useRef, useState} from "react";
import styles from "./DeposList.module.scss";
import {
    Button,
    ButtonType, CellAction,
    CellModel,
    Checkbox,
    ColumnDefinition,
    ColumnType,
    EmailValidator,
    Grid,
    GridHoveringType, GridModel,
    GridOddType,
    IconSize,
    Inline,
    JustifyContent, PageRow, PhoneValidator,
    RowModel,
    ToolbarContainer
} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import Depo from "@/models/server/Depo";
import {ch, TextAlign} from "@renta-apps/athenaeum-react-common";
import {ActionType} from "@/models/Enums";
import {GeoLocation, IPagedList, Utility} from "@renta-apps/athenaeum-toolkit";
import ListDeposRequest from "@/models/server/requests/ListDeposRequest";
import AddDepoRequest from "@/models/server/requests/AddDepoRequest";
import SaveDepoResponse from "@/models/server/responses/SaveDepoResponse";
import SaveDepoRequest from "@/models/server/requests/SaveDepoRequest";
import User from "@/models/server/User";
import EndpointPaths from "@/common/EndpointPaths";
import HttpClient from "@/common/HttpClient";

const DeposList = () => {

    const _deposGridRef = useRef<Grid<Depo>>(null);
    const [deposGrid, setDeposGrid] = useState<GridModel<Depo> | null>(null);

    useEffect(() => {
        if (_deposGridRef.current) {
            setDeposGrid(_deposGridRef.current.model);
        }
    }, [_deposGridRef.current]);

    const _deposColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 50,
            noWrap: true,
            className: "grey",
            textAlign: TextAlign.Center
        },
        {
            header: Localizer.deposPageGridNameLanguageItemName,
            accessor: "name",
            minWidth: 150,
            type: ColumnType.Text,
            editable: true,
            noWrap: true,
            reRenderRow: true,
            settings: {
                required: true
            }
        },
        {
            header: Localizer.deposPageGridCostPoolLanguageItemName,
            accessor: "costPool",
            minWidth: 70,
            type: ColumnType.Text,
            editable: true,
            noWrap: true,
            reRenderRow: true,
            settings: {
                required: true
            }
        },
        {
            header: Localizer.deposPageGridEmailLanguageItemName,
            accessor: "email",
            minWidth: 150,
            type: ColumnType.Text,
            editable: true,
            noWrap: true,
            reRenderRow: true,
        },
        {
            header: Localizer.deposPageGridPhoneLanguageItemName,
            accessor: "phone",
            minWidth: 150,
            type: ColumnType.Text,
            editable: true,
            noWrap: true,
            reRenderRow: true,
        },
        {
            header: Localizer.deposPageGridAddressLanguageItemName,
            accessor: "location.formattedAddress",
            minWidth: 300,
            type: ColumnType.Address,
            editable: true,
            noWrap: true,
            init: (cell: CellModel<Depo>) => initAddressColumn(cell)
        },
        {
            stretch: true,
            minWidth: "6rem",
            removable: false,
            init: (cell) => initDepoOperationsAsync(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.deposPageGridSaveLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await processDepoOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.deposPageGridCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await processDepoOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.deposPageGridDeleteLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell) => getDeleteConfirmation(cell),
                    callback: async (cell, action) => await processDepoOperationAsync(cell, action)
                },
                {
                    name: "restore",
                    title: Localizer.deposPageGridRestoreLanguageItemName,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => await processDepoOperationAsync(cell, action)
                }
            ]
        }
    ];

    const [showDeleted, setShowDeleted] = useState(false);

    const getDeposAsync = async (pageNumber: number, pageSize: number): Promise<IPagedList<Depo>> => {
        const request = new ListDeposRequest();
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.showDeleted = showDeleted;

        return await HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.DepoPaths.GetDepos, request);
    }

    const initRow = (row: RowModel<Depo>): void => {
        const model: Depo = row.model;
        const isNew: boolean = (!model.id);
        const isValid: boolean = isSelectionValid(model);
        row.deleted = model.deleted;
        row.className = (!isValid)
            ? "danger"
            : (isNew)
                ? "bg-processed"
                : "";
    }

    const initAddressColumn = (cell: CellModel<Depo>): void => {
        const model: Depo = cell.row.model;
        if (model.location == null) {
            model.location = new GeoLocation();
            cell.bind();
        }
    }

    const isSelectionValid = (depo: Depo): boolean => {
        const depoName: string | null = depo.name?.trim() ?? null;
        const depoCostPool: string | null = depo.costPool?.trim() ?? null;

        let isValid: boolean = (!!depoCostPool) && (!!depoName);
        isValid = isValid && ((!depo.email || !EmailValidator.instance.validate(depo.email)));
        isValid = isValid && ((!depo.phone || !PhoneValidator.instance.validate(depo.phone)));
        return isValid;
    }

    const newRowAlreadyExists = (): boolean => {
        return (deposGrid?.rows.some(row => !row.deleted && !row.model.id)) ?? false;
    }

    const addDepoAsync = async (): Promise<void> => {
        if (deposGrid && !newRowAlreadyExists()) {
            const depo = new Depo();

            const newRows: RowModel<Depo>[] = await deposGrid.insertAsync(0, depo);

            const newRow: RowModel<Depo> = newRows[0];
            const nameCell: CellModel<Depo> = newRow.get("name");

            await nameCell.editAsync(true);
        }
    }

    const initDepoOperationsAsync = async (cell: CellModel<Depo>): Promise<void> => {

        const model: Depo = cell.row.model;

        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const isValid: boolean = isSelectionValid(model);
        const isNew: boolean = !model.id;

        const saveAction: CellAction<Depo> = cell.actions[0];
        const cancelAction: CellAction<Depo> = cell.actions[1];
        const deleteAction: CellAction<Depo> = cell.actions[2];
        const restoreAction: CellAction<Depo> = cell.actions[3];

        saveAction.visible = (modified) && (isValid);
        cancelAction.visible = (modified) && (!isNew);
        deleteAction.visible = (!deleted) && ((!modified) || (isNew));
        restoreAction.visible = (deleted);
    }

    const getDeleteConfirmation = (cell: CellModel<Depo>): string => {
        const model: Depo = cell.model;
        const isNew: boolean = !model.id;
        return (isNew)
            // Grid types require returned value to be a string, but has been like this forever
            ? null as any
            : Utility.format(Localizer.deposPageConfirmationButtonDelete, `'${cell.model.name} (${cell.model.costPool})'`);
    }

    const processDepoOperationAsync = async (cell: CellModel<Depo>, action: CellAction<Depo>): Promise<void> => {

        await ch.hideAlertAsync();

        const model: Depo = cell.model;
        const isNew: boolean = (!model.id);

        if (action.action.name === "save") {

            if (isNew) {

                const request = new AddDepoRequest();
                request.name = model.name!;
                request.costPool = model.costPool!;
                request.email = model.email;
                request.phone = model.phone;
                request.formattedAddress = (model.location) ? model.location.formattedAddress : null;

                const response: SaveDepoResponse = await HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.DepoPaths.AddDepo, request);

                if (response.depoAlreadyExists) {
                    await ch.alertErrorAsync(Utility.format(Localizer.deposPageAlertErrorAsyncDepotExist, model.name, model.costPool), true);
                    return;
                }

                cell.row.model = response.depo!;

                await cell.row.saveAsync();

            } else {

                const request = new SaveDepoRequest();
                request.id = model.id;
                request.name = model.name!;
                request.costPool = model.costPool!;
                request.email = model.email;
                request.phone = model.phone;
                request.formattedAddress = (model.location) ? model.location.formattedAddress : null;

                const response: SaveDepoResponse = await HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.DepoPaths.SaveDepo, request);

                if (response.depoAlreadyExists) {
                    await ch.alertErrorAsync(Utility.format(Localizer.deposPageAlertErrorAsyncDepotExist, model.name, model.costPool), true);
                    return;
                }

                cell.row.model = response.depo!;

                await cell.row.bindAsync();
            }

            await cell.row.bindAsync();

        } else if (action.action.name === "cancel") {

            await cell.row.cancelAsync();

        } else if (action.action.name === "delete") {

            model.deleted = true;
            model.deletedAt = new Date();
            model.deletedBy = ch.getUser<User>();

            if (isNew) {
                await cell.grid.deleteAsync(cell.row.index);
            } else {

                await HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.DepoPaths.DeleteDepo, model.id);

                await cell.row.setDeletedAsync(true);
            }

        } else if (action.action.name === "restore") {

            const restoreOnServer: boolean = !isNew;

            if (restoreOnServer) {
                await HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.DepoPaths.RestoreDepo, model.id);

                model.deleted = false;
                model.deletedAt = null;
                model.deletedBy = null;
            }

            await cell.row.setDeletedAsync(false);
        }
    }

    const setShowDeletedAsync = async (showDeleted: boolean): Promise<void> => {
        setShowDeleted(showDeleted);
        await reloadAsync();
    }

    const reloadAsync = async (): Promise<void> => {
        await deposGrid?.reloadAsync();
    }

    return (
        <PageRow>
            <div className="col">

                <div className={styles.container}>

                    <ToolbarContainer className={styles.toolbar}>


                        <Inline justify={JustifyContent.End}>

                            <Checkbox inline
                                      label={Localizer.genericShowDeleted}
                                      value={showDeleted}
                                      onChange={(_, value) => setShowDeletedAsync(value)}
                            />

                            <Button title={Localizer.deposPageButtonReload} className="ml-1"
                                    icon={{name: "far history", size: IconSize.Large}}
                                    type={ButtonType.Info}
                                    onClick={async () => await reloadAsync()}
                            />

                            <Button icon={{name: "plus", size: IconSize.Large}}
                                    type={ButtonType.Orange}
                                    title={Localizer.deposPageButtonAddDepot}
                                    onClick={() => addDepoAsync()}
                            />

                        </Inline>

                    </ToolbarContainer>

                    <Grid responsive
                          className={styles.depoGrid}
                          ref={_deposGridRef}
                          pagination={10}
                          hovering={GridHoveringType.EditableCell}
                          odd={GridOddType.None}
                          minWidth="auto"
                          columns={_deposColumns}
                          initRow={(row) => initRow(row)}
                          fetchData={(_, pageNumber, pageSize) => getDeposAsync(pageNumber, pageSize)}
                    />

                </div>

            </div>
        </PageRow>
    );
}

export default DeposList;